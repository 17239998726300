import { emptyImg } from "../../assets/img";

const NoData = () => {
  return (
    <div className="w-100 d-grid mt-5">
      <div className="d-flex ac-jc">
        <img src={emptyImg} className="noDataImg mt-5" />
      </div>
      <div className="d-flex ac-jc">
        <p className="f3 fs-xs-10 mt-4 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 gray">
          There are no vendors to be shown.
        </p>
      </div>
    </div>
  );
};

export default NoData;
