import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import { useState } from "react";
import { cityList, countriesList, stateList } from "../../redux/api/Country";

const EditAddressPopup = ({
  showPopupHander,
  setAddressPopup,
  addressName,
  setAddressName,
  companyName,
  setCompanyName,
  landmark,
  setLandMark,
  street,
  setStreet,
  city,
  setCity,
  country,
  setCountry,
  state,
  setState,
  pincode,
  setPincode,
  addressType,
  setAddressType,
  addressNameErr,
  companyNameErr,
  landmarkErr,
  streetErr,
  cityErr,
  countryErr,
  stateErr,
  pincodeErr,
  addressTypeErr,
  btnAdd,
  submitHandleAddress,
  stateId,
  setStateId,
  countryId,
  setCountryId,
  listCity,
  setListCity,
  listStates,
  setListStates,
}) => {
  const getCounty = (even) => {
    let countryFind = countriesList?.find((i) => i?.id == even);
    setCountry(countryFind?.name);
    setCountryId(even);
    let finder = stateList?.filter((i) => i?.country_id == even);
    setListStates(finder);
    setStateId("");
    setState("");
  };

  const getCity = (even) => {
    let stateFind = listStates?.find((i) => i?.id == even);
    setState(stateFind?.name);
    setStateId(even);
    let finder = cityList?.filter((i) => i?.state_id == even);
    setListCity(finder);
    setCity("");
  };

  return (
    <div className="modal-popup">
      <div onClick={showPopupHander} className="back-close" />
      <div className="center-content-address p-4">
        <div className="w-100 d-flex ac-jb">
          <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            Add Address
          </p>
          <CloseIcon
            onClick={() => showPopupHander(false)}
            className="pointerView white p-1 rounded-5 f2 bg-primar fs-xs-28 fs-sm-28 fs-md-28 fs-lg-28 fs-xl-28 fs-xxl-25"
          />
        </div>
        <div className="w-100 overflow-scroll addScrollPop">
          <div className="w-100 mt-lg-4 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Address Name{" "}
                <span className="gray f2 fs-xs-6 fs-sm-7 fs-md-8 fs-lg-9 fs-xl-10 fs-xxl-11 mt-1 ">
                  (e.g Head Office)
                </span>
              </p>
              <input
                className="editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                onChange={(e) => setAddressName(e.target.value)}
                value={addressName}
              />
              {addressName?.length == 0 && addressNameErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Address </p>
                </div>
              )}
            </div>
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Company Name
              </p>
              <input
                className=" editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                onChange={(e) => setCompanyName(e.target.value)}
                value={companyName}
              />
              {companyName?.length == 0 && companyNameErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Company Name </p>
                </div>
              )}
            </div>
          </div>
          <div className="w-100 mt-lg-4 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Landmark
              </p>
              <input
                className="editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                onChange={(e) => setLandMark(e.target.value)}
                value={landmark}
              />
              {landmark?.length == 0 && landmarkErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Landmark</p>
                </div>
              )}
            </div>
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Street
              </p>
              <input
                className=" editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                onChange={(e) => setStreet(e.target.value)}
                value={street}
              />
              {street?.length == 0 && streetErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Street</p>
                </div>
              )}
            </div>
          </div>
          <div className="w-100 mt-lg-4 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Country
              </p>
              <select
                placeholder=""
                className="w-90 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3 mt-2"
                name=""
                id=""
                onChange={(e) => getCounty(e.target.value)}
                value={countryId}
              >
                <option value={""} disabled>
                  Select Country
                </option>
                {countriesList?.map((item, ind) => {
                  return (
                    <option value={item?.id} key={ind}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
              {country?.length == 0 && countryErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select Country</p>
                </div>
              )}
            </div>
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                State/Province
              </p>
              <select
                placeholder=""
                className="w-90 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3 mt-2"
                name=""
                id=""
                onChange={(e) => getCity(e.target.value)}
                value={stateId}
              >
                <option value={""} disabled>
                  Select State
                </option>
                {listStates?.map((item, ind) => {
                  return (
                    <option value={item?.id} key={ind}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
              {state?.length == 0 && stateErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select State</p>
                </div>
              )}
            </div>
          </div>
          <div className="w-100 mt-lg-4 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                City
              </p>
              <select
                placeholder=""
                className="w-90 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3 mt-2"
                name=""
                id=""
                onChange={(e) => setCity(e.target.value)}
                value={city}
              >
                <option value={""} disabled>
                  Select City
                </option>
                {listCity?.map((item, ind) => {
                  return (
                    <option value={item?.name} key={ind}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
              {city?.length == 0 && cityErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select City</p>
                </div>
              )}
            </div>
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Postal Zip Code
              </p>
              <input
                placeholder=""
                type={"number"}
                className=" editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                onChange={(e) => setPincode(e.target.value)}
                value={pincode}
              />
              {pincode?.length == 0 && pincodeErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Postal Zip Code</p>
                </div>
              )}
            </div>
          </div>
          <div className="w-100 mt-lg-4 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Address Type
              </p>
              <select
                placeholder=""
                className="w-90 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3 mt-2"
                name=""
                id=""
                onChange={(e) => setAddressType(e.target.value)}
                value={addressType}
              >
                <option value={""} disabled>
                  Select Address Type
                </option>
                <option value={1}>Shipping</option>
                <option value={2}>Billing</option>
                <option value={3}>Both</option>
              </select>
              {addressType?.length == 0 && addressTypeErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select Address Type</p>
                </div>
              )}
            </div>
          </div>
          <div className="d-flex ac-jc mb-4">
            <button
              className={`cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                btnAdd && "opacity-50"
              }`}
              onClick={() => submitHandleAddress()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAddressPopup;
