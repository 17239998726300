import { Search } from "@mui/icons-material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import manImage from "../../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import LogoutPopup from "../Popup/LogoutPopup";

const ProductSearchHeader = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const [logAction, setLogAction] = useState(false);
  const [logoutPopupShow, setLogoutPopupShow] = useState(false);

  const toggleLogoutPopup = () => {
    setLogoutPopupShow(!logoutPopupShow);
    setLogAction(false);
  };

  return (
    <div className="d-flex w-100 searchField p-2 ">
      {logoutPopupShow && <LogoutPopup onCancelClick={toggleLogoutPopup} />}
      <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
      <input
        className="cust-btn ms-4 w-100"
        placeholder="Enter Project Name, Client Name, number or PO# to search"
      />
      <div className="position-relative">
        <img
          src={user?.image_url ? user?.image_url : manImage}
          className="searchManImg rounded-5"
          role={"button"}
          onClick={() =>
            navigate("/view-profile", {
              state: { type: "view", data: user },
            })
          }
          // onClick={() => setLogAction(!logAction)}
        />

        {logAction && (
          <div
            className="bg-light pointerView px-2 py-2 ac-js rounded-3 position-absolute mt-2"
            style={{ marginRight: "20%" }}
          >
            <button
              className="border-0 bg-transparent"
              onClick={() => toggleLogoutPopup()}
            >
              Logout
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductSearchHeader;
