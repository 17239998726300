import MyProductListComp from "../ProductComp/MyProductListComp";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import DropdownTap from "../ProductComp/DropdownTap";
import UploadIcon from "@mui/icons-material/Upload";
import MapProductListComp from "../ProductComp/MapProductListComp";
import RequestListComp from "../ProductComp/RequestListComp";
import NewProductSuggestion from "../ProductComp/NewProductSuggestion";
import UploadImages from "../ProductComp/UploadImages";
import GetAppIcon from "@mui/icons-material/GetApp";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../redux/slice/loaderNoti";
import {
  useLazyCategoryListsQuery,
  usePriceListMutation,
  useProductSuggestionListMutation,
  useProductSuggestionStoreMutation,
  useRequestListMutation,
} from "../../redux/api/api";
import {
  useMapProductsAcceptedListsMutation,
  useMapProductsListsMutation,
} from "../../redux/api/api";
import PriceChange from "./PriceChange";

const ProductDetails = ({ place, path, name, categoryList }) => {
  const vendors = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({});
  const [pagenationNew, setPagenationNew] = useState({});
  const [list, setList] = useState([]);
  const [listNew, setListNew] = useState([]);
  const [pagenationReq, setPagenationReq] = useState({});
  const [listReq, setListReq] = useState([]);
  const [ReqSearch, setReqSearch] = useState("");
  const [reqStatus, setReqStatus] = useState("");

  const [product, setProduct] = useState(false);
  const [decorative, setDecorative] = useState(false);
  const [newProductsSuggestions, SetNewProductsSuggestions] = useState(false);
  const [myProducts, setMyProducts] = useState(true);
  const [productList, setParoductList] = useState("");
  const location = useLocation();
  const [productTab, setProductTab] = useState(0);
  const [uploadPopUp, setUploadPopUp] = useState(false);
  const [types, setTypes] = useState("");
  const [status, setStauts] = useState("");
  const [suggestSearch, setSuggestSearch] = useState("");
  const [suggestStatus, setSuggestStatus] = useState("");
  const [btn, setBtn] = useState(false);
  const dispatch = useDispatch();
  const [listCategory, setListCategory] = useState([]);
  const [approveCat, setApproveCat] = useState("");
  const [mapCat, setMapCat] = useState("");
  // console.log(location);

  const [PoductStoreApi] = useProductSuggestionStoreMutation();
  const [productRequestList] = useRequestListMutation();
  const [ProductSuggestionApi] = useProductSuggestionListMutation();
  const [categoryListApi] = useLazyCategoryListsQuery();
  // Upload images states

  const [fileData, setFileData] = useState([]);
  const [price, setPrice] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImages] = useState("");

  //Err
  const [priceErr, setPriceErr] = useState(false);
  const [titleErr, setTitleErr] = useState(false);
  const [imageErr, setImageErr] = useState(false);

  //PRICE CHANGE LIST
  const [priceChangeList, setPriceChangeList] = useState([]);
  const [searchPrice, setSearchPrice] = useState("");
  const [priceStatus, setPriceStatus] = useState("");
  const [priceCat, setPriceCat] = useState("");
  const [pageChange, setPageChange] = useState(1);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setTodate] = useState("");
  const [newCat, setNewCat] = useState("");

  const getCategories = () => {
    categoryListApi(vendors?.vendor?.id)
      .unwrap()
      .then((res) => {
        setListCategory(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const [reqPage, setReqPage] = useState(10);

  const getRequest = (status, event, category, row) => {
    let params = `?page=${pageReq}`;
    const formData = new FormData();
    // setSearch(event)
    if (status) {
      formData.append("status", status);
    }
    if (event?.length > 0) {
      formData.append("search", event);
    }

    if (category) {
      formData.append("category_id", category);
    }

    if (row) {
      formData.append("rows", row);
    }

    setNewCat(category);
    setReqSearch(event);
    setReqStatus(status);
    setReqPage(row);
    formData.append("vendor_id", vendors?.vendor?.id);
    productRequestList({ payload: formData, params: params })
      .unwrap()
      .then((res) => {
        // // console.log(res);
        // const temp =[]
        // res?.lists?.map((item,ind)=>{
        // const images =  item?.image_urls?.product_images?.find((image)=>(
        //     image?.is_default==1
        // ))

        // if(images?.image){
        //   temp.push({
        //     ...item,
        //     image:images
        //    })
        // }else{
        //   temp.push({
        //     ...item,
        //     image:item?.image_urls?.product_images?.[0]
        //    })
        // }

        //  console.log(temp)
        // })
        setListReq(res?.lists);
        setPagenationReq(res?.pagination_meta);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [newProCat, setNewProCat] = useState("");
  const [newProPage, setNewProPage] = useState(10);

  const getProduct = (status, event, row, category) => {
    // console.log(status,"status")
    // console.log(filter,"s")
    const formData = new FormData();
    // setSearch(event)
    setSuggestSearch(event);
    setSuggestStatus(status);
    if (status) {
      formData.append("status", status);
    }
    if (event?.length > 0) {
      formData.append("search", event);
    }

    if (category) {
      formData.append("category_id", category);
    }

    if (row) {
      formData.append("rows", row);
    }

    setNewProPage(row);

    formData.append("vendor_id", vendors?.vendor?.id);
    ProductSuggestionApi(formData)
      .unwrap()
      .then((res) => {
        // console.log(res)
        setListNew(res?.lists);
        setPagenationNew(res?.pagination_meta);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (vendors?.vendor?.id) {
      getRequest();
      getProduct();
    }
  }, []);
  const handleUploadHandler = (e) => {
    // console.log(e, "e");
    if (title?.length == "" || image?.length == "" || price?.length == "") {
      setPriceErr(true);
      setTitleErr(true);
      setImageErr(true);
    } else {
      const formData = new FormData();
      formData.append("image", image);
      formData.append("vendor_id", vendors?.vendor?.id);
      formData.append("name", title);
      formData.append("total_amount", price);
      setBtn(true);
      dispatch(saveLoader(true));
      PoductStoreApi(formData)
        .unwrap()
        .then((res) => {
          setBtn(false);
          dispatch(saveLoader(false));
          setUploadPopUp(!uploadPopUp);
          getProduct();
        })
        .catch((err) => {
          setBtn(false);
          dispatch(saveLoader(false));
          console.log(err);
        });
    }
  };
  const handleClose = (e) => {
    setUploadPopUp(!uploadPopUp);
  };
  const [acceptList, setAcceptList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [pageRow, setPageRow] = useState(10);
  const [pageReq, setPageReq] = useState(1);
  const [pageReqRow, setPageReqRow] = useState(10);
  const [page, setPage] = useState(1);
  const [acceptPagenation, setAcceptPagination] = useState({});
  const [acceptSearchValue, setAcceptSearchValue] = useState("");
  const [acceptPageRow, setAcceptPageRow] = useState(10);
  const [acceptPage, setAcceptPage] = useState(1);
  const [acceptStatus, setAcceptStatus] = useState("");

  // RTK QUERY
  const [mapProductListApi] = useMapProductsListsMutation();
  const [acceptedProductsApi] = useMapProductsAcceptedListsMutation();
  const [priceListApi] = usePriceListMutation();
  // GET MAP LIST
  const getMapList = (event, row, status, category) => {
    let formdata = new FormData();
    let params = `?page=${page}`;

    setSearchValue(event);

    if (event?.length > 0) {
      formdata.append("search", event);
    }

    if (row) {
      formdata.append("rows", row);
    }

    if (status) {
      formdata.append("status", status);
    }

    if (category) {
      formdata.append("category_id", category);
    }

    setMapCat(category);
    setStauts(status);
    setPageRow(row);

    formdata.append("vendor_id", vendors?.vendor?.id);

    mapProductListApi({ payload: formdata, params: params })
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setList(res?.lists);
          setPagination(res?.pagination_meta);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // GET ACCEPT MAP LIST
  const getAcceptedList = (event, row, status, category) => {
    let formdata = new FormData();
    let params = `?page=${acceptPage}`;

    setAcceptSearchValue(event);

    if (event?.length > 0) {
      formdata.append("search", event);
    }

    if (row) {
      formdata.append("rows", row);
    }

    if (status) {
      formdata.append("status", status);
    }

    if (category) {
      formdata.append("category_id", category);
    }

    setAcceptPageRow(row);
    setAcceptStatus(status);
    setApproveCat(category);

    formdata.append("vendor_id", vendors?.vendor?.id);

    acceptedProductsApi({ payload: formdata, params: params })
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setAcceptList(res?.lists);
          setAcceptPagination(res?.pagination_meta);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getMapList();
    getAcceptedList();
  }, [page, acceptPage]);

  useEffect(() => {
    if (location?.state?.productTab) {
      setProductTab(location?.state?.productTab);
    }
  }, []);

  const [priceChagePage, setPriceChangePage] = useState(10);

  const getPriceList = (event, row, status, category) => {
    let formdata = new FormData();
    let params = `?page=${1}`;
    // formdata.append("status", 1);

    if (event?.length > 0) {
      formdata.append("search", event);
    }
    if (fromDate && toDate) {
      formdata.append("from_date", fromDate);
      formdata.append("to_date", toDate);
    }

    if (row) {
      formdata.append("rows", row);
    }

    if (status) {
      formdata.append("status", status);
    }

    if (category) {
      formdata.append("category_id", category);
    }

    setSearchPrice(event);
    setPriceStatus(status);
    setPriceCat(category);
    setPriceChangePage(row);

    formdata.append("vendor_id", vendors?.vendor?.id);
    priceListApi({ payload: formdata, params: params })
      .unwrap()
      .then((res) => {
        // console.log(res);
        setPriceChangeList(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getProduct();
    getPriceList();
    getCategories();
  }, []);

  return (
    <div className="w-100">
      <div className="d-flex mt-5 ac-jb flex-wrap">
        <div className="d-flex mt-3 rk">
          <button
            onClick={() => {
              setProductTab(0);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              productTab == 0
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                productTab == 0 ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Approved Products
            </p>
          </button>
          <button
            onClick={() => {
              setProductTab(1);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              productTab == 1
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                productTab == 1 ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Map Products
            </p>
          </button>
          <button
            onClick={() => {
              setProductTab(2);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              productTab == 2
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                productTab == 2 ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Add New Products
            </p>
          </button>
          <button
            onClick={() => {
              setProductTab(3);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              productTab == 3
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                productTab == 3 ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              New Products Suggestions
            </p>
          </button>
          <button
            onClick={() => {
              setProductTab(4);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              productTab == 4
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                productTab == 4 ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Price Change
            </p>
          </button>
        </div>
        <div>
          {productTab == 1 && (
            <button className="cust-btn addbtn2 mt-sm-3 mx-3 mt-2 gray f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
              <GetAppIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
              Export
            </button>
          )}
          {/* {(path === '/map-product-vendor-details' && product || product) &&
            <button
              className="cust-btn addbtn2 mt-sm-3 mx-3 mt-2 gray f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              <UploadIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
              Import
            </button>
          } */}
          {/* {((path === "/vendor-details" && product) || product) && (
            <button
              onClick={() => navigate("/map-product")}
              className="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              Map Product
            </button>
          )} */}

          {/* {(path === '/vendor-details' && decorative || decorative) && <button
            className="cust-btn addbtn2 mt-sm-3 mx-3 mt-2 gray f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            <UploadIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            Sample Download
          </button>}
          {(path === '/vendor-details' && decorative || decorative) && <button
            className="cust-btn addbtn2 mt-sm-3 mx-3 mt-2 gray f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            <UploadIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            Import
          </button>} */}

          {productTab == 2 && (
            <button
              onClick={() =>
                navigate("/create-new-request", {
                  state: { categoryList: categoryList, tab: "3" },
                })
              }
              className="cust-btn addbtn mt-sm-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              Create New Request
            </button>
          )}

          {productTab == 3 && (
            <button
              onClick={() => handleClose()}
              className="cust-btn addbtn mt-sm-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              Upload Image
            </button>
          )}
        </div>
      </div>
      {/* {!myProducts && <DropdownTap path={path} />} */}

      {productTab == 0 && (
        <MyProductListComp
          acceptList={acceptList}
          name={name}
          acceptPagenation={acceptPagenation}
          setAcceptPagination={setAcceptPagination}
          acceptSearchValue={acceptSearchValue}
          setAcceptSearchValue={setAcceptSearchValue}
          acceptPageRow={acceptPageRow}
          setAcceptPageRow={setAcceptPageRow}
          acceptPage={acceptPage}
          setAcceptPage={setAcceptPage}
          getAcceptedList={getAcceptedList}
          acceptStatus={acceptStatus}
          listCategory={listCategory}
          approveCat={approveCat}
        />
      )}
      {productTab == 1 && (
        <MapProductListComp
          list={list}
          getMapList={getMapList}
          status={status}
          name={name}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          pageRow={pageRow}
          setPageRow={setPageRow}
          page={page}
          setPage={setPage}
          pagenation={pagination}
          setPagination={setPagination}
          listCategory={listCategory}
          mapCat={mapCat}
        />
      )}
      {productTab == 2 && (
        <RequestListComp
          name={name}
          categoryList={categoryList}
          ReqSearch={ReqSearch}
          pageReqRow={pageReqRow}
          setPageReq={setPageReq}
          getProduct={getRequest}
          reqStatus={reqStatus}
          list={listReq}
          pagination={pagenationReq}
          setPageReqRow={setPageReqRow}
          pageReq={pageReq}
          listCategory={listCategory}
          newCat={newCat}
          reqPage={reqPage}
        />
      )}
      {productTab == 3 && (
        <NewProductSuggestion
          place={name}
          handleUploadHandler={handleUploadHandler}
          product={product}
          places={place}
          location={location}
          list={listNew}
          pagination={pagenationNew}
          suggestStatus={suggestStatus}
          getProduct={getProduct}
          btn={btn}
          suggestSearch={suggestSearch}
          newProPage={newProPage}
        />
      )}
      {productTab == 4 && (
        <PriceChange
          priceChangeList={priceChangeList}
          setPriceChangeList={setPriceChangeList}
          searchPrice={searchPrice}
          // setSearch={setSearch}

          priceCat={priceCat}
          priceStatus={priceStatus}
          pageChange={pageChange}
          setPageChange={setPageChange}
          setFromDate={setFromDate}
          setTodate={setTodate}
          toDate={toDate}
          fromDate={fromDate}
          getPriceList={getPriceList}
          listCategory={listCategory}
          priceChagePage={priceChagePage}
        />
      )}
      {/* <RequestListComp place={place} path={path} setProduct={product} setDecorative={decorative} myProducts={myProducts} /> */}
      {uploadPopUp && (
        <UploadImages
          onUploadHandler={handleUploadHandler}
          types={types}
          setTypes={setTypes}
          handleClose={handleClose}
          setFileData={setFileData}
          fileData={fileData}
          setPrice={setPrice}
          price={price}
          setTitle={setTitle}
          title={title}
          priceErr={priceErr}
          setPriceErr={setPriceErr}
          titleErr={titleErr}
          setTitleErr={setTitleErr}
          imageErr={imageErr}
          setImageErr={setImageErr}
          setImages={setImages}
          image={image}
          btn={btn}
        />
      )}
    </div>
  );
};

export default ProductDetails;
