import React, { useState } from "react";
import {
  animatedImg,
  product,
  searchIcon,
  toggleOff,
  toggleOn,
} from "../../assets/img";
import CachedIcon from "@mui/icons-material/Cached";
import { useLocation, useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import { Search } from "@mui/icons-material";
import { useProductsBulkStatusChangeMutation } from "../../redux/api/api";
import { toast } from "react-hot-toast";

const MyProductListComp = ({
  acceptList,
  loc,
  setDecorative,
  setProduct,
  place,
  path,
  myProducts,
  name,

  acceptPagenation,
  setAcceptPagination,
  acceptSearchValue,
  setAcceptSearchValue,
  acceptPageRow,
  setAcceptPageRow,
  acceptPage,
  setAcceptPage,
  getAcceptedList,
  acceptStatus,
  listCategory,
  approveCat,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const path_line = location?.pathname;
  const [permissions, setPermissions] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);

  // RTK QUERY
  const [productBulkStatusApi] = useProductsBulkStatusChangeMutation();

  const rolefunction = (id) => {
    let roleId = [...permissions];
    let ids = roleId.indexOf(id);

    if (roleId.includes(id)) {
      roleId.splice(ids, 1);
    } else {
      roleId.push(id);
    }

    if (roleId.length == acceptList?.length) {
      setIsCheckAll(true);
    } else {
      setIsCheckAll(false);
    }

    setPermissions(roleId);
  };

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setPermissions(acceptList?.map((li) => li?.id));
    if (isCheckAll) {
      setPermissions([]);
    }
  };

  const bulkStatusChange = (status, type, id) => {
    let formdata = new FormData();

    let temp = [];
    temp.push(id);

    if (type == "one") {
      temp?.map((item, ind) => {
        formdata.append(
          `approvedRequestStatus[${ind}][vendor_product_price_id]`,
          item
        );
        formdata.append(`approvedRequestStatus[${ind}][status]`, status);
      });
    } else {
      permissions?.map((item, ind) => {
        formdata.append(
          `approvedRequestStatus[${ind}][vendor_product_price_id]`,
          item
        );
        formdata.append(`approvedRequestStatus[${ind}][status]`, status);
      });
    }

    productBulkStatusApi(formdata)
      .unwrap()
      .then((res) => {
        setPermissions([]);
        setIsCheckAll(false);
        toast.success(res?.message);
        getAcceptedList();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // console.log("acceptList", acceptList);

  return (
    <>
      <div className="mt-5 banner-left">
        <div className="d-flex ac-jb flex-column flex-md-row w-100">
          <div className="d-flex gap-3">
            <select
              className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3"
              onChange={(e) =>
                getAcceptedList(
                  acceptSearchValue,
                  acceptPageRow,
                  acceptStatus,
                  e.target.value
                )
              }
              value={approveCat}
            >
              <option value={""}>Select Categories</option>
              {listCategory?.map((item, ind) => {
                return (
                  <option key={ind} value={item?.id}>
                    {item?.name}
                  </option>
                );
              })}
            </select>
            <select
              className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3"
              onChange={(e) =>
                getAcceptedList(
                  acceptSearchValue,
                  acceptPageRow,
                  e.target.value,
                  approveCat
                )
              }
              value={acceptStatus}
            >
              <option value={""}>Select Status</option>
              <option value={1}>Active</option>
              <option value={0}>Deactive</option>
            </select>
          </div>
          <div className="d-flex ac-jc place-holde justify-content-md-end gap-3 flex-wrap w-100 w-md-90 mb-md-0  mb-4">
            <div className="d-flex search_new_design mx-2">
              <img src={searchIcon} className="searchiConImg" />
              <input
                className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 w-100"
                placeholder="Search"
                onChange={(e) =>
                  getAcceptedList(
                    e.target.value,
                    acceptPageRow,
                    acceptStatus,
                    approveCat
                  )
                }
                value={acceptSearchValue}
              />
            </div>
            <div className="d-flex ac-jc gap-3">
              {/* <button
                // onClick={() => navigate("/add-vendor")}
                className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
              >
                <Search />
              </button> */}
              <button
                onClick={(e) => getAcceptedList("", acceptPageRow, "", "")}
                className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
              >
                <CachedIcon />
              </button>
            </div>{" "}
          </div>
        </div>
      </div>
      <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select
            className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary"
            onChange={(e) => getAcceptedList(acceptSearchValue, e.target.value)}
            value={acceptPageRow}
          >
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont">
            Total Count : {acceptPagenation?.total}
          </p>
        </div>
      </div>
      <div className="w-95 d-flex flex-md-row flex-column mt-4 gap-2">
        <div
          className="d-flex gap-1 mt-4"
          onClick={() => handleSelectAll()}
          role={"button"}
        >
          <DoneIcon
            className={`rounded-5 f2 p-1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 mt-3 ${
              isCheckAll ? "checkIcon" : "checkIcon1"
            }`}
          />
          <p
            className={`f1 ms-2 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 mt-3 ${
              isCheckAll ? "black" : "primary1"
            }`}
          >
            Select All
          </p>
        </div>
        {permissions?.length > 0 && (
          <div className="d-flex gap-2 mt-3">
            <button
              className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
              onClick={() => bulkStatusChange(1)}
            >
              Active
            </button>
            <button
              className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
              onClick={() => bulkStatusChange(0)}
            >
              Deactive
            </button>
          </div>
        )}
      </div>
      <div className="w-100 d-flex ac-js flex-wrap mt-5 ">
        {acceptList?.map((item, ind) => {
          return (
            <div
              className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2 position-relative"
              key={ind}
            >
              <div className="prod-list-box">
                <div
                  className="cust-btn d-flex ac-jc rounded-3"
                  onClick={() => {
                    navigate("/add-new-product/products/product-detail", {
                      state: { status: "accept", data: item, type: "approve" },
                    });
                    // navigate("/myproduct-detail", {
                    //   state: { status: "accept", data: item },
                    // });1
                  }}
                  // onClick={() =>
                  //   navigate("/map-request-view", { state: { data: item } })
                  // }3
                >
                  <img
                    src={
                      item?.productDetails?.productImage
                        ? item?.productDetails?.productImage
                        : product
                    }
                    alt=""
                    className="custom-w-h rounded-3"
                  />
                </div>
                <p
                  className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark mb-2 mt-2 text-custom-res"
                  onClick={() => {
                    navigate("/add-new-product/products/product-detail", {
                      state: { status: "accept", data: item, type: "approve" },
                    });
                  }}
                  // onClick={() => {
                  //   navigate("/myproduct-detail", {
                  //     state: { status: "accept", data: item },
                  //   });
                  // }} 1
                  // onClick={() =>
                  //   navigate("/map-request-view", { state: { data: item } })
                  // }3
                >
                  {item?.productDetails?.name}
                </p>
                <p
                  className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2 primary text-custom-res"
                  onClick={() => {
                    navigate("/add-new-product/products/product-detail", {
                      state: { status: "accept", data: item, type: "approve" },
                    });
                  }}
                  // onClick={() =>
                  //   navigate("/map-request-view", { state: { data: item } })
                  // }
                >
                  {item?.productDetails?.categoryCode}
                </p>
                <p
                  className="d-flex ac-jb f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2 primary1 text-custom-res "
                  role={"button"}
                  onClick={() =>
                    bulkStatusChange(item?.status == 1 ? 2 : 1, "one", item?.id)
                  }
                >
                  Status
                  <span>
                    <img
                      src={item?.status == 1 ? toggleOn : toggleOff}
                      className="toggleOnDes"
                      alt="icon"
                    />
                  </span>
                </p>
                <p
                  className="f4 fs-11 fs-sm-12 text-custom-res fs-lg-13 parag mb-0 Accept-text"
                  onClick={() => {
                    navigate("/add-new-product/products/product-detail", {
                      state: { status: "accept", data: item, type: "approve" },
                    });
                  }}
                  // onClick={() => {
                  //   navigate("/myproduct-detail", {
                  //     state: { status: "accept", data: item },
                  //   });
                  // }}1
                  // onClick={() =>
                  //   navigate("/map-request-view", { state: { data: item } })
                  // }3
                >
                  Accepted
                </p>
              </div>
              <div className="d-flex mt-4 ms-1 position-absolute top-0 end-0 px-4">
                <DoneIcon
                  onClick={() => rolefunction(item?.id)}
                  className={`rounded-5 f2 p-1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 ${
                    permissions?.includes(item?.id) ? "checkIcon" : "checkIcon1"
                  }`}
                />
                <p
                  className={`f1 ms-2 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ${
                    permissions?.includes(item?.id) ? "black" : "primary1"
                  }`}
                ></p>
              </div>
            </div>
          );
        })}
      </div>{" "}
      <div className="d-flex justify-content-between mt-3">
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            acceptPage == 1 && "opacity-50"
          }`}
          onClick={() => setAcceptPage(acceptPage - 1)}
          disabled={acceptPage == 1 ? true : false}
        >
          Previous
        </button>
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            acceptPage == acceptPagenation?.last_page && "opacity-50"
          }`}
          onClick={() => setAcceptPage(acceptPage + 1)}
          disabled={acceptPage == acceptPagenation?.last_page ? true : false}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default MyProductListComp;
