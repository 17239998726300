import React from 'react'
import LeftComp from './LeftComp'
import RightComp from './RightComp'

const OverViewComp = ({ edit, types,
    decorativeMethod,
    setDecorativeMethod,
    list,
 }) => {
    return (
        <div className='d-flex as-jb w-100 mt-5 res-flex gap-5'>
            <LeftComp edit={edit} types={types}  setDecorativeMethod={setDecorativeMethod}
        decorativeMethod={decorativeMethod}
        list={list}/>
            <RightComp types={types} edit={edit} list={list}/>
        </div>
    )
}

export default OverViewComp