import { Search } from "@mui/icons-material";
import manImage from "../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import ProfileList from "../components/AddRole/ProfileList";
import ProfileRolesList from "../components/AddRole/ProfileRolesList";
import VendorList from "../components/AddRole/VendorList";
import ProductAdminList from "../components/AddRole/ProductAdminList";
import ClientList from "../components/AddRole/ClientList";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useLazyRoleModuleQuery, useLazyRoleviewQuery } from "../redux/api/api";
import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import DoneIcon from "@mui/icons-material/Done";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";

const RoleViewProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location?.state?.data?.id);
  const [role, setRole] = useState([]);
  const type = location?.state?.type;
  const [permissonList, setPermissionList] = useState([]);
  const [roleName, setRoleName] = useState("");
  const [roleNameErr, setRoleNameErr] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  //RTK
  const [roleview] = useLazyRoleviewQuery();
  const [roleModuleApi] = useLazyRoleModuleQuery();

  const getRoleModule = () => {
    roleModuleApi()
      .unwrap()
      .then((res) => {
        setPermissionList(res?.modules);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getRoleModule();
  }, []);

  const getview = () => {
    roleview(location?.state?.data?.id)
      .unwrap()
      .then((res) => {
        let temp = [];
        // setRoleName(res?.editData?.role);
        setRoleName(res?.showData?.role);
        res?.permissions?.map((item, ind) => {
          temp.push(item);
        });
        setPermissions(temp);
      })
      .catch();
  };

  useEffect(() => {
    if (location?.state?.data?.id) {
      getview();
    }
  }, []);

  return (
    <div className="dashRightView overflow-scroll">
      <ProductSearchHeader />
      <p
        className="f4 fs-xs-10 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-5 d-flex align-items-center gap-1"
        role={"button"}
        onClick={() => navigate(-1)}
      >
        <KeyboardBackspaceIcon /> Profile Department/ Roles
      </p>
      <div className="w-95 ac-jb d-flex flex-md-row flex-column mt-4 position-relative">
        <div className="w-100">
          <img
            src={manImage}
            className="searchManImgMan rounded-4 position-absolute"
          />
          <input
            placeholder="Create Role"
            value={roleName}
            disabled
            className="dashTotalDes ps-5 w-lg-40 w-xl-40 py-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-15 rounded-3"
          />
        </div>
      </div>

      <div className="d-flex flex-wrap">
        {" "}
        {permissonList?.map((item, ind) => {
          return (
            <Col key={ind} className="mb-4" xs={12} sm={6} lg={4} xxl={4}>
              <div className=" mt-4">
                <p
                  className={`f3 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-10 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-18 fs-xxl-19 mt-1`}
                >
                  {item?.code}
                </p>

                {item?.permissions?.map((permission, index) => {
                  return (
                    <div className="d-flex mt-4 ms-1" role={"button"}>
                      <DoneIcon
                        className={`rounded-5 f2 p-1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 ${
                          permissions?.includes(permission?.id)
                            ? "checkIcon"
                            : "checkIcon1"
                        }`}
                      />
                      <p
                        className={`f1 ms-2 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ${
                          permissions?.includes(permission?.id)
                            ? "black"
                            : "primary1"
                        }`}
                      >
                        {permission?.code}
                      </p>
                    </div>
                  );
                })}
              </div>{" "}
            </Col>
          );
        })}
      </div>

      <div className="w-80 mt-5 d-flex flex-md-row flex-column ac-jb">
        <p className="f2 primary1 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17">
          There are the roles that were provided for{" "}
          <span className="f2 text-dark ms-md-2 ms-lg-2 ms-xl-2 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17">
            Digital Marketing.
          </span>
        </p>
      </div>
      <button
        onClick={() => navigate("/role-profile")}
        className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
      >
        Back
      </button>
    </div>
  );
};

export default RoleViewProfile;
