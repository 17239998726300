import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { useEffect, useState } from "react";
import GroupsIcon from "@mui/icons-material/Groups";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LogoutPopup from "./Popup/LogoutPopup";
import { logos } from "../assets/img";

const ProfileNavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("user"));
  const { pathname } = location;
  const splitLocation = pathname.split("/signin");
  const [logoutPopupShow, setLogoutPopupShow] = useState(false);
  // console.log("Split________", splitLocation);
  const [enableDown, setEnableDown] = useState(false);
  const [prodManagement, setProdManagement] = useState(false);
  const [customerDown, setCustomerDown] = useState(false);
  const [helpDeskDown, setHelpDeskDown] = useState(false);
  const [drop, setDrop] = useState("");
  useEffect(() => {
    if (splitLocation[1] === "") {
      navigate("/home");
    }
  }, []);

  const toggleLogoutPopup = () => {
    setLogoutPopupShow(!logoutPopupShow);
  };

  return (
    <div className="navBar">
      <div className="w-100">
        <div className="mt-4 ac-jc d-flex">
          <p className="f4 fs-sm-21 fs-md-25 fs-lg-27 fs-xl-29 fs-xxl-30 primary">
            {/* Merchhq */}
            <img
              src={logos}
              className="logo"
              alt=""
              style={{ width: "120px" }}
            />
          </p>
        </div>

        <div
          onClick={() => {
            navigate("/home");
            setEnableDown(false);
          }}
          className={`${
            splitLocation[1] === "home" ? "bg-soft-gray1" : ""
          } d-flex py-2 w-100 flex-md-row flex-column mt-4 pointerBtn`}
        >
          <DashboardIcon
            className={` ${
              splitLocation[1] === "home" ? "black" : "gray"
            } f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20`}
          />
          <p
            className={`wordsDes ${
              splitLocation[1] === "home" ? "black" : "gray"
            } f2 ms-md-2 ms-lg-4 ms-xl-4 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15`}
          >
            Dashboard
          </p>
        </div>
        <div
          onClick={() => {
            navigate("/profile");
            setEnableDown(false);
          }}
          className={`d-flex pointerBtn py-2 flex-md-row flex-column mt-4 w-100 ${
            splitLocation[1] === "profile" ||
            splitLocation[1] === "add-profile" ||
            splitLocation[1] === "view-profile" ||
            splitLocation[1] === "edit-profile"
              ? "bg-soft-gray1"
              : ""
          }`}
        >
          <PersonIcon
            className={` ${
              splitLocation[1] === "profile" ||
              splitLocation[1] === "add-profile" ||
              splitLocation[1] === "view-profile" ||
              splitLocation[1] === "edit-profile"
                ? "black"
                : "gray"
            } f1 fs-xs-18 fs-sm-20 ac-jc fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20`}
          />
          <p
            className={`${
              splitLocation[1] === "profile" ||
              splitLocation[1] === "add-profile" ||
              splitLocation[1] === "view-profile" ||
              splitLocation[1] === "edit-profile"
                ? "black"
                : "gray"
            } wordsDes f2 ms-md-2 ms-lg-4 ms-xl-4 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15`}
          >
            Vendor Profile
          </p>
        </div>
        <div
          onClick={() => {
            {
              navigate("/role-profile");
              setEnableDown(false);
            }
          }}
          className={`d-flex pointerBtn py-2 flex-md-row flex-column mt-4 w-100 ${
            splitLocation[1] === "role-profile" ||
            splitLocation[1] === "add-role-profile" ||
            splitLocation[1] === "view-role-profile" ||
            splitLocation[1] === "edit-role-profile"
              ? "bg-soft-gray1"
              : ""
          }`}
        >
          <PermContactCalendarIcon
            className={`${
              splitLocation[1] === "role-profile" ||
              splitLocation[1] === "add-role-profile" ||
              splitLocation[1] === "view-role-profile" ||
              splitLocation[1] === "edit-role-profile"
                ? "black"
                : "gray"
            } f1 fs-xs-18 fs-sm-20 ac-jc fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20`}
          />
          <p
            className={`${
              splitLocation[1] === "role-profile" ||
              splitLocation[1] === "add-role-profile" ||
              splitLocation[1] === "view-role-profile" ||
              splitLocation[1] === "edit-role-profile"
                ? "black"
                : "gray"
            } wordsDes f2 ms-md-2 ms-lg-4 ms-xl-4 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15`}
          >
            Vendor Department
          </p>
        </div>
        <div
          onClick={() => {
            setCustomerDown(false);
            setProdManagement(false);
            setEnableDown(!enableDown);
            setHelpDeskDown(false);
            // navigate("/vendor-details");
            setDrop("manage");
          }}
          className={`d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100 ${
            splitLocation[1] === "vendor-details" ||
            splitLocation[1] === "view-collaborate-details"
              ? "bg-soft-gray1"
              : ""
          }`}
        >
          <SupervisorAccountIcon
            className={` f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 ${
              splitLocation[1] === "vendor-details" ||
              splitLocation[1] === "view-collaborate-details"
                ? "black"
                : "gray"
            }`}
          />
          <p
            className={`wordsDes ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ${
              splitLocation[1] === "vendor-details" ||
              splitLocation[1] === "view-collaborate-details"
                ? "black"
                : "gray"
            }`}
          >
            Management
          </p>
        </div>

        {enableDown && (
          <div>
            {user?.vendor?.offering_type == 1 ? (
              <div
                onClick={() => {
                  navigate("/vendor-details", {
                    state: { catogory: "Charles" },
                  });
                  setDrop(undefined);
                }}
                className={`${
                  splitLocation[1] === "vendor-details" ||
                  splitLocation[1] === "add-vendor"
                    ? "bg-soft-gray1"
                    : ""
                } d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100`}
              >
                <p
                  className={`${
                    splitLocation[1] === "vendor-details" ||
                    splitLocation[1] === "add-vendor"
                      ? "black"
                      : "gray"
                  } ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  Product Supplier
                </p>
              </div>
            ) : user?.vendor?.offering_type == 2 ? (
              <div
                onClick={() => {
                  // navigate("/decorative-vendor", {
                  navigate("/vendor-details", {
                    state: { catogory: "John Rolph" },
                  });
                  setDrop(undefined);
                }}
                className={`${
                  splitLocation[1] === "decorative-vendor" ||
                  splitLocation[1] === "add-vendor"
                    ? "bg-soft-gray1"
                    : ""
                } d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100`}
              >
                <p
                  className={`${
                    splitLocation[1] === "decorative-vendor" ||
                    splitLocation[1] === "add-vendor"
                      ? "black"
                      : "gray"
                  } ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  Decorator
                </p>
              </div>
            ) : user?.vendor?.offering_type == 3 ? (
              <>
                <div
                  onClick={() => {
                    navigate("/vendor-details", {
                      state: { catogory: "Charles" },
                    });
                    setDrop(undefined);
                  }}
                  className={`${
                    splitLocation[1] === "vendor-details" ||
                    splitLocation[1] === "add-vendor"
                      ? "bg-soft-gray1"
                      : ""
                  } d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100`}
                >
                  <p
                    className={`${
                      splitLocation[1] === "vendor-details" ||
                      splitLocation[1] === "add-vendor"
                        ? "black"
                        : "gray"
                    } ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                  >
                    Both
                  </p>
                </div>
                {/* <div
                  onClick={() => {
                    navigate("/decorative-vendor", {
                      state: { catogory: "John Rolph" },
                    });
                    setDrop(undefined);
                  }}
                  className={`${
                    splitLocation[1] === "decorative-vendor" ||
                    splitLocation[1] === "add-vendor"
                      ? "bg-soft-gray1"
                      : ""
                  } d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100`}
                >
                  <p
                    className={`${
                      splitLocation[1] === "decorative-vendor" ||
                      splitLocation[1] === "add-vendor"
                        ? "black"
                        : "gray"
                    } ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                  >
                    Decorator
                  </p>
                </div> */}
                {/* <div
                  onClick={() => {
                    navigate("/both-vendor", {
                      state: { catogory: "Jake Adams" },
                    });
                    setDrop(undefined);
                  }}
                  className={`${
                    splitLocation[1] === "both-vendor" ||
                    splitLocation[1] === "add-vendor"
                      ? "bg-soft-gray1"
                      : ""
                  } d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100`}
                >
                  <p
                    className={`${
                      splitLocation[1] === "both-vendor" ||
                      splitLocation[1] === "add-vendor"
                        ? "black"
                        : "gray"
                    } ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                  >
                    Both
                  </p>
                </div> */}
              </>
            ) : null}
          </div>
        )}
        {/* <div
          onClick={() => {
            setCustomerDown(false);
            setProdManagement(!prodManagement);
            setEnableDown(false);
            setHelpDeskDown(false);
            navigate("/product-feed");
          }}
          className="d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100"
        >
          <WarehouseIcon className="gray f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20" />
          <p className="wordsDes ms-md-2 ms-lg-4 ms-xl-4 gray f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
            Product Management
          </p>
        </div>
        {prodManagement && (
          <div>
            <div
              onClick={() => {
                navigate("/product-feed");
              }}
              className="d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100"
            >
              <p
                className={`${
                  splitLocation[1] === "product-feed" ? "black" : "gray"
                } wordsDes ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                Feed
              </p>
            </div>
            <div
              onClick={() => {
                navigate("/product-library");
              }}
              className="d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100"
            >
              <p
                className={`${
                  splitLocation[1] === "product-library" ? "black" : "gray"
                } wordsDes ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                Product Library
              </p>
            </div>
            <div className="d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100">
              <p className="wordsDes ms-md-2 ms-lg-4 ms-xl-4 gray f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                Pricebook Library
              </p>
            </div>
            <div
              className="d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100"
              onClick={() => {
                navigate("/category-setting");
              }}
            >
              <p className="wordsDes ms-md-2 ms-lg-4 ms-xl-4 gray f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                Category Setting
              </p>
            </div>
            <div className="d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100">
              <p className="wordsDes ms-md-2 ms-lg-4 ms-xl-4 gray f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                HSN
              </p>
            </div>
          </div>
        )}
        <div
          onClick={() => {
            setCustomerDown(!customerDown);
            setProdManagement(false);
            setEnableDown(false);
            setHelpDeskDown(false);
            navigate("/customer-list");
          }}
          className="d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100"
        >
          <GroupsIcon className="gray f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20" />
          <p className="wordsDes ms-md-2 ms-lg-4 ms-xl-4 gray f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
            Customers
          </p>
        </div>
        {customerDown && (
          <div>
            <div
              onClick={() => {
                navigate("/customer-list");
              }}
              className={`d-flex pointerBtn py-2 flex-md-row flex-column mt-4 w-100 ${
                splitLocation[1] === "customer-list" ||
                splitLocation[1] === "view-customer-details"
                  ? "bg-soft-gray1"
                  : ""
              }`}
            >
              <p
                className={`${
                  splitLocation[1] === "customer-list" ||
                  splitLocation[1] === "view-customer-details"
                    ? "black"
                    : "gray"
                } wordsDes ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                Customer List
              </p>
            </div>
            <div
              onClick={() => {
                navigate("/customer-request-list");
              }}
              className={`d-flex pointerBtn py-2 flex-md-row flex-column mt-4 w-100 ${
                splitLocation[1] === "customer-request-list" ||
                splitLocation[1] === "view-request-details-customer"
                  ? "bg-soft-gray1"
                  : ""
              }`}
            >
              <p
                className={`${
                  splitLocation[1] === "customer-request-list" ||
                  splitLocation[1] === "view-request-details-customer"
                    ? "black"
                    : "gray"
                } wordsDes ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                Customer Request List
              </p>
            </div>
            <div
              onClick={() => {
                navigate("/order-list");
              }}
              className={`d-flex pointerBtn py-2 flex-md-row flex-column mt-4 w-100 ${
                splitLocation[1] === "order-list" ||
                splitLocation[1] === "view-order-details"
                  ? "bg-soft-gray1"
                  : ""
              }`}
            >
              <p
                className={`${
                  splitLocation[1] === "order-list" ||
                  splitLocation[1] === "view-order-details"
                    ? "black"
                    : "gray"
                } wordsDes ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                Order List
              </p>
            </div>
          </div>
        )}
        <div
          onClick={() => {
            setCustomerDown(false);
            setProdManagement(false);
            setEnableDown(false);
            setHelpDeskDown(!helpDeskDown);
            navigate("/ecommerce-list");
          }}
          className="d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100"
        >
          <SupportAgentIcon className="gray f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20" />
          <p className="wordsDes ms-md-2 ms-lg-4 ms-xl-4 gray f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
            Help Desk
          </p>
        </div>
        {helpDeskDown && (
          <div>
            <div
              onClick={() => {
                navigate("/ecommerce-list");
              }}
              className={`d-flex pointerBtn py-2 flex-md-row flex-column mt-4 w-100 ${
                splitLocation[1] === "ecommerce-list" ||
                splitLocation[1] === "ecommerce-view-details"
                  ? "bg-soft-gray1"
                  : ""
              }`}
            >
              <p
                className={`${
                  splitLocation[1] === "ecommerce-list" ||
                  splitLocation[1] === "ecommerce-view-details"
                    ? "black"
                    : "gray"
                } wordsDes ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                E-Commerce List
              </p>
            </div>
            <div
              onClick={() => {
                navigate("/customer-request-list");
              }}
              className={`d-flex pointerBtn py-2 flex-md-row flex-column mt-4 w-100 ${
                splitLocation[1] === "customer-request-list" ||
                splitLocation[1] === "view-request-details-customer"
                  ? "bg-soft-gray1"
                  : ""
              }`}
            >
              <p
                className={`${
                  splitLocation[1] === "customer-request-list" ||
                  splitLocation[1] === "view-request-details-customer"
                    ? "black"
                    : "gray"
                } wordsDes ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                Customer Request List
              </p>
            </div>
            <div
              onClick={() => {
                navigate("/order-list");
              }}
              className={`d-flex pointerBtn py-2 flex-md-row flex-column mt-4 w-100 ${
                splitLocation[1] === "order-list" ||
                splitLocation[1] === "view-order-details"
                  ? "bg-soft-gray1"
                  : ""
              }`}
            >
              <p
                className={`${
                  splitLocation[1] === "order-list" ||
                  splitLocation[1] === "view-order-details"
                    ? "black"
                    : "gray"
                } wordsDes ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                Order List
              </p>
            </div>
          </div>
        )} */}
        <div
          className="d-flex flex-md-row pointerBtn flex-column py-2 mt-4 w-100"
          role={"button"}
          onClick={toggleLogoutPopup}
        >
          <LogoutIcon className="gray f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20" />
          <p className="wordsDes gray f2 ms-md-2 ms-lg-4 ms-xl-4 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
            Logout
          </p>
        </div>
      </div>
      {logoutPopupShow && <LogoutPopup onCancelClick={toggleLogoutPopup} />}
    </div>
  );
};

export default ProfileNavBar;
