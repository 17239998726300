import React, { useEffect, useState } from "react";
import {
  animatedImg,
  product,
  searchIcon,
  toggleOff,
  toggleOn,
} from "../../assets/img";
import { useLocation, useNavigate } from "react-router-dom";
import CachedIcon from "@mui/icons-material/Cached";
import { Search } from "@mui/icons-material";
import {
  useDecorativeMethodeListMutation,
  useLazyDecorativeMethodeStatusQuery,
} from "../../redux/api/api";

const DecorativeList = ({ loc, setDecorative, setProduct, place, path }) => {
  const navigate = useNavigate();
  const [pagenation, setPagination] = useState({});
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const location = useLocation();
  const path_line = location?.pathname;
  const vendors = JSON.parse(localStorage.getItem("user"));
  const [searchValue, setSearchValue] = useState("");
  const [pageRow, setPageRow] = useState(10);

  const [decorativeListApi] = useDecorativeMethodeListMutation();
  const [decorativeStatusApi] = useLazyDecorativeMethodeStatusQuery();

  const getdecorate = (event, row) => {
    const formData = new FormData();
    formData.append("vendor_id", vendors?.vendor?.id);
    let params = `?page=${page}`;

    if (event?.length > 0) {
      formData.append("search", event);
    }

    if (row) {
      formData.append("rows", row);
    }

    setSearchValue(event);
    setPageRow(row);
    decorativeListApi({ payload: formData, params: params })
      .unwrap()
      .then((res) => {
        // console.log(res)
        setList(res?.lists);
        setPagination(res?.pagination_meta);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getDecorStatus = (id) => {
    decorativeStatusApi(id)
      .unwrap()
      .then((res) => {
        // console.log(res);
        getdecorate();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getdecorate();
  }, [page]);

  return (
    <>
      <div className="mt-5 banner-left">
        <div className="d-flex ac-jb flex-column flex-md-row">
          <div className="d-flex search_new_design mx-2">
            <img src={searchIcon} className="searchiConImg" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 w-100"
              placeholder="Search"
              onChange={(e) => getdecorate(e.target.value, pageRow)}
              value={searchValue}
            />
          </div>
          <div className="d-flex ac-jc gap-3">
            {/* <button
              // onClick={() => navigate("/add-vendor")}
              className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
            >
              <Search />
            </button> */}
            <button
              onClick={() => getdecorate("", "")}
              className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
            >
              <CachedIcon />
            </button>
          </div>
        </div>
      </div>
      <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4 ">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select
            className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary"
            onChange={(e) => getdecorate(searchValue, e.target.value)}
            value={pageRow}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <div className="d-flex justify-content-end w-100">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont">
            Total Count : {pagenation?.total}
          </p>
        </div>
      </div>
      <div className="w-100 d-flex ac-js flex-wrap mt-5 ">
        {list.map((item, ind) => {
          return (
            <div className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2">
              <div className="prod-list-box">
                <div
                  className="cust-btn d-flex ac-jc rounded-3"
                  onClick={() =>
                    navigate("/decorative-detail", {
                      state: {
                        type: place,
                        id: item,
                        data: location?.state?.data,
                      },
                    })
                  }
                >
                  <img
                    src={
                      item?.decor_method_price?.[
                        item?.decor_method_price?.length - 1
                      ]?.image_url
                        ? item?.decor_method_price?.[
                            item?.decor_method_price?.length - 1
                          ]?.image_url
                        : animatedImg
                    }
                    alt=""
                    className="custom-w-h rounded-3"
                  />
                </div>
                <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
                  {item?.name}
                </p>
                <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
                  {item?.decorative_method?.legal_name}
                </p>
                <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0 mt-2">
                  Status
                  <div onClick={() => getDecorStatus(item?.id)}>
                    {item?.status == 0 ? (
                      <div>
                        <img src={toggleOff} className="toggleOnDes" />
                      </div>
                    ) : (
                      <div>
                        <img src={toggleOn} className="toggleOnDes" />
                      </div>
                    )}
                  </div>
                </p>
              </div>
            </div>
          );
        })}
      </div>{" "}
      <div className="d-flex justify-content-between mt-3">
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            page == 1 && "opacity-50"
          }`}
          onClick={() => setPage(page - 1)}
          disabled={page == 1 ? true : false}
        >
          Previous
        </button>
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            page == pagenation?.last_page && "opacity-50"
          }`}
          onClick={() => setPage(page + 1)}
          disabled={page == pagenation?.last_page ? true : false}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default DecorativeList;
