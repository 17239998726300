import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DeleteIcon from "@mui/icons-material/Delete";
import CallIcon from "@mui/icons-material/Call";
import EditIcon from "@mui/icons-material/Edit";
import { toggleOff, toggleOn } from "../../assets/img";

const AddContacts = ({
  setContactPopup,
  setPositionPopup,
  type,
  user,
  item,
  ind,
  inx,
  getVendorView,
  change,
  getPositionData,
}) => {
  return (
    <div className="w-100 d-grid mt-4 ac-jc searchField bg-white">
      <div className="d-flex w-100 ac-jb gap-3">
        <p className="f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 black">
          {user?.first_name} {user?.last_name ? user?.last_name : ""}
        </p>
        {type !== "view" && (
          <div className=" d-flex ms-5 ac-jc">
            <div onClick={() => change(user?.id)}>
              {user?.status == 1 ? (
                <img src={toggleOn} className="toggleOnDes1" />
              ) : (
                <img src={toggleOff} className="toggleOnDes1" />
              )}
            </div>
            {/* <CalendarMonthIcon className="pointerView ms-1 primary f2 fs-xs-19 fs-sm-20 fs-md-21 fs-lg-22 fs-xl-23 fs-xxl-24" /> */}
            <EditIcon
              onClick={() => getVendorView(item, user, ind, inx)}
              className="pointerView primary ms-1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25"
            />
            {/* <DeleteIcon className="pointerView ms-1 primary f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25" /> */}
          </div>
        )}
      </div>
      <div className="mt-3">
        <p className="f2 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14 primary">
          {user?.position}
        </p>
      </div>
      <div className="d-flex mt-3">
        <EmailOutlinedIcon className="pointerView primary f4 fs-xs-17 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21 fs-xxl-22" />
        <p className="f2 ms-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
          {user?.email}
        </p>
      </div>
      <div className=" d-flex mt-1 ac-jb gap-2">
        <div>
          {Array.isArray(user?.mobile_list) &&
            user?.mobile_list?.map((item, ind) => {
              return (
                <div className="d-flex mt-2" key={ind}>
                  <CallIcon className="pointerView primary f4 fs-xs-17 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21 fs-xxl-22" />
                  <p className="f2 ms-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
                    {item?.number}
                  </p>
                </div>
              );
            })}
        </div>

        {type !== "view" && (
          <button
            onClick={() => {
              getPositionData(user);
              setPositionPopup(true);
            }}
            className="cust-btn addbtn mt-sm-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
          >
            Position
          </button>
        )}
      </div>
    </div>
  );
};

export default AddContacts;
