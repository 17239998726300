import React, { useEffect, useState } from "react";
import TvIcon from "@mui/icons-material/Tv";
import ErrorIcon from "@mui/icons-material/Error";
import {
  sub_menu,
  Country,
  Hsn_type,
  Hsn_type_Child2,
  Hsn_type_Child3,
  Hsn_type_Child4,
  Supplier,
  supplier,
  supplier_rating,
  catogory_setting,
  Select_list,
} from "../../redux/api/DummyJson";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import { Edit, EditCalendarOutlined } from "@mui/icons-material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  DeleteForever,
} from "@mui/icons-material";
import { countriesList } from "../../redux/api/Country";
import { toggleOff, toggleOn } from "../../assets/img";
import { Col } from "react-bootstrap";

const LeftComp = ({
  edit,
  types,
  colorsData,
  setColorsData,
  selColors,
  setSelColors,
  parentCategoryList,
  // getCategoryId,
  subCategoryList,
  setSubCategoryList,
  subCategoryLists,
  setSubCategoryLists,
  setParentCategory,
  decorativeList,
  decorativeID,
  setDecorativeID,
  decorativeName,
  setDecorativeName,
  country,
  setCountry,
  hsnList,
  hsnCode,
  setHsnCode,
  hsnName,
  setHsnName,
  tagsList,
  tags,
  setTags,
  tagsName,
  setTagsName,
  colorsList,
  colorsID,
  setColorsID,
  colorsName,
  setColorsName,
  // varientCombination,
  varientShow,
  sizes,
  setSizes,
  materials,
  setMeterials,
  styles,
  setStyles,
  allVarients,
  // deleteVarient,
  catogoryName,
  setCatogoryName,
  searchValueSubCategory,
  setSearchSubValueCategory,
  productSuplierCode,
  setProductSuplierCode,
  // onSubCategorySelect,
  categoryID,
  setCategoryID,
  primaryCategoryIdErr,
  secondaryCategoryIdErr,
  name,
  setName,
  mrp,
  setMrp,
  decorationMethod,
  setDecorationMethod,
  productDescription,
  setProductDescription,
  productPackageSize,
  setProductPackageSize,
  productPackageSizeErr,
  setProductPackageSizeErr,
  productDescriptionErr,
  setProductDescriptionErr,
  decorationMethodErr,
  setDecorationMethodErr,
  mrpErr,
  setMrpErr,
  nameErr,
  setNameErr,
  countryErr,
  setCountryErr,
  categoryIDErr,
  setCategoryIDErr,
  setGendor,
  gendor,
  gendorErr,
  secondaryCategoryId,
  setSecondaryCategoryId,
  primaryCategoryId,
  setPrimaryCategoryId,
  // submitHandle,
  attributesList,
  productInformation,
  setProductInformation,
  varientsList,
  // submitVarient,
  setTagVal,
  tagVal,
  hsnCodeErr,
  tagErr,
  btn,
  submitBtn,
  catogory,
  setCatogory,
  listAttributes,
  setListAttributes,
  dynamicAttr,
  setDynamicAttr,
  viewData,
  vendorProCode,
  setVendorProCode,
  vendorProCodeErr,
  vendorRef,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location?.pathname;
  const data = location?.state?.data;
  // console.log(location)
  const [catogoryShow, setCatogoryShow] = useState(false);
  const [catogoryShow2, setCatogoryShow2] = useState(null);
  const [dropDown, setDropDown] = useState(null);
  const [subcatogory, setSubcatogory] = useState(false);
  const [Dropdown, setDrop] = useState(0);
  const [showDynamicAttr, setShowDynamicAttr] = useState(false);
  const [showVarient, setShowVarient] = useState(false);
  const [attributesShow, setAttributesShow] = useState(false);
  const [attributesName, setAttributesName] = useState([]);
  const [varients, setVarients] = useState(null);

  // Select Fuctction
  const [check, setCheck] = useState([]);
  const [decorativeLists, setDecorativeLists] = useState([]);
  const [selectALLSub, setSelectALLSub] = useState(true);
  const [selectALLTags, setSelectALLTags] = useState(true);
  const [selectALLProInfo, setSelectALLProInfo] = useState(true);
  const [selectALLColors, setSelectALLColors] = useState(true);
  const [searchValueSub, setSearchValueSub] = useState("");
  const [listCountries, setListCountries] = useState([]);
  const [listHSN, setListHSN] = useState([]);
  const [searchValueCountry, setSearchValueCountry] = useState("");
  const [searchValueGendor, setSearchValueGendor] = useState("");
  const [searchHsn, setSearchHsn] = useState("");
  const [listTags, setListTags] = useState([]);
  const [searchTags, setSearchTags] = useState("");
  const [searchProInfo, setSearchProInfo] = useState("");
  const [searchColors, setSearchColors] = useState("");
  const [listColors, setListColors] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [searchValueCategory, setSearchValueCategory] = useState("");

  const gendorList = ["NA", "U", "M", "F", "K"];
  const [listGendor, setListGendor] = useState([]);

  const [checkBox, setCheckBox] = useState(false);

  useEffect(() => {
    if (decorativeList?.length > 0) {
      setDecorativeLists(decorativeList);
    }
    if (hsnList?.length > 0) {
      setListHSN(hsnList);
    }
    if (tagsList?.length > 0) {
      setListTags(tagsList);
    }
    if (colorsList?.length > 0) {
      setListColors(colorsList);
    }
    if (parentCategoryList?.length > 0) {
      setCategoryList(parentCategoryList);
    }

    if (attributesList?.length > 0) {
      setListAttributes(attributesList);
    }

    setListGendor(gendorList);
    setListCountries(countriesList);
  }, [
    decorativeList,
    hsnList,
    tagsList,
    colorsList,
    parentCategoryList,
    attributesList,
  ]);

  // SEARCH CATEGROY
  const searchCategory = (event) => {
    let temp = [...parentCategoryList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setCategoryList(search);
    } else {
      setCategoryList(parentCategoryList);
    }

    setSearchValueCategory(event);
  };

  // SUB CATEGORY SEARCH
  const searchSubCatFun = (event, ind) => {
    let temp = [...subCategoryLists];
    let tempX = temp[ind];
    let temps = [...subCategoryList];
    let tempXs = temps[ind];
    let search = tempX?.child_categories?.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    // console.log("search", search);

    tempXs = search;

    let obj = {
      ...subCategoryList[ind],
      child_categories: tempXs,
    };

    let arr = [...subCategoryList];
    arr[ind] = obj;

    // console.log("tempXs", obj, arr);

    if (event?.length > 0) {
      setSubCategoryList(arr);
    } else {
      // console.log("jjj");
      setSubCategoryList(subCategoryLists);
    }

    setSearchSubValueCategory((val) => ({ ...val, [ind]: event }));
  };

  // SELECT CATEGORY
  const onCategorySelct = (item) => {
    setCatogoryName({ [item?.id]: "" });
    setCatogoryShow(false);
    setCatogory(item?.name);
    setPrimaryCategoryId(item?.id);
    setSubcatogory(true);
    setProductSuplierCode("");
    // getCategoryId(item?.id, "category");
    setCategoryList(parentCategoryList);

    setSearchValueCategory("");
    setDropDown(null);
    // setParentCategory(item?.id);
  };

  // Decoration Method START
  const allSelectSub = () => {
    let decorativeListId = [];
    let decorativeListName = [];
    if (selectALLSub) {
      decorativeList?.map((item, ind) => {
        decorativeListId.push(item?.id);
        decorativeListName.push(item?.name);
        setDecorationMethod(item?.id);
      });

      setDecorativeID(decorativeListId);
      setDecorativeName(decorativeListName);
    } else {
      setDecorativeID(decorativeListId);
      setDecorativeName(decorativeListName);
    }
  };

  const searchSub = (event) => {
    let temp = [...decorativeList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setDecorativeLists(search);
    } else {
      setDecorativeLists(decorativeList);
    }

    setSearchValueSub(event);
  };

  const onSubSelect = (event, id, type) => {
    let temp = [...decorativeName];
    let temps = [...decorativeID];

    if (temp.includes(event)) {
      const listtemp = temp.indexOf(event);
      const list = [...temp];
      list.splice(listtemp, 1);
      setDecorativeName(list);
    } else {
      setDecorativeName([...temp, event]);
    }

    if (temps.includes(id)) {
      const listtemp = temps.indexOf(id);
      const list = [...temps];
      list.splice(listtemp, 1);
      setDecorativeID(list);
    } else {
      setDecorativeID([...temps, id]);
    }

    if (type !== "check") {
      setDropDown(null);
    }

    setSearchValueSub("");
    setDecorativeLists(decorativeList);
    setDecorationMethod(id);
  };
  // Decoration Method END

  // COUNTY START
  const searchCountry = (event) => {
    let temp = [...countriesList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );
    if (event?.length > 0) {
      setListCountries(search);
    } else {
      setListCountries(countriesList);
    }

    setSearchValueCountry(event);
  };

  const getCountry = (event) => {
    setDropDown(null);
    setCountry(event);
    setSearchValueCountry("");
    setListCountries(countriesList);
  };
  // COUNTY END

  // GENDOR SEARCH
  const searchGendor = (event) => {
    let temp = [...gendorList];
    let search = temp.filter((value) =>
      value?.toLowerCase()?.includes(event?.toLowerCase())
    );
    if (event?.length > 0) {
      setListGendor(search);
    } else {
      setListGendor(gendorList);
    }

    setSearchValueGendor(event);
  };

  // GENDOR SELECT
  const onSelectGendor = (item) => {
    setGendor(item);
    setDropDown(null);
    setSearchValueGendor("");
    setListGendor(gendorList);
  };

  // HSN START
  const searchHSNValue = (event) => {
    let temp = [...hsnList];
    let search = temp.filter((value) =>
      value?.code?.toLowerCase()?.includes(event?.toLowerCase())
    );
    if (event?.length > 0) {
      setListHSN(search);
    } else {
      setListHSN(hsnList);
    }

    setSearchHsn(event);
  };

  const getHSN = (event) => {
    setDropDown(null);
    setHsnCode(event?.id);
    setHsnName(event?.code);
    setSearchHsn("");
    setListHSN(hsnList);
  };
  // HSN END

  // TAGS START
  const allSelectTags = () => {
    let ListId = [];
    let ListName = [];
    if (selectALLTags) {
      tagsList?.map((item, ind) => {
        ListId.push(item?.id);
        ListName.push(item?.name);
        setTagVal(item?.id);
      });

      setTags(ListId);
      setTagsName(ListName);
    } else {
      setTags(ListId);
      setTagsName(ListName);
    }

    setSelectALLTags(!selectALLTags);
  };

  const onTagsSelect = (event, id, type) => {
    let temp = [...tags];
    let temps = [...tagsName];

    if (temp.includes(id)) {
      const listtemp = temp.indexOf(id);
      const list = [...temp];
      list.splice(listtemp, 1);
      setTags(list);
    } else {
      setTags([...temp, id]);
    }

    if (temps.includes(event)) {
      const listtemp = temps.indexOf(event);
      const list = [...temps];
      list.splice(listtemp, 1);
      setTagsName(list);
    } else {
      setTagsName([...temps, event]);
    }

    if (type !== "check") {
      setDropDown(null);
    }

    setSearchTags("");
    setListTags(tagsList);
    setTagVal(id);
  };

  const searchTagsFun = (event) => {
    let temp = [...tagsList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setListTags(search);
    } else {
      setListTags(tagsList);
    }

    setSearchTags(event);
  };
  // TAGS END

  // COLORS START
  const allSelectColors = () => {
    let ListId = [];
    let ListName = [];
    if (selectALLColors) {
      colorsList?.map((item, ind) => {
        ListId.push({ id: item?.id });
        ListName.push(item?.name);
      });

      setColorsID(ListId);
      setColorsName(ListName);
      setColorsData(colorsList);
    } else {
      setColorsID(ListId);
      setColorsName(ListName);
      setColorsData([]);
    }

    setSelectALLColors(!selectALLColors);
  };

  const onColorsSelect = (event, id, item, ind, type) => {
    let temp = [...colorsID];
    let temps = [...colorsName];
    let temx = [...colorsData];

    if (temp.find((i) => i.id == id)) {
      let finder = temp.filter((i) => i.id !== id);
      setColorsID(finder);
    } else {
      let obj = {
        id: id,
      };
      temp.push(obj);
      setColorsID(temp);
    }

    if (temps.includes(event)) {
      const listtemp = temps.indexOf(event);
      const list = [...temps];
      list.splice(listtemp, 1);
      setColorsName(list);
    } else {
      setColorsName([...temps, event]);
    }

    // if (temx.includes(item)) {
    //   const listtemp = temx.indexOf(item);
    //   const list = [...temx];
    //   list.splice(listtemp, 1);
    //   setColorsData(list);
    // } else {
    //   setColorsData([...temx, item]);
    // }

    if (temx.find((i) => i.id == id)) {
      let finder = temx.filter((i) => i.id !== id);
      setColorsData(finder);
    } else {
      temx.push(item);
      setColorsData(temx);
    }

    if (type !== "check") {
      setVarients(null);
    }

    setSearchColors("");
    setListColors(colorsList);
  };

  // console.log("colorsData", colorsData);
  // console.log("colorsID", colorsID);

  const searchColorsFun = (event) => {
    let temp = [...colorsList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setListColors(search);
    } else {
      setListColors(colorsList);
    }

    setSearchColors(event);
  };
  // COLORS END

  // SIZE START
  const handleAdd = () => {
    const abc = [...sizes];
    abc.push({ value: "" });
    setSizes(abc);
  };
  const handleChange = (event, ind, type) => {
    const inputdata = [...sizes];
    let obj = {
      ...inputdata[ind],
      value: event,
    };
    inputdata[ind] = obj;
    setSizes(inputdata);
  };
  const handleDelete = (i) => {
    const deletVal = [...sizes];
    deletVal.splice(i, 1);
    setSizes(deletVal);
  };
  // SIZE END

  // MATERIAL START
  const handleMatAdd = () => {
    const abc = [...materials];
    abc.push({ value: "" });
    setMeterials(abc);
  };
  const handleMatChange = (event, ind, type) => {
    const inputdata = [...materials];
    let obj = {
      ...inputdata[ind],
      value: event,
    };
    inputdata[ind] = obj;
    setMeterials(inputdata);
  };
  const handleMatDelete = (i) => {
    const deletVal = [...materials];
    deletVal.splice(i, 1);
    setMeterials(deletVal);
  };
  // MATERIAL END

  // STYLES START
  const handleStyleAdd = () => {
    const abc = [...styles];
    abc.push({ value: "" });
    setStyles(abc);
  };
  const handleStyleChange = (event, ind, type) => {
    const inputdata = [...styles];
    let obj = {
      ...inputdata[ind],
      value: event,
    };
    inputdata[ind] = obj;
    setStyles(inputdata);
  };
  const handleStyleDelete = (i) => {
    const deletVal = [...styles];
    deletVal.splice(i, 1);
    setStyles(deletVal);
  };
  // STYLES END

  const onDynamicAttributeSelect = (event, id, ind) => {
    let temp = [...productInformation];

    let finder = temp.find((i) => i.id == id);

    if (finder?.id) {
      let inx = temp.findIndex((i) => i.id == id);
      temp.splice(inx, 1);
    } else {
      temp.push({
        id: id,
        title: event,
        value: "",
      });
    }

    setProductInformation(temp);

    if (dynamicAttr.includes(event)) {
      const listtemp = dynamicAttr?.indexOf(event);
      const list = [...dynamicAttr];
      list.splice(listtemp, 1);
      setDynamicAttr(list);
    } else {
      setDynamicAttr([...dynamicAttr, event]);
    }

    setListAttributes(attributesList);
    setSearchProInfo("");
  };

  // handle Product Information
  const handleProductInformation = (event, ind) => {
    let temp = [...productInformation];
    temp[ind]["value"] = event;
    setProductInformation(temp);
  };

  const onColorSelect = (e) => {
    if (selColors.includes(e)) {
      const listtemp = selColors.indexOf(e);
      const list = [...selColors];
      list.splice(listtemp, 1);
      setSelColors(list);
    } else {
      setSelColors([...selColors, e]);
    }
  };

  const onClickShowVarient = () => {
    setShowVarient(!showVarient);
  };

  const onAttribute = (e) => {
    setAttributesName(e.target.value);
    if (decorativeName.length >= 2) {
      setAttributesShow(true);
    } else {
      if (decorativeName.length >= 0) setAttributesShow(false);
    }
  };
  const onAttributeClick = (e) => {
    if (attributesName?.includes(e)) {
      const listtemp = attributesName?.indexOf(e);
      const list = [...attributesName];
      list.splice(listtemp, 1);
      setAttributesName(list);
    } else {
      setAttributesName([...attributesName, e]);
    }
  };

  // TAGS START
  const allSelectProductInfo = () => {
    let ListId = [];
    let ListName = [];
    if (selectALLProInfo) {
      attributesList?.map((item, ind) => {
        ListId.push({ id: item?.id, title: item?.name, value: "" });
        ListName.push(item?.name);
      });

      setProductInformation(ListId);
      setDynamicAttr(ListName);
    } else {
      setProductInformation(ListId);
      setDynamicAttr(ListName);
    }

    setSelectALLProInfo(!selectALLProInfo);
  };

  const searchProductInfoFun = (event) => {
    let temp = [...attributesList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setListAttributes(search);
    } else {
      setListAttributes(attributesList);
    }

    setSearchProInfo(event);
  };
  // TAGS END

  // console.log("catogoryName", catogoryName);

  // console.log("allVarients", allVarients);

  const getView = () => {
    let temp = [];

    if (viewData?.product_details?.colors?.length > 0) {
      temp.push("Color");
    }

    if (viewData?.product_details?.sizes?.length > 0) {
      temp.push("Size");
    }

    if (viewData?.product_details?.materials?.length > 0) {
      temp.push("Materials");
    }

    if (viewData?.product_details?.styles?.length > 0) {
      temp.push("Style");
    }

    setAttributesName(temp);
    setSubcatogory(true);
  };

  useEffect(() => {
    if (location?.state?.data?.id && viewData?.product_details?.id) {
      getView();
    }
  }, [viewData]);

  // console.log("varientShow", varientShow);

  return (
    <div className="w-100">
      <div className="d-flex flex-wrap as-jb flex-m-r">
        <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
          <p className="text-dark f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Category
          </p>
          <fieldset disabled={location?.state?.data?.id ? true : false}>
            <div className="position-relative z-3">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={catogory}
                onClick={(e) => {
                  setCatogoryShow(!catogoryShow);
                }}
                readOnly
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setCatogoryShow(!catogoryShow);
                  setDropDown(null);
                  setAttributesShow(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
          </fieldset>
          {primaryCategoryId?.length == 0 && primaryCategoryIdErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Select Category</p>
            </div>
          )}
          {catogoryShow && (
            <div
              className="invisible-cont2 z-0"
              onClick={() => setCatogoryShow(!catogoryShow)}
            />
          )}
          <div
            className={`${
              catogoryShow && "submenu_1"
            } submenu_cont_1 overflow-scroll z-3`}
          >
            <div className="d-flex gap-1">
              <input
                className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                placeholder="Search..."
                onChange={(e) => searchCategory(e.target.value)}
                value={searchValueCategory}
              />
            </div>
            {categoryList?.map((item, ind) => {
              return (
                <div className="d-flex hover-cust" key={ind}>
                  <button
                    className="px-2 cust-btn text-start py-1 w-100"
                    onClick={() => {
                      onCategorySelct(item);
                      // setParentCategory(item?.id);
                    }}
                  >
                    {item?.name}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        {subcatogory && (
          <>
            {subCategoryList?.map((item, ind) => {
              return (
                <div
                  className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative"
                  key={ind}
                >
                  <fieldset disabled={location?.state?.data?.id ? true : false}>
                    <p
                      className={`black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ${
                        location?.state?.data?.id && "text-white"
                      }`}
                    >
                      {item?.category?.name}
                    </p>
                    <div className="position-relative">
                      <input
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                        value={catogoryName?.[item?.category?.id]}
                        onClick={() => setCatogoryShow2(ind)}
                        readOnly
                      />
                      <button
                        className="drop_down cust-btn"
                        onClick={() => {
                          setCatogoryShow2(ind);
                          setDropDown(null);
                          setCatogoryShow(false);
                          setAttributesShow(false);
                        }}
                      >
                        <KeyboardArrowDownIcon />
                      </button>
                    </div>
                    {catogoryShow2 == ind && (
                      <div
                        className="invisible-cont2 z-0"
                        onClick={() => setCatogoryShow2(null)}
                      />
                    )}
                    <div
                      className={`${
                        catogoryShow2 == ind && "submenu_1"
                      } submenu_cont_1 overflow-scroll z-3`}
                    >
                      <div className="d-flex gap-1">
                        <input
                          className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                          placeholder="Search..."
                          onChange={(e) => searchSubCatFun(e.target.value, ind)}
                          value={searchValueSubCategory[ind]}
                        />
                      </div>
                      {item?.child_categories?.map((subItem, indx) => {
                        return (
                          <div className="d-flex hover-cust" key={indx}>
                            <button
                              className="px-2 cust-btn text-start py-1 w-100"
                              onClick={() => {
                                setCatogoryShow2(null);
                                // onSubCategorySelect(subItem, indx, ind);
                              }}
                            >
                              {subItem?.name}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                    {ind == 0 &&
                      secondaryCategoryId?.length == 0 &&
                      secondaryCategoryIdErr && (
                        <div className="d-flex gap-1 mt-2">
                          <ErrorIcon className="svg_log" />
                          <p className="err-input_log mt-1">
                            Select Sub Category 1
                          </p>
                        </div>
                      )}
                    {ind == 1 && categoryID?.length == 0 && categoryIDErr && (
                      <div className="d-flex gap-1 mt-2">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">
                          Select Sub Category 2
                        </p>
                      </div>
                    )}
                  </fieldset>
                </div>
              );
            })}

            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Sub Category Code
              </p>
              <div className="position-relative">
                <input
                  placeholder="Required Field"
                  className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  value={productSuplierCode}
                  onChange={(e) => setProductSuplierCode(e.target.value)}
                  type="text"
                  disabled
                />
              </div>
            </div>
          </>
        )}
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Product Name
          </p>
          <div className="position-relative">
            <input
              placeholder="Required Field"
              className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              disabled
            />
          </div>
          {name?.length == 0 && nameErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Name</p>
            </div>
          )}
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            SKU Code
          </p>
          <input
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            value="SKU0001"
            // onChange={(e) => {
            //   setProductSuplierCode(e.target.value);
            // }}
            disabled
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Vendor Product Code
          </p>
          <input
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            value={vendorProCode}
            onChange={(e) => {
              setVendorProCode(e.target.value);
            }}
            ref={vendorRef}
            disabled={
              location?.state?.type == "approve"
                ? true
                : path == "/add-new-product/products/product-detail" &&
                  !data?.vendorRequestDetails?.status
                ? false
                : path == "/add-new-product/products/product-detail" &&
                  data?.vendorRequestDetails?.status
                    ?.toLowerCase()
                    .startsWith("reque")
                ? false
                : true
            }
          />
          {vendorProCode?.length == 0 && vendorProCodeErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Vendor Product Code</p>
            </div>
          )}
        </div>

        <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Decoration Method
          </p>

          <fieldset disabled={location?.state?.data?.id ? true : false}>
            <div className="position-relative">
              <input
                placeholder="Decorative method"
                type="text"
                value={
                  decorativeName?.length > 0
                    ? decorativeName?.toString()
                    : "Select Decorative method"
                }
                onClick={() => {
                  setDropDown(0);
                }}
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDropDown(0);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDown == 0 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => {
                    setDropDown(null);
                  }}
                />
              )}
              <div
                className={`${
                  dropDown == 0 && "submenu_1 py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchSub(e.target.value)}
                    value={searchValueSub}
                  />
                  <button
                    className="border-0 bg-transparent"
                    onClick={() => {
                      setSelectALLSub(!selectALLSub);
                      allSelectSub();
                    }}
                  >
                    All
                  </button>
                </div>
                {decorativeLists?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      key={ind}
                    >
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                        onClick={() => {
                          onSubSelect(item?.name, item?.id);
                        }}
                      >
                        {item?.name}
                      </button>
                      <button
                        className="px-2 cust-btn text-start"
                        style={{ fontSize: "large" }}
                        onClick={() => {
                          onSubSelect(item?.name, item?.id, "check");
                        }}
                      >
                        {decorativeName?.includes(item?.name) ? (
                          <CheckCircleIcon className="primary" />
                        ) : (
                          <RadioButtonUncheckedIcon className="primary" />
                        )}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
          </fieldset>

          {decorationMethod?.length == 0 && decorationMethodErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Select Decoration Method</p>
            </div>
          )}
        </div>
        <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Country of Origin
          </p>
          <fieldset disabled={location?.state?.data?.id ? true : false}>
            <div className="position-relative">
              <input
                placeholder="Country"
                type="text"
                value={
                  country?.length > 0 ? country?.toString() : "Select Country"
                }
                onClick={() => {
                  setDropDown(1);
                }}
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDropDown(1);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDown == 1 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDown(null)}
                />
              )}
              <div
                className={`${
                  dropDown == 1 && "submenu_1 py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchCountry(e.target.value)}
                    value={searchValueCountry}
                  />
                </div>
                {listCountries?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      onClick={() => {
                        getCountry(item?.name);
                      }}
                      key={ind}
                    >
                      <button className="px-2 cust-btn text-start py-1 w-100 ">
                        {item?.name}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
          </fieldset>
          {country?.length == 0 && countryErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">
                Select Registered Address Country
              </p>
            </div>
          )}
        </div>

        <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Gendor
          </p>

          <fieldset disabled={location?.state?.data?.id ? true : false}>
            <div className="position-relative">
              <input
                placeholder="Gendor"
                type="text"
                value={
                  gendor?.length > 0 ? gendor?.toString() : "Select Gendor"
                }
                onClick={() => {
                  setDropDown(11);
                }}
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDropDown(11);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDown == 11 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDown(null)}
                />
              )}
              <div
                className={`${
                  dropDown == 11 && "submenu_1 h-auto py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchGendor(e.target.value)}
                    value={searchValueGendor}
                  />
                </div>
                {listGendor?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      onClick={() => {
                        onSelectGendor(item);
                      }}
                      key={ind}
                    >
                      <button className="px-2 cust-btn text-start py-1 w-100 ">
                        {item}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
          </fieldset>
          {gendor?.length == 0 && gendorErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Select Gendor</p>
            </div>
          )}
        </div>

        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Product Packaging Size
          </p>
          <input
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            onChange={(e) => setProductPackageSize(e.target.value)}
            value={productPackageSize}
            // type={"number"}
            disabled
          />
          {productPackageSize?.length == 0 && productPackageSizeErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Product Packaging Size</p>
            </div>
          )}
        </div>
        <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            HSN Code
          </p>

          <fieldset disabled={location?.state?.data?.id ? true : false}>
            <div className="position-relative">
              <input
                placeholder="HSN Code"
                type="text"
                value={hsnName ? hsnName : "Select HSN Code"}
                onClick={() => {
                  setDropDown(2);
                }}
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDropDown(2);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDown == 2 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDown(null)}
                />
              )}
              <div
                className={`${
                  dropDown == 2 && "submenu_1  py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchHSNValue(e.target.value)}
                    value={searchHsn}
                  />
                </div>
                {listHSN?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      onClick={() => {
                        getHSN(item);
                      }}
                      key={ind}
                    >
                      <button className="px-2 cust-btn text-start py-1 w-100 ">
                        {item?.code}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
          </fieldset>
          {hsnCode?.length == 0 && hsnCodeErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Select HSN Code</p>
            </div>
          )}
        </div>
        <div className="w-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
            Product Description
          </p>
          <textarea
            placeholder="Autogenerate"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            onChange={(e) => setProductDescription(e.target.value)}
            value={productDescription}
            readOnly
          />
          {productDescription?.length == 0 && productDescriptionErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Product Description</p>
            </div>
          )}
        </div>

        <div className="mt-5 w-md-100 w-sm-100 w-xs-100 position-relative">
          <p className="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
            Other Product Information
          </p>

          <fieldset disabled={location?.state?.data?.id ? true : false}>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={dynamicAttr}
                onClick={(e) => setShowDynamicAttr(!showDynamicAttr)}
                readOnly
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setShowDynamicAttr(!showDynamicAttr);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
          </fieldset>
          {showDynamicAttr && (
            <div
              className="invisible-cont2"
              onClick={() => setShowDynamicAttr(!showDynamicAttr)}
            />
          )}
          <div
            className={`${
              showDynamicAttr && "submenu_1 "
            } submenu_cont_1 overflow-scroll z-3`}
          >
            <div className="d-flex gap-1">
              <input
                className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                placeholder="Search..."
                onChange={(e) => searchProductInfoFun(e.target.value)}
                value={searchProInfo}
              />
              <button
                className="border-0 bg-transparent"
                onClick={() => {
                  allSelectProductInfo();
                }}
              >
                All
              </button>
            </div>
            {listAttributes?.map((item, ind) => {
              return (
                <div className="d-flex as-jb hover-cust" key={ind}>
                  <button
                    className="px-2 cust-btn text-start py-1 w-100 "
                    onClick={() => {
                      onDynamicAttributeSelect(item?.name, item?.id, ind);
                      setShowDynamicAttr(false);
                    }}
                  >
                    {item?.name}
                  </button>
                  <button
                    className="px-2 cust-btn text-start"
                    onClick={() => {
                      onDynamicAttributeSelect(item?.name, item?.id, ind);
                    }}
                  >
                    {dynamicAttr?.includes(item?.name) ? (
                      <CheckCircleIcon className="primary" />
                    ) : (
                      <RadioButtonUncheckedIcon className="primary" />
                    )}

                    {dynamicAttr?.includes(item?.list)
                      ? item?.check
                      : item.uncheck}
                  </button>
                </div>
              );
            })}
          </div>
        </div>

        {productInformation?.map((item, ind) => {
          return (
            <div
              className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100"
              key={ind}
            >
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                {item?.title}
              </p>
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                onChange={(e) => handleProductInformation(e.target.value, ind)}
                value={item?.value}
                disabled
              />
            </div>
          );
        })}

        {location?.state?.type !== "edit" && submitBtn !== 0 && (
          <div className="mt-3 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
              Add Variants
            </p>
            <div className="position-relative  w-sm-100 w-xs-100 x-md-100">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={attributesName}
                onClick={() => setAttributesShow(!attributesShow)}
                readOnly
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setAttributesShow(!attributesShow);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
            {attributesShow && (
              <div
                className="invisible-cont2"
                onClick={() => setAttributesShow(!attributesShow)}
              />
            )}
            <div
              className={`${
                attributesShow && "submenu_1 h-auto"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {Select_list?.map((item, ind) => {
                return (
                  <div className="d-flex as-jb hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100 "
                      onClick={() => {
                        onAttributeClick(item?.list);
                        setAttributesShow(false);
                      }}
                    >
                      {item?.list}
                    </button>
                    <button
                      className="px-2 cust-btn text-start"
                      onClick={() => {
                        onAttributeClick(item?.list);
                      }}
                    >
                      {attributesName.includes(item?.list)
                        ? item?.check
                        : item.uncheck}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {attributesName?.includes("Color") && (
          <div className=" w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f4 fs-xs-12 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-2 ">
              Color
            </p>
            <fieldset disabled={location?.state?.data?.id ? true : false}>
              <div className="position-relative">
                <input
                  placeholder="Color"
                  type="text"
                  value={
                    colorsName?.length > 0
                      ? colorsName?.toString()
                      : "Select Color"
                  }
                  onClick={() => {
                    setVarients(0);
                  }}
                  className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setVarients(0);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
                {varients == 0 && (
                  <div
                    className="invisible-cont2 z-0"
                    onClick={() => setVarients(null)}
                  />
                )}
                <div
                  className={`${
                    varients == 0 && "submenu_1  py-2"
                  } submenu_cont_1 overflow-scroll z-3`}
                >
                  <div className="d-flex gap-1">
                    <input
                      className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                      placeholder="Search..."
                      onChange={(e) => searchColorsFun(e.target.value)}
                      value={searchColors}
                    />
                    <button
                      className="border-0 bg-transparent"
                      onClick={() => {
                        allSelectColors();
                      }}
                    >
                      All
                    </button>
                  </div>
                  {listColors?.map((item, ind) => {
                    return (
                      <button
                        className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                        key={ind}
                      >
                        <button
                          className="px-2 cust-btn text-start py-1 w-100 "
                          onClick={() => {
                            onColorsSelect(item?.name, item?.id, item, ind);
                          }}
                        >
                          {item?.name}
                        </button>
                        <button
                          className="px-2 cust-btn text-start"
                          style={{ fontSize: "large" }}
                          onClick={() => {
                            onColorsSelect(
                              item?.name,
                              item?.id,
                              item,
                              ind,
                              "check"
                            );
                          }}
                        >
                          {colorsName?.includes(item?.name) ? (
                            <CheckCircleIcon
                              style={{ color: item?.hex_code }}
                            />
                          ) : (
                            <RadioButtonUncheckedIcon className="primary" />
                          )}
                        </button>
                      </button>
                    );
                  })}
                </div>
              </div>
            </fieldset>
          </div>
        )}

        {sizes?.length > 0 && attributesName?.includes("Size") && (
          <div className=" w-md-100 w-sm-100 w-xs-100">
            <p className=" black f4 fs-xs-12 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-2 ">
              Size
            </p>
            {/* <div className="d-flex justify-content-start me-3 ">
              <div className="editBtn text-center  rounded-3 mt-2 p-2  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                <button
                  className="border-0 primary f3 "
                  onClick={() => handleAdd()}
                >
                  +Add New
                </button>
              </div>
            </div> */}
            <div className="d-flex flex-wrap w-100 gap-2">
              {sizes?.map((data, i) => {
                return (
                  <div
                    className="d-flex  w-md-100 w-sm-100 w-xs-100"
                    style={{ width: "30% " }}
                  >
                    <div className="position-relative add w-100">
                      <input
                        value={data?.value}
                        disabled
                        onChange={(e) =>
                          handleChange(e.target.value, i, "value")
                        }
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      />
                    </div>
                    {/* {sizes?.length > 1 && (
                      <button className="mx-2 d-flex ac-jc cp cust-btn">
                        <DeleteForever
                          className="primary"
                          onClick={() => handleDelete(i)}
                        />
                      </button>
                    )} */}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {materials?.length > 0 && attributesName?.includes("Materials") && (
          <div className=" w-md-100 w-sm-100 w-xs-100">
            <p className=" black f4 fs-xs-12 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-2 ">
              Materials
            </p>
            {/* <div className="d-flex justify-content-start me-3 ">
              <div className="editBtn text-center  rounded-3 mt-2 p-2  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                <button
                  className="border-0 primary f3 "
                  onClick={() => handleMatAdd()}
                >
                  +Add New
                </button>
              </div>
            </div> */}
            <div className="d-flex flex-wrap w-100">
              {materials?.map((data, i) => {
                return (
                  <div
                    className="d-flex  w-md-100 w-sm-100 w-xs-100"
                    style={{ width: "30% " }}
                  >
                    <div className="position-relative add w-100">
                      <input
                        value={data?.value}
                        disabled
                        onChange={(e) =>
                          handleMatChange(e.target.value, i, "value")
                        }
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      />
                    </div>
                    {sizes?.length > 1 && (
                      <button className="mx-2 d-flex ac-jc cp cust-btn">
                        <DeleteForever
                          className="primary"
                          onClick={() => handleMatDelete(i)}
                        />
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {styles?.length > 0 && attributesName?.includes("Style") && (
          <div className=" w-md-100 w-sm-100 w-xs-100">
            <p className=" black f4 fs-xs-12 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-2 ">
              Style
            </p>
            {/* <div className="d-flex justify-content-start me-3 ">
              <div className="editBtn text-center  rounded-3 mt-2 p-2  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                <button
                  className="border-0 primary f3 "
                  onClick={() => handleStyleAdd()}
                >
                  +Add New
                </button>
              </div>
            </div> */}
            <div className="d-flex flex-wrap w-100">
              {styles?.map((data, i) => {
                return (
                  <div
                    className="d-flex  w-md-100 w-sm-100 w-xs-100"
                    style={{ width: "30% " }}
                  >
                    <div className="position-relative add w-100">
                      <input
                        value={data?.value}
                        disabled
                        onChange={(e) =>
                          handleStyleChange(e.target.value, i, "value")
                        }
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      />
                    </div>
                    {sizes?.length > 1 && (
                      <button className="mx-2 d-flex ac-jc cp cust-btn">
                        <DeleteForever
                          className="primary"
                          onClick={() => handleStyleDelete(i)}
                        />
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {varientShow && (
          <div className="w-100 d-flex flex-wrap mt-4">
            {varientsList?.map((item, ind) => {
              return (
                <Col md={12} lg={12} xl={12} xxl={12}>
                  <table className="w-100 mt-2" key={ind}>
                    <tr className="mt-4">
                      <div className="">
                        <td>
                          <div className="d-flex w-100">
                            <div className="mx-2">
                              <p
                                className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-start primary f3"
                                style={{ width: "200px" }}
                              >
                                {item?.parent_name}
                              </p>
                            </div>
                          </div>
                        </td>
                      </div>
                    </tr>
                    {item?.childs?.map((child, inx) => {
                      return (
                        <tr className="mt-2">
                          <div className="my-3">
                            <td>
                              <div className="d-flex w-100">
                                <div className="mx-2 d-flex gap-3">
                                  <div
                                    className="num-cls-btn d-flex overflow-scroll p-2"
                                    style={{ width: "auto" }}
                                  >
                                    <p className="text-wrap px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-nowrap">
                                      {child?.sku_code}
                                    </p>
                                  </div>
                                  <div
                                    className="num-cls-btn d-flex overflow-scroll p-2"
                                    style={{ width: "auto" }}
                                  >
                                    <p className="text-wrap px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-nowrap">
                                      {child?.sku_name}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex ac-js">
                                  {child?.status == 0 ? (
                                    <div>
                                      <img
                                        src={toggleOff}
                                        className="toggleOnDes"
                                      />
                                    </div>
                                  ) : (
                                    <div>
                                      <img
                                        src={toggleOn}
                                        className="toggleOnDes"
                                      />
                                    </div>
                                  )}
                                </div>
                                {/* <button className="mx-2 d-flex ac-jc cp cust-btn">
                                  <DeleteForever
                                    className="primary"
                                    onClick={() => deleteVarient(ind, inx)}
                                  />
                                </button> */}
                              </div>
                            </td>
                          </div>
                        </tr>
                      );
                    })}
                  </table>
                </Col>
              );
            })}
          </div>
        )}
      </div>

      {/* <div className="w-100 my-3">
        <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
          Product Website
        </p>
        {/* <button className="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded float-end"
                    onClick={() => navigate('/vendor-details')}>Back</button> 
        <div className="d-flex ac-js gap-2">
          <TvIcon className="primary text-decoration-underline cp f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start" />
          <p className="primary text-decoration-underline cp f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
            Link will open a new tab
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default LeftComp;
