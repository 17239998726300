import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";
import { useLoginMutation } from "../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";
import { logos } from "../assets/img";

const SignInScreen = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage?.getItem("token")) {
      navigate("/home");
    }
  }, []);

  //RTK
  const [loginapi] = useLoginMutation();

  let [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  let [passwordStringerr, setPasswordStringerr] = useState("");

  let [emailerr, setEmailerr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);
  const [btn, setBtn] = useState(false);

  const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);

  const handlePassword = (event) => {
    let new_pass = event.target.value;
    if (new_pass?.length !== 0) {
      setPassword(new_pass);

      // regular expressions to validate password
      var lowerCase = /[a-z]/g;
      var upperCase = /[A-Z]/g;
      var numbers = /[0-9]/g;
      var special = /[*@!#%&()^~{}]+/g;
      if (!new_pass.match(lowerCase)) {
        setPasswordStringerr("Password should contains lowercase letters!");
      } else if (!new_pass.match(upperCase)) {
        setPasswordStringerr("Password should contain uppercase letters!");
      } else if (!new_pass.match(numbers)) {
        setPasswordStringerr("Password should contains numbers also!");
      } else if (new_pass.length < 6) {
        setPasswordStringerr("Password length should be more than 6.");
      } else if (!new_pass.match(special)) {
        setPasswordStringerr(
          "Password should contains special character also!"
        );
      } else {
        // setErrorMessage("Password is strong!");
        setPasswordStringerr("");
      }
    } else {
      setPasswordStringerr("");
      setPassword(new_pass);
    }
  };

  const submitHandler = () => {
    if (email.length === 0 || password.length == 0) {
      setEmailerr(true);
      setPasswordErr(true);
    } else if (passwordStringerr?.length == 0) {
      const formdata = new FormData();
      formdata.append("email", email);
      formdata.append("password", password);
      setBtn(true);
      dispatch(saveLoader(true));
      loginapi(formdata)
        .unwrap()
        .then((res) => {
          //  console.log(res,"login")
          setBtn(false);
          dispatch(saveLoader(false));
          if (res?.status == "success") {
            toast.success(res?.message);
            localStorage.setItem("token", res?.token);
            localStorage.setItem("user", JSON.stringify(res?.vendor_user));
            localStorage.setItem(
              "permissions",
              JSON.stringify(res?.permissions)
            );
            setTimeout(() => {
              navigate("/home");
              window.location.reload();
            }, 500);
          }
        })
        .catch((err) => {
          setBtn(false);
          dispatch(saveLoader(false));
          console.log("err", err);
        });
    }
  };

  const keydownHandler = (event) => {
    if (event.key == "Enter") {
      submitHandler();
    }
  };

  return (
    <div className="signInDes d-flex">
      <div className="w-50 h-100 p-4 bg-back-blue">
        {/* <p className="w-100 f4 fs-sm-26 fs-md-27 fs-lg-28 fs-xl-29 fs-xxl-30 primary">
          Merchhq
        </p> */}
        <img src={logos} className="logo" alt="" style={{ width: "14rem" }} />
        <div className="signLeftDes" />
      </div>
      <div className="w-50 position-relative">
        <div className="viewBoxDesSign w-sm-100 w-md-90 w-lg-80 w-xl-70 p-5 rounded-5 bg-white">
          <p className="f2 fs-xs-10 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20">
            Welcome to MerchHQ
          </p>
          <p className="w-100 f4 fs-sm-26 fs-md-32 fs-lg-33 fs-xl-34 fs-xxl-35 primary">
            Sign In
          </p>
          <div className="mt-4">
            <p className="f2 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-17">
              Enter your Email address
            </p>
            <input
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={keydownHandler}
              className="editBtn w-100 mt-2 f2 py-2 ps-2 rounded-3 fs-xs-12 fs-sm-13 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18"
            />
            {email?.length == 0 && emailerr && (
              <div className="d-flex gap-1 mt-2">
                {" "}
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Email</p>
              </div>
            )}
            {email.length > 0 && validEmail.test(email) === false && (
              <div className="d-flex gap-1 mt-2">
                {" "}
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter valid password</p>
              </div>
            )}
          </div>
          <div>
            <div className="mt-4 position-relative">
              <p className="f2 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-17">
                Enter your Password
              </p>
              <input
                value={password}
                placeholder="Password"
                onKeyDown={keydownHandler}
                type={visible ? "text" : "password"}
                className="editBtn mt-2 f2 w-100 py-2 rounded-3 ps-2 fs-xs-12 fs-sm-13 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18"
                onChange={(e) => handlePassword(e)}
              />

              <div className="visibleDes">
                {visible && (
                  <VisibilityIcon
                    className="fs-xs-18 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-23 fs-xxl-24"
                    onClick={() => setVisible(!visible)}
                  />
                )}
                {!visible && (
                  <VisibilityOffIcon
                    className="fs-xs-18 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-23 fs-xxl-24"
                    onClick={() => setVisible(!visible)}
                  />
                )}
              </div>
            </div>
            {password?.length == 0 && passwordErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter password</p>
              </div>
            )}
            {password?.length > 0 && passwordStringerr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">{passwordStringerr}</p>
              </div>
            )}
          </div>
          <div className="w-100 forgotPassword">
            <p
              onClick={() => navigate("/forgot-password")}
              className="text-danger mt-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15"
            >
              Forgot Password
            </p>
          </div>
          <button
            onClick={submitHandler}
            className={`cust-btn addbtn w-100 mt-3 mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded ${
              btn && "opacity-50"
            }`}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignInScreen;
