import React, { useState, useMemo } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CheckBox } from "@mui/icons-material";
import ErrorIcon from "@mui/icons-material/Error";

const PricingComp = ({
  types,
  edit,
  vendor,
  priceName,
  adminPrice,
  setAdminPrice,
  priceErr,
  noEdit,
  legalEntityName,
  adminPriceErr,
  setPriceErr,
  setShowPricing,
}) => {
  const [customer, setCustomer] = useState(false);
  const [reseller, setReseller] = useState(false);
  const [decorativeShow, setDecorativeShow] = useState(false);
  // const [showPricing, setShowPricing] = useState(false);

  const [ISCount, setISCount] = useState("3");

  const [check, setCheck] = useState([]);
  const [marginShow, SetMarginShow] = useState(false);
  const [show, setShow] = useState(false);
  const [boxShow, setBoxShow] = useState(0);

  const [inches, setInches] = useState("50");
  const [color1, setColor1] = useState("100");
  const [color2, setColor2] = useState("200");
  const [color3, setColor3] = useState("300");

  const checkBox = (ind) => {
    if (check.includes(ind)) {
      const indtemp = check.indexOf(ind);
      const temp = [...check];
      temp.splice(ind);
      setCheck(temp);
    } else {
      setCheck([...check, ind]);
    }
    // console.log(check);
  };
  // ADMIN PRICE CHANGER
  const changeHandler = (even, ind, type) => {
    let tempX = Object.values(adminPrice[0]).some(
      (item) => item == " " || item == ""
    );
    let temps = Object.values(adminPrice[1]).some(
      (item) => item == " " || item == ""
    );

    console.log(tempX, temps);

    if (tempX == false || temps == false) {
      setPriceErr(false);
    }

    let temp = [...adminPrice];

    temp[ind] = {
      ...temp[ind],
      [type]: even,
    };
    setAdminPrice(temp);
    setShowPricing(true);
  };

  // ADMIN HIDE PRICING
  const hidePricing = () => {
    // setShowPricing(!showPricing);
  };
  return (
    <div className="h-auto mt-4">
      <p class="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
        Vendor Pricing
      </p>
      <div className="w-100">
        <div className="w-md-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-3">
            {legalEntityName ? legalEntityName : " "}
          </p>
        </div>
        <div className="overflow-scroll mt-4">
          <p class="fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17 primary f4 mb-5 text-decoration-underline">
            {priceName ? priceName : ""}
          </p>
          <div className="overflow-scroll mt-4">
            {adminPrice?.map((item, ind) => {
              return (
                <table className="w-100" key={ind}>
                  <tr className="mt-4">
                    <div className="">
                      <td>
                        <div className="d-flex w-100">
                          <div className="mx-2">
                            <p
                              className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                              style={{ width: "100px" }}
                            >
                              UOM
                            </p>
                          </div>
                          <div className="mx-2">
                            <p
                              className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                              style={{ width: "150px" }}
                            >
                              Inches
                            </p>
                          </div>
                          <div className="mx-2">
                            <p
                              className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                              style={{ width: "100px" }}
                            >
                              Color 1 Rate
                            </p>
                          </div>
                          <div className="mx-2">
                            <p
                              className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                              style={{ width: "100px" }}
                            >
                              Color 2 Rate
                            </p>
                          </div>
                          <div className="mx-2">
                            <p
                              className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                              style={{ width: "100px" }}
                            >
                              Color 3 Rate
                            </p>
                          </div>
                        </div>
                      </td>
                    </div>
                  </tr>
                  <tr className="mt-2">
                    <div className="my-3">
                      <td>
                        <div className="d-flex w-100">
                          <div className="mx-2">
                            <input
                              className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              value={item?.uom}
                              style={{ width: "100px" }}
                              onChange={(e) =>
                                changeHandler(e.target.value, ind, "uom")
                              }
                              disabled={noEdit ? true : false}
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              type="number"
                              value={item?.uom_count}
                              style={{ width: "150px" }}
                              onChange={(e) =>
                                changeHandler(e.target.value, ind, "uom_count")
                              }
                              disabled={noEdit ? true : false}
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              type="number"
                              value={item?.color1_rate}
                              onChange={(e) =>
                                changeHandler(
                                  e.target.value,
                                  ind,
                                  "color1_rate"
                                )
                              }
                              style={{ width: "100px" }}
                              disabled={noEdit ? true : false}
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              type="number"
                              value={item?.color2_rate}
                              onChange={(e) =>
                                changeHandler(
                                  e.target.value,
                                  ind,
                                  "color2_rate"
                                )
                              }
                              style={{ width: "100px" }}
                              disabled={noEdit ? true : false}
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              type="number"
                              value={item?.color3_rate}
                              onChange={(e) =>
                                changeHandler(
                                  e.target.value,
                                  ind,
                                  "color3_rate"
                                )
                              }
                              style={{ width: "100px" }}
                              disabled={noEdit ? true : false}
                            />
                          </div>
                        </div>
                      </td>
                    </div>
                  </tr>
                </table>
              );
            })}
          </div>
        </div>
      </div>
      {adminPriceErr && (
        <div className="d-flex gap-1 mt-2">
          <ErrorIcon className="svg_log" />
          <p className="err-input_log mt-1">Fill Price Details</p>
        </div>
      )}
    </div>
  );
};

export default PricingComp;
