import React from "react";
import FormComp from "../AddNewProductComps/FormComp";
import ImageUplodeComp from "../AddNewProductComps/ImageUplodeComp";
import LeftComp from "./LeftComp";
import RightComp from "./RightComp";
import ImageRightComp from "./ImageRightComp";
import { useNavigate } from "react-router-dom";

const OverViewComp = ({
  edit,
  types,
  colorsData,
  setColorsData,
  selColors,
  setSelColors,
  parentCategoryList,
  // getCategoryId,
  subCategoryList,
  setSubCategoryList,
  subCategoryLists,
  setSubCategoryLists,
  setParentCategory,
  decorativeList,
  decorativeID,
  setDecorativeID,
  decorativeName,
  setDecorativeName,
  country,
  setCountry,
  hsnList,
  hsnCode,
  setHsnCode,
  hsnName,
  setHsnName,
  tagsList,
  tags,
  setTags,
  tagsName,
  setTagsName,
  colorsList,
  colorsID,
  setColorsID,
  colorsName,
  setColorsName,
  // varientCombination,
  varientShow,
  sizes,
  setSizes,
  materials,
  setMeterials,
  styles,
  setStyles,
  allVarients,
  // deleteVarient,
  catogoryName,
  setCatogoryName,
  searchValueSubCategory,
  setSearchSubValueCategory,
  productSuplierCode,
  setProductSuplierCode,
  // onSubCategorySelect,
  categoryID,
  setCategoryID,
  primaryCategoryIdErr,
  secondaryCategoryIdErr,
  name,
  setName,
  mrp,
  setMrp,
  decorationMethod,
  setDecorationMethod,
  productDescription,
  setProductDescription,
  productPackageSize,
  setProductPackageSize,
  productPackageSizeErr,
  setProductPackageSizeErr,
  productDescriptionErr,
  setProductDescriptionErr,
  decorationMethodErr,
  setDecorationMethodErr,
  mrpErr,
  setMrpErr,
  nameErr,
  setNameErr,
  countryErr,
  setCountryErr,
  categoryIDErr,
  setCategoryIDErr,
  setGendor,
  gendor,
  gendorErr,
  secondaryCategoryId,
  setSecondaryCategoryId,
  primaryCategoryId,
  setPrimaryCategoryId,
  // submitHandle,
  attributesList,
  productInformation,
  setProductInformation,
  varientsList,
  // submitVarient,
  setTagVal,
  tagVal,
  hsnCodeErr,
  tagErr,
  btn,
  submitBtn,
  catogory,
  setCatogory,
  listAttributes,
  setListAttributes,
  dynamicAttr,
  setDynamicAttr,
  viewData,

  setSmallImage,
  setSmallMockImage,
  smallimage,
  smallMockimage,
  productImg,
  setProductImg,
  productImgErr,
  setProductImgErr,
  morkupImg,
  setMorkupImg,
  morkupImgErr,
  setMorkupImgErr,

  colorsImg,
  setColorsImg,
  colorImgErr,
  setColorImgErr,
  vendorProCode,
  setVendorProCode,
  vendorProCodeErr,
  vendorRef,
}) => {
  const navigate = useNavigate();
  return (
    <div className="d-flex as-jb w-100 mt-5 res-flex gap-5">
      <LeftComp
        types={types}
        colorsData={colorsData}
        setColorsData={setColorsData}
        selColors={selColors}
        setSelColors={setSelColors}
        parentCategoryList={parentCategoryList}
        // getCategoryId={getCategoryId}
        subCategoryList={subCategoryList}
        setSubCategoryList={setSubCategoryList}
        subCategoryLists={subCategoryLists}
        setSubCategoryLists={setSubCategoryLists}
        setParentCategory={setParentCategory}
        decorativeList={decorativeList}
        decorativeID={decorativeID}
        setDecorativeID={setDecorativeID}
        decorativeName={decorativeName}
        setDecorativeName={setDecorativeName}
        country={country}
        setCountry={setCountry}
        hsnList={hsnList}
        hsnCode={hsnCode}
        setHsnCode={setHsnCode}
        hsnName={hsnName}
        setHsnName={setHsnName}
        tagsList={tagsList}
        tags={tags}
        setTags={setTags}
        tagsName={tagsName}
        setTagsName={setTagsName}
        colorsList={colorsList}
        colorsID={colorsID}
        setColorsID={setColorsID}
        colorsName={colorsName}
        setColorsName={setColorsName}
        // varientCombination={varientCombination}
        varientShow={varientShow}
        sizes={sizes}
        setSizes={setSizes}
        materials={materials}
        setMeterials={setMeterials}
        styles={styles}
        setStyles={setStyles}
        allVarients={allVarients}
        // deleteVarient={deleteVarient}
        catogoryName={catogoryName}
        setCatogoryName={setCatogoryName}
        searchValueSubCategory={searchValueSubCategory}
        setSearchSubValueCategory={setSearchSubValueCategory}
        productSuplierCode={productSuplierCode}
        setProductSuplierCode={setProductSuplierCode}
        // onSubCategorySelect={onSubCategorySelect}
        categoryID={categoryID}
        setCategoryID={setCategoryID}
        primaryCategoryIdErr={primaryCategoryIdErr}
        secondaryCategoryIdErr={secondaryCategoryIdErr}
        name={name}
        setName={setName}
        mrp={mrp}
        setMrp={setMrp}
        decorationMethod={decorationMethod}
        setDecorationMethod={setDecorationMethod}
        productDescription={productDescription}
        setProductDescription={setProductDescription}
        productPackageSize={productPackageSize}
        setProductPackageSize={setProductPackageSize}
        productPackageSizeErr={productPackageSizeErr}
        setProductPackageSizeErr={setProductPackageSizeErr}
        productDescriptionErr={productDescriptionErr}
        setProductDescriptionErr={setProductDescriptionErr}
        decorationMethodErr={decorationMethodErr}
        setDecorationMethodErr={setDecorationMethodErr}
        mrpErr={mrpErr}
        setMrpErr={setMrpErr}
        nameErr={nameErr}
        setNameErr={setNameErr}
        countryErr={countryErr}
        setCountryErr={setCountryErr}
        categoryIDErr={categoryIDErr}
        setCategoryIDErr={setCategoryIDErr}
        setGendor={setGendor}
        gendor={gendor}
        gendorErr={gendorErr}
        secondaryCategoryId={secondaryCategoryId}
        setSecondaryCategoryId={setSecondaryCategoryId}
        primaryCategoryId={primaryCategoryId}
        setPrimaryCategoryId={setPrimaryCategoryId}
        // submitHandle={submitHandle}
        attributesList={attributesList}
        productInformation={productInformation}
        setProductInformation={setProductInformation}
        varientsList={varientsList}
        // submitVarient={submitVarient}
        setTagVal={setTagVal}
        tagVal={tagVal}
        hsnCodeErr={hsnCodeErr}
        tagErr={tagErr}
        btn={btn}
        submitBtn={submitBtn}
        catogory={catogory}
        setCatogory={setCatogory}
        listAttributes={listAttributes}
        setListAttributes={setListAttributes}
        dynamicAttr={dynamicAttr}
        setDynamicAttr={setDynamicAttr}
        viewData={viewData}
        vendorProCode={vendorProCode}
        setVendorProCode={setVendorProCode}
        vendorProCodeErr={vendorProCodeErr}
        vendorRef={vendorRef}
      />
      <RightComp
        colorsData={colorsData}
        setColorsData={setColorsData}
        edit={edit}
        viewData={viewData}
        setSmallImage={setSmallImage}
        setSmallMockImage={setSmallMockImage}
        smallimage={smallimage}
        smallMockimage={smallMockimage}
        productImg={productImg}
        setProductImg={setProductImg}
        productImgErr={productImgErr}
        setProductImgErr={setProductImgErr}
        morkupImg={morkupImg}
        setMorkupImg={setMorkupImg}
        morkupImgErr={morkupImgErr}
        setMorkupImgErr={setMorkupImgErr}
        colorImg={colorsImg}
        setColorsImg={setColorsImg}
        colorImgErr={colorImgErr}
        setColorImgErr={setColorImgErr}
      />
      {/* <ImageRightComp /> */}
    </div>
  );
};

export default OverViewComp;
