export const searchIcon = require("./bx-search-alt.png");
export const editIcon = require("./icons/edit.png");
export const viewIcon = require("./icons/Vector.png");
export const toggleOn = require("./icons/toggle_on.png");
export const toggleOff = require("./icons/toggle_off.png");
export const animatedImg = require("./cd8c65c7813855d5f58cf91801b1d256.jpg");
export const emptyImg = require("./empty_vendor.png");
export const uploadIcon = require("./upload_icon.png");
export const product = require("./product_detail_img.png");
export const logos = require("./owl-logo2.png");

export const profilePic = require("./admin_panel_icons/vendor_img.jpg");
export const empty_profile = require("./admin_panel_icons/empty_profile_img.jpg");
export const mailSendImg = require("./admin_panel_icons/mail_sent.png");
export const vendorSuccessImg = require("./admin_panel_icons/vendor_added.png");
export const vendorBannerImg = require("./In-the-News-02.png");
export const noLogo = require("./noLogo.png");
export const fileDocIcon = require("./file.png");
