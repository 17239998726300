import React from "react";

const LogoutPopup = ({ onCancelClick }) => {
  const confirmLogout = () => {
    localStorage.clear();
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };
  return (
    <div onClick={onCancelClick} className="popup-wrap_new">
      <div className="popup-innerbox_new">
        <p>Are you sure you want to logout?</p>
        <div>
          <button onClick={() => confirmLogout()}>Confirm</button>
          <button onClick={onCancelClick}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default LogoutPopup;
