import AddContacts from "./AddContacts";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { toggleOff, toggleOn } from "../../assets/img";

const ContactDetails = ({
  setContactPopup,
  setAddressPopup,
  setPositionPopup,
  vendorDepartmentList,
  getVendorView,
  getPositionData,
  vendorData,
  change,
  getVIewAddress,
  addressStatus,
  vendorAddressList,
}) => {
  const [showVendor, setShowVendor] = useState(null);

  const getVendorShow = (item) => {
    if (showVendor == item) {
      setShowVendor(null);
    } else {
      setShowVendor(item);
    }
  };

  // console.log("vendorData", vendorData);

  return (
    <div className="w-100 mt-5 ">
      <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
        Main Contact Information
      </p>
      <div className="w-xs-100 w-sm-100 w-md-100 w-lg-50 d-flex ac-jc mt-3">
        <p className="primary w-30 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
          Primary
        </p>
        <div className="d-flex w-70">
          <p className="black f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-capitalize">
            {vendorData?.contact?.primaryContact?.first_name
              ? vendorData?.contact?.primaryContact?.first_name
              : " "}{" "}
            {vendorData?.contact?.primaryContact?.last_name
              ? vendorData?.contact?.primaryContact?.last_name
              : " "}{" "}
            <span className="primary1 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              ({vendorData?.contact?.primaryContact?.department?.name})
            </span>
          </p>
          {/* <p
            className={`${
              vendorData?.contact?.primaryContact?.status == 1
                ? "text-success bg-soft-green"
                : "text-danger bg-soft-red"
            } ms-2 rounded-2 p-1  f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-12`}
          >
            {vendorData?.contact?.primaryContact?.status == 1
              ? "Verified"
              : "Not Verified"}
          </p> */}
          {vendorData?.contact?.primaryContact?.first_name && (
            <p
              className={`${"text-success bg-soft-green"} ms-2 rounded-2 p-1  f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-12`}
            >
              {"Verified"}
            </p>
          )}
        </div>
      </div>
      <div className="w-xs-100 w-sm-100 w-md-100 w-lg-50 d-flex ac-jb mt-3">
        <p className="primary w-30 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
          Secondary
        </p>
        <div className="d-flex w-70">
          <p className="black f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-capitalize">
            {vendorData?.contact?.secondaryContact?.first_name}{" "}
            {vendorData?.contact?.secondaryContact?.last_name}
            <span className="primary1 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              ( {vendorData?.contact?.secondaryContact?.department?.name})
            </span>
          </p>
          {/* <p
            className={`${
              vendorData?.contact?.secondaryContact?.status == 1
                ? "text-success bg-soft-green"
                : "text-danger bg-soft-red"
            } ms-2 rounded-2 p-1  f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-12`}
          >
            {vendorData?.contact?.secondaryContact?.status == 1
              ? "Verified"
              : "Not Verified"}
          </p> */}
          {vendorData?.contact?.secondaryContact?.first_name && (
            <p
              className={`${"text-success bg-soft-green"} ms-2 rounded-2 p-1  f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-12`}
            >
              {"Verified"}
            </p>
          )}
        </div>
      </div>
      <div className="w-xxl-50 w-xl-50 w-lg-50 w-md-70">
        <div className="w-100 mt-5">
          <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            Departments
          </p>
          {vendorDepartmentList?.map((item, ind) => {
            return (
              <div>
                <div className="pointerView w-100 mt-3 ac-jb d-flex" key={ind}>
                  <p className="f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
                    <PlayArrowIcon
                      onClick={() => getVendorShow(ind)}
                      className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${
                        showVendor == ind ? "arrowIcon" : ""
                      } `}
                    />
                    {item?.name}{" "}
                    <span className="primary">
                      ({item?.vendor_user?.length})
                    </span>
                  </p>
                  <div
                    onClick={() => getVendorView(item, "", ind, "", "add")}
                    className="pointerView "
                  >
                    <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
                      Add Contact
                    </p>
                  </div>
                </div>
                {showVendor == ind &&
                  item?.vendor_user?.map((user, inx) => {
                    return (
                      <AddContacts
                        user={user}
                        item={item}
                        ind={ind}
                        inx={inx}
                        getVendorView={getVendorView}
                        setContactPopup={setContactPopup}
                        setPositionPopup={setPositionPopup}
                        getPositionData={getPositionData}
                        change={change}
                      />
                    );
                  })}
              </div>
            );
          })}
        </div>
      </div>
      <div className="w-100 mt-5">
        <div className="w-100 ac-jb d-flex">
          <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            Addresses
          </p>
          <button
            onClick={() => setAddressPopup(true)}
            className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
          >
            Add
          </button>
        </div>
        {vendorAddressList?.map((item, ind) => {
          return (
            <div className="w-95 mt-4" key={ind}>
              <div className="w-100 ac-jb d-flex">
                <p className="black f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mt-1 ">
                  {item?.address}
                  <span className="primary1 f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mt-1 ">
                    {/* (Shipping) */}
                  </span>
                </p>

                <div className=" d-flex ms-5 ac-jc gap-2">
                  <div onClick={() => addressStatus(item?.id)}>
                    {item?.status == 1 ? (
                      <img src={toggleOn} className="toggleOnDes1" />
                    ) : (
                      <img src={toggleOff} className="toggleOnDes1" />
                    )}
                  </div>

                  <EditIcon
                    onClick={() => getVIewAddress(item)}
                    className="pointerView primary ms-1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25"
                  />
                </div>
              </div>
              {/* <p className="primary1 mt-3 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
                {`${item?.address ? item?.address + "," : " "}${
                  item?.street ? item?.street + "," : " "
                }${item?.landmark ? item?.landmark + "," : " "}${
                  item?.city ? item?.city + "," : " "
                }${item?.country ? item?.country + "," : " "}${
                  item?.pincode ? item?.pincode : " "
                }`}
              </p> */}
              <p className="primary1 mt-3 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
                {item?.name}
              </p>
              <p className="primary1 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
                {item?.landmark}
              </p>
              <p className="primary1 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
                {item?.street}
              </p>
              <p className="primary1 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
                {item?.country}
              </p>
              <p className="primary1 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
                {item?.state}
              </p>
              <p className="primary1 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
                {item?.city}
              </p>
              <p className="primary1 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
                {item?.pincode}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ContactDetails;
