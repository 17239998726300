import CloseIcon from "@mui/icons-material/Close";
import { mailSendImg } from "../../assets/img";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import FolderIcon from "@mui/icons-material/Folder";
// import UploadLibraryImage from "./UploadLibraryImage";
import DeleteIcon from "@mui/icons-material/Delete";
import { dummyImages } from "../../redux/api/DummyJson";
import EditIcon from "@mui/icons-material/Edit";
// import { useNavigate } from "react-router-dom";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import UploadIcon from "@mui/icons-material/Upload";
import {
  useVendorLibraryCreateMutation,
  useVendorLibraryEditMutation,
} from "../../redux/api/api";
import { uploadIcon } from "../../assets/img";
import ErrorIcon from "@mui/icons-material/Error";
import { useDispatch } from "react-redux";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { type } from "@testing-library/user-event/dist/type";

const EditLibraryVendor = ({
  toggleShowPopup,
  Submit,
  setImage,
  setImageTitle,
  image,
  btn,
  imageTitle,
  types,
  setTypes,
}) => {
  const fileRef = useRef();
  return (
    <div className="modal-popup w-100 ">
      <div
        className="w-70 ms-xxl-5"
        style={{
          background: "#fff",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: " 7px 7px 15px -1px #d1d6eb",
          WebkitBoxShadow: "7px 7px 15px -1px #d1d6eb",
        }}
      >
        <div className="d-flex ac-jb">
          <p className="w-100 f2 mt-4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-16 gray">
            Add Image to
            <span className="f2 mt-4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-16 text-black">
              {types == 1 ? " Product" : types == 0 ? " Vendor" : ""}
            </span>
          </p>
          {/* <button
                        className={`
          cust-btn addbtn mt-sm-4  mt-4 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${btn && "opacity-50"
                            }`}
                        onClick={toggleShowPopup}
                    >
                        Close
                    </button> */}
          <button
            onClick={() => {
              toggleShowPopup();
              setImageTitle("");
              setImage("");
            }}
            className="cust-btn"
          >
            <HighlightOffIcon />
          </button>
        </div>

        <div>
          {image === "" ? (
            <div className="pointerView w-100 mt-4">
              <div
                onClick={() => fileRef.current.click()}
                className="w-100 w-md-100 d-flex ac-jc"
              >
                <img src={uploadIcon} className="uploadImgLib" />
              </div>
              <div className="w-100 w-md-100 d-flex ac-jc">
                <p className="black w-100 f2 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14 mt-1 d-flex ac-jc">
                  Drag and drop to upload or Click
                </p>
              </div>
              <div className="w-100 w-md-100 d-flex ac-jc">
                <p className="black w-100 f2 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14 mt-1 d-flex ac-jc">
                  here to upload
                </p>
              </div>
            </div>
          ) : (
            <div className="w-100 w-md-100  mt-4 d-flex ac-jb">
              <div
                //   onClick={() => fileRef.current.click()}
                className="w-100  w-md-100 d-flex ac-jc"
              >
                <div className="w-20">
                  <input
                    type="file"
                    onChange={(e) => setImage(e.target.files[0])}
                  />

                  {image?.type === "application/pdf" ? (
                    <iframe
                      src={URL.createObjectURL(image)}
                      data-bs-toggle="modal"
                      data-bs-target="#imageExample"
                      className="custom-w-h rounded-3 mt-3"
                    />
                  ) : !image?.name && image?.endsWith(".pdf") ? (
                    <iframe
                      src={image}
                      data-bs-toggle="modal"
                      data-bs-target="#imageExample"
                      // className="custom-w-h rounded-3 mt-3"
                      className="rounded-3 mt-3"
                      style={{ height: "300px" }}
                    />
                  ) : (
                    <img
                      src={image?.name ? URL.createObjectURL(image) : image}
                      alt="Image"
                      data-bs-toggle="modal"
                      data-bs-target="#imageExample"
                      className="custom-w-h rounded-3 mt-3"
                    />
                  )}

                  {/* {image?.type === "image/png" ||
                    image?.type === "image/jpeg" ||
                    image?.type === "image/jpg" ? (
                      <img
                        src={window.URL.createObjectURL(image)}
                        className="uploadImgLib"
                      />
                    ) : null}
                    {image?.type === "application/pdf" ||
                    image?.type ===
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                    image?.type === "application/doc" ||
                    image?.type === "application/msword" ? (
                      <iframe
                        src={window.URL.createObjectURL(image)}
                        className="uploadImgLib"
                      />
                    ) : null} */}
                </div>
                <p className="f2 w-100 w-md-100 fs-xs-13 ms-2 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-16 primary1">
                  {image?.name}
                </p>
              </div>
              {/* <div className="w-20 ac-jc d-flex">
                  <DeleteIcon
                    onClick={() => setImage("")}
                    className="pointerView ms-1 primary1 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20"
                  />
                </div> */}
            </div>
          )}
        </div>

        <div className="w-100 mt-4">
          <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Image Title
          </p>
          <input
            placeholder="Enter Image Title"
            value={imageTitle}
            onChange={(e) => setImageTitle(e.target.value)}
            className="editBtn rounded-3 mt-3 p-2 w-100 w-md-100 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
          {/* {imageTitle?.length == 0 && imageTitleErr && (
        <div className="d-flex gap-1 mt-2">
          <ErrorIcon className="svg_log" />
          <p className="err-input_log mt-1">Enter Image Title</p>
        </div>
      )} */}
        </div>
        <div className="w-100 mt-4">
          <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Type
          </p>
          <select
            onChange={(e) => setTypes(e.target.value)}
            value={types}
            className="w-100 editBtnSelect mt-3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3"
          >
            <option value={""} disabled>
              Select Type
            </option>
            <option value={1}>Product Library</option>
            <option value={0}>Vendor Library</option>
          </select>
          {/* {types?.length == 0 && typesErr && (
                        <div className="d-flex gap-1 mt-2">
                            <ErrorIcon className="svg_log" />
                            <p className="err-input_log mt-1">Select Type</p>
                        </div>
                    )} */}
        </div>
        <div className="w-100 d-flex ac-jc gap-3 w-md-100">
          {/* {image === "" && (
                        <button
                            onClick={() => fileRef.current.click()}
                            className=" cust-btn addbtn mt-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                        >
                            Upload Image
                        </button>
                    )} */}

          {/* {image !== "" && ( */}
          {types !== "view" && (
            <button
              // onClick={() => {
              //   if (type === "vendor") {
              //     navigate("/view-library-list", {
              //       state: { name: "Vendor" },
              //     });
              //   } else {
              //     navigate("/view-library-list", {
              //       state: { name: "Product" },
              //     });
              //   }
              // }}
              //   onClick={() => createLibaray()}
              // disabled={btn}
              className={`
          cust-btn addbtn mt-sm-4 ms-1 mt-4 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            btn && "opacity-50"
          }`}
              onClick={() => Submit()}
              disabled={btn}
            >
              Submit
            </button>
          )}
          {/* )} */}
        </div>

        {/* <input
                    type="file"
                    accept=".pdf,.doc,.jpeg,.docx,.png,.jpg"
                    onChange={(e) => setImage(e.target.files[0])}
                    className="d-none"
                    ref={fileRef}
                /> */}
      </div>
      {/* //  )} */}
    </div>
  );
};

export default EditLibraryVendor;
