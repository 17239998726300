import React, { useState } from "react";
import { Search } from "@mui/icons-material";
import { animatedImg, product, searchIcon } from "../../assets/img";
import { useLocation, useNavigate } from "react-router-dom";
import CachedIcon from "@mui/icons-material/Cached";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const MapProductListComp = ({
  name,
  loc,
  setDecorative,
  setProduct,
  place,
  path,
  myProducts,
  categoryList,
  list,
  pagination,
  getProduct,
  ReqSearch,
  reqStatus,
  setPageReqRow,
  setPageReq,
  pageReq,
  newCat,
  listCategory,
  reqPage,
}) => {
  const navigate = useNavigate();
  // console.log(loc, "loc");
  // console.log(place, "place");
  const location = useLocation();
  // console.log(location?.pathname, "/reqest-detail");
  const path_line = location?.pathname;

  const tooltip = (
    <Tooltip id="tooltip"> Product Rejected reason will show here</Tooltip>
  );

  return (
    <>
      <div className="mt-5 banner-left">
        <div className="d-flex ac-jb flex-column flex-md-row">
          <div className="d-flex gap-3">
            <select
              className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3"
              onChange={(e) =>
                getProduct(reqStatus, ReqSearch, e.target.value, reqPage)
              }
              value={newCat}
            >
              <option value={""}>Select Categories</option>
              {listCategory?.map((item, ind) => {
                return (
                  <option key={ind} value={item?.id}>
                    {item?.name}
                  </option>
                );
              })}
            </select>
            <select
              className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3"
              onChange={(e) =>
                getProduct(e.target.value, ReqSearch, newCat, reqPage)
              }
              value={reqStatus}
            >
              <option value={""}>Select Status</option>
              <option value={"1"}>Requested </option>
              <option value={"2"}>Inprogress</option>
              <option value={"3"}>Accepted</option>
              <option value={"4"}>Rejected</option>
            </select>
          </div>
          <div className="d-flex ac-jc place-holde justify-content-md-end gap-3 flex-wrap w-100 w-md-90 mb-md-0  mb-4">
            <div className="d-flex mt-sm-3 border-search">
              <img src={searchIcon} alt="img" className="searchiConImg mt-1" />
              <input
                className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                placeholder="Search"
                onChange={(e) =>
                  getProduct(reqStatus, e.target.value, newCat, reqPage)
                }
                value={ReqSearch}
              />
            </div>
            <div className="d-flex ac-jc gap-3">
              <button
                onClick={(e) => getProduct("", "", "", "", "")}
                // onClick={() => navigate("/add-vendor")}
                className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
              >
                <CachedIcon />
              </button>
            </div>{" "}
          </div>
        </div>
      </div>
      <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select
            className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary"
            onChange={(e) =>
              getProduct(reqStatus, e.target.value, newCat, e.target.value)
            }
            value={reqPage}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont">
            Total Count : {pagination?.total}
          </p>
        </div>
      </div>
      <div className="w-100 d-flex ac-js flex-wrap mt-5 ">
        {Array.isArray(list) &&
          list.map((item, ind) => {
            return (
              <div
                className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2 cp position-relative"
                onClick={() => {
                  // setPopView(!popView)
                  // setProductId(item?.id)
                  // getView(item?.id)
                  navigate("/create-new-request", {
                    state: {
                      status: "accept",
                      name: name,
                      id: item?.id,
                      categoryList: categoryList,
                      type: "edit",
                    },
                  });
                }}
              >
                <div className="prod-list-box">
                  <div className="cust-btn d-flex ac-jc rounded-3">
                    <img
                      src={
                        item?.image_urls?.product_images?.[
                          item?.image_urls?.product_images?.length - 1
                        ]
                          ? item?.image_urls?.product_images?.[
                              item?.image_urls?.product_images?.length - 1
                            ]
                          : product
                      }
                      alt=""
                      className="custom-w-h rounded-3"
                    />
                  </div>
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark mb-2 text-custom-res mt-3">
                    {item?.name}
                  </p>
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2 primary1 text-custom-res">
                    {item?.parent_category?.name}
                  </p>
                  <p>
                    {item?.status == 1 ? (
                      <p className="f4 fs-11 fs-sm-12 text-custom-res fs-lg-13 parag mb-0 request-text">
                        Request{" "}
                      </p>
                    ) : item?.status == 2 ? (
                      <p className="f4 fs-11 fs-sm-12 text-custom-res fs-lg-13 parag mb-0 Inprocess-text">
                        Inprogress
                      </p>
                    ) : item?.status == 3 ? (
                      <p className="f4 fs-11 fs-sm-12 text-custom-res fs-lg-13 parag mb-0 Accept-text">
                        Accepted
                      </p>
                    ) : item?.status == 4 ? (
                      <p className="f4 fs-11 fs-sm-12 text-custom-res fs-lg-13 parag mb-0 text-danger">
                        Rejected
                        <OverlayTrigger
                          delay={{ hide: 450, show: 300 }}
                          overlay={(props) => (
                            <Tooltip {...props}>
                              Product Rejected reason will show here
                            </Tooltip>
                          )}
                          placement="bottom"
                        >
                          <InfoOutlinedIcon
                            className="fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-1 parag mt-1"
                            style={{ color: "#06679c" }}
                          />
                        </OverlayTrigger>
                      </p>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
              </div>
            );
          })}
      </div>
      <div className="d-flex justify-content-between mt-3">
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            pageReq == 1 && "opacity-50"
          }`}
          onClick={() => setPageReq(pageReq - 1)}
          disabled={pageReq == 1 ? true : false}
        >
          Previous
        </button>
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            pageReq == pagination?.last_page && "opacity-50"
          }`}
          onClick={() => setPageReq(pageReq + 1)}
          disabled={pageReq == pagination?.last_page ? true : false}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default MapProductListComp;
