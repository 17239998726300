import React, { useState } from "react";
import TvIcon from "@mui/icons-material/Tv";
import {
  sub_menu,
  Country,
  Hsn_type,
  Hsn_type_Child2,
  Hsn_type_Child3,
  Hsn_type_Child4,
  Supplier,
  supplier,
  supplier_rating,
  catogory_setting,
} from "../../../redux/api/DummyJson";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Edit, EditCalendarOutlined } from "@mui/icons-material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import PricingComp from "./PricingComp";

const LeftComp = ({
  edit,
  types,
  decorativeMethod,
  setDecorativeMethod,
  list,
}) => {
  const [catogoryShow, setCatogoryShow] = useState(false);
  const [catogoryShow2, setCatogoryShow2] = useState(false);
  const [catogoryShow3, setCatogoryShow3] = useState(false);
  const [productNameShow, setProductNameShow] = useState(false);
  const [childMenu, setChildMenu] = useState(false);
  const [childMenu2, setChildMenu2] = useState(false);
  const [decorativeShow, setDecorativeShow] = useState(false);
  const [hsnTypeShow, setHsnTypeShow] = useState(false);
  const [PackingSizeShow, setPackingSizeShow] = useState(false);
  const [countryShow, setCountryShow] = useState(false);
  const [uomShow, setuomShow] = useState(false);
  const [isCountShow, setISCountShow] = useState(false);

  //

  const [subcatogory, setSubcatogory] = useState(false);
  const [subcatogory2, setSubcatogory2] = useState(false);
  const [subcatogory3, setSubcatogory3] = useState(false);

  const [Dropdown, setDrop] = useState(0);

  // input box
  const [catogory, setCatogory] = useState("Mobile");
  const [catogory2, setCatogory2] = useState("Android");
  const [catogory3, setCatogory3] = useState("Samsung");
  const [productName, setProductName] = useState("Mobile Phone");
  const [productSuplierCode, setProductSuplierCode] = useState("SM10001");
  // const [decorativeMethod, setDecorativeMethod] = useState("Game consoles");
  const [hsnType, sethsnType] = useState("CGST-85");
  const [packingSize, setPackingSize] = useState("");
  const [country, setCountry] = useState("");
  const [uomCont, setuomCont] = useState("Inches");
  const [ISCount, setISCount] = useState("3");
  const [color1, setColor1] = useState("100");
  const [color2, setColor2] = useState("200");
  const [color3, setColor3] = useState("300");
  const [productLink, setProductLink] = useState("");
  const [productDescription, setProductDescription] = useState(
    "Once, there was a boy who became bored when he watched over the village sheep grazing on the hillside. To entertain himself, he sang out, “Wolf! Wolf! The wolf is chasing the sheep!”"
  );
  const [decorativeDescription, setDecorativeDescription] = useState(
    "Once, there was a boy who became bored when he watched over the village sheep grazing on the hillside. To entertain himself, he sang out, “Wolf! Wolf! The wolf is chasing the sheep!”"
  );

  // Select Fuctction

  const [selectCondtion, setSelectCondtion] = useState("");

  const [check, setCheck] = useState([]);

  const [editComp, setEditComp] = useState(false);

  // input box

  const onCatogory = (e) => {
    setCatogory(e.target.value);
    if (catogory.length >= 2) {
      setCatogoryShow(true);
      setProductNameShow(false);
    } else {
      if (catogory.length >= 0) setCatogoryShow(false);
    }
  };
  const onCatogory2 = (e) => {
    setCatogory2(e.target.value);
    if (catogory2.length >= 2) {
      setCatogoryShow2(true);
    } else {
      if (catogory2.length >= 0) setCatogoryShow2(false);
    }
  };
  const onCatogory3 = (e) => {
    setCatogory3(e.target.value);
    if (catogory3.length >= 2) {
      setCatogoryShow3(true);
    } else {
      if (catogory2.length >= 0) setCatogoryShow2(false);
    }
  };
  const onProduct = (e) => {
    setProductName(e.target.value);
    if (productName.length >= 2) {
      setProductNameShow(true);
    } else {
      if (productName.length >= 0) setProductNameShow(false);
      setProductSuplierCode("");
    }
    setCatogoryShow(false);
  };
  const onDecorative = (e) => {
    setDecorativeMethod(e.target.value);
    if (decorativeMethod.length >= 2) {
      setDecorativeShow(true);
    } else {
      if (decorativeMethod.length >= 0) setDecorativeShow(false);
    }
  };
  const decorativeM = (e) => {
    const arr = [...decorativeMethod, e];
    setDecorativeMethod(arr);
  };
  const onHsnTyoe = (e) => {
    sethsnType(e.target.value);
    if (hsnType.length >= 2) {
      setHsnTypeShow(true);
    } else {
      if (hsnType.length >= 0) setHsnTypeShow(false);
    }
  };
  const onPackingSize = (e) => {
    setPackingSize(e.target.value);
    if (packingSize.length >= 2) {
      setPackingSizeShow(true);
    } else {
      if (packingSize.length >= 0) setPackingSizeShow(false);
    }
  };
  const onCountry = (e) => {
    setCountry(e.target.value);
    if (country.length >= 2) {
      setCountryShow(true);
    } else {
      if (country.length >= 0) setCountryShow(false);
    }
  };
  const onUom = (e) => {
    setuomCont(e.target.value);
    if (uomCont.length >= 2) {
      setuomShow(true);
    } else {
      if (uomCont.length >= 0) setuomShow(false);
    }
  };
  const onISCount = (e) => {
    setISCount(e.target.value);
    if (ISCount.length >= 2) {
      setISCountShow(true);
    } else {
      if (ISCount.length >= 0) setISCountShow(false);
    }
  };
  const checkBox = (ind) => {
    if (check.includes(ind)) {
      const indtemp = check.indexOf(ind);
      const temp = [...check];
      temp.splice(1, indtemp);
      setCheck(temp);
    } else {
      setCheck([...check, ind]);
    }
    console.log(check);
  };
  return (
    // <div className="w-100">
    //   <div className="d-flex flex-wrap as-jb flex-m-r">
    //     <div className="w-100">
    //       <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
    //         Decoration method
    //       </p>
    //       <div className="position-relative">
    //         <input
    //           placeholder="Required Field"
    //           className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
    //           // style={{ height: "100px", alignItems: "center" }}
    //           value={list?.name}
    //           onChange={onDecorative}
    //         disabled
    //         // multiple={6}
    //         />
    //         <button
    //           className="drop_down cust-btn"
    //           onClick={() => {
    //             edit && setDecorativeShow(!decorativeShow);
    //             setCatogoryShow(false);
    //             setCatogoryShow2(false);
    //             setCatogoryShow3(false);
    //             setProductNameShow(false);
    //             setHsnTypeShow(false);
    //             setPackingSizeShow(false);
    //             setCountryShow(false);
    //             setuomShow(false);
    //             setISCountShow(false);
    //           }}
    //         >
    //           <KeyboardArrowDownIcon />
    //         </button>
    //         {decorativeShow && (
    //           <div
    //             className="invisible-cont2 z-0"
    //             onClick={() => setDecorativeShow(!decorativeShow)}
    //           />
    //         )}
    //         <div
    //           className={`${decorativeShow && "submenu_1"
    //             } submenu_cont_1 overflow-scroll z-3`}
    //         >
    //           {/* {sub_menu?.map((item, ind) => {
    //                             return (
    //                                 <div className="d-flex as-jb hover-cust">
    //                                     <button
    //                                         className="px-2 cust-btn text-start py-1 w-100 "
    //                                         onClick={() => { decorativeM(item?.list); checkBox(ind) }}
    //                                     >
    //                                         {item?.list}
    //                                     </button>
    //                                     <button
    //                                         className="px-2 cust-btn text-start"
    //                                         onClick={() => {
    //                                             setDecorativeMethod(check.includes(item?.list));
    //                                             checkBox(ind);
    //                                         }}
    //                                     >
    //                                         {check.includes(ind) ? item?.check : item.uncheck}
    //                                     </button>
    //                                 </div>
    //                             );
    //                         })} */}
    //           {sub_menu?.map((item, ind) => {
    //             return (
    //               <div className="d-flex as-jb hover-cust">
    //                 <button
    //                   className="px-2 cust-btn text-start py-1 w-100 "
    //                   onClick={() => {
    //                     setDecorativeShow(false);
    //                     setDecorativeMethod(item?.list);
    //                   }}
    //                 >
    //                   {item?.list}
    //                 </button>
    //                 {/* <button
    //                                         className="px-2 cust-btn text-start"
    //                                         onClick={() => {
    //                                             setDecorativeMethod(check.includes(item?.list));
    //                                             checkBox(ind);
    //                                         }}
    //                                     >
    //                                         {check.includes(ind) ? item?.check : item.uncheck}
    //                                     </button> */}
    //               </div>
    //             );
    //           })}
    //         </div>
    //       </div>
    //     </div>
    //     <div className="w-100">
    //       <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
    //         Decoration Description
    //       </p>
    //       <textarea
    //         placeholder="Autogenerate"
    //         className="editBtn w-100  rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
    //         style={{ height: "100px" }}
    //         disabled={edit ? false : true}
    //         value={decorativeDescription}
    //         onChange={(e) => setDecorativeDescription(e.target.value)}
    //       />
    //     </div>
    //   </div>
    //   <div className="w-100 my-3">
    //     <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
    //       Product Website
    //     </p>
    //     <div className="d-flex ac-js gap-2">
    //       <TvIcon className="primary text-decoration-underline cp f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start" />
    //       <p className="primary text-decoration-underline cp f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
    //         Link will open a new tab
    //       </p>
    //     </div>
    //   </div>

    // </div>
    <div className="w-100">
      <div className="d-flex flex-wrap as-jb flex-m-r">
        <div className="w-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Decoration method
          </p>
          <div className="position-relative">
            <input
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              // style={{ height: "100px", alignItems: "center" }}
              value={list?.name}
              onChange={onDecorative}
              // disabled={edit ? false : true}
              disabled
              // multiple={6}
            />
            <button
              className="drop_down cust-btn"
              onClick={() => {
                edit && setDecorativeShow(!decorativeShow);
                setCatogoryShow(false);
                setCatogoryShow2(false);
                setCatogoryShow3(false);
                setProductNameShow(false);
                setHsnTypeShow(false);
                setPackingSizeShow(false);
                setCountryShow(false);
                setuomShow(false);
                setISCountShow(false);
              }}
            >
              <KeyboardArrowDownIcon />
            </button>
            {decorativeShow && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setDecorativeShow(!decorativeShow)}
              />
            )}
            <div
              className={`${
                decorativeShow && "submenu_1"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {sub_menu?.map((item, ind) => {
                return (
                  <div className="d-flex as-jb hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100 "
                      onClick={() => {
                        setDecorativeMethod(item?.list);
                        checkBox(ind);
                      }}
                    >
                      {item?.list}
                    </button>
                    <button
                      className="px-2 cust-btn text-start"
                      onClick={() => {
                        setDecorativeMethod(check.includes(item?.list));
                        checkBox(ind);
                      }}
                    >
                      {check.includes(ind) ? item?.check : item.uncheck}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="w-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
            Decoration Description
          </p>
          <textarea
            placeholder="Autogenerate"
            className="editBtn w-100  rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            style={{ height: "100px" }}
            disabled={edit ? false : true}
            value={list?.decor_method_price?.[0]?.description}
            // value={decorativeDescription}
          />
        </div>
      </div>
      <div className="w-100 my-3">
        <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
          Product Website
        </p>
        <div className="d-flex ac-js gap-2">
          <TvIcon className="primary text-decoration-underline cp f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start" />
          <p className="primary text-decoration-underline cp f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
            Link will open a new tab
          </p>
        </div>
      </div>
      <PricingComp
        edit={edit}
        adminPrice={list?.decor_method_price}
        noEdit={true}
      />
    </div>
  );
};

export default LeftComp;
