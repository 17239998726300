import React, { useEffect, useState } from "react";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { toggleOn } from "../assets/img";
import OverViewComp from "../components/ProductDetailComp/OverViewComp";
import VendorPricingComp from "../components/RequestDetailComp/VendorPricingComp";
import { useLocation } from "react-router-dom";
import RequestOverViewComp from "../components/RequestDetailComp/RequestOverViewComp";
import RequestVendorPricingComp from "../components/RequestDetailComp/RequestVendorPricingComp";
import { useLazyRequestViewQuery } from "../redux/api/api";

const CreateReqestDetailScreen = () => {
  const [priceBook, setpriceBook] = useState(true);
  const [previousOrder, setPreviousOrder] = useState(false);
  const [tagged, setTagged] = useState(false);
  const [edit, setEdit] = useState(false);
  const location = useLocation();
  console.log(location?.state?.type);
  const types = location?.state?.type;
  console.log(location?.state?.status, "kdnfjn");
  const status = location?.state?.status;
  const name = location?.state?.name;
  const id = location?.state?.id;

 //RTk
 const [getViewApi]=useLazyRequestViewQuery();

 const getView=()=>{
  getViewApi(id)
  .unwrap()
  .then(res=>{
    console.log(res)
  })
  .catch(err=>{console.log(err)})
 }
useEffect(()=>{
  if(id){

    getView()
  }
},[])
  return (
    <div className="dashRightView overflow-scroll">
      <ProductSearchHeader />
      <div className="w-100 top_head_cust py-3 d-flex ac-jb px-4">
        <h5 className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary">
          {name}
        </h5>
        {status === "request" && (
          <div className="mt-md-4 mt-3">
            <h5 className="f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 w-100 text-dark">
              Status :
              <span className="f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 request-text">
                {" "}
                Request
              </span>
            </h5>
          </div>
        )}
        {status === "inprocess" && (
          <div className="mt-md-4 mt-3">
            <h5 className="f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 w-100 text-dark">
              Status :
              <span className="f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 Inprocess-text">
                {" "}
                Inprocess
              </span>
            </h5>
          </div>
        )}
        {status === "accept" && (
          <div className="mt-md-4 mt-3">
            <h5 className="f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 w-100 text-dark">
              Status :
              <span className="f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 Accept-text">
                {" "}
                Accepted
              </span>
            </h5>
          </div>
        )}
        {status === "regected" && (
          <div className="mt-md-4 mt-3">
            <h5 className="f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 w-100 text-dark">
              Status :
              <span className="f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 Regected-text">
                {" "}
                Regected
              </span>
            </h5>
          </div>
        )}
      </div>
      <div className="d-flex mt-2 ac-jb flex-column flex-md-row w-100">
        <div className="d-flex mt-3 ac-jc">
          <div
            onClick={() => {
              setpriceBook(true);
              setTagged(false);
              setPreviousOrder(false);
              setEdit(false);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              priceBook
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } ms-4`}
          >
            <p
              className={`${
                priceBook ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Overview
            </p>
          </div>
          {/* <div
                        onClick={() => {
                            setPreviousOrder(true);
                            setpriceBook(false);
                            setTagged(false)
                            setEdit(false)
                        }}
                        style={{ cursor: 'pointer' }}
                        className={`${previousOrder
                            ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                            : ""
                            } ms-4`}
                    >
                        <p
                            className={`${previousOrder ? "black" : "gray"
                                } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
                        >
                            Pricing
                        </p>
                    </div> */}
          {status === "request" && (
            <div className="ms-5">
              <button
                className={
                  edit
                    ? "cust_two_E cust-btn f3 primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-18"
                    : "cust_two_S cust-btn f3 primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-18"
                }
                onClick={() => setEdit(!edit)}
              >
                {edit ? "Submit" : "Edit"}
              </button>
            </div>
          )}
        </div>
        <div className="mt-md-4 mt-3">
          <h5 className="f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 w-100 text-dark">
            Currency :
            <span className="primary f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25">
              {" "}
              INR
            </span>
          </h5>
        </div>
      </div>

      {priceBook && (
        <RequestOverViewComp edit={edit} types={types} status={status} />
      )}
      {/* {previousOrder && */}
      {/* <VendorPricingComp types={types} edit={edit} /> */}
      {/* } */}
    </div>
  );
};

export default CreateReqestDetailScreen;
