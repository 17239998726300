import React, { useEffect, useState } from "react";
import {
  animatedImg,
  emptyImg,
  product,
  profilePic,
  uploadIcon,
} from "../../../assets/img";
import Carousel from "react-bootstrap/Carousel";
import { productDetailImg } from "../../../redux/api/constants";
import { EditAttributes } from "@mui/icons-material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import ImageCroper from "../../Popup/ImageCroper";

// const RightComp = ({ edit, types,list}) => {
//   console.log(types);
//   const [logo, setLogo] = useState({});
//   const [textShow, setTextShow] = useState(true);
//   const [index, setIndex] = useState(0);
//   const [imageShow, setImageShow] = useState(false);

//   const [croper, setCroper] = useState(false);
//   const [croperCol, setCroperCol] = useState(false);
//   const [croperImage, setCropImage] = useState();
//   // const [textShow2, setTextShow2] = useState(false)
//   const [fistShow, setFirstShow] = useState(false);
//   const [smallimage, setSmallImage] = useState([]);
//   const [bigImage, setBigImage] = useState({ image: "", ind: "" });

//   const [image, setImage] = useState([]);

//   const [Indeximage, setIndexImage] = useState("");
//   const [imageName, setImageName] = useState("defauld");

//   const dualToggele = (type) => {
//     if (type == "color") {
//       setCroperCol(false);
//       toggleImagePopup();
//     } else {
//       setCroperCol(true);
//       toggleImagePopup();
//     }
//   };

//   const toggleImagePopup = () => {
//     setCroper(!croper);
//   };

//   useEffect(() => {
//     if (croperCol && croperImage) {
//       setTextShow(false);
//       setFirstShow(true);

//       let temp = [...smallimage];

//       if (croperImage) {
//         temp.push(croperImage);
//       }

//       setSmallImage(temp);

//       setBigImage({ image: temp[temp.length - 1], ind: temp.length - 1 });
//       setCropImage("");
//     } else if (!croperCol && croperImage) {
//       imageUplode(croperImage);
//     }
//   }, [croperImage]);

//   const imageSelector = (item, ind) => {
//     setBigImage({ image: item, ind: ind });
//   };

//   const removeImg = (ind) => {
//     let temp = [...smallimage];

//     temp.splice(ind, 1);
//     setSmallImage(temp);
//     if (temp?.length - 1 === 0) {
//       setBigImage({ image: temp[temp.length - 1], ind: temp.length - 1 });
//     } else {
//       setBigImage({ image: temp[ind - 1], ind: ind - 1 });
//     }
//   };

//   const imageAdd = () => { };
//   const removeimg = () => {
//     const arr = [...image];
//     const index = arr.indexOf();
//     arr.splice(Indeximage);
//     setImage([...arr]);
//     console.log(arr);
//   };
//   // useEffect((ind) => {
//   //     setIndexImage(ind)
//   //     console.log(ind)
//   // })

//   const smallimg = (item) => {
//     setSmallImage(item);
//     // console.log(item)
//   };

//   const handleSelect = (selectedIndex) => {
//     setIndex(selectedIndex);
//   };

//   // Slide
//   const [file, setFile] = useState([]);

//   const imageUplode = (e) => {
//     setFile([...file, URL.createObjectURL(e.target.files[0])]);
//     console.log("file", file);
//   };

//   const deleteimg = (e) => {
//     const s = file.filter((item, index) => item !== smallimage);
//     setFile(s);
//     console.log(s, index);
//   };

//   console.log(smallimage);
//   const [ind, setInd] = useState();
//   console.log(types, "sinfienin");
//   // Slide
//   return (
//     <div className="d-flex gap-2 flex-column ac-jc w-100 mb-5">
//       {croper && (
//         <ImageCroper
//           toggleImagePopup={toggleImagePopup}
//           setCropImage={setCropImage}
//         />
//       )}
//       <div className="w-100 d-flex flex-column ac-jc pb-4">
//         <div className="d-flex ac-js mb-3 gap-3">
//           <h4 className="primary">
//             {types === "decorative" ? "Decoration Image" : "Product Image"}
//           </h4>
//         </div>
//         {/* <div className="slide_image position-relative">
//           <img
//             src={smallimage.length === 0 ? animatedImg : smallimage}
//             className="imagr_box"
//           />
//           <div className="w-100 mt-3 gap-2 d-flex flex-wrap ac-js pb-4">
//             <div className="add_img d-flex ac-jc">
//               <img
//                 src={animatedImg}
//                 onClick={() => setSmallImage(animatedImg)}
//                 className="cp add_img object-fit-fill"
//               />
//             </div>
//             <div className="add_img d-flex ac-jc">
//               <img
//                 src={product}
//                 onClick={() => setSmallImage(product)}
//                 className="cp add_img object-fit-fill"
//               />
//             </div>
//             <div className="add_img d-flex ac-jc">
//               <img
//                 src={animatedImg}
//                 onClick={() => setSmallImage(animatedImg)}
//                 className="cp add_img object-fit-fill"
//               />
//             </div>
//             <ul
//               className={`${
//                 edit && "image-hide"
//               } edit-image image-show d-flex border_img`}
//             >
//               <li className="set-default-image">Set&nbsp;Default</li>
//               <li className="remove-image" onClick={() => deleteimg(ind)}>
//                 Remove&nbsp;Image
//               </li>
//             </ul>
//             {file?.map((images, ind) => {
//               console.log(images);
//               return (
//                 <div className="add_img d-flex ac-jc cp">
//                   <img
//                     src={images}
//                     className="add_img object-fit-fill"
//                     onClick={() => {
//                       setSmallImage(images);
//                       setInd(ind);
//                     }}
//                   />
//                   {/* <ul className={`${edit && 'image-hide'} edit-image image-show d-flex border_img`}>
//                                         <li className="set-default-image">Set&nbsp;Default</li>
//                                         <li className="remove-image" onClick={() => deleteimg(images && smallimage)}>Remove&nbsp;Image</li>
//                                     </ul> 
//                 </div>
//               );
//             })}
//             {edit ? (
//               <label className="add_img d-flex ac-jc cp f3 primary fs-3">
//                 +
//                 <input
//                   className="d-none"
//                   type="file"
//                   accept="image/png, image/gif, image/jpeg"
//                   onChange={imageUplode}
//                 />
//               </label>
//             ) : null}
//           </div>
//         </div> */}
//         <div>
//           <label
//             className="img_up_cont d-flex ac-jc cp"
//             onClick={() => !fistShow && dualToggele("product")}
//           >
//             <div className="d-flex ac-jc flex-column">
//               <div className="position-relative">
//                 <img
//                   className={
//                     bigImage?.image
//                       ? "img_up_cont object-fit-contain "
//                       : "placeholder_icon object-fit-contain"
//                   }
//                   src={bigImage?.image ? bigImage?.image : uploadIcon}
//                 />
//                 {!textShow && (
//                   <div>
//                     <ul
//                       className={`${"image-hide"}  edit-image image-show d-flex justify-content-between border_img`}
//                     >
//                       <li className="set-default-image">Set&nbsp;Default</li>
//                       {smallimage?.length > 1 && (
//                         <li
//                           className="set-default-image"
//                           onClick={() => removeImg(bigImage?.ind)}
//                         >
//                           Remove
//                         </li>
//                       )}
//                     </ul>
//                   </div>
//                 )}
//               </div>
//               {textShow ? (
//                 <p className="f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 px-5 primary">
//                   {" "}
//                   Click here to upload
//                 </p>
//               ) : null}
//             </div>
//           </label>
//           <div className="d-flex ac-jc gap-3 w-100">
//             {fistShow && (
//               <div
//                 class=" mt-3 gap-3 d-flex ac-jc pb-4 ps-5"
//                 style={{ width: "300px", overflow: "scroll" }}
//               >
//                 {smallimage?.map((item, ind) => {
//                   return (
//                     <div className="gap-3 d-flex ac-j w-100">
//                       <div
//                         class="add_img d-flex ac-jc"
//                         onClick={() => imageSelector(item, ind)}
//                       >
//                         {" "}
//                         <img src={item} class="cp add_img object-fit-fill" />
//                       </div>
//                     </div>
//                   );
//                 })}
//               </div>
//             )}
//             {smallimage?.length > 0 && (
//               <label
//                 className={`add_img d-flex ac-jc cp f3 primary fs-3 ${smallimage?.length == 0 && "mt-3"
//                   }`}
//                 onClick={() => dualToggele("product")}
//               >
//                 +
//               </label>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default RightComp; 
const RightComp = ({ edit, types, list }) => {
  console.log(types);
  const [logo, setLogo] = useState({});
  const [textShow, setTextShow] = useState(true);
  const [index, setIndex] = useState(0);
  const [imageShow, setImageShow] = useState(false);

  const [image, setImage] = useState([]);

  const [Indeximage, setIndexImage] = useState("");
  const [imageName, setImageName] = useState("defauld");

  const [croper, setCroper] = useState(false);
  const [croperImage, setCropImage] = useState();
  // const [textShow2, setTextShow2] = useState(false)
  const [fistShow, setFirstShow] = useState(false);
  const [croperCol, setCroperCol] = useState(false);
  const [smallimage, setSmallImage] = useState([]);
  const [bigImage, setBigImage] = useState({ image: "", ind: "" });

  const imageAdd = () => {};
  const removeimg = () => {
    const arr = [...image];
    const index = arr.indexOf();
    arr.splice(Indeximage);
    setImage([...arr]);
    console.log(arr);
  };
  // useEffect((ind) => {
  //     setIndexImage(ind)
  //     console.log(ind)
  // })

  const smallimg = (item) => {
    setSmallImage(item);
    // console.log(item)
  };

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  // Slide
  const [file, setFile] = useState([]);
  const [defaultImage, setDefaultImage] = useState(profilePic);

  const imageUplode = (e) => {
    setFile([...file, URL.createObjectURL(e.target.files[0])]);
    console.log("file", file);
  };

  const deleteimg = (e) => {
    const s = file.filter((item, index) => item !== smallimage);

    setFile(s);
    console.log(s, index);
  };

  console.log(smallimage);
  const [ind, setInd] = useState();
  console.log(types);

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  const dualToggele = (type) => {
    if (type == "color") {
      setCroperCol(false);
      toggleImagePopup();
    } else {
      setCroperCol(true);
      toggleImagePopup();
    }
  };

  useEffect(() => {
    if (croperCol && croperImage) {
      setTextShow(false);
      setFirstShow(true);

      let temp = [...smallimage];

      if (croperImage) {
        temp.push(croperImage);
      }

      setSmallImage(temp);

      setBigImage({ image: temp[temp.length - 1], ind: temp.length - 1 });
      setCropImage("");
    } else if (!croperCol && croperImage) {
      imageUplode(croperImage);
    }
  }, [croperImage]);

  const imageSelector = (item, ind) => {
    setBigImage({ image: item, ind: ind });
  };

  const removeImg = (ind) => {
    let temp = [...smallimage];

    temp.splice(ind, 1);
    setSmallImage(temp);
    if (temp?.length - 1 === 0) {
      setBigImage({ image: temp[temp.length - 1], ind: temp.length - 1 });
    } else {
      setBigImage({ image: temp[ind - 1], ind: ind - 1 });
    }
  };

  // Slide
  return (
    <div className="d-flex gap-2 flex-column ac-jc w-100 ">
      <div className="w-100 d-flex flex-column ac-jc pb-4">
        <div className="d-flex ac-js mb-3 gap-3">
          <h4 className="primary">
            {types === "Charles" ? "Product Image" : "Decorative Image"}
          </h4>
        </div>
        <div>
          <label
            className="img_up_cont d-flex ac-jc cp"
            // onClick={() => !fistShow && dualToggele("product")}
          >
            <div className="d-flex ac-jc flex-column">
              <div className="position-relative">
                <img
                  className={
                    list?.icon_url
                      ? "img_up_cont object-fit-contain "
                      : "placeholder_icon object-fit-contain"
                  }
                  src={list?.
                    decor_method_price?.[0]?.image_url
                    
                     ? list?.decor_method_price?.[0]?.image_url : uploadIcon}
                  // src={list?.icon_url ? list?.icon_url : uploadIcon}
                />
                {!textShow && (
                  <div>
                    <ul
                      className={`${"image-hide"}  edit-image image-show d-flex justify-content-between border_img`}
                    >
                      <li className="set-default-image">Set&nbsp;Default</li>
                      {smallimage?.length > 1 && (
                        <li
                          className="set-default-image"
                          onClick={() => removeImg(bigImage?.ind)}
                        >
                          Remove
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
              {/* {textShow ? (
                <p className="f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 px-5 primary">
                  {" "}
                  Click here to upload
                </p>
              ) : null} */}
            </div>
          </label>
          <div className="d-flex ac-jc gap-3 w-100">
            {fistShow && (
              <div
                class=" mt-3 gap-3 d-flex ac-jc pb-4 ps-5"
                style={{ width: "300px", overflow: "scroll" }}
              >
                {smallimage?.map((item, ind) => {
                  return (
                    <div className="gap-3 d-flex ac-j w-100">
                      <div
                        class="add_img d-flex ac-jc"
                        onClick={() => imageSelector(item, ind)}
                      >
                        {" "}
                        <img src={item} class="cp add_img object-fit-fill" />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {smallimage?.length > 0 && (
              <label
                className={`add_img d-flex ac-jc cp f3 primary fs-3 ${
                  smallimage?.length == 0 && "mt-3"
                }`}
                onClick={() => dualToggele("product")}
              >
                +
              </label>
            )}
          </div>
        </div>
      </div>
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
    </div>
  );
};

export default RightComp;

