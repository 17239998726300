import React, { useEffect, useRef, useState } from "react";
import { product, uploadIcon } from "../../assets/img";
import { useLocation, useNavigate } from "react-router-dom";
import { productDetailImg } from "../../redux/api/constants";
import ImageCroper from "../Popup/ImageCroper";
import ErrorIcon from "@mui/icons-material/Error";

const ImageUplodeComp = ({
  vendor,
  vendor2,
  vendor3,
  image,
  imageErr,
  setImage,
}) => {
  const navigate = useNavigate();
  // console.log(vendor);
  const [logo, setLogo] = useState({});
  const [textShow, setTextShow] = useState(true);
  // const [textShow2, setTextShow2] = useState(false)
  const [file, setFile] = useState("");

  const [croper, setCroper] = useState(false);
  const [croperCol, setCroperCol] = useState(false);
  const [croperImage, setCropImage] = useState();
  // const [textShow2, setTextShow2] = useState(false)
  const [fistShow, setFirstShow] = useState(false);
  const [smallimage, setSmallImage] = useState([]);
  const [bigImage, setBigImage] = useState({ image: "", ind: "" });

  // const [image, setImage] = useState([]);
  const proImgRef = useRef();

  const [Indeximage, setIndexImage] = useState("");
  const [imageName, setImageName] = useState("defauld");

  const dualToggele = (type) => {
    if (type == "color") {
      setCroperCol(false);
      toggleImagePopup();
    } else {
      setCroperCol(true);
      toggleImagePopup();
    }
  };

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  useEffect(() => {
    if (croperCol && croperImage) {
      setTextShow(false);
      setFirstShow(true);

      let temp = [...smallimage];

      if (croperImage) {
        temp.push(croperImage);
      }

      setSmallImage(temp);

      setBigImage({ image: temp[temp.length - 1], ind: temp.length - 1 });
      setCropImage("");
    } else if (!croperCol && croperImage) {
      imageUplode(croperImage);
    }
  }, [croperImage]);

  const imageSelector = (item, ind) => {
    setBigImage({ image: item, ind: ind });
  };

  const removeImg = (ind) => {
    let temp = [...smallimage];

    temp.splice(ind, 1);
    setSmallImage(temp);
    if (temp?.length - 1 === 0) {
      setBigImage({ image: temp[temp.length - 1], ind: temp.length - 1 });
    } else {
      setBigImage({ image: temp[ind - 1], ind: ind - 1 });
    }
  };

  const imageUplode = (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));
    // console.log(file, "windfine");
    // setTextShow(false)
  };
  // console.log(vendor2);

  const imgclick = (e) => {
    setSmallImage(URL.createObjectURL(e.target.files[0]));
    // console.log(smallimage, "windfine");
    setTextShow(false);
  };
  return (
    <div className="d-flex gap-3 ac-jc w-100">
      <div>
        <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 text-center mb-3">
          Product Image
        </p>

        <div>
          <label
            className="img_up_cont d-flex ac-jc cp"
            onClick={() => {
              proImgRef?.current?.click();
              setTextShow(false);
            }}
          >
            <div className="d-flex ac-jc flex-column">
              <div className="position-relative">
                <img
                  className={
                    image?.name
                      ? "img_up_cont object-fit-contain "
                      : image
                      ? "img_up_cont object-fit-contain "
                      : "placeholder_icon object-fit-contain"
                  }
                  src={
                    image?.name
                      ? URL.createObjectURL(image)
                      : image
                      ? image
                      : uploadIcon
                  }
                />
              </div>
              {textShow ? (
                <p className="f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 px-5 primary">
                  {" "}
                  Click here to upload
                </p>
              ) : null}
            </div>
          </label>
        </div>
        {image?.length == "" && imageErr && (
          <div className="d-flex gap-1 mt-2">
            <ErrorIcon className="svg_log" />
            <p className="err-input_log mt-1">Enter Image</p>
          </div>
        )}
      </div>

      <input
        type="file"
        accept=".pdf,.doc,.jpeg,.docx,.png,.jpg"
        onChange={(e) => setImage(e.target.files[0])}
        className="d-none"
        ref={proImgRef}
      />

      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
    </div>
  );
};

export default ImageUplodeComp;
