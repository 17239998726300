import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import {
  useLazyAddressListQuery,
  useRoleListMutation,
  useTagsListMutation,
} from "../../redux/api/api";

const EditContactPopup = ({
  vendorDepartment,
  showPopupHander,
  setPopup,
  setContactPopup,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  setEmail,
  email,
  setPassword,
  password,
  setPosition,
  position,
  setRole,
  role,
  setNumber,
  number,
  setDepartment,
  department,
  setTwitter,
  twitter,
  setFacebook,
  facebook,
  setLinkIn,
  linkIn,
  setSkype,
  skype,
  setAddress,
  address,
  setNoMarketing,
  noMarketing,
  setTags,
  tags,
  firstNameErr,
  lastNameErr,
  emailErr,
  passwordErr,
  positionErr,
  roleErr,
  numberErr,
  departmentErr,
  twitterErr,
  facebookErr,
  linkInErr,
  skypeErr,
  addressErr,
  noMarketingErr,
  tagsErr,
  addressBtn,
  submitHandleVendorUser,
  departmentList,
  vendorAddressList,
  roleList,
  tagsList,
  numbers,
  setNumbers,
  primaryNumbers,
  setPrimaryNumbers,
  primaryNumbersErr,
}) => {
  const addPhone = () => {
    let temp = [...number];

    temp.push({ number_type: "", number: "" });
    setNumber(temp);
  };

  const removePhone = (item, ind) => {
    let temp = [...number];

    temp.splice(ind, 1);

    setNumber(temp);
    if (temp?.length == 1) {
      setNumbers("0000000000");
    } else {
      setNumbers("");
    }
  };

  const handleChange = (event, ind) => {
    let name = event.target.name;
    let value = event.target.value;

    let temp = [...number];

    temp[ind][name] = value;
    setNumber(temp);
  };

  const [passwordStringerr, setPasswordStringerr] = useState("");
  const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);

  const handlePassword = (event) => {
    let new_pass = event.target.value;
    if (new_pass?.length !== 0) {
      setPassword(new_pass);

      // regular expressions to validate password
      var lowerCase = /[a-z]/g;
      var upperCase = /[A-Z]/g;
      var numbers = /[0-9]/g;
      var special = /[*@!#%&()^~{}]+/g;
      if (!new_pass.match(lowerCase)) {
        setPasswordStringerr("Password should contains lowercase letters!");
      } else if (!new_pass.match(upperCase)) {
        setPasswordStringerr("Password should contain uppercase letters!");
      } else if (!new_pass.match(numbers)) {
        setPasswordStringerr("Password should contains numbers also!");
      } else if (new_pass.length < 6) {
        setPasswordStringerr("Password length should be more than 6.");
      } else if (!new_pass.match(special)) {
        setPasswordStringerr(
          "Password should contains special character also!"
        );
      } else {
        // setErrorMessage("Password is strong!");
        setPasswordStringerr("");
      }
    } else {
      setPasswordStringerr("");
      setPassword(new_pass);
    }
  };

  const handleMarketing = () => {
    if (noMarketing == 0) {
      setNoMarketing(1);
    } else {
      setNoMarketing(0);
    }
  };

  return (
    <div className="modal-popup">
      <div onClick={showPopupHander} className="back-close" />
      <div className="center-content-contact p-4">
        <div className="w-100 d-flex ac-jb">
          <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            Add Contact
          </p>
          <CloseIcon
            onClick={() => showPopupHander(false)}
            className="pointerView white p-1 rounded-5 f2 bg-primar fs-xs-28 fs-sm-28 fs-md-28 fs-lg-28 fs-xl-28 fs-xxl-25"
          />
        </div>
        <div className="w-100 addScrollPop overflow-scroll">
          <div className="w-100 mt-lg-4 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                First Name
              </p>
              <input
                placeholder="First Name"
                className="editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
              />
              {firstName?.length == 0 && firstNameErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter First Name </p>
                </div>
              )}
            </div>
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Last Name
              </p>
              <input
                placeholder="Last Name"
                className=" editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
              />
              {lastName?.length == 0 && lastNameErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Last Name </p>
                </div>
              )}
            </div>
          </div>
          <div className="w-100 mt-lg-4 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Email
              </p>
              <input
                placeholder="Email"
                className="editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                disabled={vendorDepartment?.vendor_department_id ? true : false}
              />
              {email?.length == 0 && emailErr && (
                <div className="d-flex gap-1 mt-2">
                  {" "}
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Email</p>
                </div>
              )}
              {email.length > 0 && validEmail.test(email) === false && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Vaild Email </p>
                </div>
              )}
            </div>
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Password
              </p>
              <input
                placeholder="Password"
                className=" editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                onChange={(e) => handlePassword(e)}
                value={password}
              />
              {password?.length == 0 && passwordErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Password </p>
                </div>
              )}
              {password?.length > 0 && passwordStringerr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">{passwordStringerr}</p>
                </div>
              )}
            </div>
          </div>
          <div className="w-100 mt-lg-4 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Designation
              </p>
              <input
                placeholder="Position"
                className="editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                onChange={(e) => setPosition(e.target.value)}
                value={position}
              />
              {position?.length == 0 && positionErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Designation </p>
                </div>
              )}
            </div>
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Role
              </p>
              <select
                placeholder=""
                className="w-90 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3 mt-2"
                name=""
                id=""
                onChange={(e) => setRole(e.target.value)}
                value={role}
              >
                <option value={""}>Select Role</option>
                {roleList?.map((item, ind) => {
                  return (
                    <option value={item?.id} key={ind}>
                      {item?.role}
                    </option>
                  );
                })}
              </select>
              {role?.length == 0 && roleErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select Role </p>
                </div>
              )}
            </div>
          </div>
          <div className="w-100 mt-lg-4 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Department
              </p>
              <select
                placeholder=""
                className="w-90 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3 mt-2"
                name=""
                id=""
                onChange={(e) => setDepartment(e.target.value)}
                value={department}
              >
                <option value={""}>Select Department</option>
                {departmentList?.map((item, ind) => {
                  return (
                    <option value={item?.id} key={ind}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
              {department?.length == 0 && departmentErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select Department </p>
                </div>
              )}
            </div>
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Primary Number
              </p>
              <input
                placeholder="Primary Number"
                className="editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value={primaryNumbers}
                onChange={(e) => {
                  setPrimaryNumbers(e.target.value);
                }}
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                  if (e.target.value.length !== 10) {
                    e.target.setCustomValidity("invalid Number");
                  } else if (e.target.value.length == 10) {
                    e.target.setCustomValidity("");

                    setPrimaryNumbers(e.target.value);
                  }
                }}
                onFocus={(e) =>
                  e.target.addEventListener(
                    "wheel",
                    function (e) {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
                maxlength={10}
              />
              {primaryNumbers?.length == 0 && primaryNumbersErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Primary Number </p>
                </div>
              )}
            </div>
          </div>
          <div className="w-95 mt-lg-4">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Phone Number
            </p>
            {number?.map((item, ind) => {
              return (
                <div className="w-100 d-flex ac-jb">
                  <select
                    placeholder=""
                    className="w-18 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3 mt-2"
                    name="number_type"
                    id=""
                    value={item?.number_type}
                    onChange={(e) => {
                      handleChange(e, ind);
                    }}
                  >
                    <option value={""}>Select</option>
                    <option value={"work"}>Work</option>
                    <option value={"home"}>Home</option>
                    <option value={"others"}>Others</option>
                  </select>
                  <input
                    placeholder=""
                    name="number"
                    type={"number"}
                    value={item?.number}
                    className=" editBtn rounded-3 mt-2 p-2 w-55 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17"
                    onChange={(e) => {
                      handleChange(e, ind);
                      setNumbers(e.target.value);
                    }}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                      if (e.target.value.length !== 10) {
                        e.target.setCustomValidity("invalid Number");
                      } else if (e.target.value.length == 10) {
                        e.target.setCustomValidity("");

                        handleChange(e, ind);
                      }
                    }}
                    onFocus={(e) =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                    maxlength={10}
                  />
                  <div className="d-flex ac-jc">
                    {number?.length > 1 && (
                      <div onClick={() => removePhone(item, ind)}>
                        <DeleteIcon className="pointerView ms-1 primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25" />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
            <div className="d-flex justify-content-end mt-2">
              {number?.length < 3 && (
                <button
                  className="primary border-0 bg-transparent f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-13"
                  onClick={addPhone}
                >
                  +Add Phone
                </button>
              )}
            </div>
            {numbers?.length == 0 && numberErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Phone No. </p>
              </div>
            )}
          </div>
          <div className="w-100 mt-lg-4 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Address
              </p>
              <select
                placeholder=""
                className="w-90 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3 mt-2"
                name=""
                id=""
                onChange={(e) => setAddress(e.target.value)}
                value={address}
              >
                <option value={""}>Select Address</option>
                {vendorAddressList?.map((item, ind) => {
                  return (
                    <option value={item?.id} key={ind}>
                      {item?.address}
                    </option>
                  );
                })}
              </select>
              {address?.length == 0 && addressErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select Address</p>
                </div>
              )}
            </div>
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Tags
              </p>
              <select
                placeholder=""
                className="w-90 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3 mt-2"
                name=""
                id=""
                onChange={(e) => setTags(e.target.value)}
                value={tags}
              >
                <option value={""}>Select Tags</option>
                {tagsList?.map((item, ind) => {
                  return (
                    <option value={item?.id} key={ind}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>

              {tags?.length == 0 && tagsErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Tags</p>
                </div>
              )}
            </div>
          </div>
          <div className="w-100 mt-lg-4 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Facebook
              </p>
              <input
                placeholder=""
                className="editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                onChange={(e) => setFacebook(e.target.value)}
                value={facebook}
              />
              {facebook?.length == 0 && facebookErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Facebook </p>
                </div>
              )}
            </div>
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Twitter Handle
              </p>
              <input
                className="editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                onChange={(e) => setTwitter(e.target.value)}
                value={twitter}
              />
              {twitter?.length == 0 && twitterErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Twitter </p>
                </div>
              )}
            </div>
          </div>
          <div className="w-100 mt-lg-4 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Skype
              </p>
              <input
                className="editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                onChange={(e) => setSkype(e.target.value)}
                value={skype}
              />
              {skype?.length == 0 && skypeErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Skype</p>
                </div>
              )}
            </div>
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Linked In
              </p>
              <input
                placeholder=""
                className=" editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                onChange={(e) => setLinkIn(e.target.value)}
                value={linkIn}
              />
              {linkIn?.length == 0 && linkInErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Linked In </p>
                </div>
              )}
            </div>
          </div>
          <div className="w-100 mt-lg-4 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                No Marketing
              </p>
              <input
                type="radio"
                className="pointerView rounded-3 mt-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                onClick={(e) => handleMarketing()}
                checked={noMarketing == 1 ? true : false}
              />
              {noMarketing?.length == 0 && noMarketingErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select No Marketing</p>
                </div>
              )}
            </div>
          </div>
          <div className="d-flex ac-jc mb-4">
            <button
              className={`cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                addressBtn && "opacity-50"
              }`}
              onClick={submitHandleVendorUser}
              disabled={addressBtn}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditContactPopup;
