import React, { useEffect, useState } from "react";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import { uploadIcon } from "../assets/img";
import FormComp from "../components/DecorativeVendor/FormComp";
import ImageUplodeComp from "../components/DecorativeVendor/ImageUplodeComp";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  useDecorativeListMutation,
  useDecorativeMethodeAddedMutation,
  useLazyDecorativedroupdounQuery,
} from "../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";

const AddDecorativeVendor = () => {
  const vendors = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const vendor = location?.state?.type;
  const vendor2 = location?.state?.type;
  const legalEntityName = location?.state?.legalEntityName;
  const vendor3 = location?.state?.form;
  console.log(location);
  // const ven = location?.state?.product
  const [list, setList] = useState([]);
  const [imageErr, setImageErr] = useState(false);
  const [image, setImage] = useState("");
  const [decorativeId, setDecorativeId] = useState("");
  const [decorativeMethod, setDecorativeMethod] = useState("");
  const [decorativeMethodErr, setDecorativeMethodErr] = useState(false);
  const [btn, setBtn] = useState(false);
  const [adminPriceErr, setAdminPriceErr] = useState(false);
  const [showPricing, setShowPricing] = useState(false);
  const [priceErr, setPriceErr] = useState(false);

  const [adminPrice, setAdminPrice] = useState([
    {
      uom: "Stitches",
      uom_count: "",
      color1_rate: "",
      color2_rate: "",
      color3_rate: "",
      id: "",
    },
    {
      uom: "Inches",
      uom_count: "",
      color1_rate: "",
      color2_rate: "",
      color3_rate: "",
      id: "",
    },
  ]);
  // console.log(adminPrice, "adminPrice");

  const [description, setDescription] = useState("");
  //ERR
  const [descriptionErr, setDescriptionErr] = useState(false);
  //RTK
  const [decorativeListsApi] = useLazyDecorativedroupdounQuery();
  const [decorativeAddedApi] = useDecorativeMethodeAddedMutation();

  const getList = () => {
    decorativeListsApi()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          // console.log(res, "res");
          setList(res?.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // DECORATION SUBMIT

  const onSubmit = () => {
    let stitchesCheck = false;
    let inchesCheck = false;
    let inches = adminPrice?.[0];
    let stitches = adminPrice?.[1];

    if (
      !inches?.uom_count ||
      !inches?.color1_rate ||
      !inches?.color2_rate ||
      !inches?.color3_rate
    ) {
      inchesCheck = true;
    }

    if (
      !stitches?.uom_count ||
      !stitches?.color1_rate ||
      !stitches?.color2_rate ||
      !stitches?.color3_rate
    ) {
      stitchesCheck = true;
    }

    if (
      description?.length == "" ||
      decorativeMethod?.length == "" ||
      image?.length == "" ||
      (stitchesCheck && inchesCheck)
    ) {
      setDescriptionErr(true);
      setDecorativeMethodErr(true);
      setImageErr(true);
      setAdminPriceErr(true);
    } else {
      const formData = new FormData();
      if (image?.name) {
        formData.append("image", image);
      }
      formData.append("vendor_id", vendors?.vendor?.id);
      formData.append("decorative_method_id", decorativeId);
      formData.append("description", description);
      adminPrice?.map((item, ind) => {
        // if (
        //   item?.uom &&
        //   item?.uom_count &&
        //   item?.color1_rate &&
        //   item?.color2_rate &&
        //   item?.color3_rate
        //   // item?.id
        // ) {

        formData.append(`decor_price_details[${ind}][uom]`, item?.uom);
        formData.append(
          `decor_price_details[${ind}][uom_count]`,
          item?.uom_count
        );
        formData.append(
          `decor_price_details[${ind}][color1_rate]`,
          item?.color1_rate
        );
        formData.append(
          `decor_price_details[${ind}][color2_rate]`,
          item?.color2_rate
        );
        formData.append(
          `decor_price_details[${ind}][color3_rate]`,
          item?.color3_rate
        );
        // formData.append(
        //   `decor_price_details[${ind}][decor_method_price_id]`,
        //   item?.id
        // );
        //  }
      });
      setBtn(true);
      dispatch(saveLoader(true));
      decorativeAddedApi(formData)
        .unwrap()
        .then((res) => {
          setBtn(false);
          dispatch(saveLoader(false));
          navigate("/vendor-details", {
            state: { tab: 3, data: vendor2, name: "John Rolph" },
          });
        })
        .catch((err) => {
          setBtn(false);
          dispatch(saveLoader(false));
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (showPricing) {
      let stitchesCheck = false;
      let inchesCheck = false;
      let inches = adminPrice?.[0];
      let stitches = adminPrice?.[1];

      if (
        !inches?.uom_count ||
        !inches?.color1_rate ||
        !inches?.color2_rate ||
        !inches?.color3_rate
      ) {
        inchesCheck = true;
      }

      if (
        !stitches?.uom_count ||
        !stitches?.color1_rate ||
        !stitches?.color2_rate ||
        !stitches?.color3_rate
      ) {
        stitchesCheck = true;
      }

      if (stitchesCheck && inchesCheck) {
        setAdminPriceErr(true);
      } else {
        setAdminPriceErr(false);
      }
    }
  }, [adminPrice]);

  useEffect(() => {
    getList();
  }, []);

  // console.log(vendor);
  // console.log(list, "decorativeList");
  return (
    <div className="dashRightView overflow-scroll w-100">
      <ProductSearchHeader />
      <p
        className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 d-flex align-items-center gap-1"
        role={"button"}
        onClick={() => navigate(-1)}
      >
        <KeyboardBackspaceIcon /> Add New Decoration
      </p>
      <div className="d-flex as-jb w-100 mt-5 res-flex gap-5">
        <FormComp
          priceErr={priceErr}
          legalEntityName={legalEntityName}
          vendor={vendors?.vendor?.id}
          list={list}
          setAdminPrice={setAdminPrice}
          adminPrice={adminPrice}
          description={description}
          setDescription={setDescription}
          setDecorativeId={setDecorativeId}
          decorativeId={decorativeId}
          onSubmit={onSubmit}
          descriptionErr={descriptionErr}
          btn={btn}
          setDecorativeMethod={setDecorativeMethod}
          decorativeMethod={decorativeMethod}
          decorativeMethodErr={decorativeMethodErr}
          adminPriceErr={adminPriceErr}
          setShowPricing={setShowPricing}
        />
        <ImageUplodeComp
          vendor={vendors?.vendor?.id}
          vendor2={vendor2}
          setImage={setImage}
          image={image}
          imageErr={imageErr}
        />
      </div>
    </div>
  );
};

export default AddDecorativeVendor;
