import ProfileNavBar from "../components/ProfileNavBar";
import { Search } from "@mui/icons-material";
import manImage from "../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import AddVendorDetails1 from "../components/AddVendor/AddVendorDetails1";
import AddVendorDetails2 from "../components/AddVendor/AddVendorDetails2";
import DoneIcon from "@mui/icons-material/Done";
import AddVendorFinal from "../components/AddVendor/AddVendorFinal";
import AddVendorPopup from "../components/Popup/AddVendorPopup";
import VendorSuccessPopup from "../components/Popup/VendorSuccessPopup";
import AddDet1 from "../components/AddDetails/AddDet1";
import AddDet2 from "../components/AddDetails/AddDet2";
import AddDet3 from "../components/AddDetails/AddDet3";
import AddDetPopup from "../components/Popup/AddDetpopup";
import {
  useLazyCategoriesListVendorQuery,
  useLazyCurrencyListQuery,
  useLazyVendor_categoryQuery,
  useLazyVerificationQuery,
  useRegisterMutation,
} from "../redux/api/api";
import { useAccordionButton } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";

const AddDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchparams] = useSearchParams();

  //RTK
  const [registerapi] = useRegisterMutation();
  const [Verification] = useLazyVerificationQuery();
  const [vendorCatApi] = useLazyVendor_categoryQuery();
  const [categoryListApi] = useLazyCategoriesListVendorQuery();
  const [currencyListApi] = useLazyCurrencyListQuery();

  //General Details
  const [categoryList, setCategoryList] = useState([]);
  const [firstname, setfirstname] = useState("");
  const [email, setEmail] = useState("");
  const [vendorCatogory, setVendorCatogory] = useState([]);
  const [currenciesLists, setCurrenciesLists] = useState([]);
  const [mobile, setmobile] = useState("");
  const [name, setname] = useState("");
  const [Tradename, setTradename] = useState("");
  const [vendorOfferType, setVendorOfferType] = useState("");
  const [lineBusiness, setLineBusiness] = useState("");
  const [paymentTerms, setPaymentTerms] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [GstID, setGstID] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [currency, setCurrency] = useState("");
  const [currencyName, setCurrencyName] = useState("");
  const [description, setDescription] = useState("");
  const [password, setpassword] = useState("");
  const [confirmPassword, setConfirmPass] = useState("");
  const [logo, setLogo] = useState("");
  const [banner, setBanner] = useState("");
  const [btn, setBtn] = useState(false);

  //error
  const [nameErr, setnameErr] = useState(false);
  const [lineBusinessErr, setLineBusinessErr] = useState(false);
  const [TradenameErr, setTradenameErr] = useState(false);
  const [vendorCatErr, setVendorCatErr] = useState(false);
  const [vendorOfferTypeErr, setVendorOfferTypeErr] = useState(false);
  const [paymentTermsErr, setPaymentTermsErr] = useState(false);
  const [paymentMethodErr, setPaymentMethodErr] = useState(false);
  const [GstIDErr, setGstIDErr] = useState(false);
  const [address1Err, setAddress1Err] = useState(false);
  const [address2Err, setAddress2Err] = useState(false);
  const [countryErr, setCountryErr] = useState(false);
  const [stateErr, setStateErr] = useState(false);
  const [cityErr, setCityErr] = useState(false);
  const [pincodeErr, setPincodeErr] = useState(false);
  const [currencyErr, setCurrencyErr] = useState(false);
  const [descriptionErr, setDescriptionErr] = useState(false);
  const [passwordErr, setpasswordErr] = useState(false);
  const [confirmPasswordErr, setConfirmPassErr] = useState(false);
  const [logoErr, setLogoErr] = useState(false);
  const [bannerErr, setBannerErr] = useState(false);

  const [secondDet, setSecondDet] = useState(false);
  const [finalView, setFinalView] = useState(false);
  const [vendorCat, setVendorCat] = useState("");
  const [popup, setPopup] = useState(false);
  const [successPop, setSuccessPop] = useState(false);
  const location = useLocation();

  const [listStates, setListStates] = useState([]);
  const [listCities, setListCities] = useState([]);

  //vendorcate
  const vendorCatGet = () => {
    vendorCatApi()
      .unwrap()
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {});
  };
  // useEffect(()=>{
  //   vendorCatGet()
  // },[])
  //Verification

  const getverification = () => {
    categoryListApi()
      .unwrap()
      .then((responsive) => {
        // setCategoryList(responsive?.categories);
        Verification(searchParams.get("token"))
          .unwrap()
          .then((res) => {
            // console.log(res);
            let data = res?.v_user;
            setfirstname(data?.name);
            setEmail(data?.email);
            setmobile(data?.mobile);
            setVendorOfferType(data?.offering_type);
            let tempID = [];
            data?.category_ids?.map((item, ind) => {
              tempID.push(+item);
            });
            let temp = [];
            responsive?.categories?.map((item, ind) => {
              if (tempID.includes(+item?.id)) {
                temp.push(item?.name);
              }
            });
            // console.log("temp", temp);
            setCategoryList(temp);
            setVendorCatogory(data?.category_ids);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (searchParams.get("token")) {
      getverification();
    }

    localStorage.clear();
  }, []);

  useEffect(() => {
    if (location?.state?.type === "email") {
      setSecondDet(true);
    }
  }, [location]);

  const showPopupHander = () => {
    setPopup(false);
    setSuccessPop(false);
  };

  useEffect(() => {
    localStorage.clear();
    currencyListApi()
      .unwrap()
      .then((res) => {
        setCurrenciesLists(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  const onNext = () => {
    if (
      name?.length == 0 ||
      Tradename?.length == 0 ||
      vendorCat?.length == 0 ||
      // vendorOfferType?.length == 0 ||
      paymentTerms?.length == 0 ||
      paymentMethod?.length == 0 ||
      GstID?.length == 0 ||
      address1?.length == 0 ||
      // address2.length == 0 ||
      country?.length == 0 ||
      state?.length == 0 ||
      city?.length == 0 ||
      pincode?.length == 0 ||
      currency?.length == 0 ||
      description?.length == 0 ||
      // password.length == 0 ||
      // confirmPassword.length == 0 ||
      // logo.length == 0 ||
      // banner.length == 0 ||
      lineBusiness.length == 0
    ) {
      setnameErr(true);
      setTradenameErr(true);
      setVendorCatErr(true);
      // setVendorOfferTypeErr(true);
      setPaymentTermsErr(true);
      setPaymentMethodErr(true);
      setGstIDErr(true);
      setAddress1Err(true);
      // setAddress2Err(true);
      setCountryErr(true);
      setStateErr(true);
      setCityErr(true);
      setPincodeErr(true);
      setCurrencyErr(true);
      setDescriptionErr(true);
      // setpasswordErr(true);
      // setConfirmPassErr(true);
      // setLogoErr(true);
      // setBannerErr(true);
      setLineBusinessErr(true);
    } else {
      localStorage.clear();
      setSecondDet(false);
      setFinalView(true);
    }
  };

  const submitdata = () => {
    if (
      name?.length == 0 ||
      Tradename?.length == 0 ||
      vendorCat?.length == 0 ||
      // vendorOfferType?.length == 0 ||
      paymentTerms?.length == 0 ||
      paymentMethod?.length == 0 ||
      GstID?.length == 0 ||
      address1?.length == 0 ||
      // address2.length == 0 ||
      country?.length == 0 ||
      state?.length == 0 ||
      city?.length == 0 ||
      pincode?.length == 0 ||
      currency?.length == 0 ||
      description?.length == 0 ||
      // password.length == 0 ||
      // confirmPassword.length == 0 ||
      // logo.length == 0 ||
      // banner.length == 0 ||
      lineBusiness?.length == 0
    ) {
      setnameErr(true);
      setTradenameErr(true);
      setVendorCatErr(true);
      // setVendorOfferTypeErr(true);
      setPaymentTermsErr(true);
      setPaymentMethodErr(true);
      setGstIDErr(true);
      setAddress1Err(true);
      // setAddress2Err(true);
      setCountryErr(true);
      setStateErr(true);
      setCityErr(true);
      setPincodeErr(true);
      setCurrencyErr(true);
      setDescriptionErr(true);
      // setpasswordErr(true);
      // setConfirmPassErr(true);
      // setLogoErr(true);
      // setBannerErr(true);
      setLineBusinessErr(true);
    } else {
      const formdata = new FormData();
      formdata.append("logo", logo);
      formdata.append("banner", banner);
      formdata.append("name", Tradename);
      formdata.append("legal_name", name);
      // formdata.append("code","798777")
      formdata.append("type_of_business", vendorCat);
      formdata.append("offering_type", vendorOfferType);
      // formdata.append(
      //   "offering_type",
      //   vendorOfferType == "Product"
      //     ? 1
      //     : vendorOfferType == "Services"
      //     ? 2
      //     : vendorOfferType == "Both"
      //     ? 3
      //     : 0
      // );
      formdata.append("payment_terms", paymentTerms);
      formdata.append("gst", GstID);
      formdata.append("address1", address1);
      formdata.append("country", country);
      formdata.append("city", city);
      formdata.append("state", state);
      formdata.append("pincode", pincode);
      formdata.append("currency_id", currency);
      formdata.append("password", firstname);

      vendorCatogory?.map((item, ind) => {
        formdata.append(`category_ids[${ind}]`, item);
      });

      lineBusiness?.map((item, ind) => {
        formdata.append(`line_of_business[${ind}]`, item);
      });

      paymentMethod?.map((item, ind) => {
        formdata.append(`payment_method[${ind}]`, item);
      });

      // formdata.append("line_of_business", lineBusiness);
      // formdata.append("payment_method", paymentMethod);

      formdata.append("description", description);
      // formdata.append("department_id",)
      formdata.append("first_name", firstname);
      formdata.append("email", email);
      formdata.append("primary_mobile", mobile);
      formdata.append("type", 3);
      // formdata.append("additional_info",)
      // formdata.append("password", password);
      // formdata.append("password_confirmation", confirmPassword);
      // formdata.append("position","ftfttyg")
      // formdata.append("is_marketing",'GYUhh')
      // formdata.append("landmark",'678676689667')
      // formdata.append("type",'678676689667')
      // formdata.append("address",address1)
      dispatch(saveLoader(true));
      setBtn(true);
      registerapi(formdata)
        .unwrap()
        .then((res) => {
          localStorage.clear();
          console.log(res);
          toast.success(res?.message);
          setBtn(false);
          dispatch(saveLoader(false));
          setPopup(true);
        })
        .catch((err) => {
          localStorage.clear();
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  const keydownHandler = (event) => {
    if (event.key == "Enter") {
      submitdata();
    }
  };

  return (
    <div className="dashRightView overflow-scroll w-90 m-auto">
      {popup && (
        <AddDetPopup
          showPopupHander={showPopupHander}
          setPopup={setPopup}
          setSuccessPop={setSuccessPop}
        />
      )}
      {/* {successPop && <VendorSuccessPopup showPopupHander={showPopupHander} />} */}
      {/* <div className="d-flex w-90 searchField p-2">
        <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
        <input
          className="cust-btn ms-4 w-100"
          placeholder="Enter Project Name, Client Name, number or PO# to search" />
        <img src={manImage} className="searchManImg" />
      </div> */}
      <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-5">
        Add Vendor
      </p>

      <div className="d-flex ac-js mt-4">
        {!secondDet && !finalView && (
          <p className="white bg-primar f2 radiusVendDes fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            1
          </p>
        )}
        {(finalView || secondDet) && (
          <DoneIcon className="white bg-green1 f2 radiusVendDesIcon fs-xs-28 fs-sm-28 fs-md-28 fs-lg-28 fs-xl-28 fs-xxl-28 mt-1 " />
        )}
        <p className="black f2 p-2 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
          Primary Detail
        </p>
        <div className="border-bottom w-5 border-primary1" />
        {!finalView && (
          <p
            className={`${
              secondDet ? "bg-primar" : "bg-primary1"
            } white ms-3 f2 radiusVendDes rounded-5 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1`}
          >
            2
          </p>
        )}
        {finalView && (
          <DoneIcon className="white bg-green1 f2 ms-3 radiusVendDesIcon fs-xs-28 fs-sm-28 fs-md-28 fs-lg-28 fs-xl-28 fs-xxl-28 mt-1 " />
        )}
        <p
          className={`
        ${
          secondDet || finalView ? "black" : "primary1"
        } f2 p-2 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1`}
        >
          General Detail
        </p>
        <div className="border-bottom w-5 border-primary1" />

        <p
          className={`
        ${
          finalView ? "bg-primar" : "bg-primary1"
        } white ms-3 f2 radiusVendDes rounded-5 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1`}
        >
          3
        </p>
        <p
          className={`${
            finalView ? "black" : "primary1"
          }  f2 p-2 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1`}
        >
          Final Verification
        </p>
      </div>
      {!secondDet && !finalView && (
        <AddDet1
          setVendorCat={setVendorCat}
          vendorCat={vendorCat}
          firstname={firstname}
          setfirstname={setfirstname}
          email={email}
          setEmail={setEmail}
          mobile={mobile}
          setmobile={setmobile}
          categoryList={categoryList}
          vendorOfferType={vendorOfferType}
        />
      )}
      {secondDet && (
        <AddDet2
          currenciesLists={currenciesLists}
          name={name}
          setname={setname}
          nameErr={nameErr}
          Tradename={Tradename}
          setTradename={setTradename}
          TradenameErr={TradenameErr}
          vendorCat={vendorCat}
          setVendorCat={setVendorCat}
          vendorCatErr={vendorCatErr}
          vendorOfferType={vendorOfferType}
          setVendorOfferType={setVendorOfferType}
          vendorOfferTypeErr={vendorOfferTypeErr}
          paymentTerms={paymentTerms}
          setPaymentTerms={setPaymentTerms}
          paymentTermsErr={paymentTermsErr}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          paymentMethodErr={paymentMethodErr}
          GstID={GstID}
          setGstID={setGstID}
          GstIDErr={GstIDErr}
          address1={address1}
          setAddress1={setAddress1}
          address1Err={address1Err}
          address2={address2}
          setAddress2={setAddress2}
          address2Err={address2Err}
          country={country}
          setCountry={setCountry}
          state={state}
          setState={setState}
          countryErr={countryErr}
          stateErr={stateErr}
          city={city}
          setCity={setCity}
          cityErr={cityErr}
          pincode={pincode}
          setPincode={setPincode}
          pincodeErr={pincodeErr}
          currency={currency}
          setCurrency={setCurrency}
          setCurrencyName={setCurrencyName}
          currencyName={currencyName}
          currencyErr={currencyErr}
          descriptionErr={descriptionErr}
          description={description}
          setDescription={setDescription}
          password={password}
          setpassword={setpassword}
          passwordErr={passwordErr}
          confirmPasswordErr={confirmPasswordErr}
          confirmPassword={confirmPassword}
          setConfirmPass={setConfirmPass}
          logo={logo}
          logoErr={logoErr}
          banner={banner}
          bannerErr={bannerErr}
          setBanner={setBanner}
          setLogo={setLogo}
          lineBusiness={lineBusiness}
          setLineBusiness={setLineBusiness}
          lineBusinessErr={lineBusinessErr}
          setListStates={setListStates}
          listStates={listStates}
          listCities={listCities}
          setListCities={setListCities}
        />
      )}

      {!secondDet && finalView && (
        <AddDet3
          currenciesLists={currenciesLists}
          name={name}
          setname={setname}
          nameErr={nameErr}
          Tradename={Tradename}
          setTradename={setTradename}
          TradenameErr={TradenameErr}
          vendorCat={vendorCat}
          setVendorCat={setVendorCat}
          vendorCatErr={vendorCatErr}
          vendorOfferType={vendorOfferType}
          setVendorOfferType={setVendorOfferType}
          vendorOfferTypeErr={vendorOfferTypeErr}
          paymentTerms={paymentTerms}
          setPaymentTerms={setPaymentTerms}
          paymentTermsErr={paymentTermsErr}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          paymentMethodErr={paymentMethodErr}
          GstID={GstID}
          setGstID={setGstID}
          GstIDErr={GstIDErr}
          address1={address1}
          setAddress1={setAddress1}
          address1Err={address1Err}
          address2={address2}
          setAddress2={setAddress2}
          address2Err={address2Err}
          country={country}
          setCountry={setCountry}
          state={state}
          setState={setState}
          countryErr={countryErr}
          stateErr={stateErr}
          city={city}
          setCity={setCity}
          cityErr={cityErr}
          pincode={pincode}
          setPincode={setPincode}
          pincodeErr={pincodeErr}
          currency={currency}
          setCurrency={setCurrency}
          setCurrencyName={setCurrencyName}
          currencyName={currencyName}
          currencyErr={currencyErr}
          descriptionErr={descriptionErr}
          description={description}
          setDescription={setDescription}
          password={password}
          setpassword={setpassword}
          passwordErr={passwordErr}
          confirmPasswordErr={confirmPasswordErr}
          confirmPassword={confirmPassword}
          setConfirmPass={setConfirmPass}
          logo={logo}
          logoErr={logoErr}
          banner={banner}
          bannerErr={bannerErr}
          setBanner={setBanner}
          setLogo={setLogo}
          firstname={firstname}
          setfirstname={setfirstname}
          email={email}
          setEmail={setEmail}
          mobile={mobile}
          setmobile={setmobile}
          lineBusinessErr={lineBusinessErr}
          lineBusiness={lineBusiness}
          setLineBusiness={setLineBusiness}
          keydownHandler={keydownHandler}
          setListStates={setListStates}
          listStates={listStates}
          listCities={listCities}
          setListCities={setListCities}
        />
      )}

      <div className="w-100 g ac-jb flex-md-row flex-column d-flex">
        <button
          onClick={() => {
            if (secondDet === true) {
              setSecondDet(false);
            } else if (finalView === true) {
              setFinalView(false);
              setSecondDet(true);
            } else {
              navigate("/vendor-home");
            }
          }}
          className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
        >
          Back
        </button>
        {!finalView && (
          <button
            onClick={() => {
              if (secondDet === false) {
                setSecondDet(true);
              } else if (secondDet === true) {
                onNext();
                // setSecondDet(false);
                // setFinalView(true);
              }
            }}
            className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
          >
            Next
          </button>
        )}
        {finalView && (
          <button
            onClick={() => {
              submitdata();
            }}
            className={`cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded ${
              btn && "opacity-50"
            }`}
            disabled={btn}
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
};

export default AddDetails;
