import DeleteIcon from "@mui/icons-material/Delete";
import EventNoteIcon from "@mui/icons-material/EventNote";
import CallIcon from "@mui/icons-material/Call";
import GroupsIcon from "@mui/icons-material/Groups";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  animatedImg,
  empty_profile,
  profilePic,
  toggleOff,
  toggleOn,
  vendorBannerImg,
} from "../../assets/img";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LinkIcon from "@mui/icons-material/Link";
import {
  line_business,
  payment_Method,
  replyData,
  sub_menu,
  vendor_category,
} from "../../redux/api/DummyJson";
import SendIcon from "@mui/icons-material/Send";
import { useEffect, useState } from "react";
import AddContacts from "./AddContacts";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import AddNotes from "./AddNotes";
import { useNavigate } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ImageCroper from "../Popup/ImageCroper";
import ErrorIcon from "@mui/icons-material/Error";
import {
  useLazyCurrencyListQuery,
  useVendorLogoDeleteMutation,
} from "../../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../redux/slice/loaderNoti";

const ProfileDetails = ({
  getViewProfile,
  vendorData,
  setEditProfile,
  setProfile,
  setAddressPopup,
  setContactPopup,
  path,
  name,
  setPositionPopup,
  setTab,
  legalEntityName,
  setLegalEntityName,
  tradeName,
  setTradeName,
  vendorID,
  setVendorID,
  vendorOfferingType,
  setVendorOfferingType,
  typeofBusiness,
  setTypeofBusiness,
  paymentTerms,
  setPaymentTerms,
  paymentMethod,
  setPaymentMethod,
  GSTNumber,
  setGSTNumber,
  currency,
  setCurrency,
  logoImage,
  setLogoImage,
  bannerImage,
  setBannerImage,
  legalEntityNameErr,
  tradeNameErr,
  vendorIDErr,
  vendorOfferingTypeErr,
  typeofBusinessErr,
  paymentTermsErr,
  paymentMethodErr,
  GSTNumberErr,
  currencyErr,
  logoImageErr,
  bannerImageErr,
  setUpdate,
  update,
  submitHandle,
  btn,
  vendorDepartmentList,
  getVendorView,
  vendorAddressList,
  getVIewAddress,
  addressStatus,
  change,
  getPositionData,
  currenciesLists,
  decorativeListsNew

}) => {
  const vendors = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const [reply, setReply] = useState(false);
  const [note, setNote] = useState(false);
  const [call, setCall] = useState(false);
  const [meeting, setMeeting] = useState(false);
  const [repIndex, setRepIndex] = useState("");
  const [openNotes, setOpenNotes] = useState(false);
  const [noteIndex, setNoteIndex] = useState("");
  const [vendorCatogory, setVendorCatogory] = useState("");
  const [lineBusiness, setLineBusiness] = useState("");
  const [btnLogo, setBtnLogo] = useState(false);
  const [decorativeShow, setDecorativeShow] = useState(false);

  const [croper, setCroper] = useState(false);
  const [croperImage, setCropImage] = useState();
  const [croperCol, setCroperCol] = useState(false);
  const [logo, setLogo] = useState();
  const [banner, setBanner] = useState();

  // RTK QUERY
  const [vendorLogoDeleteApi] = useVendorLogoDeleteMutation();

  const dualToggele = (type) => {
    if (type == "logo") {
      setCroperCol(false);
      toggleImagePopup();
    } else {
      setCroperCol(true);
      toggleImagePopup();
    }
  };

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  useEffect(() => {
    if (croperCol && croperImage) {
      setBanner(croperImage);
      fetch(croperImage)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "File name", { type: "image/png" });
          setBannerImage(file);
        });

      setCropImage("");
    } else if (!croperCol && croperImage) {
      setLogo(croperImage);
      fetch(croperImage)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "File name", { type: "image/png" });
          setLogoImage(file);
        });
      setCropImage("");
    }
  }, [croperImage]);

  const navigate = useNavigate();
  const [showVendor, setShowVendor] = useState(null);

  const getVendorShow = (item) => {
    if (showVendor == item) {
      setShowVendor(null);
    } else {
      setShowVendor(item);
    }
  };

  const handleReply = (ind) => {
    if (repIndex === ind) {
      setReply(!reply);
    }
  };

  const handleNotes = (ind) => {
    if (noteIndex === ind) {
      setOpenNotes(!openNotes);
    }
  };

  const onClickVendorCat = (e) => {
    if (vendorCatogory.includes(e)) {
      const listtemp = vendorCatogory.indexOf(e);
      const list = [...vendorCatogory];
      list.splice(listtemp, 1);
      setVendorCatogory(list);
    } else {
      setVendorCatogory([...vendorCatogory, e]);
    }
  };
  const onClickLineBusiness = (e) => {
    if (lineBusiness.includes(e)) {
      const listtemp = lineBusiness.indexOf(e);
      const list = [...lineBusiness];
      list.splice(listtemp, 1);
      setLineBusiness(list);
    } else {
      setLineBusiness([...lineBusiness, e]);
    }
  };
  const onClickPaymentMethod = (e) => {
    if (paymentMethod.includes(e)) {
      const listtemp = paymentMethod.indexOf(e);
      const list = [...paymentMethod];
      list.splice(listtemp, 1);
      setPaymentMethod(list);
    } else {
      setPaymentMethod([...paymentMethod, e]);
    }
  };
  // console.log(path);

  const [decorativeList, setDecorativeList] = useState([]);
  const [decorativeID, setDecorativeID] = useState([]);
  const [decorativeName, setDecorativeName] = useState([]);
  const [decorativeLists, setDecorativeLists] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryID, setCategoryID] = useState([]);
  const [categoryName, setCategoryName] = useState([]);
  const [categoryLists, setCategoryLists] = useState([]);
  const [decorationMethod, setDecorationMethod] = useState("");
  const [searchValueSub, setSearchValueSub] = useState("");
  const [searchValueCat, setSearchValueCat] = useState("");
  const [dropDown, setDropDown] = useState(null);
  const [selectALLSub, setSelectALLSub] = useState(true);
  const [selectALLCat, setSelectALLCat] = useState(true);

  // Decoration Method START
  const allSelectSub = () => {
    let decorativeListId = [];
    let decorativeListName = [];
    if (selectALLSub) {
      decorativeList?.map((item, ind) => {
        decorativeListId.push(item?.id);
        decorativeListName.push(item?.name);
        setDecorationMethod(item?.id);
      });

      setDecorativeID(decorativeListId);
      setDecorativeName(decorativeListName);
    } else {
      setDecorativeID(decorativeListId);
      setDecorativeName(decorativeListName);
    }
  };

  const searchSub = (event) => {
    let temp = [...decorativeList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setDecorativeLists(search);
    } else {
      setDecorativeLists(decorativeList);
    }

    setSearchValueSub(event);
  };

  const onSubSelect = (event, id, type) => {
    let temp = [...decorativeName];
    let temps = [...decorativeID];

    if (temp.includes(event)) {
      const listtemp = temp.indexOf(event);
      const list = [...temp];
      list.splice(listtemp, 1);
      setDecorativeName(list);
    } else {
      setDecorativeName([...temp, event]);
    }

    if (temps.includes(id)) {
      const listtemp = temps.indexOf(id);
      const list = [...temps];
      list.splice(listtemp, 1);
      setDecorativeID(list);
    } else {
      setDecorativeID([...temps, id]);
    }

    if (type !== "check") {
      setDropDown(null);
    }

    setSearchValueSub("");
    setDecorativeLists(decorativeList);
    setDecorationMethod(id);
  };
  // Decoration Method END

  // Category START
  const allSelectCat = () => {
    let decorativeListId = [];
    let decorativeListName = [];
    if (selectALLCat) {
      categoryList?.map((item, ind) => {
        decorativeListId.push(item?.id);
        decorativeListName.push(item?.name);
        // setDecorationMethod(item?.id);
      });

      setCategoryID(decorativeListId);
      setCategoryName(decorativeListName);
    } else {
      setCategoryID(decorativeListId);
      setCategoryName(decorativeListName);
    }
  };

  const searchCat = (event) => {
    let temp = [...categoryList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setCategoryLists(search);
    } else {
      setCategoryLists(categoryList);
    }

    setSearchValueCat(event);
  };

  const onCatSelect = (event, id, type) => {
    let temp = [...categoryName];
    let temps = [...categoryID];

    if (temp.includes(event)) {
      const listtemp = temp.indexOf(event);
      const list = [...temp];
      list.splice(listtemp, 1);
      setCategoryName(list);
    } else {
      setCategoryName([...temp, event]);
    }

    if (temps.includes(id)) {
      const listtemp = temps.indexOf(id);
      const list = [...temps];
      list.splice(listtemp, 1);
      setCategoryID(list);
    } else {
      setCategoryID([...temps, id]);
    }

    if (type !== "check") {
      setDropDown(null);
    }

    setSearchValueCat("");
    setCategoryLists(categoryList);
    // setDecorationMethod(id);
  };
  // Category END

  useEffect(() => {
    if (vendorData?.profile?.offering_type == 2) {
      // type 2 for decor
      setDecorativeList(vendorData?.profile?.vendorDecorativePrice);
      setDecorativeLists(vendorData?.profile?.vendorDecorativePrice);
      let temps = [];
      vendorData?.profile?.vendorDecorativePrice?.map((item, ind) => {
        temps.push(item?.name);
      });
      setDecorativeName(temps);
    } else if (vendorData?.profile?.offering_type == 3) {
      // type 3 for both
      setCategoryList(vendorData?.profile?.vendorCategory);
      setCategoryLists(vendorData?.profile?.vendorCategory);
      setDecorativeList(vendorData?.profile?.vendorDecorativePrice);
      setDecorativeLists(vendorData?.profile?.vendorDecorativePrice);
      let temp = [];
      let temps = [];

      vendorData?.profile?.vendorCategory?.map((item, ind) => {
        temp.push(item?.name);
      });
      setCategoryName(temp);
      vendorData?.profile?.vendorDecorativePrice?.map((item, ind) => {
        temps.push(item?.name);
      });
      setDecorativeName(temps);
    } else if (vendorData?.profile?.offering_type == 1) {
      // type 1 for category
      setCategoryList(vendorData?.profile?.vendorCategory);
      setCategoryLists(vendorData?.profile?.vendorCategory);
      let temp = [];

      vendorData?.profile?.vendorCategory?.map((item, ind) => {
        temp.push(item?.name);
      });

      setCategoryName(temp);
    }
  }, [vendorData]);

  const deleteLogo = (type) => {
    let formdata = new FormData();
    formdata.append("vendor_id", vendors?.vendor?.id);
    formdata.append("type", type);
    setBtnLogo(true);
    dispatch(saveLoader(true));
    vendorLogoDeleteApi(formdata)
      .unwrap()
      .then((res) => {
        setBtnLogo(false);
        dispatch(saveLoader(false));
        getViewProfile(vendors?.vendor?.id);
      })
      .catch((err) => {
        setBtnLogo(false);
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  return (
    <div className="d-flex flex-md-row px-lg-4 flex-column flex-wrap w-100 mt-4">
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      <div className="w-xl-50 w-lg-50 w-md-100 w-sm-100 w-xs-100">
        <div className="w-100 mt-1 ac-jb d-flex">
          <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            Vendor Details
          </p>

          {update ? (
            <button
              onClick={() => {
                // setEditProfile(true);
                // setProfile(false);
                submitHandle();
              }}
              disabled={btn}
              className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded ${
                btn && "opacity-50"
              }`}
            >
              Submit
            </button>
          ) : (
            <button
              onClick={() => {
                // setEditProfile(true);
                // setProfile(false);
                setUpdate(!update);
              }}
              className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
            >
              Edit
            </button>
          )}
        </div>
        <div className="d-flex flex-wrap as-jb flex-m-r">
          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Legal Entity Name*
            </p>
            <input
              placeholder=""
              type="text"
              disabled
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              onChange={(e) => setLegalEntityName(e.target.value)}
              value={legalEntityName}
            />
            {legalEntityName?.length == 0 && legalEntityNameErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Legal Entity Name </p>
              </div>
            )}
          </div>
          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Trade Name
            </p>
            <input
              type="text"
              disabled
              placeholder=""
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              onChange={(e) => setTradeName(e.target.value)}
              value={tradeName}
            />
            {tradeName?.length == 0 && tradeNameErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Trade Name </p>
              </div>
            )}
          </div>
          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Vendor ID
            </p>
            <input
              placeholder=""
              type="text"
              disabled
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              onChange={(e) => setVendorID(e.target.value)}
              value={vendorID}
            />
            {vendorID?.length == 0 && vendorIDErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Vendor ID</p>
              </div>
            )}
          </div>
          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Vendor Offering Type*
            </p>
            <input
              placeholder=""
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              type="text"
              disabled
              onChange={(e) => setVendorOfferingType(e.target.value)}
              value={
                vendorOfferingType == 1
                  ? "Product"
                  : vendorOfferingType == 2
                  ? "Services"
                  : vendorOfferingType == 3
                  ? "Both"
                  : 0
              }
            />
            {vendorOfferingType?.length == 0 && vendorOfferingTypeErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Vendor Offering Type</p>
              </div>
            )}
          </div>

          {vendorData?.profile?.offering_type == 1 && (
            <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Category
              </p>
              <fieldset>
                <div className="position-relative">
                  <textarea
                    placeholder="Category"
                    type="text"
                    value={
                      categoryName?.length > 0
                        ? categoryName?.toString()
                        : "Select Category"
                    }
                    // onClick={() => {
                    //   setDropDown(1);
                    // }}
                    className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    readOnly
                  />
                  {/* <button
                    className="drop_down cust-btn"
                    onClick={() => {
                      setDropDown(1);
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </button> */}
                  {dropDown == 1 && (
                    <div
                      className="invisible-cont2 z-0"
                      onClick={() => {
                        setDropDown(null);
                      }}
                    />
                  )}
                  <div
                    className={`${
                      dropDown == 1 && "submenu_1 py-2"
                    } submenu_cont_1 overflow-scroll z-3`}
                  >
                    <div className="d-flex gap-1">
                      <input
                        className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                        placeholder="Search..."
                        onChange={(e) => searchCat(e.target.value)}
                        value={searchValueCat}
                      />
                      <button
                        className="border-0 bg-transparent"
                        onClick={() => {
                          setSelectALLCat(!selectALLCat);
                          allSelectCat();
                        }}
                      >
                        All
                      </button>
                    </div>
                    {categoryLists?.map((item, ind) => {
                      return (
                        <button
                          className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                          key={ind}
                        >
                          <button
                            className="px-2 cust-btn text-start py-1 w-100 "
                            onClick={() => {
                              onCatSelect(item?.name, item?.id);
                            }}
                          >
                            {item?.name}
                          </button>
                          <button
                            className="px-2 cust-btn text-start"
                            style={{ fontSize: "large" }}
                            onClick={() => {
                              onCatSelect(item?.name, item?.id, "check");
                            }}
                          >
                            {categoryName?.includes(item?.name) ? (
                              <CheckCircleIcon className="primary" />
                            ) : (
                              <RadioButtonUncheckedIcon className="primary" />
                            )}
                          </button>
                        </button>
                      );
                    })}
                  </div>
                </div>
              </fieldset>
            </div>
          )}

          {vendorData?.profile?.offering_type == 2 &&(
            // <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            //   <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            //     Decoration Method
            //   </p>
            //   <fieldset>
            //     <div className="position-relative">
            //       <textarea
            //         placeholder="Decorative method"
            //         type="text"
            //         value={
            //           decorativeName?.length > 0
            //             ? decorativeName?.toString()
            //             : "Select Decorative method"
            //         }
            //         // onClick={() => {
            //         //   setDropDown(2);
            //         // }}
            //         className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
            //         readOnly
            //       />
            //       {/* <button
            //         className="drop_down cust-btn"
            //         onClick={() => {
            //           setDropDown(2);
            //         }}
            //       >
            //         <KeyboardArrowDownIcon />
            //       </button> */}
            //       {dropDown == 2 && (
            //         <div
            //           className="invisible-cont2 z-0"
            //           onClick={() => {
            //             setDropDown(null);
            //           }}
            //         />
            //       )}
            //       <div
            //         className={`${
            //           dropDown == 2 && "submenu_1 py-2"
            //         } submenu_cont_1 overflow-scroll z-3`}
            //       >
            //         <div className="d-flex gap-1">
            //           <input
            //             className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
            //             placeholder="Search..."
            //             onChange={(e) => searchSub(e.target.value)}
            //             value={searchValueSub}
            //           />
            //           <button
            //             className="border-0 bg-transparent"
            //             onClick={() => {
            //               setSelectALLSub(!selectALLSub);
            //               allSelectSub();
            //             }}
            //           >
            //             All
            //           </button>
            //         </div>
            //         {decorativeLists?.map((item, ind) => {
            //           return (
            //             <button
            //               className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
            //               key={ind}
            //             >
            //               <button
            //                 className="px-2 cust-btn text-start py-1 w-100 "
            //                 onClick={() => {
            //                   onSubSelect(item?.name, item?.id);
            //                 }}
            //               >
            //                 {item?.name}
            //               </button>
            //               <button
            //                 className="px-2 cust-btn text-start"
            //                 style={{ fontSize: "large" }}
            //                 onClick={() => {
            //                   onSubSelect(item?.name, item?.id, "check");
            //                 }}
            //               >
            //                 {decorativeName?.includes(item?.name) ? (
            //                   <CheckCircleIcon className="primary" />
            //                 ) : (
            //                   <RadioButtonUncheckedIcon className="primary" />
            //                 )}
            //               </button>
            //             </button>
            //           );
            //         })}
            //       </div>
            //     </div>
            //   </fieldset>
            // </div>
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
                Decorative Method
              </p>
              <div className="position-relative">
                <input
                  placeholder="Required Field"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  // value={decorativeName}
                  // onChange={onDecorativeClick}
                  // disabled={profileEditBtn ? false : true}
                />
                {/* {profileEditBtn && ( */}
                  <button
                    className="drop_down cust-btn"
                    onClick={() => {
                      setDecorativeShow(!decorativeShow);
                      // setCatogoryShow(false);
                      // setCatogoryShow2(false);
                      // setCatogoryShow3(false);
                      // setProductNameShow(false);
                      // setPackingSizeShow(false);
                      // setColorsShow(false)
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </button>
                {/* )}z */}
              </div>
              {decorativeShow && (
                <div
                  className="invisible-cont2"
                  onClick={() => setDecorativeShow(!decorativeShow)}
                />
              )}
              <div
                className={`${
                  decorativeShow && "submenu_1"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                {decorativeListsNew?.map((item, ind) => {
                  return (
                    <div className="d-flex ac-jb hover-cust">
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                        onClick={() => {
                          // onDecorativeClick(item?.list);
                          // checkBox(ind);
                        }}
                      >
                        {item?.name}
                      </button>
                      <button
                        className="px-2 cust-btn text-start"
                        onClick={() => {
                          // onDecorativeClick(item?.list);
                        }}
                      >
                        {/* {decorativeName.includes(item?.list)
                          ? item?.check
                          : item.uncheck} */}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {vendorData?.profile?.offering_type == 3 && (
            <>
              <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Category
                </p>
                <fieldset>
                  <div className="position-relative">
                    <textarea
                      placeholder="Category"
                      type="text"
                      value={
                        categoryName?.length > 0
                          ? categoryName?.toString()
                          : "Select Category"
                      }
                      // onClick={() => {
                      //   setDropDown(1);
                      // }}
                      readOnly
                      className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    />
                    {/* <button
                      className="drop_down cust-btn"
                      onClick={() => {
                        setDropDown(1);
                      }}
                      disabled
                    >
                      <KeyboardArrowDownIcon />
                    </button> */}
                    {dropDown == 1 && (
                      <div
                        className="invisible-cont2 z-0"
                        onClick={() => {
                          setDropDown(null);
                        }}
                      />
                    )}
                    <div
                      className={`${
                        dropDown == 1 && "submenu_1 py-2"
                      } submenu_cont_1 overflow-scroll z-3`}
                    >
                      <div className="d-flex gap-1">
                        <input
                          className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                          placeholder="Search..."
                          onChange={(e) => searchCat(e.target.value)}
                          value={searchValueCat}
                        />
                        <button
                          className="border-0 bg-transparent"
                          onClick={() => {
                            setSelectALLCat(!selectALLCat);
                            allSelectCat();
                          }}
                        >
                          All
                        </button>
                      </div>
                      {categoryLists?.map((item, ind) => {
                        return (
                          <button
                            className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                            key={ind}
                          >
                            <button
                              className="px-2 cust-btn text-start py-1 w-100 "
                              onClick={() => {
                                onCatSelect(item?.name, item?.id);
                              }}
                            >
                              {item?.name}
                            </button>
                            <button
                              className="px-2 cust-btn text-start"
                              style={{ fontSize: "large" }}
                              onClick={() => {
                                onCatSelect(item?.name, item?.id, "check");
                              }}
                            >
                              {categoryName?.includes(item?.name) ? (
                                <CheckCircleIcon className="primary" />
                              ) : (
                                <RadioButtonUncheckedIcon className="primary" />
                              )}
                            </button>
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </fieldset>
              </div>
              {/* <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Decoration Method
                </p>
                <fieldset>
                  <div className="position-relative">
                    <textarea
                      placeholder="Decorative method"
                      type="text"
                      value={
                        decorativeName?.length > 0
                          ? decorativeName?.toString()
                          : "Select Decorative method"
                      } */}
                      {/* // onClick={() => { */}
                      {/* //   setDropDown(4);
                      // }}
                    //   className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    //   readOnly
                    // /> */}
                    {/* <button
                      className="drop_down cust-btn"
                      onClick={() => {
                        setDropDown(4);
                      }}
                      disabled
                    >
                      <KeyboardArrowDownIcon />
                    </button> */}
                    {/* {dropDown == 4 && (
                      <div
                        className="invisible-cont2 z-0"
                        onClick={() => {
                          setDropDown(null);
                        }}
                      />
                    )}
                    <div
                      className={`${
                        dropDown == 4 && "submenu_1 py-2"
                      } submenu_cont_1 overflow-scroll z-3`}
                    >
                      <div className="d-flex gap-1">
                        <input
                          className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                          placeholder="Search..."
                          onChange={(e) => searchSub(e.target.value)}
                          value={searchValueSub}
                        />
                        <button
                          className="border-0 bg-transparent"
                          onClick={() => {
                            setSelectALLSub(!selectALLSub);
                            allSelectSub();
                          }}
                        >
                          All
                        </button>
                      </div>
                      {decorativeLists?.map((item, ind) => {
                        return (
                          <button
                            className="d-flex cust-btn w-100 as-jb hover-cust ac-jb" 
                            key={ind}
                          >
                            <button
                              className="px-2 cust-btn text-start py-1 w-100 "
                              onClick={() => {
                                onSubSelect(item?.name, item?.id);
                              }}
                            >
                              {item?.name}
                            </button>
                            <button
                              className="px-2 cust-btn text-start"
                              style={{ fontSize: "large" }}
                              onClick={() => {
                                onSubSelect(item?.name, item?.id, "check");
                              }}
                            >
                              {decorativeName?.includes(item?.name) ? (
                                <CheckCircleIcon className="primary" />
                              ) : (
                                <RadioButtonUncheckedIcon className="primary" />
                              )}
                            </button>
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </fieldset>
              </div> */}
               <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
                Decorative Method
              </p>
              <div className="position-relative">
                <input
                  placeholder="Required Field"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  // value={decorativeName}
                  // onChange={onDecorativeClick}
                  // disabled={profileEditBtn ? false : true}
                />
                {/* {profileEditBtn && ( */}
                  <button
                    className="drop_down cust-btn"
                    onClick={() => {
                      setDecorativeShow(!decorativeShow);
                      // setCatogoryShow(false);
                      // setCatogoryShow2(false);
                      // setCatogoryShow3(false);
                      // setProductNameShow(false);
                      // setPackingSizeShow(false);
                      // setColorsShow(false)
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </button>
                {/* )}z */}
              </div>
              {decorativeShow && (
                <div
                  className="invisible-cont2"
                  onClick={() => setDecorativeShow(!decorativeShow)}
                />
              )}
              <div
                className={`${
                  decorativeShow && "submenu_1"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                {decorativeListsNew?.map((item, ind) => {
                  return (
                    <div className="d-flex ac-jb hover-cust">
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                        onClick={() => {
                          // onDecorativeClick(item?.list);
                          // checkBox(ind);
                        }}
                      >
                        {item?.name}
                      </button>
                      <button
                        className="px-2 cust-btn text-start"
                        onClick={() => {
                          // onDecorativeClick(item?.list);
                        }}
                      >
                        {/* {decorativeName.includes(item?.list)
                          ? item?.check
                          : item.uncheck} */}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
            </>
          )}

          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Type of Business*
            </p>
            <input
              placeholder=""
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              name=""
              id=""
              type="text"
              disabled
              onChange={(e) => setTypeofBusiness(e.target.value)}
              value={typeofBusiness}
            />
            {typeofBusiness?.length == 0 && typeofBusinessErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Type of Business</p>
              </div>
            )}
          </div>

          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Payment Terms
            </p>
            <input
              placeholder=""
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              type="text"
              disabled
              onChange={(e) => setPaymentTerms(e.target.value)}
              value={paymentTerms}
            />
            {paymentTerms?.length == 0 && paymentTermsErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Payment Terms</p>
              </div>
            )}
          </div>
          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Payment Method
            </p>
            <div className="position-relative">
              <textarea
                placeholder="Select Category"
                type="text"
                disabled
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                onChange={(e) => setPaymentMethod(e.target.value)}
                value={paymentMethod}
              />
              {paymentMethod?.length == 0 && paymentMethodErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Payment Method</p>
                </div>
              )}
            </div>
          </div>

          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              GST Number
            </p>
            <input
              placeholder="Select Category"
              type="text"
              disabled
              className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              onChange={(e) => setGSTNumber(e.target.value)}
              value={GSTNumber}
            />
            {GSTNumber?.length == 0 && GSTNumberErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter GST Number</p>
              </div>
            )}
          </div>
          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Currency*
            </p>

            <select
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              disabled
              onChange={(e) => setCurrency(e.target.value)}
              value={currency}
            >
              <option disabled>Select Currency</option>
              {currenciesLists?.map((item, ind) => {
                return (
                  <option value={item?.id} key={ind}>
                    {item?.code}
                  </option>
                );
              })}
            </select>
            {currency?.length == 0 && currencyErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Currency</p>
              </div>
            )}
          </div>

          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Profile Description
            </p>
            <div className="position-relative">
              <textarea
                placeholder="Profile Description"
                type="text"
                readOnly
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                // onChange={(e) => setPaymentMethod(e.target.value)}
                value={vendorData?.profile?.description}
              />
              {/* {paymentMethod?.length == 0 && paymentMethodErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Payment Method</p>
                </div>
              )} */}
            </div>
          </div>

          {update ? (
            <>
              {" "}
              <div
                className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100"
                onClick={() => dualToggele("logo")}
              >
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Logo Image(180 * 180 px)
                </p>
                <input
                  style={{ cursor: "pointer" }}
                  value={
                    logoImage?.name
                      ? "Logo Uploaded Successfully"
                      : "Browse or Drag a file"
                  }
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                />
                {logoImage?.length == 0 && logoImageErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Upload Logo Image</p>
                  </div>
                )}
              </div>
              <div
                className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100"
                // onClick={() => dualToggele("ban")}
              >
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Banner Image(8000 * 2550 px)
                </p>
                {/* <input
                  style={{ cursor: "pointer" }}
                  value={
                    bannerImage?.name
                      ? "Banner Uploaded Successfully"
                      : "Browse or Drag a file"
                  }
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                /> */}
                <input
                  style={{ cursor: "pointer" }}
                  type={"file"}
                  // value={
                  //   banner
                  //     ? "Banner Uploaded Successfully"
                  //     : "Browse or Drag a file"
                  // }
                  onChange={(e) => setBannerImage(e.target.files[0])}
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                />
                {bannerImage?.length == 0 && bannerImageErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Upload Banner Image</p>
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="d-flex gap-5">
              <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                <div className="d-flex justify-content-between">
                  <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                    Logo Image
                  </p>
                  {logoImage && (
                    <button
                      className="border-0 bg-transparent black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-danger"
                      onClick={() => deleteLogo(1)}
                      disabled={btnLogo}
                    >
                      <DeleteForeverIcon />
                    </button>
                  )}
                </div>
                <img
                  className="mt-2"
                  style={{ width: "150px" }}
                  src={
                    logoImage?.name
                      ? URL.createObjectURL(logoImage)
                      : logoImage
                      ? logoImage
                      : profilePic
                  }
                />
              </div>
              <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                <div className="d-flex justify-content-between">
                  <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                    Banner Image
                  </p>
                  {bannerImage && (
                    <button
                      className="border-0 bg-transparent black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-danger"
                      onClick={() => deleteLogo(2)}
                      disabled={btnLogo}
                    >
                      <DeleteForeverIcon />
                    </button>
                  )}
                </div>
                <img
                  className="mt-2"
                  style={{ width: "150px" }}
                  src={
                    bannerImage?.name
                      ? URL.createObjectURL(bannerImage)
                      : bannerImage
                      ? bannerImage
                      : vendorBannerImg
                  }
                />
              </div>
            </div>
          )}
        </div>
        <div className="w-100 mt-5">
          <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            Departments
          </p>
          {vendorDepartmentList?.map((item, ind) => {
            return (
              <div>
                <div className="pointerView w-100 mt-3 ac-jb d-flex" key={ind}>
                  <p className="f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
                    <PlayArrowIcon
                      onClick={() => getVendorShow(ind)}
                      className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${
                        showVendor == ind ? "arrowIcon" : ""
                      } `}
                    />
                    {item?.name}{" "}
                    <span className="primary">
                      ({item?.vendor_user?.length})
                    </span>
                  </p>
                  <div
                    onClick={() => getVendorView(item, "", ind, "", "add")}
                    className="pointerView "
                  >
                    <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
                      Add Contact
                    </p>
                  </div>
                </div>
                {showVendor == ind &&
                  item?.vendor_user?.map((user, inx) => {
                    return (
                      <AddContacts
                        user={user}
                        item={item}
                        ind={ind}
                        inx={inx}
                        getVendorView={getVendorView}
                        setContactPopup={setContactPopup}
                        setPositionPopup={setPositionPopup}
                        change={change}
                        getPositionData={getPositionData}
                      />
                    );
                  })}
              </div>
            );
          })}
        </div>
        <div className="w-100 mt-5">
          <div className="w-100 ac-jb d-flex">
            <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
              Addresses
            </p>
            <button
              onClick={() => setAddressPopup(true)}
              className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
            >
              Add
            </button>
          </div>
          {vendorAddressList?.map((item, ind) => {
            return (
              <div className="w-95 mt-4" key={ind}>
                <div className="w-100 ac-jb d-flex">
                  <p className="black f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mt-1 ">
                    {item?.address}
                    <span className="primary1 f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mt-1 ">
                      {/* (Shipping) */}
                    </span>
                  </p>

                  <div className=" d-flex ms-5 ac-jc gap-2">
                    <div onClick={() => addressStatus(item?.id)}>
                      {item?.status == 1 ? (
                        <img src={toggleOn} className="toggleOnDes1" />
                      ) : (
                        <img src={toggleOff} className="toggleOnDes1" />
                      )}
                    </div>

                    <EditIcon
                      onClick={() => getVIewAddress(item)}
                      className="pointerView primary ms-1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25"
                    />
                  </div>
                </div>
                <p className="primary1 mt-3 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
                  {item?.name}
                </p>
                <p className="primary1 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
                  {item?.landmark}
                </p>
                <p className="primary1 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
                  {item?.street}
                </p>
                <p className="primary1 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
                  {item?.country}
                </p>
                <p className="primary1 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
                  {item?.state}
                </p>
                <p className="primary1 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
                  {item?.city}
                </p>
                <p className="primary1 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
                  {item?.pincode}
                </p>
              </div>
            );
          })}
        </div>
      </div>

      <div className="w-xl-50 w-lg-50 w-md-100 w-sm-100 w-xs-100">
        <div className="w-100 replyPaddings mt-1">
          {!note && !call && !meeting && (
            <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
              Hey Charles,
            </p>
          )}
          {!note && !call && !meeting && (
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Find below your recent feed:
            </p>
          )}
          {!note && !call && !meeting && (
            <div className="w-100 ac-jc d-flex">
              <div className="d-flex w-lg-90 w-md-60 w-sm-70 flex-column flex-xl-row flex-lg-row flex-md-row flex-sm-row ac-jb">
                <div
                  onClick={() => setNote(true)}
                  className="d-flex pointerView px-4 py-2 rounded-3 bg-soft-primary mt-3"
                >
                  <EventNoteIcon className="primary f2 fs-xs-17 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21 fs-xxl-22" />
                  <p className="black f2 ms-1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Note
                  </p>
                </div>
                <div
                  onClick={() => setCall(true)}
                  className="d-flex pointerView px-4 py-2 rounded-3 bg-soft-primary mt-3"
                >
                  <CallIcon className="primary f2 fs-xs-17 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21 fs-xxl-22" />
                  <p className="black f2 ms-1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Call
                  </p>
                </div>
                <div
                  onClick={() => setMeeting(true)}
                  className="d-flex pointerView px-3 py-2 rounded-3 bg-soft-primary mt-3"
                >
                  <GroupsIcon className="primary f2 fs-xs-17 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21 fs-xxl-22" />
                  <p className="black f2 ms-1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Meeting
                  </p>
                </div>
              </div>
            </div>
          )}
          {note && <AddNotes type="note" setNote={setNote} />}
          {call && <AddNotes type="call" setNote={setCall} />}
          {meeting && <AddNotes type="meet" setNote={setMeeting} />}
          <div className="w-100 d-flex ac-je mt-5">
            <div className="d-flex flex-row">
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
                Show:
              </p>
              <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
                <option value="10">All</option>
              </select>
              <p className="f2 ms-3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
                Time Period:
              </p>
              <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
                <option value="10">All</option>
              </select>
            </div>
          </div>
          {replyData?.map((item, index) => {
            return (
              <div className=" w-xl-100 w-lg-100 w-md-90 w-sm-100 w-xs-100 d-grid ac-jc mt-5">
                <div className="w-100 d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row ac-jc gap-3">
                  <div>
                    <img src={empty_profile} className="replyImg rounded-3" />
                  </div>
                  <div>
                    <p className="primary f3 fs-xs-10 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18">
                      {item?.name}{" "}
                      <span className="primary1 f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                        Hourglass Essentials Pvt. Ltd.
                      </span>
                    </p>
                    <p className="primary1 f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                      Roger added the vendor account{" "}
                      <span className="text-black f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                        {item?.referName}
                      </span>
                    </p>
                    <p className="primary1 f3 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Tagged in this post.
                    </p>
                  </div>
                </div>
                <div className="mt-3 w-xl-100 w-lg-100 w-xs-100 w-sm-100 d-flex ac-jb">
                  <div
                    onClick={() => {
                      handleReply(index);
                      setRepIndex(index);
                      setNoteIndex("");
                    }}
                    className="d-flex pointerView"
                  >
                    <ChatBubbleOutlineIcon className="black f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
                    <p className="black f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Reply
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      setNoteIndex(index);
                      handleNotes(index);
                      setRepIndex("");
                    }}
                    className="d-flex pointerView"
                  >
                    <NoteAddOutlinedIcon className="black f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
                    <p className="black f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Add Note
                    </p>
                  </div>
                  <div
                    onClick={() => navigate("/view-thread")}
                    className="d-flex pointerView"
                  >
                    <LinkIcon className="black linkImg f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
                    <p className="black f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Full thread
                    </p>
                  </div>
                  <div className="d-flex pointerView">
                    <DownloadIcon className="black f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
                    <p className="black f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Download
                    </p>
                  </div>
                  {/* <div className="d-flex pointerView">
                    <DeleteIcon className="black f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
                    <p className="black f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Delete
                    </p>
                  </div> */}
                </div>
                <div className="mt-2">
                  <p className="primary f2 ms-1 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    {item?.date}
                  </p>
                </div>
                {repIndex === index && reply && (
                  <div className="w-100 mt-2">
                    <div className="d-flex ac-je">
                      <textarea
                        className="editBtn rounded-3 p-2 w-90"
                        placeholder="Type here..."
                      />
                      <div className="mt-4 ms-1">
                        <SendIcon className="pointerView primary f2 fs-xs-17 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21 fs-xxl-22" />
                      </div>
                    </div>
                  </div>
                )}
                {noteIndex === index && openNotes && (
                  <AddNotes type="profile" setOpenNotes={setOpenNotes} />
                )}
              </div>
            );
          })}

          <div
            onClick={() => {
              setProfile(false);
              setEditProfile(false);
              setTab(5);
            }}
            className="w-100 ac-jc d-flex mt-4"
          >
            <p className="pointerView f3 mt-3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
              See more
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;
