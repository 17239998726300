import { Search } from "@mui/icons-material";
import { animatedImg, profilePic, vendorBannerImg } from "../assets/img";
import BusinessIcon from "@mui/icons-material/Business";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ProfileDetails from "../components/VendorDetails/ProfileDetails";
import EditProfileDetails from "../components/VendorDetails/EditProfileDetails";
import HistoryDetails from "../components/VendorDetails/HistoryDetails";
import EditContactPopup from "../components/Popup/EditContactPopup";
import EditAddressPopup from "../components/Popup/EditAddressPopup";
import ContactDetails from "../components/VendorDetails/ContactDetails";
import PositionPopup from "../components/Popup/PositionPopup";
import LibraryDetails from "../components/VendorDetails/LibraryDetails";
import ProductDetails from "../components/VendorDetails/ProductDetails";
import { useDispatch, useSelector } from "react-redux";
import POSandBills from "../components/VendorDetails/POSandBills";
import CollaborateList from "../components/VendorDetails/CollaborateList";
import {
  useAddProfileMutation,
  useAddressAddMutation,
  useAddressEditMutation,
  useEditProfileMutation,
  useLazyAddressListQuery,
  useLazyAddressStatusQuery,
  useLazyCurrencyListQuery,
  useLazyProfile_statusQuery,
  useLazyVendorViewNewQuery,
  useLazyVendorViewQuery,
  useRoleListMutation,
  useTagsListMutation,
  useVendorDepartmentListMutation,
  useVendorEditMutation,
  useVendorLibraryCreateMutation,
  useVendorLibraryListMutation,
  useVendorPositionChangeMutation,
} from "../redux/api/api";
import { saveLoader } from "../redux/slice/loaderNoti";
import { cityList, countriesList, stateList } from "../redux/api/Country";
import DecorativeDetails from "../components/DecorativeVendor/DecorativeDetails";
import LogoutPopup from "../components/Popup/LogoutPopup";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";

const VendorDetailsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const vendors = JSON.parse(localStorage.getItem("user"));
  const [name, setname] = useState("");
  const [decorative, setDecorative] = useState(false);
  const [logAction, setLogAction] = useState(false);
  const formtypes = location?.state?.type;
  // const path = location?.pathname;
  // const name = location?.state?.catogory;
  // console.log(location.state.type);
  // console.log(location.state);
  const [tab, setTab] = useState(0);
  const [profile, setProfile] = useState(false);
  const [productName, setProductName] = useState("productTab");

  const [editProfile, setEditProfile] = useState(false);
  const [contactPopup, setContactPopup] = useState(false);
  const [addressPopup, setAddressPopup] = useState(false);
  const [positionPopup, setPositionPopup] = useState(false);
  const [logoutPopupShow, setLogoutPopupShow] = useState(false);
  const [decorativeLists, setDecorativeLists] = useState([]);

  const toggleLogoutPopup = () => {
    setLogoutPopupShow(!logoutPopupShow);
    setLogAction(false);
  };

  const [action, setAction] = useState(false);

  const place = location?.state?.type;

  // FOR PROFILE START
  const [legalEntityName, setLegalEntityName] = useState("");
  const [tradeName, setTradeName] = useState("");
  const [vendorID, setVendorID] = useState("");
  const [vendorOfferingType, setVendorOfferingType] = useState("");
  const [typeofBusiness, setTypeofBusiness] = useState("");
  const [paymentTerms, setPaymentTerms] = useState("");
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [GSTNumber, setGSTNumber] = useState("");
  const [currency, setCurrency] = useState("");
  const [logoImage, setLogoImage] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [legalEntityNameErr, setLegalEntityNameErr] = useState(false);
  const [tradeNameErr, setTradeNameErr] = useState(false);
  const [vendorIDErr, setVendorIDErr] = useState(false);
  const [vendorOfferingTypeErr, setVendorOfferingTypeErr] = useState(false);
  const [typeofBusinessErr, setTypeofBusinessErr] = useState(false);
  const [paymentTermsErr, setPaymentTermsErr] = useState(false);
  const [paymentMethodErr, setPaymentMethodErr] = useState(false);
  const [GSTNumberErr, setGSTNumberErr] = useState(false);
  const [currencyErr, setCurrencyErr] = useState(false);
  const [logoImageErr, setLogoImageErr] = useState(false);
  const [bannerImageErr, setBannerImageErr] = useState(false);
  const [btn, setBtn] = useState(false);
  const [update, setUpdate] = useState(false);
  const [vendorData, setVendorData] = useState({});
  const [currenciesLists, setCurrenciesLists] = useState([]);
  // FOR PROFILE END

  // FOR CONTACT START
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [position, setPosition] = useState("");
  const [role, setRole] = useState("");
  const [number, setNumber] = useState([{ number_type: "work", number: "" }]);
  const [numbers, setNumbers] = useState("");
  const [department, setDepartment] = useState("");
  const [twitter, setTwitter] = useState("");
  const [facebook, setFacebook] = useState("");
  const [linkIn, setLinkIn] = useState("");
  const [skype, setSkype] = useState("");
  const [address, setAddress] = useState("");
  const [noMarketing, setNoMarketing] = useState(0);
  const [tags, setTags] = useState("");

  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [positionErr, setPositionErr] = useState("");
  const [roleErr, setRoleErr] = useState("");
  const [numberErr, setNumberErr] = useState("");
  const [departmentErr, setDepartmentErr] = useState("");
  const [twitterErr, setTwitterErr] = useState("");
  const [facebookErr, setFacebookErr] = useState("");
  const [linkInErr, setLinkInErr] = useState("");
  const [skypeErr, setSkypeErr] = useState("");
  const [addressErr, setAddressErr] = useState("");
  const [noMarketingErr, setNoMarketingErr] = useState("");
  const [tagsErr, setTagsErr] = useState("");
  const [addressBtn, setAddressBtn] = useState(false);
  const [primaryNumbers, setPrimaryNumbers] = useState("");
  const [primaryNumbersErr, setPrimaryNumbersErr] = useState(false);

  const [vendorDepartmentList, setVendorDepartmentList] = useState([]);
  const [vendorDepartment, setVendorDepartment] = useState({});
  const [departmentList, setDepartmentList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [vendorAddressList, setVendorAddressList] = useState([]);
  const [vendorAddressLists, setVendorAddressLists] = useState([]);
  // FOR CONTACT END

  // FOR ADDRESS START
  const [addressName, setAddressName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [landmark, setLandMark] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [addressType, setAddressType] = useState("");

  const [addressNameErr, setAddressNameErr] = useState(false);
  const [companyNameErr, setCompanyNameErr] = useState(false);
  const [landmarkErr, setLandMarkErr] = useState(false);
  const [streetErr, setStreetErr] = useState(false);
  const [cityErr, setCityErr] = useState(false);
  const [countryErr, setCountryErr] = useState(false);
  const [stateErr, setStateErr] = useState(false);
  const [pincodeErr, setPincodeErr] = useState(false);
  const [addressTypeErr, setAddressTypeErr] = useState(false);
  const [btnAdd, setBtnAdd] = useState(false);

  const [listStates, setListStates] = useState([]);
  const [listCity, setListCity] = useState([]);
  const [countryId, setCountryId] = useState("");
  const [stateId, setStateId] = useState("");
  const [updateAddress, setUpdateAddress] = useState({});
  // FOR ADDRESS END

  // POSITION CHANGE START
  const [positionNumber, setPoistionNumber] = useState(null);
  const [positionNumberErr, setPoistionNumberErr] = useState(false);
  const [positionData, setPositionData] = useState({});
  const [positionBtn, setPositionBtn] = useState(false);
  // POSITION CHANGE END

  //CategortList
  const [categoryList, setCategoryList] = useState([]);

  // RTK QUERY
  const [vendorViewApi] = useLazyVendorViewQuery();
  const [viewVendorApi] = useLazyVendorViewNewQuery();
  const [vendorEditApi] = useVendorEditMutation();
  const [vendorDepartmentApi] = useVendorDepartmentListMutation();
  const [vendorUserCreateApi] = useAddProfileMutation();
  const [vendorUserUpdateApi] = useEditProfileMutation();
  const [vendorPositionApi] = useVendorPositionChangeMutation();

  const [roleapi] = useRoleListMutation();
  const [tagsApi] = useTagsListMutation();
  const [statusApi] = useLazyProfile_statusQuery();
  const [currencyListApi] = useLazyCurrencyListQuery();

  const [addressApi] = useLazyAddressListQuery();
  const [addressAddApi] = useAddressAddMutation();
  const [addressEditApi] = useAddressEditMutation();
  const [addressStatusApi] = useLazyAddressStatusQuery();

  const showPopupHander = () => {
    setContactPopup(false);
    setAddressPopup(false);
    setPositionPopup(false);

    setTwitter("");
    setFacebook("");
    setAddress("");
    setSkype("");
    setLinkIn("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setRole("");
    setPassword("");
    setPosition("");
    setDepartment("");
    setNoMarketing("");
    setTags("");
    setVendorDepartment({});
    setNumber([{ number_type: "work", number: "" }]);
    setCompanyName("");
    setAddressName("");
    setLandMark("");
    setState("");
    setCity("");
    setCountry("");
    setAddressType("");
    setPincode("");
    setStreet("");
    setUpdateAddress({});
    setStateId("");
    setListStates([]);
    setListCity([]);
    setCountryId("");
    setAddressNameErr(false);
    setCompanyNameErr(false);
    setLandMarkErr(false);
    setStreetErr(false);
    setCityErr(false);
    setCountryErr(false);
    setStateErr(false);
    setPincodeErr(false);
    setAddressTypeErr(false);
    setFirstNameErr(false);
    setLastNameErr(false);
    setEmailErr(false);
    setRoleErr(false);
    setPositionErr(false);
    setAddressErr(false);
    setDepartmentErr(false);
    setPasswordErr(false);
    setNumberErr(false);
    setNumbers("");
    setPrimaryNumbersErr(false);
    setPrimaryNumbers("");
    setPoistionNumber(null);
  };

  const getrole = () => {
    const formdata = new URLSearchParams();
    formdata.append("type", "2");
    roleapi(formdata)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setRoleList(res?.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });

    tagsApi()
      .unwrap()
      .then((res) => {
        setTagsList(res?.normalTag);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    getrole();
  }, []);

  // VENDOR VIEW API
  const getViewProfile = (id) => {
    // vendorViewApi(id)
    viewVendorApi(id)
      .unwrap()
      .then((res) => {
        let data = res?.profile;
        // console.log("data", data);
        setCategoryList(data?.vendorCategory);
        setVendorData(res);
        setLegalEntityName(data?.legal_name);
        setTradeName(data?.name);
        setLogoImage(data?.logo_url);
        setBannerImage(data?.banner_url);
        setVendorOfferingType(data?.offering_type);
        setTypeofBusiness(data?.type_of_business);
        setCurrency(data?.currency?.id);
        setPaymentTerms(data?.payment_terms);
        setGSTNumber(data?.gst);
        setVendorID(data?.id);
        let temp = []
        data?.decorativeMethods?.map(item=>{
          temp.push(item)
        })
        setDecorativeLists(temp)
        if (Array.isArray(data?.payment_method)) {
          setPaymentMethod(data?.payment_method);
        } else {
          setPaymentMethod([]);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // DEPARTMENT API
  const getDepartments = () => {
    vendorDepartmentApi()
      .unwrap()
      .then((res) => {
        let vendorDep = [];
        let temp = [];
        let vendorArr = [];
        res?.vender_departments?.map((item, ind) => {
          item?.vendor_user?.map((vendor, inx) => {
            let jsonParserAddional = vendor?.additional_info
              ? vendor?.additional_info
              : JSON.parse(vendor?.additional_info);
            let jsonParserMobile =
              vendor?.mobile == "-"
                ? vendor?.mobile
                : JSON.parse(vendor?.mobile);
            let obj2 = {
              ...vendor,
            };

            if (jsonParserMobile) {
              obj2.mobile_list = jsonParserMobile;
            }

            if (jsonParserAddional) {
              obj2.addional_list = jsonParserAddional;
            }

            vendorArr.push(obj2);
          });

          // console.log("vendorArr", vendorArr);

          vendorDep.push({
            ...item,
            vendor_user: vendorArr?.filter(
              (i) => i?.vendor_department_id == item?.id
            ),
          });

          temp.push({
            id: item?.id,
            name: item?.name,
          });
        });

        // console.log("vendorDep", vendorDep);

        setVendorDepartmentList(vendorDep);

        setDepartmentList(temp);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // GET VENDOR FROM DEPARTMENT LIST
  const getVendorView = (item, user, ind, inx, type) => {
    // console.log("item", item);
    // console.log("user", user);
    if (type == "add") {
      setVendorDepartment(item);
      setDepartment(item?.id);
    } else {
      setFirstName(user?.first_name);
      setLastName(user?.last_name);
      setEmail(user?.email);
      setPosition(user?.position);
      setRole(user?.role_id);
      setDepartment(user?.vendor_department_id);
      if (user?.vendor_address_id) {
        setAddress(user?.vendor_address_id);
      }

      if (user?.vendor_address_id) {
        setAddress(user?.vendor_address_id);
      }

      if (user?.primary_mobile) {
        setPrimaryNumbers(user?.primary_mobile);
      }

      setNoMarketing(user?.is_marketing);
      setVendorDepartment(user);
      setTags(user?.tags?.[0]?.id);
      let additional_info =
        user?.additional_info == ""
          ? user?.additional_info
          : JSON.parse(user?.additional_info);
      let mobile =
        user?.mobile == "-" ? user?.mobile : JSON.parse(user?.mobile);
      if (typeof additional_info == "object") {
        setTwitter(additional_info?.twitter);
        setFacebook(additional_info?.facebook);
        setLinkIn(additional_info?.linkedin);
        setSkype(additional_info?.skype);
      }
      let mobileArr = [];
      if (Array.isArray(mobile)) {
        mobile?.map((item, ind) => {
          mobileArr.push({
            number_type: item?.type,
            number: item?.number,
          });
          setNumbers(item?.number);
        });
      } else {
        mobileArr.push({ number_type: "work", number: "" });
      }

      setNumber(mobileArr);
    }
    setContactPopup(true);
  };

  // VENDOR USER CREATE
  const submitHandleVendorUser = () => {
    if (
      firstName?.length == 0 ||
      lastName?.length == 0 ||
      email?.length == 0 ||
      role?.length == 0 ||
      numbers?.length == 0 ||
      position?.length == 0 ||
      address?.length == 0 ||
      department?.length == 0 ||
      primaryNumbers?.length == 0 ||
      (!vendorDepartment?.vendor_department_id && password?.length < 8)
    ) {
      setFirstNameErr(true);
      setLastNameErr(true);
      setEmailErr(true);
      setRoleErr(true);
      setPositionErr(true);
      setAddressErr(true);
      setNumberErr(true);
      setDepartmentErr(true);
      setPrimaryNumbersErr(true);

      !vendorDepartment?.vendor_department_id && setPasswordErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("first_name", firstName);
      formdata.append("last_name", lastName);
      formdata.append("email", email);
      formdata.append("role_id", role);
      formdata.append("position", position);
      formdata.append("vendor_address_id", address);
      formdata.append("is_marketing", noMarketing);
      formdata.append("vendor_department_id", department);
      formdata.append("primary_mobile", primaryNumbers);

      if (password) {
        formdata.append("password", password);
        formdata.append("password_confirmation", password);
      }

      formdata.append("vendor_id", vendors?.vendor?.id);

      // if (twitter) {
      formdata.append("twitter", twitter ? twitter : "");
      // }

      // if (facebook) {
      formdata.append("facebook", facebook ? facebook : "");
      // }

      // if (linkIn) {
      formdata.append("linkedin", linkIn ? linkIn : "");
      // }

      // if (skype) {
      formdata.append("skype", skype ? skype : "");
      // }

      // let temp = [];

      // let additional_info = {};
      // additional_info.twitter = twitter;
      // additional_info.facebook = facebook;
      // additional_info.linkedin = linkIn;
      // additional_info.skype = skype;

      // temp.push(additional_info);

      // temp.map((item, ind) => {
      //   if (item?.twitter) {
      //     formdata.append(`additional_info[${ind}][twitter]`, item?.twitter);
      //   }

      //   if (item?.facebook) {
      //     formdata.append(`additional_info[${ind}][facebook]`, item?.facebook);
      //   }

      //   if (item?.linkedin) {
      //     formdata.append(`additional_info[${ind}][linkedin]`, item?.linkedin);
      //   }

      //   if (item?.skype) {
      //     formdata.append(`additional_info[${ind}][skype]`, item?.skype);
      //   }
      // });

      let tagArr = [];
      tagArr.push(tags);

      if (tagArr?.length > 0) {
        tagArr?.map((item, ind) => {
          if (item) {
            formdata.append(`tags[${ind}]`, item);
          }
        });
      }

      number?.map((item, ind) => {
        if (item?.number_type && item?.number) {
          formdata.append(`mobiles[${ind}][number_type]`, item?.number_type);
          formdata.append(`mobiles[${ind}][mobile]`, item?.number);
        }
      });
      dispatch(saveLoader(true));
      setAddressBtn(true);
      if (vendorDepartment?.vendor_department_id) {
        formdata.append("vendor_user_id", vendorDepartment?.id);
        vendorUserUpdateApi(formdata)
          .unwrap()
          .then((res) => {
            setNumber([{ number_type: "work", number: "" }]);
            setTwitter("");
            setFacebook("");
            setAddress("");
            setSkype("");
            setLinkIn("");
            setFirstName("");
            setLastName("");
            setEmail("");
            setRole("");
            setPassword("");
            setPosition("");
            setNoMarketing("");
            setVendorDepartment({});
            setDepartment("");
            setTags("");
            getDepartments();
            showPopupHander();
            dispatch(saveLoader(false));
            setAddressBtn(false);
            setContactPopup(false);
          })
          .catch((err) => {
            console.log("err", err);
            dispatch(saveLoader(false));
            setAddressBtn(false);
          });
      } else {
        vendorUserCreateApi(formdata)
          .unwrap()
          .then((res) => {
            setNumber([{ number_type: "work", number: "" }]);
            setTwitter("");
            setFacebook("");
            setAddress("");
            setSkype("");
            setLinkIn("");
            setFirstName("");
            setLastName("");
            setEmail("");
            setRole("");
            setPassword("");
            setPosition("");
            setDepartment("");
            setNoMarketing("");
            setTags("");
            showPopupHander();
            setVendorDepartment({});
            getDepartments();
            dispatch(saveLoader(false));
            setAddressBtn(false);
            setContactPopup(false);
          })
          .catch((err) => {
            console.log("err", err);
            dispatch(saveLoader(false));
            setAddressBtn(false);
          });
      }
    }
  };

  // console.log("address", address);

  // VENDOR USER STATUS CHANGE
  const change = (id) => {
    statusApi(id)
      .unwrap()
      .then((res) => {
        getDepartments();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // VENDOR ADDRESS LIST
  const getAddressList = (id) => {
    addressApi(id)
      .unwrap()
      .then((res) => {
        let temp = res?.vendor_address?.filter((i) => i?.status == 1);
        setVendorAddressList([...res?.vendor_address]?.reverse());
        setVendorAddressLists([...temp]?.reverse());
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // console.log("vendorDepartmentList",   vendorDepartmentList);
  // console.log("vendorData", vendorData);
  // VENDOR EDIT API
  const submitHandle = () => {
    let formdata = new FormData();
    formdata.append("name", tradeName);
    formdata.append("legal_name", legalEntityName);
    formdata.append("code", vendorData?.profile?.code);
    formdata.append("type_of_business", typeofBusiness);
    formdata.append("offering_type", vendorOfferingType);
    formdata.append("payment_terms", paymentTerms);
    formdata.append("gst", vendorData?.profile?.gst);
    formdata.append("currency_id", currency);
    formdata.append("description", vendorData?.profile?.description);
    formdata.append("address1", vendorData?.profile?.address1);
    formdata.append("country", vendorData?.profile?.country);
    formdata.append("state", vendorData?.profile?.state);
    formdata.append("pincode", vendorData?.profile?.pincode);
    formdata.append("city", vendorData?.profile?.city);

    if (Array.isArray(vendorData?.profile?.tags)) {
      vendorData?.profile?.tags?.map((item, ind) => {
        formdata.append(`tags[${ind}]`, item);
      });
    }

    if (Array.isArray(paymentMethod)) {
      paymentMethod?.map((item, ind) => {
        formdata.append(`payment_method[${ind}]`, item);
      });
    } else {
      formdata.append(`payment_method[${0}]`, " ");
    }

    if (Array.isArray(vendorData?.profile?.line_of_business)) {
      vendorData?.profile?.line_of_business?.map((item, ind) => {
        formdata.append(`line_of_business[${ind}]`, item);
      });
    } else {
      formdata.append(`line_of_business[${0}]`, " ");
    }

    if (Array.isArray(vendorData?.profile?.vendorCategory)) {
      vendorData?.profile?.vendorCategory?.map((item, ind) => {
        formdata.append(`category_ids[${ind}]`, item?.id);
      });
    }

    if (logoImage?.name) {
      formdata.append("logo", logoImage);
    }

    if (bannerImage?.name) {
      formdata.append("banner", bannerImage);
    }

    formdata.append("vendor_id", vendorID);

    // vendorEditApi({ id: vendorID, payload: formdata })
    setBtn(true);
    dispatch(saveLoader(true));
    vendorEditApi(formdata)
      .unwrap()
      .then((res) => {
        setBtn(false);
        dispatch(saveLoader(false));
        getViewProfile(vendorID);
        setUpdate(!update);
      })
      .catch((err) => {
        setBtn(false);
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  const profileUpdateHandler = () => {
    if (editProfile) {
      setEditProfile(false);
      setTab(0);
    } else {
      setEditProfile(true);
      setTab(7);
    }
  };

  // GET ADDRESS FOR EDIT
  const getVIewAddress = (item) => {
    setUpdateAddress(item);
    let coutryFinder = countriesList.find((i) => i?.name == item?.country);
    let stateFinder = stateList.find((i) => i?.name == item?.state);
    let stateFinders = stateList.filter(
      (i) => i?.country_id == coutryFinder?.id
    );
    let cityFinder = cityList.filter((i) => i?.state_id == stateFinder?.id);
    setCountry(coutryFinder?.name);
    setCountryId(coutryFinder?.id);
    setListStates(stateFinders);
    setState(stateFinder?.name);
    setStateId(stateFinder?.id);
    setListCity(cityFinder);
    setCity(item?.city);
    setAddressName(item?.address);
    setAddressType(item?.type);
    setLandMark(item?.landmark);
    setStreet(item?.street);
    setCompanyName(item?.name);
    setPincode(item?.pincode);
    setAddressPopup(true);
  };

  // ADDRESS CREATE
  const submitHandleAddress = () => {
    if (
      addressName?.length == 0 ||
      companyName?.length == 0 ||
      landmark?.length == 0 ||
      street?.length == 0 ||
      country?.length == 0 ||
      state?.length == 0 ||
      city?.length == 0 ||
      pincode?.length == 0 ||
      addressType?.length == 0
    ) {
      setAddressNameErr(true);
      setCompanyNameErr(true);
      setLandMarkErr(true);
      setStreetErr(true);
      setCountryErr(true);
      setStateErr(true);
      setCityErr(true);
      setPincodeErr(true);
      setAddressTypeErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("vendor_id", vendors?.vendor?.id);
      formdata.append("name", companyName);
      formdata.append("address", addressName);
      formdata.append("landmark", landmark);
      formdata.append("state", state);
      formdata.append("city", city);
      formdata.append("country", country);
      formdata.append("type", addressType);
      formdata.append("pincode", pincode);
      formdata.append("street", street);

      setBtnAdd(true);
      dispatch(saveLoader(true));

      if (updateAddress?.id) {
        formdata.append("vendor_address_id", updateAddress?.id);
        // addressEditApi({ payload: formdata, id: updateAddress?.id })
        addressEditApi(formdata)
          .unwrap()
          .then((res) => {
            setUpdateAddress({});
            setBtnAdd(false);
            dispatch(saveLoader(false));
            setCompanyName("");
            setAddressName("");
            setLandMark("");
            setState("");
            setCity("");
            setCountry("");
            setAddressType("");
            setPincode("");
            setStreet("");
            getAddressList(vendors?.vendor?.id);
            setAddressPopup(false);
            setStateId("");
            setListStates([]);
            setListCity([]);
            setCountryId("");
          })
          .catch((err) => {
            setBtnAdd(false);
            dispatch(saveLoader(false));
            console.log("err", err);
          });
      } else {
        addressAddApi(formdata)
          .unwrap()
          .then((res) => {
            setBtnAdd(false);
            setUpdateAddress({});
            dispatch(saveLoader(false));
            setCompanyName("");
            setAddressName("");
            setLandMark("");
            setState("");
            setCity("");
            setCountry("");
            setAddressType("");
            setPincode("");
            setStreet("");
            getAddressList(vendors?.vendor?.id);
            setAddressPopup(false);
            setStateId("");
            setListStates([]);
            setListCity([]);
            setCountryId("");
          })
          .catch((err) => {
            setBtnAdd(false);
            dispatch(saveLoader(false));
            console.log("err", err);
          });
      }
    }
  };

  // ADDRESS STATUS
  const addressStatus = (id) => {
    addressStatusApi(id)
      .unwrap()
      .then((res) => {
        getAddressList(vendors?.vendor?.id);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // POSITION CHANGE
  const getPositionData = (item) => {
    setPositionData(item);
  };

  // POSITON CHANGE FUNCTION
  const changePosition = () => {
    if (positionNumber?.length == 0) {
      setPoistionNumberErr(true);
    } else {
      setPoistionNumberErr(false);
      let formdata = new FormData();
      formdata.append("vendor_id", vendors?.vendor?.id);
      formdata.append("vendor_user_id", positionData?.id);
      formdata.append("position", positionNumber);
      // formdata.append('type', )
      dispatch(saveLoader(true));
      setPositionBtn(true);
      vendorPositionApi(formdata)
        .unwrap()
        .then((res) => {
          setPoistionNumber("");
          setPositionBtn(false);
          dispatch(saveLoader(false));
          getViewProfile(vendors?.vendor?.id);
          getDepartments();
          setPositionPopup(false);
        })
        .catch((err) => {
          setPositionBtn(false);
          dispatch(saveLoader(false));
          console.log("err", err);
        });
    }
  };

  useEffect(() => {
    if (vendors?.vendor?.id) {
      getViewProfile(vendors?.vendor?.id);
      getAddressList(vendors?.vendor?.id);
    }

    currencyListApi()
      .unwrap()
      .then((res) => {
        setCurrenciesLists(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });

    if (location?.state?.tab) {
      // console.log("tabssss");
      setTab(+location?.state?.tab);
    }

    getDepartments();
  }, []);
  // console.log(tab, "tab", location);
  return (
    <div className="dashRightView p-5">
      {logoutPopupShow && <LogoutPopup onCancelClick={toggleLogoutPopup} />}
      {contactPopup && (
        <EditContactPopup
          vendorDepartment={vendorDepartment}
          tagsList={tagsList}
          roleList={roleList}
          setContactPopup={setContactPopup}
          showPopupHander={showPopupHander}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          setEmail={setEmail}
          email={email}
          setPassword={setPassword}
          password={password}
          setPosition={setPosition}
          position={position}
          setRole={setRole}
          role={role}
          setNumber={setNumber}
          number={number}
          setDepartment={setDepartment}
          department={department}
          setTwitter={setTwitter}
          twitter={twitter}
          setFacebook={setFacebook}
          facebook={facebook}
          setLinkIn={setLinkIn}
          linkIn={linkIn}
          setSkype={setSkype}
          skype={skype}
          setAddress={setAddress}
          address={address}
          setNoMarketing={setNoMarketing}
          noMarketing={noMarketing}
          setTags={setTags}
          tags={tags}
          firstNameErr={firstNameErr}
          lastNameErr={lastNameErr}
          emailErr={emailErr}
          passwordErr={passwordErr}
          positionErr={positionErr}
          roleErr={roleErr}
          numberErr={numberErr}
          departmentErr={departmentErr}
          twitterErr={twitterErr}
          facebookErr={facebookErr}
          linkInErr={linkInErr}
          skypeErr={skypeErr}
          addressErr={addressErr}
          noMarketingErr={noMarketingErr}
          tagsErr={tagsErr}
          addressBtn={addressBtn}
          submitHandleVendorUser={submitHandleVendorUser}
          departmentList={departmentList}
          vendorAddressList={vendorAddressLists}
          numbers={numbers}
          setNumbers={setNumbers}
          primaryNumbers={primaryNumbers}
          setPrimaryNumbers={setPrimaryNumbers}
          primaryNumbersErr={primaryNumbersErr}
        />
      )}
      {addressPopup && (
        <EditAddressPopup
          setAddressPopup={setAddressPopup}
          showPopupHander={showPopupHander}
          addressName={addressName}
          setAddressName={setAddressName}
          companyName={companyName}
          setCompanyName={setCompanyName}
          landmark={landmark}
          setLandMark={setLandMark}
          street={street}
          setStreet={setStreet}
          city={city}
          setCity={setCity}
          country={country}
          setCountry={setCountry}
          state={state}
          setState={setState}
          pincode={pincode}
          setPincode={setPincode}
          addressType={addressType}
          setAddressType={setAddressType}
          addressNameErr={addressNameErr}
          companyNameErr={companyNameErr}
          landmarkErr={landmarkErr}
          streetErr={streetErr}
          cityErr={cityErr}
          countryErr={countryErr}
          stateErr={stateErr}
          pincodeErr={pincodeErr}
          addressTypeErr={addressTypeErr}
          btnAdd={btnAdd}
          submitHandleAddress={submitHandleAddress}
          setStateId={setStateId}
          stateId={stateId}
          listCity={listCity}
          setListCity={setListCity}
          listStates={listStates}
          setListStates={setListStates}
          setCountryId={setCountryId}
          countryId={countryId}
        />
      )}
      {positionPopup && (
        <PositionPopup
          showPopupHander={showPopupHander}
          positionNumberErr={positionNumberErr}
          positionNumber={positionNumber}
          setPoistionNumber={setPoistionNumber}
          positionData={positionData}
          setPositionData={setPositionData}
          changePosition={changePosition}
          positionBtn={positionBtn}
        />
      )}
      <ProductSearchHeader />
      <div
        className="w-100 p-1"
        style={{
          backgroundImage: `url(${
            bannerImage?.name
              ? URL.createObjectURL(bannerImage)
              : bannerImage
              ? bannerImage
              : vendorBannerImg
          })`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "70%",
          height: "285px",
        }}
      >
        <div className="d-flex as-jb flex-md-row flex-column ms-xl-5 ms-lg-5 ms-md-5 vendorDetSpace w-xs-100 w-sm-100 w-md-90 w-lg-90 w-xl-90">
          <div className=" d-lg-flex d-xl-flex d-md-flex as-jc">
            <img
              src={
                logoImage?.name
                  ? URL.createObjectURL(logoImage)
                  : logoImage
                  ? logoImage
                  : profilePic
              }
              className="profileImg"
            />
            <div className="ms-xl-5 ms-lg-5 ms-md-5">
              <p className="f4 fs-xs-22 fs-sm-23 fs-md-24 fs-lg-25 fs-xl-26 fs-xxl-27 text-capitalize text-white">
                {/* {tradeName} */}
              </p>
              <div className="d-flex mt-2">
                <BusinessIcon className="text-light f4 fs-xs-15 fs-sm-17 fs-md-18 fs-lg-19 fs-xl-20 fs-xxl-21" />
                <p className="text-light ms-2 f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18">
                  {vendorData?.profile?.legal_name}
                </p>
              </div>
              <div className="d-flex mt-2">
                <EmailOutlinedIcon className="text-light f4 fs-xs-15 fs-sm-17 fs-md-18 fs-lg-19 fs-xl-20 fs-xxl-21" />
                <p className="text-light ms-2 f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18">
                  {vendorData?.profile?.vendorUser?.email}
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex ac-jc flex-column"></div>
        </div>
      </div>

      <div className="w-100 d-flex mt-4">
        <div className="d-flex flex-wrap mt-3 w-xs-100 ac-jc">
          <div
            onClick={() => {
              setTab(0);
            }}
            className={`${
              tab == 0 || editProfile
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } pointerView`}
          >
            <p
              className={`${
                tab == 0 || editProfile ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Profile
            </p>
          </div>
          <div
            onClick={() => {
              setTab(1);
            }}
            className={`${
              tab == 1
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } ms-xl-5 ms-lg-5 ms-md-3 ms-sm-3 ms-2 pointerView`}
          >
            <p
              className={`${
                tab == 1 ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Contact
            </p>
          </div>
          <div
            onClick={() => {
              setTab(2);
            }}
            className={`${
              tab == 2
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            }  ms-xl-5 ms-lg-5 ms-md-3 ms-sm-3 ms-2 pointerView`}
          >
            <p
              className={`${
                tab == 2 ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Library
            </p>
          </div>
          {vendors?.vendor?.offering_type != 2 && (
            <div
              onClick={() => {
                setTab(7);
              }}
              className={`${
                tab == 7
                  ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                  : ""
              }  ms-xl-5 ms-lg-5 ms-md-3 ms-sm-3 ms-2 pointerView`}
            >
              <p
                className={`${
                  tab == 7 ? "black" : "gray"
                } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
              >
                Products
              </p>
            </div>
          )}
          {vendors?.vendor?.offering_type != 1 && (
            <div
              onClick={() => {
                setTab(3);
              }}
              className={`${
                tab == 3
                  ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                  : ""
              }  ms-xl-5 ms-lg-5 ms-md-3 ms-sm-3 ms-2 pointerView`}
            >
              <p
                className={`${
                  tab == 3 ? "black" : "gray"
                } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
              >
                Decoration
              </p>
            </div>
          )}
          <div
            onClick={() => {
              setTab(4);
            }}
            className={`${
              tab == 4
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            }  ms-xl-5 ms-lg-5 ms-md-3 ms-sm-3 ms-2 pointerView`}
          >
            <p
              className={`${
                tab == 4 ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              PO
            </p>
          </div>
          <div
            onClick={() => {
              setTab(5);
            }}
            className={`${
              tab == 5
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            }  ms-xl-5 ms-lg-5 ms-md-3 ms-sm-3 ms-3 pointerView`}
          >
            <p
              className={`${
                tab == 5 ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Meeting History
            </p>
          </div>
          <div
            onClick={() => {
              setTab(6);
            }}
            className={`${
              tab == 6
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            }  ms-xl-5 ms-lg-5 ms-md-3 ms-sm-3 ms-3 pointerView`}
          >
            <p
              className={`${
                tab == 6 ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Collaborate
            </p>
          </div>
        </div>
      </div>
      {tab == 0 && (
        <ProfileDetails
          getViewProfile={getViewProfile}
          vendorData={vendorData}
          setEditProfile={setEditProfile}
          setProfile={setProfile}
          setAddressPopup={setAddressPopup}
          setContactPopup={setContactPopup}
          setTab={setTab}
          legalEntityName={legalEntityName}
          setLegalEntityName={setLegalEntityName}
          tradeName={tradeName}
          setTradeName={setTradeName}
          vendorID={vendorID}
          setVendorID={setVendorID}
          vendorOfferingType={vendorOfferingType}
          setVendorOfferingType={setVendorOfferingType}
          typeofBusiness={typeofBusiness}
          setTypeofBusiness={setTypeofBusiness}
          paymentTerms={paymentTerms}
          setPaymentTerms={setPaymentTerms}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          GSTNumber={GSTNumber}
          setGSTNumber={setGSTNumber}
          currency={currency}
          setCurrency={setCurrency}
          logoImage={logoImage}
          setLogoImage={setLogoImage}
          bannerImage={bannerImage}
          setBannerImage={setBannerImage}
          legalEntityNameErr={legalEntityNameErr}
          tradeNameErr={tradeNameErr}
          vendorIDErr={vendorIDErr}
          vendorOfferingTypeErr={vendorOfferingTypeErr}
          typeofBusinessErr={typeofBusinessErr}
          paymentTermsErr={paymentTermsErr}
          paymentMethodErr={paymentMethodErr}
          GSTNumberErr={GSTNumberErr}
          currencyErr={currencyErr}
          logoImageErr={logoImageErr}
          bannerImageErr={bannerImageErr}
          setUpdate={setUpdate}
          update={update}
          submitHandle={submitHandle}
          btn={btn}
          vendorDepartmentList={vendorDepartmentList}
          getVendorView={getVendorView}
          vendorAddressList={vendorAddressList}
          getVIewAddress={getVIewAddress}
          addressStatus={addressStatus}
          change={change}
          setPositionPopup={setPositionPopup}
          getPositionData={getPositionData}
          currenciesLists={currenciesLists}
          decorativeListsNew={decorativeLists}
        />
      )}
      {editProfile && (
        <EditProfileDetails
          setContactPopup={setContactPopup}
          setAddressPopup={setAddressPopup}
          setPositionPopup={setPositionPopup}
          setEditProfile={setEditProfile}
          setProfile={setProfile}
          setTab={setTab}
        />
      )}
      {tab == 5 && <HistoryDetails vendorData={vendorData} />}
      {tab == 1 && (
        <ContactDetails
          setContactPopup={setContactPopup}
          setAddressPopup={setAddressPopup}
          setPositionPopup={setPositionPopup}
          vendorDepartmentList={vendorDepartmentList}
          getVendorView={getVendorView}
          change={change}
          getPositionData={getPositionData}
          vendorData={vendorData}
          getVIewAddress={getVIewAddress}
          addressStatus={addressStatus}
          vendorAddressList={vendorAddressList}
        />
      )}
      {tab == 2 && <LibraryDetails vendorID={vendors?.vendor?.id} />}
      {tab == 3 && vendors?.vendor?.offering_type != 1 && (
        <DecorativeDetails
          // name={"test"}
          legalEntityName={legalEntityName}
          place={place}
          formtypes={formtypes}
          path={""}
          vendor={""}
          decorative2={decorative}
          setDecorative={setDecorative}
          name={name}
        />
      )}
      {tab == 7 && vendors?.vendor?.offering_type != 2 && (
        <ProductDetails
          place={place}
          formtypes={formtypes}
          categoryList={categoryList}
        />
      )}
      {tab == 4 && <POSandBills />}
      {tab == 6 && <CollaborateList />}
    </div>
  );
};

export default VendorDetailsPage;
