import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";
import {
  useLazyThreadListQuery,
  useVendorProfileContactMutation,
  useVendorProfileRemindMutation,
  useVendorScheduleStoreMutation,
} from "../../redux/api/api";
import { useDispatch } from "react-redux";
import DoneIcon from "@mui/icons-material/Done";
import { saveLoader } from "../../redux/slice/loaderNoti";
import moment from "moment/moment";
import { toast } from "react-hot-toast";
// import { saveLoader } from "../../../redux/slice/loaderNoti";

const AddNotes = ({
  setNote,
  type,
  setOpenNotes,
  vendorData,
  getNotesList,
  index,
  item,
  setNoteIndex,
}) => {
  const vendors = JSON.parse(localStorage.getItem("user"));
  const [isCheckAll, setIsCheckAll] = useState(1);
  const [isCheckAlll, setIsCheckAlll] = useState(false);
  const [reminder, setReminder] = useState(false);
  const [date, setDate] = useState(new Date());
  const [dateTime, setDateTime] = useState(new Date());
  const [dateTime2, setDateTime2] = useState(new Date());
  const fileRef = useRef();
  const [imgList, setImgList] = useState("");
  const [thread, setThread] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Role, setRole] = useState("");
  const [rolelist, setRoleList] = useState([]);
  const [RoleErr, setRoleErr] = useState(false);
  const [Remind, setRemind] = useState("");
  const [remindlist, setRemindList] = useState([]);
  const location = useLocation();
  const [btn, setBtn] = useState(false);
  // const [RemindErr, setRemindErr] = useState(false);

  const [noteText, setNoteText] = useState("");
  const [contacts, setContacts] = useState();
  const [remindd, setRemindd] = useState();
  const [remindrr, setRemindrr] = useState();
  const [file, setFile] = useState();

  const types = location?.state?.type;

  //RTK QUERY
  const [profileContact] = useVendorProfileContactMutation();
  const [profileRemind] = useVendorProfileRemindMutation();
  const [profileThreadList] = useLazyThreadListQuery();
  const [vendorScheduleStore] = useVendorScheduleStoreMutation();

  const getrole = () => {
    const formdata = new URLSearchParams();
    formdata.append("type", "1");
    profileContact(formdata)
      .unwrap()
      .then((res) => {
        setRoleList(res?.vendor_users);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    getrole();
  }, []);

  const getremind = () => {
    const formdata = new URLSearchParams();
    formdata.append("type", "1");
    profileRemind(formdata)
      .unwrap()
      .then((res) => {
        setRemindList(res?.admins);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    getremind();
  }, []);

  console.log("vendorData", vendorData);

  const submitHandle = () => {
    if (noteText?.length == 0) {
      toast.error("Enter Note");
    } else if (Role?.length == 0) {
      toast.error("Select Contact");
    } else if (Remind?.length == 0) {
      toast.error("Add and Select Reminder");
    } else {
      let formdata = new FormData();
      if (imgList?.name) {
        formdata.append("images", imgList);
      }
      formdata.append("vendor_id", vendors?.vendor?.id);

      formdata.append("note", noteText);
      formdata.append("contact_id", Role);
      formdata.append("remind_id", Remind);

      if (isCheckAll == 1) {
        if (vendorData?.contact?.primaryContact?.department?.id) {
          formdata.append(
            "primary_vendor_user_id",
            vendorData?.contact?.primaryContact?.department?.id
          );
        }
      }

      if (isCheckAll == 2) {
        if (vendorData?.contact?.secondaryContact?.department?.id) {
          formdata.append(
            "secondary_vendor_user_id",
            vendorData?.contact?.secondaryContact?.department?.id
          );
        }
      }

      if (type == "call" || type == "meet") {
        formdata.append(
          "schedule_slot",
          moment(dateTime2).format("YYYY-MM-DD h:mm:ss")
        );
      }

      if (item?.type) {
        formdata.append("type", item?.type);
      } else if (type == "call") {
        formdata.append("type", 2);
      } else if (type == "meet") {
        formdata.append("type", 3);
      } else {
        formdata.append("type", 1);
      }

      if (item?.id) {
        formdata.append("parent_id", item?.id);
      }

      formdata.append(
        "remind_slot",
        moment(dateTime).format("YYYY-MM-DD h:mm:ss")
      );

      setBtn(true);
      dispatch(saveLoader(true));
      // if (location?.state?.data?.id) {
      //   formdata.append("testimonial_id", location?.state?.data?.id);

      vendorScheduleStore(formdata)
        .unwrap()
        .then((res) => {
          setBtn(false);
          dispatch(saveLoader(false));
          removeStateValue();
          getNotesList();
        })
        .catch((err) => {
          setBtn(false);
          dispatch(saveLoader(false));
          console.log("err", err);
        });
    }
    // }
  };

  const handleSelectAll = (e) => {
    vendorData?.contact?.primaryContact != null && setIsCheckAll(!isCheckAll);
  };
  const handleSelectAlll = (e) => {
    vendorData?.contact?.secondaryContact != null &&
      setIsCheckAlll(!isCheckAlll);
  };

  const removeStateValue = () => {
    setNoteText("");
    setRemind("");
    setRole("");
    setImgList("");
    setIsCheckAll(1);
    setNote(false);
    setOpenNotes(false);
    setNoteIndex(null);
  };

  return (
    <div className="w-100 mt-3">
      <textarea
        placeholder="Add a note, a call or a meeting..."
        className="rounded-3 editBtn w-100 p-2"
        value={noteText}
        onChange={(e) => setNoteText(e.target.value)}
      />
      <div className="w-100">
        {type === "thread" ||
          (type === "profile" && (
            <div className="d-flex ac-jb">
              <p className="w-35 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
                Type
              </p>
              <div className="d-flex w-75 ms-3">
                <select
                  onChange={(e) => setThread(e.target.value)}
                  className="cust-btn w-100 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 black"
                >
                  <option value="Note">Note</option>
                  <option value="Call">Call</option>
                  <option value="Meeting">Meeting</option>
                </select>
              </div>
            </div>
          ))}
        <div className="w-100 mt-3 d-flex ac-jb">
          <div className="d-flex ac-jb ">
            <p className="w-35 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
              Contacts
            </p>
            <div className="d-flex w-75 ms-3">
              <select
                className="cust-btn w-100 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 black"
                onChange={(e) => setRole(e.target.value)}
                value={Role}
              >
                <option value="">None</option>
                {/* <option value="10">Sakthi Exec</option> */}
                {rolelist?.map((item, ind) => (
                  <option value={item?.id} key={item?.id}>
                    {item?.first_name}
                  </option>
                ))}
              </select>
              {Role?.length == 0 && RoleErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select Contact</p>
                </div>
              )}
            </div>
          </div>

          <div className="d-flex ac-jc">
            <div
              onClick={() => setReminder(!reminder)}
              className="d-flex ac-jc pointerView"
            >
              <AccessAlarmIcon className="primary f2 fs-xs-19 fs-sm-20 fs-md-21 fs-lg-22 fs-xl-23 fs-xxl-24" />
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
                Reminder
              </p>
            </div>
            <div
              onClick={() => fileRef.current.click()}
              className="d-flex ac-jc pointerView"
            >
              <NoteAddIcon className="primary ms-2 f2 fs-xs-19 fs-sm-20 fs-md-21 fs-lg-22 fs-xl-23 fs-xxl-24" />
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
                File
              </p>
              {/* {types !== "view" && <input type="file" className="mb-2" onChange={(e) => setClient_Img(e.target.files[0])} />} */}
              <input
                type="file"
                // value={file}
                onChange={(e) => {
                  // setFile(e.target.value)
                  setImgList(e.target.files[0]);
                }}
                className="d-none"
                ref={fileRef}
              />
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div>
            {(type === "note" ||
              type === "call" ||
              type === "meet" ||
              type === "thread") &&
              reminder && (
                <div className="d-flex mt-3 ac-jb">
                  <p className="w-35 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
                    Remind
                  </p>
                  <div className="d-flex w-75 ms-3">
                    <select
                      className="cust-btn w-100 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 black"
                      onChange={(e) => setRemind(e.target.value)}
                      value={Remind}
                    >
                      <option value="">None</option>
                      {remindlist?.map((item, ind) => (
                        <option value={item?.id}>{item?.first_name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            {(type === "note" ||
              type === "call" ||
              type === "meet" ||
              type === "thread") &&
              reminder && (
                <div className="d-flex ac-jb ">
                  <p className="w-35 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
                    Reminder
                  </p>
                  <div className="d-flex w-75 mt-2 ms-3">
                    <ReactDatePicker
                      className="editBtn rounded-3 w-100 f2 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14"
                      onChange={(date1) => setDateTime(date1)}
                      showTimeSelect
                      value={dateTime}
                      selected={dateTime}
                      dateFormat="Pp"
                    />
                  </div>
                </div>
              )}
            {type === "thread" ||
              (type === "profile" && (
                <div className="d-flex ac-jb ">
                  <p className="w-35 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
                    Reminder
                  </p>
                  <div className="d-flex w-75 mt-2 ms-3">
                    <ReactDatePicker
                      className="editBtn rounded-3 w-100 f2 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14"
                      onChange={(date1) => setDateTime(date1)}
                      showTimeSelect
                      value={dateTime}
                      selected={dateTime}
                      dateFormat="Pp"
                    />
                  </div>
                </div>
              ))}
            {type === "call" && reminder && (
              <div className="d-flex ac-jb">
                <p className="w-35 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
                  Call Date
                </p>
                <div className="d-flex w-75 mt-2 ms-3">
                  <ReactDatePicker
                    className="editBtn rounded-3 w-100 f2 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14"
                    onChange={(date1) => setDateTime2(date1)}
                    showTimeSelect
                    selected={dateTime2}
                    dateFormat="Pp"
                  />
                </div>
              </div>
            )}
            {(type === "thread" || type === "profile") && thread === "Call" && (
              <div className="d-flex ac-jb ">
                <p className="w-35 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
                  Call Date
                </p>
                <div className="d-flex w-75 mt-2 ms-3">
                  <ReactDatePicker
                    className="editBtn rounded-3 w-100 f2 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14"
                    onChange={(date1) => setDateTime2(date1)}
                    showTimeSelect
                    selected={dateTime2}
                    dateFormat="Pp"
                  />
                </div>
              </div>
            )}
            {type === "meet" && reminder && (
              <div className="d-flex ac-jb ">
                <p className="w-35 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
                  Meeting Date
                </p>
                <div className="d-flex w-75 mt-2 ms-3">
                  <ReactDatePicker
                    className="editBtn rounded-3 w-100 f2 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14"
                    onChange={(date1) => setDateTime2(date1)}
                    showTimeSelect
                    selected={dateTime2}
                    dateFormat="Pp"
                  />
                </div>
              </div>
            )}
            {(type === "thread" || type === "profile") &&
              thread === "Meeting" && (
                <div className="d-flex ac-jb ">
                  <p className="w-35 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
                    Meeting Date
                  </p>
                  <div className="d-flex w-75 mt-2 ms-3">
                    <ReactDatePicker
                      className="editBtn rounded-3 w-100 f2 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14"
                      onChange={(date1) => setDateTime2(date1)}
                      showTimeSelect
                      selected={dateTime2}
                      dateFormat="Pp"
                    />
                  </div>
                </div>
              )}
            {imgList?.length !== "" && (
              <div className="d-flex ac-jb mt-2 w-100">
                <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
                  {imgList?.name}
                </p>
              </div>
            )}
          </div>
          <div className="mt-3 ms-3">
            <div className="d-flex gap-1 " onClick={() => setIsCheckAll(1)}>
              <DoneIcon
                className={`rounded-5 f2 p-1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20  ${
                  isCheckAll == 1 ? "checkIcon" : "checkIcon1"
                }`}
              />
              <p className="w-35 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 d-flex">
                Primary{" "}
                <span className="primary text-nowrap">
                  {" "}
                  ({vendorData?.contact?.primaryContact?.first_name}{" "}
                  {vendorData?.contact?.primaryContact?.last_name})
                </span>
              </p>
            </div>
            <div className="d-flex gap-1 mt-2" onClick={() => setIsCheckAll(2)}>
              <DoneIcon
                className={`rounded-5 f2 p-1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20  ${
                  isCheckAll == 2 ? "checkIcon" : "checkIcon1"
                }`}
              />
              <p className="w-35 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 d-flex">
                Secondary{" "}
                <span className="primary text-nowrap">
                  ({vendorData?.contact?.secondaryContact?.first_name}
                  {vendorData?.contact?.secondaryContact?.last_name})
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-100 d-flex ac-jb">
        <button
          className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
          onClick={() => submitHandle()}
        >
          Post
        </button>
        {type !== "thread" && type !== "profile" && (
          <button
            onClick={() => setNote(false)}
            className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
          >
            Cancel
          </button>
        )}
        {type === "thread" && (
          <button
            onClick={() => navigate(-1)}
            className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
          >
            Back
          </button>
        )}
        {type === "profile" && (
          <button
            onClick={() => setOpenNotes(false)}
            className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
          >
            Cancel
          </button>
        )}
      </div>
    </div>
  );
};

export default AddNotes;
