import React, { useEffect, useRef, useState } from "react";
import { product, uploadIcon } from "../../assets/img";
import { useLocation, useNavigate } from "react-router-dom";
import ImageCroper from "../Popup/ImageCroper";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ErrorIcon from "@mui/icons-material/Error";
import { toast } from "react-hot-toast";
const RequestImageUplodeComp = ({
  type,
  vendor,
  vendor2,
  colors,
  colorsName,
  textShow2,
  setSmallImage,
  smallimage,
  setProductImg,
  colorsData,
  setColorsData,
  colorsImg,
  setColorsImg,
  colorImgErr,
  setColorImgErr,
  smallimageErr,
  productImg,
  viewData,
}) => {
  const colorImgRef = useRef();
  const [colorIndex, setColorIndex] = useState(0);

  const handleColorImg = (event, ind) => {
    let temp = [...colorsData];

    if (event?.name) {
      temp[ind] = {
        ...temp[ind],
        image: event,
      };

      setColorsData(temp);
    }
  };

  const navigate = useNavigate();
  // console.log(vendor);
  const [logo, setLogo] = useState({});
  const [textShow, setTextShow] = useState(true);
  const [croper, setCroper] = useState(false);
  const [croperCol, setCroperCol] = useState(false);
  const [croperImage, setCropImage] = useState();
  const [proIndex, setProIndex] = useState(0);
  // console.log([...viewData?.image_urls?.product_images]);

  const proImgRef = useRef();
  // const morkupImgRef = useRef();
  // const colorImgRef = useRef();
  // const [proIndex, setProIndex] = useState(0);
  // const [morkupIndex, setMorkupIndex] = useState(0);
  // const [colorIndex, setColorIndex] = useState(0);
  // const [textShow2, setTextShow2] = useState(false)
  const [fistShow, setFirstShow] = useState(false);
  const [file, setFile] = useState("");
  // const [smallimage, setSmallImage] = useState([]);
  const [bigImage, setBigImage] = useState({
    image: "",
    ind: "",
    is_default: 0,
  });
  const [mockImage, setMockImage] = useState({ image: "", ind: "" });
  const imageUplode = (e) => {
    // setFile(URL.createObjectURL(e.target.files[0]));
    setFile(e);
    console.log(file, "windfine");
    // setTextShow(false)
  };

  const imageSelector = (item, ind, type, is_default) => {
    // if (type == "mock") {
    //   setMockImage({ image: item, ind: ind });
    if (type == "product") {
      setBigImage({ image: item, ind: ind, is_default: is_default });
    }
  };

  const getView = () => {
    let temp = [...viewData?.image_urls?.product_images];

    setBigImage({
      image: temp[temp?.length - 1],
      ind: temp.length - 1,
      is_default: temp[temp.length - 1]?.is_default,
    });

    setFirstShow(true);
    if (viewData?.image_urls?.product_images?.length > 0) {
      setTextShow(false);
    }
  };

  const handleProductImg = (event, ind) => {
    let temp = [...smallimage];

    if (event?.name) {
      temp[ind] = {
        ...temp[ind],
        image: event,
        is_default: 0,
      };

      setSmallImage(temp);
      setBigImage({
        image: temp[temp.length - 1]?.image,
        ind: temp.length - 1,
        is_default: 0,
      });
      setProductImg(event);
      setFirstShow(true);

      setTextShow(false);
    }
  };

  const imgclick = (e) => {
    let img = e.target.files[0];
    if (img?.name) {
      setSmallImage(URL.createObjectURL(img));
    } else {
      setSmallImage(img);
    }
    console.log(smallimage, "windfine");
    setTextShow(false);
  };

  const dualToggele = (type) => {
    if (type == "color") {
      setCroperCol(false);
      toggleImagePopup();
    } else {
      setCroperCol(true);
      toggleImagePopup();
    }
  };

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  useEffect(() => {
    if (croperCol && croperImage) {
      setTextShow(false);
      setFirstShow(true);

      let temp = [...smallimage];

      if (croperImage) {
        temp.push(croperImage);
      }

      setSmallImage(temp);

      setBigImage({ image: temp[temp?.length - 1], ind: temp?.length - 1 });
      setCropImage("");
    } else if (!croperCol && croperImage) {
      imageUplode(croperImage);
    }
  }, [croperImage]);

  // const imageSelector = (item, ind) => {
  //   setBigImage({ image: item, ind: ind });
  // };

  const removeImg = (ind, type, is_default) => {
    if (is_default == 1) {
      toast.error("Selected Image is Set Defaulted");
    } else {
      if (type == "product") {
        let temp = [...smallimage];

        temp.splice(ind, 1);
        setSmallImage(temp);
        if (temp?.length - 1 === 0) {
          setBigImage({
            image: temp[temp.length - 1]?.image,
            ind: temp.length - 1,
          });
        } else {
          setBigImage({ image: temp[ind - 1]?.image, ind: ind - 1 });
        }
      }
    }
  };
  const isDeFaultChanger = (item, type) => {
    let temp = [...smallimage];
    let final = [];

    for (let i = 0; i < temp.length; i++) {
      if (item?.ind == i) {
        let obj = (temp[i] = {
          ...temp[i],
          is_default: 1,
        });
        final.push(obj);
      } else {
        let obj = (temp[i] = {
          ...temp[i],
          is_default: 0,
        });
        final.push(obj);
      }

      setBigImage({ ...bigImage, is_default: 1 });

      setSmallImage(final);
    }
    toast.success("Default Image Setted");
  };
  useEffect(() => {
    if (viewData?.id) {
      getView();
    }
  }, [viewData]);

  // console.log(smallimage,"smallimage")
  console.log(colorImgErr, "smallimage");

  return (
    <div className="d-flex gap-3 ac-jc w-100">
      <div>
        <p class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 text-center mb-3">
          Product Image (185 x 205)
        </p>
        <div>
          <div className="">
            <div
              className="position-relative"
              onClick={() => {
                if (!fistShow) {
                  setProIndex(0);
                  proImgRef?.current?.click();
                }
              }}
            >
              <img
                className={"img_up_cont"}
                src={
                  bigImage?.image?.name
                    ? URL.createObjectURL(bigImage?.image)
                    : bigImage?.image
                    ? bigImage?.image
                    : uploadIcon
                }
              />
              {!textShow && (
                <div>
                  {smallimage?.length > 1 && (
                    <ul
                      className={`${"image-hide"}  edit-image image-show d-flex justify-content-between border_img`}
                    >
                      {/* <li
                      className="set-default-image"
                      onClick={() => isDeFaultChanger(bigImage, "product")}
                    >
                      Set&nbsp;Default
                    </li> */}
                      <li
                        className="set-default-image"
                        onClick={() =>
                          removeImg(
                            bigImage?.ind,
                            "product",
                            bigImage?.is_default
                          )
                        }
                      >
                        Remove
                      </li>
                    </ul>
                  )}
                </div>
              )}
            </div>
            <div className="d-flex ac-jc gap-3 w-100">
              {fistShow && (
                <div
                  class=" mt-3 gap-3 d-flex ac-jc pb-4 ps-5"
                  style={{ width: "300px", overflow: "scroll" }}
                >
                  {smallimage?.map((item, ind) => {
                    // console.log('item', item);
                    return (
                      <div className="gap-3 d-flex ac-j w-100">
                        <div
                          class="add_img d-flex ac-jc"
                          onClick={() =>
                            imageSelector(
                              item?.image,
                              ind,
                              "product",
                              item?.is_default
                            )
                          }
                        >
                          <img
                            src={
                              item?.image?.name
                                ? URL.createObjectURL(item?.image)
                                : item?.image
                            }
                            class="cp add_img object-fit-fill"
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {smallimage?.length > 0 && (
                <label
                  className={`add_img d-flex ac-jc cp f3 primary fs-3 ${
                    smallimage?.length == 0 && "mt-3"
                  }`}
                  onClick={() => {
                    setProIndex(smallimage?.length);
                    proImgRef?.current?.click();
                    //  dualToggele("product")
                  }}
                >
                  {" "}
                  +
                </label>
              )}
            </div>
          </div>
          {productImg?.length == 0 && smallimageErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Upload Product Image</p>
            </div>
          )}
        </div>
        <input
          type="file"
          accept=".pdf,.doc,.jpeg,.docx,.png,.jpg"
          onChange={(e) => handleProductImg(e.target.files[0], proIndex)}
          className="d-none"
          ref={proImgRef}
        />
        {colorsData?.length > 0 && (
          <div>
            <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 text-center mb-3">
              Color Image (312 x 312)
            </p>
            <div
              className="d-flex gap-3 mt-3 ac-jc flex-wrap"
              style={{ width: "350px" }}
            >
              {colorsData?.map((item, ind) => {
                return (
                  <div className="d-flex flex-column ac-jc" key={ind}>
                    {item?.image ? (
                      <div className="position-relative for_hover">
                        <img
                          alt="icon"
                          src={
                            item?.image?.name
                              ? URL.createObjectURL(item?.image)
                              : item?.image
                          }
                          className="add_img object-fit-fill"
                          onClick={() => {
                            setColorIndex(ind);
                            colorImgRef.current.click();
                          }}
                        />
                        <div className="d-flex w-100 justify-content-between position-absolute in_hover text-white">
                          <FileDownloadOutlinedIcon />
                          <ClearOutlinedIcon />
                        </div>
                      </div>
                    ) : (
                      <label
                        className="add_img d-flex ac-jc cp f3 primary fs-3"
                        onClick={() => {
                          setColorIndex(ind);
                          colorImgRef.current.click();
                        }}
                      >
                        +
                      </label>
                    )}
                    <p className="f3" style={{ color: item?.hex_code }}>
                      {item?.name}
                    </p>
                    {item?.image?.length == 0 && colorImgErr && (
                      <div className="d-flex gap-1 mt-2">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">Upload Color Image</p>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        <input
          type="file"
          accept=".pdf,.doc,.jpeg,.docx,.png,.jpg"
          onChange={(e) => handleColorImg(e.target.files[0], colorIndex)}
          className="d-none"
          ref={colorImgRef}
        />
      </div>
    </div>
  );
};

export default RequestImageUplodeComp;
