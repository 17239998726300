import React, { useEffect, useState } from "react";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import { uploadIcon } from "../assets/img";
import FormComp from "../components/AddNewProductComps/FormComp";
import ImageUplodeComp from "../components/AddNewProductComps/ImageUplodeComp";
import { useLocation, useNavigate } from "react-router-dom";
import RequestFormComp from "../components/CreateNewRequest/RequestFormComp";
import RequestImageUplodeComp from "../components/CreateNewRequest/RequestImageUplodeComp";
import VendorPricingComp from "../components/ProductDetailComp/VendorPricingComp";
import PricingBoxComp from "../components/MyProductComp/PricingBoxComp";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  useDecorativeListMutation,
  useHsnListMutation,
  useLazyAttributesListQuery,
  useLazyColorListQuery,
  useLazyDecorativedroupdounQuery,
  useLazyRequestViewQuery,
  useLazySubCategoryQuery,
  useRequestEditMutation,
  useStoreRequestMutation,
} from "../redux/api/api";
import RequestPricing from "../components/MyProductComp/RequestPricing";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";

const CreateNewRequestScreen = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigat = useNavigate();
  const [btn, setBtn] = useState(false);
  // console.log(location);
  const vendor_id = JSON.parse(localStorage.getItem("user"));
  const categoryLists = location?.state?.categoryList;
  const id = location?.state?.id;
  const type = location?.state?.type;
  const [categoryList, setCategoryList] = useState(categoryLists);
  const [parentCategoryList, setParentCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [subCategoryLists, setSubCategoryLists] = useState([]);
  const [listAttributes, setListAttributes] = useState([]);
  const [dynamicAttr, setDynamicAttr] = useState([]);
  const [attributesList, setAttributesList] = useState([]);
  const [attributesName, setAttributesName] = useState(["Color"]);

  const [otherProInfoErr, setOtherProInfoErr] = useState(false);
  const [otherProInfo, setOtherProInfo] = useState([]);

  // const [colorsName, setColorsName] = useState([]);
  const [viewData, setViewData] = useState({});
  const [secondaryCategoryId, setSecondaryCategoryId] = useState("");
  const [productSuplierCode, setProductSuplierCode] = useState("");
  const [searchValueSubCategory, setSearchSubValueCategory] = useState("");
  const [catogoryName, setCatogoryName] = useState({});
  const [categoryID, setCategoryID] = useState("");
  const [catogory, setCatogory] = useState("");
  const [country, setCountry] = useState("");
  //singele state
  const [productName, setProductName] = useState("");
  const [productNameErr, setProductNameErr] = useState(false);
  const [decorList, setDecorList] = useState([]);
  const [hsnList, setHsnList] = useState([]);
  const [decorativeID, setDecorativeID] = useState([]);
  const [decorativeName, setDecorativeName] = useState([]);
  const [decorativeNameErr, setDecorativeNameErr] = useState(false);
  const [decorationMethod, setDecorationMethod] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [hsnCodeErr, setHsnCodeErr] = useState(false);
  const [hsnName, setHsnName] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionErr, setDescriptionErr] = useState("");
  const [smallimage, setSmallImage] = useState([]);
  const [productImg, setProductImg] = useState("");
  const [productCode, setProductCode] = useState("");
  const [productCodeErr, setProductCodeErr] = useState(false);
  const [primaryCategoryId, setPrimaryCategoryId] = useState("");
  // const [secondaryCategoryId, setSecondaryCategoryId] = useState("");
  const [primaryCategoryIdErr, setPrimaryCategoryIdErr] = useState(false);
  const [secondaryCategoryIdErr, setSecondaryCategoryIdErr] = useState(false);
  const [categoryIDErr, setCategoryIDErr] = useState(false);
  // const [categoryID, setCategoryID] = useState("");
  const [smallimageErr, setSmallImageErr] = useState(false);
  const [hsnErr, setHsnErr] = useState(false);
  const [countryErr, setCountryErr] = useState(false);
  const [gendor, setGendor] = useState("");
  const [gendorErr, setGendorErr] = useState("");

  const [colorsList, setColorsList] = useState([]);
  const [packingSize, setPackingSize] = useState("");
  const [packingSizeErr, setPackingSizeErr] = useState(false);
  const [colorsID, setColorsID] = useState([]);
  const [colorsName, setColorsName] = useState([]);
  const [colorsData, setColorsData] = useState([]);
  const [varientShow, setVarientShow] = useState(false);
  const [sizes, setSizes] = useState([{ value: "" }]);
  const [materials, setMeterials] = useState([{ value: "" }]);
  const [styles, setStyles] = useState([{ value: "" }]);
  const [allVarients, setAllVarients] = useState([]);
  const [colorsImg, setColorsImg] = useState("");
  const [colorImgErr, setColorImgErr] = useState(false);
  const [productInformation, setProductInformation] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [marginBox, setMarginBox] = useState(false);
  const [addVarientErr, setAddVarientErr] = useState(false);
  const [addVarients, setAddVarients] = useState("");
  const [colorVarientErr, setColorVarientErr] = useState(false);
  const [sizeVarientErr, setSizeVarientErr] = useState(false);
  const [styleVarientErr, setStyleVarientErr] = useState(false);
  const [matVarientErr, setMatVarientErr] = useState(false);
  const [colorVarient, setColorVarient] = useState("");
  const [sizeVarient, setSizeVarient] = useState("");
  const [styleVarient, setStyleVarient] = useState("");
  const [matVarient, setMatVarient] = useState("");
  const [defaultImage, setdefaultImage] = useState([]);

  //PRICE
  const [edit, setEdit] = useState(false);
  const [priceBook, setpriceBook] = useState(true);
  const [previousOrder, setPreviousOrder] = useState(false);
  const [cost, setCost] = useState({
    for_25: "",
    for_50: "",
    for_100: "",
    for_150: "",
    for_250: "",
    for_500: "",
    for_1000: "",
  });
  const [net, setNet] = useState({
    for_25: "",
    for_50: "",
    for_100: "",
    for_150: "",
    for_250: "",
    for_500: "",
    for_1000: "",
  });

  const [margin, setMargin] = useState({
    for_25: "",
    for_50: "",
    for_100: "",
    for_150: "",
    for_250: "",
    for_500: "",
    for_1000: "",
  });

  const [distributorPrice, setDistributorPrice] = useState({
    for_25: "",
    for_50: "",
    for_100: "",
    for_150: "",
    for_250: "",
    for_500: "",
    for_1000: "",
  });

  const [distributorPriceErr, setDistributorPriceErr] = useState(false);
  const [productInformationErr, setProductInformationErr] = useState(false);

  const [priceCharge, setPriceCharge] = useState([]);

  // ADD CHANGES
  const addpriceCharge = () => {
    let temp = [...priceCharge];

    temp.push({
      name: "",
      for_25: "",
      for_50: "",
      for_100: "",
      for_150: "",
      for_250: "",
      for_500: "",
      for_1000: "",
    });
    setPriceCharge(temp);
  };

  // REMOVE CHANGES
  const removepriceCharge = (e, ind) => {
    let temp = [...priceCharge];
    temp.splice(ind, 1);
    setPriceCharge(temp);
  };

  // CHARGES VALUE CHANGER
  const handlepriceCharge = (e, index, type) => {
    let temp = [...priceCharge];
    const name = e.target.name;
    const value = e.target.value;

    if (type == "name") {
      let obj = {
        ...temp[index],
        [type]: value,
      };

      temp[index] = obj;
      setPriceCharge(temp);
    } else {
      let obj = {
        ...temp[index],
        [type]: value,
      };

      temp[index] = obj;
      setPriceCharge(temp);
    }
  };

  // COST VALUE CHANGER
  const handleCost = (event, type) => {
    setCost({ ...cost, [type]: event });
  };

  // MARGIN VALUE AND DISTRIBUTOR PRICE CHANGER
  const handleMargin = (event, type) => {
    // let percen = net[type];
    // let cal = (+percen / 100) * +event;
    // let sum_value = +cal + +percen;
    setMargin({ ...margin, [type]: event });
    // setDistributorPrice({ ...net, [type]: sum_value });
  };

  const handleCloseMargin = () => {
    setDistributorPrice(net);
    setMargin({
      for_25: "",
      for_50: "",
      for_100: "",
      for_150: "",
      for_250: "",
      for_500: "",
      for_1000: "",
    });
    setMarginBox(!marginBox);
  };

  // FOR CALCULATION
  useEffect(() => {
    if (priceCharge?.length > 0) {
      const result = priceCharge?.reduce((accum, current) => {
        Object.entries(current).forEach(([key, value]) => {
          accum[key] = +accum[key] + +value || +value;
        });
        return {
          ...accum,
        };
      }, {});

      delete result?.name;
      const net_value = Object.fromEntries(
        Object.keys(cost).map((k) => [k, +cost[k] + +result[k]])
      );

      setNet(net_value);

      const distributorPrice = Object.fromEntries(
        Object.keys(net_value).map((k) => [
          k,
          Number((+net_value[k] / 100) * +margin[k] + +net_value[k]).toFixed(2),
        ])
      );
      setDistributorPrice(distributorPrice);
    } else if (marginBox == false) {
      const distributorPrice = Object.fromEntries(
        Object.keys(cost).map((k) => [
          k,
          Number((+cost[k] / 100) * +margin[k] + +cost[k]).toFixed(2),
        ])
      );
      setNet(cost);
      setDistributorPrice(distributorPrice);
    } else {
      setNet(cost);
      setDistributorPrice(cost);
    }
  }, [cost, priceCharge, margin]);

  //RTK
  const [subCategoryApi] = useLazySubCategoryQuery();
  const [decorativeListApi] = useLazyDecorativedroupdounQuery();
  const [hsnListApi] = useHsnListMutation();
  const [colorListApi] = useLazyColorListQuery();
  const [attributesApi] = useLazyAttributesListQuery();
  const [storeApi] = useStoreRequestMutation();
  const [editApi] = useRequestEditMutation();

  // GET PARENT ID FOR SUB CATEGORY LIST
  const getCategoryId = (id, type, ind, index, data) => {
    if (type == "category") {
      // setParentCategory(id);
      getView(id, "", "", type, "");
    } else {
      // setSubCategory(id);
      onSubCatSelect(id, ind, index, data);
    }
  };

  // SLICE ARRAY
  const onSubCatSelect = (id, ind, index, data) => {
    if (index < data?.length - 1) {
      let temp = [...data];
      let slicer = temp.slice(0, index + 1);
      setSubCategoryList(slicer);

      let temps = [...subCategoryLists];
      let slicers = temps.slice(0, index + 1);
      setSubCategoryLists(slicers);

      getView(id, ind, index, "sub_category", slicer);
    } else {
      getView(id, ind, index, "", data);
    }
  };

  // //View SubCategory list

  const getView = (id, ind, index, type, data) => {
    subCategoryApi(id)
      .unwrap()
      .then((res) => {
        console.log(res, "resbonse");
        let child = [];
        res?.child_categories?.map((item, ind) => {
          child.push({
            ...item,
            category: {
              ...res?.category,
            },
          });
        });

        let responce = {
          category: res?.category,
          child_categories: child,
        };

        let temp = [];
        let temps = [];
        if (data?.length > 0) {
          temp = [...data];
          temps = [...data];
        } else if (type !== "category") {
          temp = [...subCategoryList];
          temps = [...subCategoryLists];
        }

        if (type == "subSumit") {
          let temX = responce?.child_categories;

          let obj = {
            ...temp[index],
            child_categories: temX,
          };

          let final = subCategoryList?.filter((i) => i?.category?.id !== id);
          final.splice(index, 0, obj);

          let finals = subCategoryLists?.filter((i) => i?.category?.id !== id);
          finals.splice(index, 0, obj);

          setSubCategoryList(final);
          setSubCategoryLists(finals);
        } else {
          let finder = temp.indexOf(id);

          if (temp?.includes(responce)) {
            temp.slice(0, 1);
            temp.push(responce);
          } else {
            temp.push(responce);
          }

          if (temps?.includes(responce)) {
            temps.slice(0, 1);
            temps.push(responce);
          } else {
            temps.push(responce);
          }

          setSubCategoryList(temp);
          setSubCategoryLists(temps);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // SELECT CHILD CATEGORY
  const onSubCategorySelect = (subItem, indx, ind) => {
    setSearchSubValueCategory((val) => ({ ...val, [ind]: "" }));
    let temp = [...subCategoryList];
    // console.log("temp[ind]", temp[ind], subCategoryLists[ind]);
    temp[ind] = subCategoryLists[ind];
    // console.log("temp", temp);
    setSubCategoryList(temp);
    getCategoryId(subItem?.id, "sub_category", indx, ind, temp);
    setCatogoryName((val) => ({
      ...val,
      [subItem?.category?.id]: subItem?.name,
    }));
    if (ind == 0) {
      setSecondaryCategoryId(subItem?.id);
    } else {
      setCategoryID(subItem?.id);
    }

    setProductSuplierCode(subItem?.code);
  };

  // DECORATIVE LIST
  const getdecorative = () => {
    decorativeListApi()
      .unwrap()
      .then((res) => {
        // console.log(res);
        setDecorList(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // HSN LIST
  const getHsnList = () => {
    hsnListApi()
      .unwrap()
      .then((res) => {
        // console.log(res?.data)
        setHsnList(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // HSN LIST
  const getColorList = () => {
    colorListApi()
      .unwrap()
      .then((res) => {
        // console.log(res)
        let temp = [];
        res?.data?.map((item, ind) => {
          temp.push({
            ...item,
            image: "",
          });
        });
        setColorsList(temp);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // HSN LIST
  const getAttriList = () => {
    attributesApi()
      .unwrap()
      .then((res) => {
        // console.log(res)
        setAttributesList(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const isCostObjectEmpty = Object.values(distributorPrice).some(
    (value) => value == 0
  );

  // console.log(isCostObjectEmpty);
  useEffect(() => {
    getdecorative();
    getHsnList();
    getColorList();
    getAttriList();
  }, []);
  const onSubmit = () => {
    let check1;
    productInformation?.map((item, ind) => {
      check1 = Object.values(item).some((x) => x === " " || x === "");
    });
    let check;
    if (colorsData?.length > 0) {
      colorsData?.map((item, ind) => {
        // check = Object.hasOwn(item,"image")
        check = Object.values(item).some((e) => e == "" || e == " ");
      });
    }
    if (
      primaryCategoryId?.length == 0 ||
      secondaryCategoryId?.length == 0 ||
      categoryID?.length == 0 ||
      productName?.length == 0 ||
      productCode?.length == 0 ||
      description?.length == 0 ||
      decorativeID?.length == 0 ||
      smallimage?.length == 0 ||
      hsnCode?.length == 0 ||
      packingSize?.length == 0 ||
      country?.length == 0 ||
      Object.values(cost).some((value) => value == 0) ||
      // otherProInfo?.length == 0 ||
      gendor?.length == 0 ||
      // attributesName?.length == 1 ||
      // (attributesName?.includes("Color") && colorVarient?.length == 0) ||
      colorVarient?.length == 0 ||
      (attributesName?.includes("Size") && sizeVarient?.length == 0) ||
      (attributesName?.includes("Materials") && matVarient?.length == 0) ||
      (attributesName?.includes("Style") && styleVarient?.length == 0) ||
      check == true ||
      check1 == true
    ) {
      setProductInformationErr(true);
      // setAddVarientErr(true);
      // !attributesName?.includes("Varient") && setAddVarientErr(true);
      // attributesName?.includes("Color") && setColorVarientErr(true);
      setColorVarientErr(true);
      // setAddVarientErr(true);
      attributesName?.includes("Size") && setSizeVarientErr(true);
      attributesName?.includes("Materials") && setMatVarientErr(true);
      attributesName?.includes("Style") && setStyleVarientErr(true);
      setPrimaryCategoryIdErr(true);
      setCategoryIDErr(true);
      setSecondaryCategoryIdErr(true);
      setProductNameErr(true);
      setProductCodeErr(true);
      setDescriptionErr(true);
      setDecorativeNameErr(true);
      setSmallImageErr(true);
      setHsnErr(true);
      setPackingSizeErr(true);
      setCountryErr(true);
      setDistributorPriceErr(true);
      // setOtherProInfoErr(true);
      setGendorErr(true);
      setColorImgErr(true);
    } else {
      const formData = new FormData();
      formData.append("vendor_id", vendor_id?.vendor?.id);
      formData.append("category_id", categoryID);
      formData.append("parent_category_id", primaryCategoryId);
      formData.append("name", productName);
      formData.append("code", productCode);
      formData.append("description", description);
      formData.append("hsn_id", hsnCode);
      formData.append("country_origin", country);
      formData.append("package_size", packingSize);
      formData.append("product_for", gendor);

      // if(decorativeID.length>1)
      decorativeID?.map((item, ind) => {
        formData.append(`decorative_methods[${ind}]`, item);
      });
      let emptyArr = [];
      if (colorsID?.length > 0) {
        colorsID?.map((item, ind) => {
          formData.append(`colors[${ind}]`, item?.id);
        });
      } else {
        formData.append(`colors[${0}]`, emptyArr);
      }

      if (sizes?.length > 0) {
        sizes?.map((item, ind) => {
          formData.append(`size[${ind}]`, item?.value);
        });
      } else {
        formData.append(`size[${0}]`, emptyArr);
      }

      if (materials?.length > 0) {
        materials?.map((item, ind) => {
          formData.append(`material[${ind}]`, item?.value);
        });
      } else {
        formData.append(`material[${0}]`, emptyArr);
      }

      if (styles?.length > 0) {
        styles?.map((item, ind) => {
          formData.append(`style[${ind}]`, item?.value);
        });
      } else {
        formData.append(`style[${0}]`, emptyArr);
      }

      let smallimageArr = [];

      smallimage?.map((item, ind) => {
        if (item?.image?.name) {
          smallimageArr.push(item);
        }
      });
      if (smallimageArr?.length > 0) {
        smallimageArr?.map((item, ind) => {
          formData.append(`product_images[${ind}]`, item?.image);
          // formdata.append(`product_images[${ind}][description]`,
        });
      }

      productInformation?.map((item, ind) => {
        formData.append(`attribute_values[${ind}][id]`, item?.id);
        formData.append(`attribute_values[${ind}][value]`, item?.value);
      });

      let colorimageArr = [];

      colorsData?.map((item, ind) => {
        if (item?.image?.name) {
          colorimageArr.push(item);
        }
      });
      if (colorimageArr?.length > 0) {
        colorimageArr?.map((item, ind) => {
          formData.append(
            `color_images[${ind}][color_id]`,
            item?.color_id ? item?.color_id : item?.id
          );
          formData.append(`color_images[${ind}][image]`, item?.image);
          // formData.append(`color_images[${ind}][description]`, "");
        });
      }
      // BASIC PRICE
      formData.append(`cost[for_25]`, cost?.for_25);
      formData.append(`cost[for_50]`, cost?.for_50);
      formData.append(`cost[for_100]`, cost?.for_100);
      formData.append(`cost[for_150]`, cost?.for_150);
      formData.append(`cost[for_250]`, cost?.for_250);
      formData.append(`cost[for_500]`, cost?.for_500);
      formData.append(`cost[for_1000]`, cost?.for_1000);

      // NET PRICE
      formData.append(`net[for_25]`, net?.for_25);
      formData.append(`net[for_50]`, net?.for_50);
      formData.append(`net[for_100]`, net?.for_100);
      formData.append(`net[for_150]`, net?.for_150);
      formData.append(`net[for_250]`, net?.for_250);
      formData.append(`net[for_500]`, net?.for_500);
      formData.append(`net[for_1000]`, net?.for_1000);

      // MARGIN PRICE
      formData.append(`margin[for_25]`, margin?.for_25);
      formData.append(`margin[for_50]`, margin?.for_50);
      formData.append(`margin[for_100]`, margin?.for_100);
      formData.append(`margin[for_150]`, margin?.for_150);
      formData.append(`margin[for_250]`, margin?.for_250);
      formData.append(`margin[for_500]`, margin?.for_500);
      formData.append(`margin[for_1000]`, margin?.for_1000);

      // DISTRIBUTOR PRICE
      formData.append(`final_price[for_25]`, distributorPrice?.for_25);
      formData.append(`final_price[for_50]`, distributorPrice?.for_50);
      formData.append(`final_price[for_100]`, distributorPrice?.for_100);
      formData.append(`final_price[for_150]`, distributorPrice?.for_150);
      formData.append(`final_price[for_250]`, distributorPrice?.for_250);
      formData.append(`final_price[for_500]`, distributorPrice?.for_500);
      formData.append(`final_price[for_1000]`, distributorPrice?.for_1000);

      // PRICE CHARGE PRICE
      if (priceCharge?.length > 0) {
        priceCharge?.map((item, ind) => {
          formData.append(`charges[${ind}][name]`, item?.name);
          formData.append(`charges[${ind}][for_25]`, item?.for_25);
          formData.append(`charges[${ind}][for_50]`, item?.for_50);
          formData.append(`charges[${ind}][for_100]`, item?.for_100);
          formData.append(`charges[${ind}][for_150]`, item?.for_150);
          formData.append(`charges[${ind}][for_250]`, item?.for_250);
          formData.append(`charges[${ind}][for_500]`, item?.for_500);
          formData.append(`charges[${ind}][for_1000]`, item?.for_1000);
        });
      }
      if (type == "edit") {
        dispatch(saveLoader(true));
        setBtn(true);
        formData.append("product_request_id", id);
        editApi(formData)
          .unwrap()
          .then((res) => {
            console.log(res);
            dispatch(saveLoader(false));
            setBtn(false);
            navigat("/vendor-details", { state: { tab: 7, productTab: 2 } });
          })
          .catch((err) => {
            dispatch(saveLoader(false));
            setBtn(false);
            console.log(err);
          });
      } else {
        dispatch(saveLoader(true));
        setBtn(true);
        storeApi(formData)
          .unwrap()
          .then((res) => {
            dispatch(saveLoader(false));
            setBtn(false);
            navigat("/vendor-details", { state: { tab: 7, productTab: 2 } });
            console.log(res);
          })
          .catch((err) => {
            dispatch(saveLoader(false));
            setBtn(false);
            console.log(err);
          });
      }
    }
  };
  //RTk
  const [getViewApi] = useLazyRequestViewQuery();

  // const getViewProduct = () => {
  //   getViewApi(id)
  //     .unwrap()
  //     .then((res) => {
  //       // console.log('res',res?.product_request)
  //       const data = res?.product_request;
  //       // console.log("data", data);
  //       setViewData(data);
  //       const productImagesArr = [];
  //       let DecorationName = [];
  //       let DecorationID = [];
  //       let productAttributesName = [];
  //       let productAttributesID = [];

  //       let subCategoryListArr = [];
  //       let colorsDataArr = [];
  //       let categorys = [];
  //       let cat = {};

  //       data?.category?.parentCategory?.map((item, ind) => {
  //         if (item?.icon) {
  //           setCatogory(item?.name);
  //         }
  //       });
  //       setSizeVarient("new");
  //       setColorVarient("new");
  //       setMatVarient("new");
  //       setStyleVarient("new");

  //       const items = [];
  //       data?.category?.parentCategory?.map((item, ind) => {
  //         if (item?.code) {
  //           setProductSuplierCode(item?.code);
  //         }

  //         if (item?.icon) {
  //           cat.status = item?.status;
  //           cat.icon = item?.icon;
  //           cat.id = item?.id;
  //           cat.name = item?.name;
  //           items.push(item);
  //           setCategoryList(items);
  //         } else {
  //           categorys.push(item);
  //         }
  //       });

  //       categorys.push({
  //         icon: data?.category?.icon,
  //         id: data?.category?.id,
  //         name: data?.category?.name,
  //         status: data?.category?.status,
  //       });

  //       categorys?.map((item, ind) => {
  //         let obj = { ...item };
  //         if (ind == 0) {
  //           obj.category = cat;
  //         } else {
  //           obj.category = categorys[ind - 1];
  //         }
  //         subCategoryListArr.push(obj);
  //       });

  //       let catArrs = data?.category?.parentCategory;

  //       setPrimaryCategoryId(catArrs?.[0]?.id);
  //       setSecondaryCategoryId(catArrs?.[1]?.id);
  //       setCategoryID(data?.category?.id);

  //       subCategoryListArr?.map((item, ind) => {
  //         setCatogoryName((val) => ({
  //           ...val,
  //           [item?.id]: item?.name,
  //         }));
  //       });

  //       subCategoryListArr?.map((item, ind) => {
  //         setCatogoryName((val) => ({
  //           ...val,
  //           [item?.category?.id]: item?.name,
  //         }));
  //       });

  //       const name = data?.decorative_methods?.map((item) => item?.name);
  //       const id = data?.decorative_methods?.map((item) => item?.id);

  //       setDecorativeID(id);
  //       setDecorativeName(name);
  //       // setGendor(product_for);

  //       data?.attributes?.map((item, ind) => {
  //         let id = item?.id;
  //         productAttributesName.push(item?.name);
  //         productAttributesID.push({
  //           id: item?.json?.id,
  //           title: item?.name,
  //           value: item?.json?.value,
  //         });
  //         setOtherProInfo(id);
  //       });

  //       setProductInformation(productAttributesID);
  //       setDynamicAttr(productAttributesName);
  //       setCountry(data?.country_origin);
  //       setProductName(data?.name);
  //       setProductCode(data?.code);
  //       setDescription(data?.description);
  //       setHsnCode(data?.hsn?.id);
  //       setHsnName(data?.hsn?.code);
  //       setPackingSize(data?.package_size);
  //       setSubCategoryList(subCategoryListArr);
  //       setGendor(data?.product_for);

  //       // console.log('data?.image_urls', data?.image_urls?.product_images);

  //       data?.image_urls?.product_images?.map((item, ind) => {
  //         productImagesArr.push({
  //           image: item,
  //           // is_default: item?.is_default
  //         });

  //         setProductImg(item?.image);
  //       });

  //       // console.log("productImagesArr", productImagesArr);
  //       setSmallImage(productImagesArr);

  //       const colorId = data?.variant_master?.color;
  //       if (data?.variant_master?.color?.length > 0) {
  //         const temp = data?.variant_master?.color?.map(
  //           (item, ind) => item?.name
  //         );
  //         setColorsName(temp);
  //       }
  //       setColorsID(colorId);
  //       data?.image_urls?.color_images?.map((item, ind) => {
  //         let finder = data?.variant_master?.color?.find(
  //           (col) => col?.id == item?.color_id
  //         );
  //         colorsDataArr.push({
  //           color_id: finder?.id,
  //           id: item?.id,
  //           code: finder?.code,
  //           // hex_code: finder?.code,
  //           name: finder?.name,
  //           image: item?.image,
  //           // status: finder?.status,
  //           // description: item?.description,
  //           image_url: item?.image,
  //         });
  //       });
  //       setColorsData(colorsDataArr);
  //       const styletemp = [];

  //       if (data?.variant_master?.size?.length > 0) {
  //         data?.variant_master?.size?.map((item, id) => {
  //           if (item !== "null") {
  //             styletemp.push({ value: item });
  //           }
  //         });
  //       }
  //       setSizes(styletemp);
  //       const sizeTemp = [];
  //       if (data?.variant_master?.style?.length > 0) {
  //         data?.variant_master?.style?.map((item, id) => {
  //           if (item !== "null") {
  //             sizeTemp.push({ value: item });
  //           }
  //         });
  //       }
  //       // console.log("sizeTemp", sizeTemp);
  //       setStyles(sizeTemp);
  //       const mattemp = [];
  //       if (data?.variant_master?.material?.length > 0) {
  //         data?.variant_master?.material?.map((item) => {
  //           if (item !== "null") {
  //             mattemp.push({ value: item });
  //           }
  //         });
  //       }

  //       setMeterials(mattemp);
  //       const price = data?.price;
  //       //  setCost(price?.cost)
  //       //  setNet(price?.net)
  //       if (JSON.stringify(price)[0] === "{") {
  //         setCost(price?.cost);

  //         setNet(price?.net);
  //         setDistributorPrice(price?.final_price);
  //         setMargin({
  //           for_25: price?.margin?.for_25 == "null" ? 0 : price?.margin?.for_25,
  //           for_50: price?.margin?.for_50 == "null" ? 0 : price?.margin?.for_50,
  //           for_100:
  //             price?.margin?.for_100 == "null" ? 0 : price?.margin?.for_100,
  //           for_150:
  //             price?.margin?.for_150 == "null" ? 0 : price?.margin?.for_150,
  //           for_250:
  //             price?.margin?.for_250 == "null" ? 0 : price?.margin?.for_250,
  //           for_500:
  //             price?.margin?.for_500 == "null" ? 0 : price?.margin?.for_500,
  //           for_1000:
  //             price?.margin?.for_1000 == "null" ? 0 : price?.margin?.for_1000,
  //         });

  //         const chagre = [];
  //         price?.charges?.map((item) => {
  //           chagre.push({
  //             name: item?.name,
  //             for_25: item?.for_25 == "null" ? 0 : item?.for_25,
  //             for_50: item?.for_50 == "null" ? 0 : item?.for_50,
  //             for_100: item?.for_100 == "null" ? 0 : item?.for_100,
  //             for_150: item?.for_150 == "null" ? 0 : item?.for_150,
  //             for_250: item?.for_250 == "null" ? 0 : item?.for_250,
  //             for_500: item?.for_500 == "null" ? 0 : item?.for_500,
  //             for_1000: item?.for_1000 == "null" ? 0 : item?.for_1000,
  //           });
  //         });
  //         setPriceCharge(chagre);
  //       }
  //     })

  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const getViewProduct = () => {
    getViewApi(id)
      .unwrap()
      .then((res) => {
        // console.log(res?.product_request)
        const data = res?.product_request;
        setViewData(data);
        const productImagesArr = [];
        let DecorationName = [];
        let DecorationID = [];
        let productAttributesName = [];
        let productAttributesID = [];

        let subCategoryListArr = [];
        let colorsDataArr = [];
        let categorys = [];
        let cat = {};

        data?.category?.parentCategory?.map((item, ind) => {
          if (item?.icon) {
            setCatogory(item?.name);
          }
        });

        const items = [];
        data?.category?.parentCategory?.map((item, ind) => {
          if (item?.code) {
            setProductSuplierCode(item?.code);
          }

          if (item?.icon) {
            cat.status = item?.status;
            cat.icon = item?.icon;
            cat.id = item?.id;
            cat.name = item?.name;
            items.push(item);
            setCategoryList(items);
          } else {
            categorys.push(item);
          }
        });

        categorys.push({
          icon: data?.category?.icon,
          id: data?.category?.id,
          name: data?.category?.name,
          status: data?.category?.status,
        });

        categorys?.map((item, ind) => {
          let obj = { ...item };
          if (ind == 0) {
            obj.category = cat;
          } else {
            obj.category = categorys[ind - 1];
          }
          subCategoryListArr.push(obj);
        });

        let catArrs = data?.category?.parentCategory;

        setPrimaryCategoryId(catArrs?.[0]?.id);
        setSecondaryCategoryId(catArrs?.[1]?.id);
        setCategoryID(data?.category?.id);

        subCategoryListArr?.map((item, ind) => {
          setCatogoryName((val) => ({
            ...val,
            [item?.id]: item?.name,
          }));
        });

        subCategoryListArr?.map((item, ind) => {
          setCatogoryName((val) => ({
            ...val,
            [item?.category?.id]: item?.name,
          }));
        });

        const name = data?.decorative_methods?.map((item) => item?.name);
        const id = data?.decorative_methods?.map((item) => item?.id);

        setDecorativeID(id);
        setDecorativeName(name);

        data?.attributes?.map((item, ind) => {
          let id = item?.id;
          productAttributesName.push(item?.name);
          productAttributesID.push({
            id: item?.json?.id,

            title: item?.name,
            value: item?.json?.value,
          });
          setOtherProInfo(id);
        });

        setProductInformation(productAttributesID);
        setDynamicAttr(productAttributesName);
        setCountry(data?.country_origin);
        setProductName(data?.name);
        setProductCode(data?.code);
        setDescription(data?.description);
        setHsnCode(data?.hsn?.id);
        setHsnName(data?.hsn?.code);
        setPackingSize(data?.package_size);
        setSubCategoryList(subCategoryListArr);
        setGendor(data?.product_for);
        setSizeVarient("new");
        setColorVarient("new");
        setMatVarient("new");
        setStyleVarient("new");
        data?.image_urls?.product_images?.map((item, ind) => {
          productImagesArr.push({
            image: item,
          });

          setProductImg(item?.image);
        });
        setSmallImage(productImagesArr);

        const colorId = data?.variant_master?.color;

        if (data?.variant_master?.color?.length > 0) {
          const temp = data?.variant_master?.color?.map(
            (item, ind) => item?.name
          );
          setColorsName(temp);
        }
        // data?.variant_master?.color?.length>0 &&
        setColorsID(colorId);
        data?.image_urls?.color_images?.map((item, ind) => {
          let finder = data?.variant_master?.color?.find(
            (col) => col?.id == item?.color_id
          );
          colorsDataArr.push({
            color_id: finder?.id,
            id: item?.id,
            code: finder?.code,
            // hex_code: finder?.hex_code,
            name: finder?.name,
            image: item?.image,
            // status: finder?.status,
            // description: item?.description,
            image_url: item?.image,
          });
        });
        setColorsData(colorsDataArr);
        const styletemp = [];

        if (data?.variant_master?.size?.length > 0) {
          data?.variant_master?.size?.map((item, id) => {
            if (item !== "null") {
              styletemp.push({ value: item });
            }
          });
        }
        setSizes(styletemp);
        const sizeTemp = [];
        if (data?.variant_master?.style?.length > 0) {
          data?.variant_master?.style?.map((item, id) => {
            if (item !== "null") {
              sizeTemp.push({ value: item });
            }
          });
        }
        // console.log("sizeTemp", sizeTemp);
        setStyles(sizeTemp);
        const mattemp = [];
        if (data?.variant_master?.material?.length > 0) {
          data?.variant_master?.material?.map((item) => {
            if (item !== "null") {
              mattemp.push({ value: item });
            }
          });
        }

        setMeterials(mattemp);

        const price = data?.price;

        if (JSON.stringify(price)[0] === "{") {
          setCost(price?.cost);

          setNet(price?.net);
          setDistributorPrice(price?.final_price);
          setMargin({
            for_25: price?.margin?.for_25 == "null" ? 0 : price?.margin?.for_25,
            for_50: price?.margin?.for_50 == "null" ? 0 : price?.margin?.for_50,
            for_100:
              price?.margin?.for_100 == "null" ? 0 : price?.margin?.for_100,
            for_150:
              price?.margin?.for_150 == "null" ? 0 : price?.margin?.for_150,
            for_250:
              price?.margin?.for_250 == "null" ? 0 : price?.margin?.for_250,
            for_500:
              price?.margin?.for_500 == "null" ? 0 : price?.margin?.for_500,
            for_1000:
              price?.margin?.for_1000 == "null" ? 0 : price?.margin?.for_1000,
          });

          const chagre = [];
          price?.charges?.map((item) => {
            chagre.push({
              name: item?.name,
              for_25: item?.for_25 == "null" ? 0 : item?.for_25,
              for_50: item?.for_50 == "null" ? 0 : item?.for_50,
              for_100: item?.for_100 == "null" ? 0 : item?.for_100,
              for_150: item?.for_150 == "null" ? 0 : item?.for_150,
              for_250: item?.for_250 == "null" ? 0 : item?.for_250,
              for_500: item?.for_500 == "null" ? 0 : item?.for_500,
              for_1000: item?.for_1000 == "null" ? 0 : item?.for_1000,
            });
          });
          setPriceCharge(chagre);
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (type == "edit") {
      getViewProduct();
    }
  }, []);
  // console.log("subCategoryList",colorVarient);

  return (
    <div className="dashRightView overflow-scroll w-100">
      <ProductSearchHeader />
      <p
        className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 d-flex align-items-center gap-1"
        role={"button"}
        onClick={() =>
          navigat("/vendor-details", { state: { tab: 7, productTab: 2 } })
        }
      >
        <KeyboardBackspaceIcon />
        {type == "edit" ? "Edit New Request" : "Create New Request"}
      </p>
      {/* <div className='d-flex as-jb w-100 mt-5 res-flex gap-5'> */}
      <RequestFormComp
        productInformationErr={productInformationErr}
        addVarients={addVarients}
        setAddVarients={setAddVarients}
        colorVarientErr={colorVarientErr}
        setColorVarientErr={setColorVarientErr}
        sizeVarientErr={sizeVarientErr}
        setSizeVarientErr={setSizeVarientErr}
        styleVarientErr={styleVarientErr}
        setStyleVarientErr={setStyleVarientErr}
        matVarientErr={matVarientErr}
        setMatVarientErr={setMatVarientErr}
        colorVarient={colorVarient}
        sizeVarient={sizeVarient}
        matVarient={matVarient}
        styleVarient={styleVarient}
        attributesName={attributesName}
        setAttributesName={setAttributesName}
        addVarientErr={addVarientErr}
        setColorVarient={setColorVarient}
        setMatVarient={setMatVarient}
        setSizeVarient={setSizeVarient}
        setStyleVarient={setStyleVarient}
        setdefaultImage={setdefaultImage}
        defaultImage={defaultImage}
        otherProInfoErr={otherProInfoErr}
        otherProInfo={otherProInfo}
        setOtherProInfo={setOtherProInfo}
        gendor={gendor}
        setGendor={setGendor}
        gendorErr={gendorErr}
        type={type}
        viewData={viewData}
        categoryList={categoryList}
        parentCategoryList={parentCategoryList}
        primaryCategoryId={primaryCategoryId}
        primaryCategoryIdErr={primaryCategoryIdErr}
        categoryIDErr={categoryIDErr}
        setParentCategoryList={setParentCategoryList}
        getCategoryId={getCategoryId}
        sizes={sizes}
        productCode={productCode}
        onSubmit={onSubmit}
        secondaryCategoryIdErr={secondaryCategoryIdErr}
        categoryID={categoryID}
        setSizes={setSizes}
        materials={materials}
        setMeterials={setMeterials}
        styles={styles}
        setStyles={setStyles}
        setProductCode={setProductCode}
        productNameErr={productNameErr}
        smallimageErr={smallimageErr}
        subCategoryList={subCategoryList}
        catogoryName={catogoryName}
        setCatogory={setCatogory}
        catogory={catogory}
        subCategoryLists={subCategoryLists}
        productInformation={productInformation}
        setProductInformation={setProductInformation}
        packingSize={packingSize}
        setSubCategoryList={setSubCategoryList}
        searchValueSubCategory={searchValueSubCategory}
        colorsData={colorsData}
        setColorsData={setColorsData}
        productCodeErr={productCodeErr}
        countryErr={countryErr}
        colorImg={colorsImg}
        setColorsImg={setColorsImg}
        colorImgErr={colorImgErr}
        setColorImgErr={setColorImgErr}
        setPrimaryCategoryId={setPrimaryCategoryId}
        setPackingSize={setPackingSize}
        setSearchSubValueCategory={setSearchSubValueCategory}
        onSubCategorySelect={onSubCategorySelect}
        secondaryCategoryId={secondaryCategoryId}
        descriptionErr={descriptionErr}
        setProductSuplierCode={setProductSuplierCode}
        productSuplierCode={productSuplierCode}
        setCatogoryName={setCatogoryName}
        decorativeNameErr={decorativeNameErr}
        setProductName={setProductName}
        productName={productName}
        setCountry={setCountry}
        country={country}
        decorList={decorList}
        setDescription={setDescription}
        description={description}
        setDecorativeID={setDecorativeID}
        setHsnList={setHsnList}
        hsnList={hsnList}
        setHsnCode={setHsnCode}
        hsnCode={hsnCode}
        setHsnName={setHsnName}
        hsnName={hsnName}
        productImg={productImg}
        decorativeID={decorativeID}
        setDecorativeName={setDecorativeName}
        decorativeName={decorativeName}
        setDecorationMethod={setDecorationMethod}
        listAttributes={listAttributes}
        setListAttributes={setListAttributes}
        dynamicAttr={dynamicAttr}
        setDynamicAttr={setDynamicAttr}
        attributesList={attributesList}
        colorsName={colorsName}
        hsnErr={hsnErr}
        packingSizeErr={packingSizeErr}
        decorationMethod={decorationMethod}
        setSmallImage={setSmallImage}
        smallimage={smallimage}
        setProductImg={setProductImg}
        colorsList={colorsList}
        colorsID={colorsID}
        setColorsID={setColorsID}
        setColorsName={setColorsName}
        distributorPriceErr={distributorPriceErr}
      />
      <div className="">
        <RequestPricing
          distributorPriceErr={distributorPriceErr}
          types={""}
          edit={edit}
          setEdit={setEdit}
          setpriceBook={setpriceBook}
          setPreviousOrder={setPreviousOrder}
          setCost={setCost}
          cost={cost}
          setNet={setNet}
          net={net}
          setMargin={setMargin}
          margin={margin}
          setDistributorPrice={setDistributorPrice}
          distributorPrice={distributorPrice}
          setPriceCharge={setPriceCharge}
          priceCharge={priceCharge}
          setDisabled={setDisabled}
          disabled={disabled}
          setMarginBox={setMarginBox}
          marginBox={marginBox}
          addpriceCharge={addpriceCharge}
          removepriceCharge={removepriceCharge}
          handlepriceCharge={handlepriceCharge}
          handleCost={handleCost}
          handleMargin={handleMargin}
          handleCloseMargin={handleCloseMargin}
        />
        {/* <PricingCompNew edit={true} /> */}
      </div>
      <div className="d-flex ac-jb w-75">
        <button
          className="cust-btn addbtn mt-sm-3 align-selfe-center mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          onClick={() => navigat("/vendor-details")}
        >
          Back
        </button>
        <button
          className={`cust-btn addbtn mt-sm-3 align-selfe-center mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            btn && "opacity-50"
          }`}
          // onClick={() => navigat("/vendor-details")}
          onClick={() => onSubmit()}
          disabled={btn ? true : false}
        >
          Save
        </button>
      </div>
      {/* </div> */}
    </div>
  );
};

export default CreateNewRequestScreen;
