import { useEffect, useRef, useState } from "react";
import FolderIcon from "@mui/icons-material/Folder";
import UploadLibraryImage from "./UploadLibraryImage";
import DeleteIcon from "@mui/icons-material/Delete";
import { dummyImages } from "../../redux/api/DummyJson";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import UploadIcon from "@mui/icons-material/Upload";
import { useVendorLibraryCreateMutation } from "../../redux/api/api";
import { fileDocIcon, uploadIcon } from "../../assets/img";
import ErrorIcon from "@mui/icons-material/Error";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../redux/slice/loaderNoti";

const LibraryDetails = ({ vendorID }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileRef = useRef();
  const [productImg, setProductImg] = useState(false);
  const [vendorImg, setVendorImg] = useState(false);
  const [merchImg, setMerchImg] = useState(false);
  const [folderList, setFolderList] = useState([]);
  const [folderName, setFolderName] = useState("");
  const [editFoler, setEditFolder] = useState(false);

  const [folderIndex, setFolderIndex] = useState("");
  const [selectIndex, setSelectIndex] = useState("");
  const [selectedFolder, setSelectedFolder] = useState("");

  const [image, setImage] = useState("");
  const [imageErr, setImageErr] = useState(false);
  const [imageTitle, setImageTitle] = useState("");
  const [imageTitleErr, setImageTitleErr] = useState(false);
  const [types, setTypes] = useState("");
  const [typesErr, setTypesErr] = useState(false);
  const [btn, setBtn] = useState(false);

  // RTK QUERY
  const [vendorLibarayCreateApi] = useVendorLibraryCreateMutation();

  const handleFolders = () => {
    setFolderList([...folderList, folderName]);
    setFolderName("");
    // setEditFolder(false);
    setMerchImg(false);
  };

  useEffect(() => {
    setVendorImg(true);
  }, []);

  const handleUpdate = () => {
    const array = [...folderList];
    const ind = array.indexOf(folderIndex);
    if (ind !== -1) {
      array[ind] = folderName;
    }
    // array.splice(folderIndex, 1, folderName);
    setFolderList([...array]);
    // setFolderList([...folderList, folderName]);
    setEditFolder(!editFoler);
    setMerchImg(false);
    setFolderName("");
  };

  // VENDOR LIBRARY CREATE
  const createLibaray = () => {
    if (image?.length == 0 || imageTitle?.length == 0 || types?.length == 0) {
      setImageErr(true);
      setImageTitleErr(true);
      setTypesErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("vendor_id", vendorID);
      formdata.append("file", image);
      formdata.append("file_title", imageTitle);
      formdata.append("is_product_image", types);
      formdata.append("is_admin_image", 2);
      setBtn(true);
      dispatch(saveLoader(true));
      vendorLibarayCreateApi(formdata)
        .unwrap()
        .then((res) => {
          setBtn(false);
          dispatch(saveLoader(false));
          if (types == 0) {
            navigate("/view-library-list", {
              state: { vendorID: vendorID, type: types },
            });
          } else if (types == 1) {
            navigate("/view-library-list", {
              state: { vendorID: vendorID, type: types },
            });
          } else {
            window.location.reload();
          }
        })
        .catch((err) => {
          setBtn(false);
          dispatch(saveLoader(false));
          console.log("err", err);
        });
    }
  };

  return (
    <div className="w-100 mt-5">
      <div className="w-100 d-flex ac-jb">
        <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
          Library
        </p>
        {/* <button
          className="cust-btn addbtn2 mt-sm-3 mx-3 mt-2 gray f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
        >
          <UploadIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
          Sample Download
        </button> */}
      </div>
      <p className="w-100 f2 mt-4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-16 primary1">
        Click on an image to download it, and on a file name to edit it. You can
        also create folders, and drag artwork into them.
      </p>
      <div className="w-100 d-xxl-flex d-xl-flex d-lg-flex d-column">
        <div className="mt-5 w-100">
          <div className="w-100  d-flex">
            <div className="w-60 d-flex ac-jb">
              <div
                onClick={() => {
                  navigate("/view-library-list", {
                    state: { vendorID: vendorID, type: 1 },
                  });
                }}
                className="pointerView d-flex"
              >
                <FolderIcon className="ms-1 primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25" />
                <p className="w-80 ms-3 f2 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-16 black">
                  Product Library
                </p>
              </div>
            </div>
          </div>
          <div className="w-100 d-flex mt-3">
            <div className="w-60 d-flex pointerView ac-jb ">
              <div
                onClick={() => {
                  navigate("/view-library-list", {
                    state: { vendorID: vendorID, type: 0 },
                  });
                }}
                className="d-flex "
              >
                <FolderIcon className="ms-1 primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25" />

                <p className="w-80 ms-3 f2 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-16 black">
                  Vendor Library
                </p>
              </div>

              <FileUploadIcon
                onClick={() => {
                  setProductImg(false);
                  setVendorImg(true);
                  setMerchImg(false);
                  setSelectedFolder("");
                }}
                className="pointerView ms-1 primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25"
              />
            </div>
          </div>
          {folderList?.map((item, index) => {
            return (
              <div className="d-flex mt-3 ac-jb w-100">
                <div className="d-flex pointerView ac-js w-50">
                  <div
                    onClick={() => {
                      navigate("/view-library-list", {
                        state: { name: item },
                      });
                    }}
                    className=" d-flex"
                  >
                    <FolderIcon className="ms-1 primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25" />
                    {!editFoler && (
                      <p className="w-60 ms-3 f2 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-16 black">
                        {item}
                      </p>
                    )}
                    {selectIndex !== index && editFoler && (
                      <p className="w-60 ms-3 f2 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-16 black">
                        {item}
                      </p>
                    )}
                  </div>

                  {selectIndex === index && editFoler && (
                    <input
                      onChange={(e) => {
                        setFolderIndex(item);
                        setFolderName(e.target.value);
                      }}
                      placeholder={item}
                      className="editBtn rounded-3 ms-3 p-1"
                    />
                  )}
                </div>
                <div className="d-flex w-50">
                  <FileUploadIcon
                    onClick={() => {
                      setProductImg(false);
                      setVendorImg(false);
                      setSelectedFolder(item);
                    }}
                    className="pointerView ms-1 primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25"
                  />
                  <EditIcon
                    onClick={() => {
                      setProductImg(false);
                      setVendorImg(false);
                      setEditFolder(true);
                      setSelectIndex(index);
                    }}
                    className="pointerView primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25"
                  />
                  <DeleteIcon className="pointerView ms-1 primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25" />
                </div>
              </div>
            );
          })}
          <div
            onClick={() => {
              setProductImg(false);
              setVendorImg(false);
              setMerchImg(true);
            }}
            className="pointerView d-flex mt-3 ac-js"
          >
            {/* {!merchImg && (
              <FolderIcon className=" ms-1 primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25" />
            )} */}
            {/* {!merchImg && (
              <p className="w-80 ms-3 f2 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-16 black">
                +Add Library
              </p>
            )} */}
            {merchImg && (
              <div className="w-100">
                <FolderIcon className=" ms-1 primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25" />

                <input
                  onChange={(e) => {
                    setFolderName(e.target.value);
                  }}
                  value={folderName}
                  className="editBtn rounded-3 ms-3 p-1"
                />
                <div className="w-60 mt-4 d-flex ac-jc">
                  {/* <button
                    onClick={() => setMerchImg(false)}
                    className="cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
                  >
                    Cancel
                  </button> */}
                  {!editFoler && (
                    <button
                      onClick={() => {
                        handleFolders();
                      }}
                      className="cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded "
                    >
                      Add
                    </button>
                  )}
                  {editFoler && (
                    <button
                      onClick={() => {
                        handleUpdate();
                      }}
                      className="cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
                    >
                      Update
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
          <p className="w-90 mt-3 f2 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-13 primary1">
            To create a new folder, simply click the "Add Folder" option.
          </p>
        </div>

        <div className="w-100 ms-xxl-5">
          <p className="w-100 f2 mt-4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-16 gray">
            Add Image to
            <span className="f2 mt-4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-16 text-black">
              {types == 1 ? " Product" : types == 0 ? " Vendor" : ""}
            </span>
          </p>
          <div>
            {image === "" ? (
              <div className="pointerView w-100 mt-4">
                <div
                  onClick={() => fileRef.current.click()}
                  className="w-100 w-md-100 d-flex ac-jc"
                >
                  <img src={uploadIcon} className="uploadImgLib" />
                </div>
                <div className="w-100 w-md-100 d-flex ac-jc">
                  <p className="black w-100 f2 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14 mt-1 d-flex ac-jc">
                    Drag and drop to upload or Click
                  </p>
                </div>
                <div className="w-100 w-md-100 d-flex ac-jc">
                  <p className="black w-100 f2 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14 mt-1 d-flex ac-jc">
                    here to upload
                  </p>
                </div>
              </div>
            ) : (
              <div className="w-100 w-md-100  mt-4 d-flex ac-jb">
                <div
                  onClick={() => fileRef.current.click()}
                  className="w-100  w-md-100 d-flex ac-jc"
                >
                  <img
                    src={
                      image?.name
                        ? image?.type === "application/pdf"
                          ? fileDocIcon
                          : URL.createObjectURL(image)
                        : image
                    }
                    className="uploadImgLib"
                  />
                  {/* <div className="w-20">
                    {image?.type === "image/png" ||
                    image?.type === "image/jpeg" ||
                    image?.type === "image/jpg" ? (
                      <img
                        src={window.URL.createObjectURL(image)}
                        className="uploadImgLib"
                      />
                    ) : null}
                    {image?.type === "application/pdf" ||
                    image?.type ===
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                    image?.type === "application/doc" ||
                    image?.type === "application/msword" ? (
                      <iframe
                        src={window.URL.createObjectURL(image)}
                        className="uploadImgLib"
                      />
                    ) : null}
                  </div> */}
                  <p className="f2 w-100 w-md-100 fs-xs-13 ms-2 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-16 primary1">
                    {image?.name}
                  </p>
                </div>
                <div className="w-20 ac-jc d-flex">
                  <DeleteIcon
                    onClick={() => setImage("")}
                    className="pointerView ms-1 primary1 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20"
                  />
                </div>
              </div>
            )}
            {image?.length == 0 && imageErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Uplodad Image</p>
              </div>
            )}
          </div>

          <div className="w-100 mt-4">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Image Title
            </p>
            <input
              placeholder="Enter Image Title"
              value={imageTitle}
              onChange={(e) => setImageTitle(e.target.value)}
              className="editBtn rounded-3 mt-3 p-2 w-100 w-md-100 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
            {imageTitle?.length == 0 && imageTitleErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Image Title</p>
              </div>
            )}
          </div>
          <div className="w-100 mt-4">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Type
            </p>
            <select
              onChange={(e) => setTypes(e.target.value)}
              value={types}
              className="w-100 editBtnSelect mt-3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3"
            >
              <option value={""} disabled>
                Select Type
              </option>
              <option value={1}>Product Library</option>
              <option value={0}>Vendor Library</option>
            </select>
            {types?.length == 0 && typesErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Select Type</p>
              </div>
            )}
          </div>
          <div className="w-100 d-flex ac-jc gap-3 w-md-100">
            {image === "" && (
              <button
                onClick={() => fileRef.current.click()}
                className=" cust-btn addbtn mt-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
              >
                Upload Image
              </button>
            )}

            {image !== "" && (
              <button
                // onClick={() => {
                //   if (type === "vendor") {
                //     navigate("/view-library-list", {
                //       state: { name: "Vendor" },
                //     });
                //   } else {
                //     navigate("/view-library-list", {
                //       state: { name: "Product" },
                //     });
                //   }
                // }}
                onClick={() => createLibaray()}
                disabled={btn}
                className={`
                cust-btn addbtn mt-sm-4 ms-1 mt-4 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                  btn && "opacity-50"
                }`}
              >
                Submit
              </button>
            )}
          </div>

          <input
            type="file"
            accept=".pdf,.doc,.jpeg,.docx,.png,.jpg"
            onChange={(e) => setImage(e.target.files[0])}
            className="d-none"
            ref={fileRef}
          />
        </div>
      </div>
    </div>
  );
};

export default LibraryDetails;
