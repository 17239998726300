import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, URL } from "./constants";
import { getToken } from "../store/userTocken";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: async (headers) => {
      const token = localStorage.getItem("token");
      headers.set("Authorization", "Bearer " + token);
      //   headers.set("Content-Type", "multipart/form-data");
      //   headers.set("Content-Type", "application/json");
      //   headers.set("Accept", "application/json");
    },
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: [],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (payload) => ({
        url: URL.LOGIN,
        method: "POST",
        body: payload,
        headers: {},
      }),
    }),
    vendor_category: builder.query({
      query: () => ({
        url: URL.VENDOR_CATEGORY,
      }),
    }),
    register: builder.mutation({
      query: (payload) => ({
        url: URL.REGISTER,
        method: "POST",
        body: payload,
      }),
    }),
    register_view: builder.query({
      query: (id) => ({
        url: URL.REGISTER_VIEW + id,
      }),
    }),
    verification: builder.query({
      query: (token) => ({
        url: URL.VERIFICATION + token,
      }),
    }),
    //PROFILE
    profile_list: builder.query({
      query: (id) => ({
        url: URL.PROFILE_LIST + id,
      }),
    }),

    //PROFILE
    profileList: builder.mutation({
      query: ({ params, payload }) => ({
        url: URL.USER_LIST + params,
        method: "POST",
        body: payload,
      }),
    }),

    profile_status: builder.query({
      query: (id) => ({
        url: URL.STATUS_LIST + id,
      }),
    }),
    profile_view: builder.query({
      query: (id) => ({
        url: URL.PROFILE_VIEW + id,
      }),
    }),
    addProfile: builder.mutation({
      query: (payload) => ({
        url: URL.ADD_PROFILE,
        method: "POST",
        body: payload,
      }),
    }),
    editProfile: builder.mutation({
      query: (payload) => ({
        url: URL.EDIT_PROFILE,
        method: "POST",
        body: payload,
      }),
    }),
    roleList: builder.mutation({
      query: (payload) => ({
        url: URL.ROLE_LIST,
        method: "POST",
        body: payload,
      }),
    }),

    roleActiveList: builder.mutation({
      query: (payload) => ({
        url: URL.ROLE_ACTIVE_LIST,
        method: "POST",
        body: payload,
      }),
    }),

    roleLists: builder.mutation({
      query: ({ params, payload }) => ({
        url: URL.ROLE_ACTIVE_LIST + params,
        body: payload,
        method: "POST",
      }),
    }),

    roleview: builder.query({
      query: (id) => ({
        url: URL.ROLE_VIEW + id,
      }),
    }),

    roleModule: builder.query({
      query: () => URL.ROLE_CREATE,
    }),

    // CATERGORY LIST VENDOR
    categoriesListVendor: builder.query({
      query: () => URL.VENDOR_PARENT_CATEGORIES,
    }),

    // FORGOT PASSWORD
    forgotPassword: builder.mutation({
      query: (payload) => ({
        url: URL.FORGOT_PASSWORD,
        method: "POST",
        body: payload,
      }),
    }),

    // RESET PASSWORD
    resetPassword: builder.mutation({
      query: (payload) => ({
        url: URL.RESET_PASSWORD,
        method: "POST",
        body: payload,
      }),
    }),

    // CURRENCY LIST
    currencyList: builder.query({
      query: () => URL.CURRENCY,
    }),

    // VENDOR VIEW
    vendorView: builder.query({
      query: (id) => URL.VENDOR_VIEW + id,
    }),

    vendorViewNew: builder.query({
      query: (id) => URL.VENDOR_VIEW_NEW + id,
    }),

    // VENDOR EDIT
    vendorEdit: builder.mutation({
      query: (payload) => ({
        url: URL.VENDOR_EDIT,
        method: "POST",
        body: payload,
      }),
    }),

    // VENDOR DEPARTMENT
    vendorDepartmentList: builder.mutation({
      query: (payload) => ({
        url: URL.VENDOR_DEPARTMENT,
        method: "POST",
        body: payload,
      }),
    }),

    // DELETE VENDOR LOGO
    vendorLogoDelete: builder.mutation({
      query: (payload) => ({
        url: URL.VENDOR_LOGO_DELETE,
        method: "POST",
        body: payload,
      }),
    }),

    // TAGS LIST
    tagsList: builder.mutation({
      query: (payload) => ({
        url: URL.TAGS_LIST,
        method: "POST",
        body: payload,
      }),
    }),

    // ADDRESS LIST
    addressList: builder.query({
      query: (id) => URL.ADDRESS_LIST + id,
    }),

    // ADDRESS ADD
    addressAdd: builder.mutation({
      query: (payload) => ({
        url: URL.ADDRESS_ADD,
        method: "POST",
        body: payload,
      }),
    }),

    // ADDRESS EDIT
    addressEdit: builder.mutation({
      query: (payload) => ({
        url: URL.ADDRESS_EDIT,
        method: "POST",
        body: payload,
      }),
    }),

    // ADDRESS STATUS
    addressStatus: builder.query({
      query: (id) => URL.ADDRESS_STATUS + id,
    }),

    // VENDOR POSITION CHANGE
    vendorPositionChange: builder.mutation({
      query: (payload) => ({
        url: URL.VENDOR_POSITION_CHANGE,
        method: "POST",
        body: payload,
      }),
    }),

    //PRODUCTS_BULK STATUS CHANGE
    productsBulkStatusChange: builder.mutation({
      query: (payload) => ({
        url: URL.PRODUCTS_BULK_STATUS_CHANGE,
        method: "POST",
        body: payload,
      }),
    }),

    //PRODUCTSUGGESTION
    productSuggestionList: builder.mutation({
      query: (payload) => ({
        url: URL.PRODUCT_SUGGESTION_LIST,
        method: "POST",
        body: payload,
      }),
    }),

    productSuggestionStore: builder.mutation({
      query: (payload) => ({
        url: URL.PRODUCT_SUGGESTION_ADD,
        method: "POST",
        body: payload,
      }),
    }),

    productSuggestionEdit: builder.mutation({
      query: (payload) => ({
        url: URL.PRODUCT_SUGGESTION_EDIT,
        method: "POST",
        body: payload,
      }),
    }),

    productSuggestionView: builder.query({
      query: (id) => URL.PRODUCT_SUGGESTION_VIEW + id,
    }),

    // VENDOR LIBRARY CREATE
    vendorLibraryCreate: builder.mutation({
      query: (payload) => ({
        url: URL.VENDOR_LIBRARY,
        method: "POST",
        body: payload,
      }),
    }),

    // VENDOR LIBRARY LIS
    vendorLibraryList: builder.mutation({
      query: (payload) => ({
        url: URL.VENDOR_LIBRARY_LIST,
        method: "POST",
        body: payload,
      }),
    }),

    // PROFILE NOTE CONTACT
    vendorProfileContact: builder.mutation({
      query: (payload) => ({
        url: URL.VENDOR_PROFILE_CONTACT,
        method: "POST",
        body: payload,
      }),
    }),

    // PROFILE NOTE REMIND
    vendorProfileRemind: builder.mutation({
      query: (payload) => ({
        url: URL.VENDOR_PROFILE_REMIND,
        method: "POST",
        body: payload,
      }),
    }),

    //THREAD LIST
    threadList: builder.query({
      query: (id) => URL.VENDOR_THREAD_LIST + id,
    }),

    // PROFILE NOTE REMIND
    vendorLibraryEdit: builder.mutation({
      query: (payload) => ({
        url: URL.VENDOR_LIBRARY_EDIT,
        method: "POST",
        body: payload,
      }),
    }),

    // VENDOR MAP PRODUCTS
    mapProductsLists: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.VENDOR_MAP_PRODUCT_LIST + params,
        method: "POST",
        body: payload,
      }),
    }),

    // VENDOR CATEGORY LIST
    categoryLists: builder.query({
      query: (id) => URL.VENDOR_CATEGORY_LIST + id,
    }),

    // VENDOR MAP PRODUCTS VIEW
    mapProductsListView: builder.query({
      query: (id) => URL.MAP_PRODUCTS_VIEW + id,
    }),

    // VENDOR MAP PRODUCTS PRICE
    mapProductsListPrice: builder.mutation({
      query: (payload) => ({
        url: URL.MAP_PRODUCTS_PRICE,
        method: "POST",
        body: payload,
      }),
    }),

    // VENDOR MAP ACCEPTED PRODUCTS
    mapProductsAcceptedLists: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.MAP_ACCEPTED_PRODUCTS + params,
        method: "POST",
        body: payload,
      }),
    }),

    // VENDOR SCHEDULE STORE
    vendorScheduleStore: builder.mutation({
      query: (payload) => ({
        url: URL.VENDOR_SCHEDULE_STORE,
        method: "POST",
        body: payload,
      }),
    }),

    // VENDOR SCHEDULE LIST
    vendorScheduleList: builder.mutation({
      query: (payload) => ({
        url: URL.VENDOR_SCHEDULE_LIST,
        method: "POST",
        body: payload,
      }),
    }),

    // VENDOR SCHEDULE THREADS
    vendorScheduleThreads: builder.query({
      query: (id) => URL.VENDOR_SCHEDULE_THREADS + id,
    }),
    //SUB CATEGORY
    subCategory: builder.query({
      query: (id) => URL.SUB_CATEGORY + id,
    }),

    // DECORATIVE LIST
    decorativeList: builder.mutation({
      query: (payload) => ({
        url: URL.DECORATIVE_DROPDOWN_LIST,
        method: "POST",
        body: payload,
      }),
    }),

    // HSN LIST
    hsnList: builder.mutation({
      query: () => ({
        url: URL.HSN_LIST,
        method: "GET",
        // body: payload,
      }),
    }),

    //COLOR LIST
    colorList: builder.query({
      query: () => URL.COLOR_LIST,
    }),
    //ATTRIBUTES
    attributesList: builder.query({
      query: () => URL.ATTRIBUTES_LIST,
    }),
    // store LIST
    storeRequest: builder.mutation({
      query: (payload) => ({
        url: URL.PRODUCT_REQUEST_STORE,
        method: "POST",
        body: payload,
      }),
    }),
    // store LIST
    requestList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.PRODUCT_REQUEST_LIST,
        method: "POST",
        body: payload,
      }),
    }),
    // request edit
    requestEdit: builder.mutation({
      query: (payload) => ({
        url: URL.PRODUCT_REQUEST_EDIT,
        method: "POST",
        body: payload,
      }),
    }),
    //ATTRIBUTES
    requestView: builder.query({
      query: (id) => URL.PRODUCT_REQUEST_VIEW + id,
    }),
    //approved EDIT
    approvedEdit: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.PRODUCT_APPROVED_EDIT + id + "/update-price",
        method: "POST",
        body: payload,
      }),
    }),
    //PRICE CHANGE LIST
    priceList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.PRICE_CHANGE_LIST + params,
        method: "POST",
        body: payload,
      }),
    }),
    // PRODUCT VENDOR PRICE REQUEST VIEW
    proVendorPriceReqShow: builder.query({
      query: (id) => URL.PRODUCT_VENDOR_PRICE_REQUEST_VIEW + id,
    }),
    //DECORATIVE METHODE
    decorativeMethodeAdded: builder.mutation({
      query: (payload) => ({
        url: URL.DECORATIVE_ADDED,
        method: "POST",
        body: payload,
      }),
    }),

    decorativeMethodeList: builder.mutation({
      query: ({ payload, params }) => ({
        url: URL.DECORATIVE_LIST + params,
        method: "POST",
        body: payload,
      }),
    }),
    decorativeMethodeStatus: builder.query({
      query: (id) => URL.DECORATIVE_STATUS + id,
    }),
    decorativeMethodeView: builder.query({
      query: (id) => URL.DECORATIVE_VIEW + id,
    }),

    decorativeMethodeViewPost: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.DECORATIVE_VIEW + id,
        method: "POST",
        body: payload,
      }),
    }),

    //MAP PRODUCT VIEWS
    mapProductShow: builder.mutation({
      query: (payload) => ({
        url: URL.MAP_PRODUCT_VIEWS,
        method: "POST",
        body: payload,
      }),
    }),
    decorativedroupdoun: builder.query({
      query: () => URL.DECORATIVE_DROPDOWN 
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useLazyRegister_viewQuery,
  useLazyVerificationQuery,
  useLazyProfile_listQuery,
  useLazyProfile_statusQuery,
  useAddProfileMutation,
  useEditProfileMutation,
  useLazyVendor_categoryQuery,
  useRoleListMutation,
  useLazyProfile_viewQuery,
  useRoleActiveListMutation,
  useLazyRoleviewQuery,
  useLazyRoleModuleQuery,
  useLazyCategoriesListVendorQuery,
  useResetPasswordMutation,
  useForgotPasswordMutation,
  useRoleListsMutation,
  useProfileListMutation,
  useLazyCurrencyListQuery,
  useLazyVendorViewQuery,
  useVendorEditMutation,
  useVendorDepartmentListMutation,
  useLazyAddressListQuery,
  useTagsListMutation,
  useAddressAddMutation,
  useAddressEditMutation,
  useLazyAddressStatusQuery,
  useVendorPositionChangeMutation,
  useProductsBulkStatusChangeMutation,
  useLazyVendorViewNewQuery,
  useVendorLogoDeleteMutation,
  useProductSuggestionEditMutation,
  useProductSuggestionListMutation,
  useProductSuggestionStoreMutation,
  useLazyProductSuggestionViewQuery,
  useVendorLibraryCreateMutation,
  useVendorLibraryListMutation,
  useVendorProfileContactMutation,
  useVendorProfileRemindMutation,
  useLazyThreadListQuery,
  useVendorLibraryEditMutation,
  useLazyCategoryListsQuery,
  useLazyMapProductsListViewQuery,
  useMapProductsListsMutation,
  useMapProductsListPriceMutation,
  useMapProductsAcceptedListsMutation,
  useVendorScheduleStoreMutation,
  useVendorScheduleListMutation,
  useLazyVendorScheduleThreadsQuery,
  useLazySubCategoryQuery,
  useDecorativeListMutation,
  useHsnListMutation,
  useLazyColorListQuery,
  useLazyAttributesListQuery,
  useStoreRequestMutation,
  useRequestListMutation,
  useLazyRequestViewQuery,
  useRequestEditMutation,
  useApprovedEditMutation,
  usePriceListMutation,
  useDecorativeMethodeAddedMutation,
  useDecorativeMethodeListMutation,
  useLazyDecorativeMethodeStatusQuery,
  useLazyDecorativeMethodeViewQuery,
  useDecorativeMethodeViewPostMutation,
  useLazyProVendorPriceReqShowQuery,
  useMapProductShowMutation,
  useLazyDecorativedroupdounQuery
} = api;
