import React, { useEffect, useState } from "react";
import {
  Country,
  Countrys,
  Cstates,
  Vendor_offer_type,
  currency_list,
  vendor_category,
  paymentMethodList,
  lineBusinessList,
  VendoroffertypeList,
  typeBusinessLists,
  termsPayment,
} from "../../redux/api/DummyJson";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ImageCroper from "../Popup/ImageCroper";
import { useLazyRegister_viewQuery } from "../../redux/api/api";
import ErrorIcon from "@mui/icons-material/Error";
import { cityes, currencies } from "../../redux/api/city";
import { cityList, countriesList, stateList } from "../../redux/api/Country";
import { IoIosRadioButtonOff } from "react-icons/io";
import { IoIosRadioButtonOn } from "react-icons/io";

const AddDet3 = ({
  currenciesLists,
  name,
  setname,
  nameErr,
  Tradename,
  setTradename,
  TradenameErr,
  vendorCat,
  setVendorCat,
  vendorCatErr,
  vendorOfferType,
  setVendorOfferType,
  vendorOfferTypeErr,
  paymentTermsErr,
  setPaymentTerms,
  paymentTerms,
  paymentMethod,
  setPaymentMethod,
  paymentMethodErr,
  GstIDErr,
  setGstID,
  GstID,
  setAddress1,
  address1,
  address1Err,
  address2,
  setAddress2,
  address2Err,
  country,
  setCountry,
  countryErr,
  state,
  setState,
  stateErr,
  city,
  setCity,
  cityErr,
  pincode,
  setPincode,
  pincodeErr,
  currency,
  setCurrency,
  setCurrencyName,
  currencyName,
  currencyErr,
  description,
  setDescription,
  descriptionErr,
  password,
  setpassword,
  passwordErr,
  confirmPassword,
  setConfirmPass,
  confirmPasswordErr,
  logo,
  logoErr,
  banner,
  bannerErr,
  setLogo,
  setBanner,
  email,
  firstname,
  mobile,
  keydownHandler,
  lineBusiness,
  setLineBusiness,
  lineBusinessErr,
  setListStates,
  listStates,
  listCities,
  setListCities,
}) => {
  // Drop Down State
  const [dropDownToggle, setDropDownToggle] = useState(undefined);
  const [vendorCatShow, setVendorCatShow] = useState(false);
  const [paymentMethodShow, setPaymentMethodShow] = useState(false);
  const [lineBusinessShow, setLineBusinessShow] = useState(false);
  let [passwordStringerr, setPasswordStringerr] = useState("");

  const [viewApi] = useLazyRegister_viewQuery();

  // Form State
  // const [vendorOfferType, setVendorOfferType] = useState('');
  const [vendorCatogory, setVendorCatogory] = useState("");
  // const [paymentTerms, setPaymentTerms] = useState('');
  // const [paymentMethod, setPaymentMethod] = useState('');
  // const [GstID, setGstID] = useState('');
  // const [address1, setAddress1] = useState('');
  // const [address2, setAddress2] = useState('');
  // const [country, setCountry] = useState('');
  // const [state, setState] = useState('');
  // const [city, setCity] = useState('');
  // const [pincode, setPincode] = useState('');
  // const [currency, setCurrency] = useState('');
  // const [description, setDescription] = useState('');

  const [croper, setCroper] = useState(false);
  const [croperImage, setCropImage] = useState();
  const [croperCol, setCroperCol] = useState(false);
  // const [logo, setLogo] = useState();
  // const [banner, setBanner] = useState();

  // const getview=()=>{
  //   viewApi().
  //   unwrap().
  //   then().
  //   catch()
  // }

  // useEffect(()=>{
  //   getview()
  // },[])

  const [subDepartmentList, setSubDepartmentList] = useState([]);
  const [paymentsMethodList, setPaymentsMethodList] = useState([]);
  const [typeBusinessList, setTypeBusinessList] = useState([]);
  const [Vendorofferty, setVendoroffertypes] = useState([]);
  const [paymentTermsLists, setPaymentsTermsLists] = useState([]);
  const [listCountries, setListCountries] = useState([]);
  const [listCityNew, setListCityNew] = useState([]);
  const [listStateNew, setListStateNew] = useState([]);
  const [listCurrency, setListCurrency] = useState([]);
  const [searchValueStates, setSearchValueStates] = useState("");
  const [searchValueCities, setSearchValueCities] = useState("");
  const [searchValueCurrency, setSearchValueCurrency] = useState("");

  const [projectValue, setProjectValue] = useState([]);
  const [showStatAttributes, setShowStatAttributes] = useState(false);
  const [showSub, setShowSub] = useState(false);
  const [showPayMethod, setShowPayMethod] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchValueSub, setSearchValueSub] = useState("");
  const [searchValueTypeBusiness, setSearchValueTypeBusiness] = useState("");
  const [searchValuePaymethod, setSearchValuePaymethod] = useState("");
  const [searchValueOffering, setSearchValueOffering] = useState("");
  const [searchValueTermsPay, setSearchValueTermsPay] = useState("");
  const [searchValueCountry, setSearchValueCountry] = useState("");
  const [listProjects, setListProjects] = useState([]);
  const [selectALL, setSelectALL] = useState(true);
  const [selectALLSub, setSelectALLSub] = useState(true);
  const [selectALLPay, setSelectALLPay] = useState(true);

  useEffect(() => {
    setSubDepartmentList(lineBusinessList);
    setPaymentsMethodList(paymentMethodList);
    setTypeBusinessList(typeBusinessLists);
    setVendoroffertypes(VendoroffertypeList);
    setPaymentsTermsLists(termsPayment);
    setListCountries(countriesList);
    setListCurrency(currenciesLists);
    setListCityNew(listCities);
    setListStateNew(listStates);
  }, []);

  // Line of Business
  const searchSub = (event) => {
    let temp = [...lineBusinessList];
    let search = temp.filter((value) =>
      value?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setSubDepartmentList(search);
    } else {
      setSubDepartmentList(lineBusinessList);
    }

    setSearchValueSub(event);
  };

  // Line of Business
  const allSelectSub = () => {
    let projectValues = [];
    if (selectALLSub) {
      lineBusinessList?.map((item, ind) => {
        projectValues.push(item);
      });

      setLineBusiness(projectValues);
    } else {
      setLineBusiness(projectValues);
    }
  };

  // Line of Business SELECT ALL
  const onSubSelect = (event, type) => {
    let temp = [...lineBusiness];

    if (temp.includes(event)) {
      const listtemp = temp.indexOf(event);
      const list = [...temp];
      list.splice(listtemp, 1);
      setLineBusiness(list);
    } else {
      setLineBusiness([...temp, event]);
    }
    if (type !== "check") {
      setShowSub(false);
    }
  };

  // Payment Method
  const searchPayMethod = (event) => {
    let temp = [...paymentMethodList];
    let search = temp.filter((value) =>
      value?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setPaymentsMethodList(search);
    } else {
      setPaymentsMethodList(paymentMethodList);
    }

    setSearchValuePaymethod(event);
  };

  // Payment Method ALL SELECT
  const allSelectPay = () => {
    let projectValues = [];
    if (selectALLPay) {
      paymentMethodList?.map((item, ind) => {
        projectValues.push(item);
      });

      setPaymentMethod(projectValues);
    } else {
      setPaymentMethod(projectValues);
    }
  };

  // Payment Method  SELECT
  const onPaySelect = (event) => {
    let temp = [...paymentMethod];

    if (temp.includes(event)) {
      const listtemp = temp.indexOf(event);
      const list = [...temp];
      list.splice(listtemp, 1);
      setPaymentMethod(list);
    } else {
      setPaymentMethod([...temp, event]);
    }
  };

  // Type of Business
  const searchTypeBusiness = (event) => {
    let temp = [...typeBusinessLists];
    let search = temp.filter((value) =>
      value?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setTypeBusinessList(search);
    } else {
      setTypeBusinessList(typeBusinessLists);
    }

    setSearchValueTypeBusiness(event);
  };

  // Vendor Offering Type
  const searchVendorOffering = (event) => {
    let temp = [...VendoroffertypeList];
    let search = temp.filter((value) =>
      value?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setVendoroffertypes(search);
    } else {
      setVendoroffertypes(VendoroffertypeList);
    }

    setSearchValueOffering(event);
  };

  // Payment Terms
  const searchTermsPayment = (event) => {
    let temp = [...termsPayment];
    let search = temp.filter((value) =>
      value?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setPaymentsTermsLists(search);
    } else {
      setPaymentsTermsLists(termsPayment);
    }

    setSearchValueTermsPay(event);
  };

  // Registered Address Country
  const searchCountry = (event) => {
    let temp = [...countriesList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );
    if (event?.length > 0) {
      setListCountries(search);
    } else {
      setListCountries(countriesList);
    }

    setSearchValueCountry(event);
  };

  // Registered Address State
  const searchStates = (event) => {
    let temp = [...listStates];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );
    if (event?.length > 0) {
      setListStateNew(search);
    } else {
      setListStateNew(listStates);
    }

    setSearchValueStates(event);
  };

  // Registered Address City
  const searchCities = (event) => {
    let temp = [...listCities];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );
    if (event?.length > 0) {
      setListCityNew(search);
    } else {
      setListCityNew(listCities);
    }

    setSearchValueCities(event);
  };

  // Currency
  const searchCurrency = (event) => {
    let temp = [...currenciesLists];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );
    if (event?.length > 0) {
      setListCurrency(search);
    } else {
      setListCurrency(currenciesLists);
    }

    setSearchValueCurrency(event);
  };

  // GET COUNTRY BASED STATES
  const getCountries = (item) => {
    let temp = [...countriesList];
    setCountry(item);

    let finder = temp.find((i) => i?.name == item);
    let states = stateList.filter((i) => i?.country_id == finder?.id);
    setListStates(states);
    setListStateNew(states);
    setDropDownToggle(undefined);
    setState("");
    setCity("");
  };

  // GET STATES BASED CITY
  const getStates = (item) => {
    let temp = [...stateList];
    setState(item);
    let finder = temp.find((i) => i?.name == item);
    let city = cityList.filter((i) => i?.state_id == finder?.id);
    setDropDownToggle(undefined);
    setListCities(city);
    setListCityNew(city);
    setCity("");
  };

  const handlePassword = (event) => {
    let new_pass = event.target.value;
    if (new_pass?.length !== 0) {
      setpassword(new_pass);

      // regular expressions to validate password
      var lowerCase = /[a-z]/g;
      var upperCase = /[A-Z]/g;
      var numbers = /[0-9]/g;
      if (!new_pass.match(lowerCase)) {
        setPasswordStringerr("Password should contains lowercase letters!");
      } else if (!new_pass.match(upperCase)) {
        setPasswordStringerr("Password should contain uppercase letters!");
      } else if (!new_pass.match(numbers)) {
        setPasswordStringerr("Password should contains numbers also!");
      } else if (new_pass.length < 8) {
        setPasswordStringerr("Password length should be more than 8.");
      } else {
        // setErrorMessage("Password is strong!");
        setPasswordStringerr("");
      }
    } else {
      setPasswordStringerr("");
      setpassword(new_pass);
    }
  };

  const dualToggele = (type) => {
    if (type == "logo") {
      setCroperCol(false);
      toggleImagePopup();
    } else {
      setCroperCol(true);
      toggleImagePopup();
    }
  };

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  useEffect(() => {
    if (croperCol && croperImage) {
      fetch(croperImage)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "File name", { type: "image/png" });
          setBanner(file);
        });
      setCropImage("");
    } else if (!croperCol && croperImage) {
      // setLogo(croperImage);
      fetch(croperImage)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "File name", { type: "image/png" });
          setLogo(file);
        });
    }
    setCropImage("");
  }, [croperImage]);

  // Function
  const onClickVendorCat = (e) => {
    if (vendorCatogory.includes(e)) {
      const listtemp = vendorCatogory.indexOf(e);
      const list = [...vendorCatogory];
      list.splice(listtemp, 1);
      setVendorCatogory(list);
    } else {
      setVendorCatogory([...vendorCatogory, e]);
    }
  };
  const onClickLineBusiness = (e) => {
    if (lineBusiness.includes(e)) {
      const listtemp = lineBusiness.indexOf(e);
      const list = [...lineBusiness];
      list.splice(listtemp, 1);
      setLineBusiness(list);
    } else {
      setLineBusiness([...lineBusiness, e]);
    }
  };
  const onClickPaymentMethod = (e) => {
    if (paymentMethod.includes(e)) {
      const listtemp = paymentMethod.indexOf(e);
      const list = [...paymentMethod];
      list.splice(listtemp, 1);
      setPaymentMethod(list);
    } else {
      setPaymentMethod([...paymentMethod, e]);
    }
  };

  const [gstNoErr, setGstNoErr] = useState(false);
  const handleGST = (event) => {
    setGstID(event);
    let regex = new RegExp(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
    );
    // Return true if the GST_CODE
    // matched the ReGex
    if (regex.test(event) == true) {
      setGstNoErr(false);
      console.log("true");
      // return 'true';
    } else {
      setGstNoErr(true);
      console.log("false");
      // return 'false';
    }
  };

  return (
    <>
      <div className="d-flex as-jb w-100 mt-5 res-flex gap-5">
        {croper && (
          <ImageCroper
            toggleImagePopup={toggleImagePopup}
            setCropImage={setCropImage}
          />
        )}
        <div className="w-100">
          <div className="d-flex flex-wrap ac-jb flex-m-r px-md-4">
            <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
                Legal Entity Name*
              </p>
              <input
                placeholder="Required Field"
                value={name}
                onChange={(e) => setname(e.target.value)}
                className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              />
              {name.length == 0 && nameErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Legal Entity Name</p>
                </div>
              )}
            </div>
            <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Trade Name*
              </p>
              <input
                placeholder="Required Field"
                value={Tradename}
                onChange={(e) => setTradename(e.target.value)}
                className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              />
              {Tradename?.length == 0 && TradenameErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Trade Name </p>
                </div>
              )}
            </div>
            {/* <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
                Vendor Category*
              </p>
              <div className="position-relative"
              >
                <input
                  placeholder="Sellect Category"
                  type='text'
                  value={vendorCatogory}
                  onClick={() =>
                    setVendorCatShow(!vendorCatShow)
                  }
                  className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() =>
                    setVendorCatShow(!vendorCatShow)
                  }
                >
                  <KeyboardArrowDownIcon />
                </button>
                {vendorCatShow && (
                  <div
                    className="invisible-cont2 z-0"
                    onClick={() => setVendorCatShow(false)}
                  />
                )} */}
            {/* <div
                  className={`${vendorCatShow && "submenu_1 h-auto"
                    } submenu_cont_1 overflow-scroll z-3`}
                >
                  {vendor_category?.map((item, ind) => {
                    return (
                      <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                        onClick={() => {
                          onClickVendorCat(item?.list)
                        }}
                      >
                        <button
                          className="px-2 cust-btn text-start py-1 w-100 "
                        >
                          {item?.list}
                        </button>
                        <button
                          className="px-2 cust-btn text-start"
                        >
                          {vendorCatogory.includes(item?.list) ? item?.check : item.uncheck}
                        </button>
                      </button>
                    );
                  })}
                </div>
              </div>
            </div> */}
            <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Line of Business*
              </p>
              <div className="position-relative">
                <input
                  placeholder="Line of Business"
                  type="text"
                  value={
                    lineBusiness?.length > 0
                      ? lineBusiness?.toString()
                      : "Select Line of Business"
                  }
                  onClick={() => {
                    setLineBusinessShow(!lineBusinessShow);
                    setVendorCatShow(false);
                    setDropDownToggle(undefined);
                    setPaymentMethodShow(false);
                    setShowSub(true);
                  }}
                  className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setShowSub(true);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
                {showSub && (
                  <div
                    className="invisible-cont2 z-0"
                    onClick={() => setShowSub(false)}
                  />
                )}
                <div
                  className={`${
                    showSub && "submenu_1 h-auto py-2"
                  } submenu_cont_1 overflow-scroll z-3`}
                >
                  <div className="d-flex gap-1">
                    <input
                      className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                      placeholder="Search..."
                      onChange={(e) => searchSub(e.target.value)}
                      value={searchValueSub}
                    />
                    <button
                      className="border-0 bg-transparent"
                      onClick={() => {
                        setSelectALLSub(!selectALLSub);
                        allSelectSub();
                      }}
                    >
                      All
                    </button>
                  </div>
                  {subDepartmentList?.map((item, ind) => {
                    return (
                      <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb">
                        <button
                          className="px-2 cust-btn text-start py-1 w-100 "
                          onClick={() => {
                            onSubSelect(item);
                          }}
                        >
                          {item}
                        </button>
                        <button
                          className="px-2 cust-btn text-start"
                          style={{ fontSize: "large" }}
                          onClick={() => {
                            onSubSelect(item, "check");
                          }}
                        >
                          {lineBusiness?.includes(item) ? (
                            <IoIosRadioButtonOn className="primary" />
                          ) : (
                            <IoIosRadioButtonOff className="primary" />
                          )}
                        </button>
                      </button>
                    );
                  })}
                </div>
              </div>

              {lineBusiness?.length == 0 && lineBusinessErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Line of Business </p>
                </div>
              )}
            </div>
            <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Type of Business*
              </p>
              <div className="position-relative">
                <input
                  placeholder="Type of Business"
                  type="text"
                  value={
                    vendorCat?.length > 0
                      ? vendorCat?.toString()
                      : "Select Type of Business"
                  }
                  onClick={() => {
                    setDropDownToggle(1);
                    setVendorCatShow(false);
                    setShowSub(false);
                    setPaymentMethodShow(false);
                    setLineBusinessShow(false);
                  }}
                  className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setDropDownToggle(1);
                    setVendorCatShow(false);
                    setShowSub(false);
                    setPaymentMethodShow(false);
                    setLineBusinessShow(false);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
                {dropDownToggle === 1 && (
                  <div
                    className="invisible-cont2 z-0"
                    onClick={() => setDropDownToggle(undefined)}
                  />
                )}
                <div
                  className={`${
                    dropDownToggle === 1 && "submenu_1 h-auto py-2"
                  } submenu_cont_1 overflow-scroll z-3`}
                >
                  <div className="d-flex gap-1">
                    <input
                      className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                      placeholder="Search..."
                      onChange={(e) => searchTypeBusiness(e.target.value)}
                      value={searchValueTypeBusiness}
                    />
                  </div>
                  {typeBusinessList?.map((item, ind) => {
                    return (
                      <button
                        className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                        onClick={() => {
                          setVendorCat(item);
                          setDropDownToggle(undefined);
                        }}
                      >
                        <button className="px-2 cust-btn text-start py-1 w-100 ">
                          {item}
                        </button>
                      </button>
                    );
                  })}
                </div>
              </div>
              {vendorCat === "others" && (
                <input
                  placeholder="Required Field"
                  className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  onChange={(e) => setVendorCat(e.target.value)}
                />
              )}
              {vendorCat?.length == 0 && vendorCatErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Type of Business </p>
                </div>
              )}
            </div>
            {/* <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Vendor Offering Type*
              </p>

              <div className="position-relative">
                <input
                  placeholder="Vendor Offering Type"
                  type="text"
                  value={
                    vendorOfferType?.length > 0
                      ? vendorOfferType?.toString()
                      : "Select Vendor Offering Type"
                  }
                  onClick={() => {
                    setDropDownToggle(2);
                    setVendorCatShow(false);
                    setShowSub(false);
                    setPaymentMethodShow(false);
                    setLineBusinessShow(false);
                  }}
                  className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setDropDownToggle(2);
                    setVendorCatShow(false);
                    setShowSub(false);
                    setPaymentMethodShow(false);
                    setLineBusinessShow(false);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
                {dropDownToggle === 2 && (
                  <div
                    className="invisible-cont2 z-0"
                    onClick={() => setDropDownToggle(undefined)}
                  />
                )}
                <div
                  className={`${
                    dropDownToggle === 2 && "submenu_1 h-auto py-2"
                  } submenu_cont_1 overflow-scroll z-3`}
                >
                  <div className="d-flex gap-1">
                    <input
                      className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                      placeholder="Search..."
                      onChange={(e) => searchVendorOffering(e.target.value)}
                      value={searchValueOffering}
                    />
                  </div>
                  {Vendorofferty?.map((item, ind) => {
                    return (
                      <button
                        className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                        onClick={() => {
                          setVendorOfferType(item);
                          setDropDownToggle(undefined);
                        }}
                      >
                        <button className="px-2 cust-btn text-start py-1 w-100 ">
                          {item}
                        </button>
                      </button>
                    );
                  })}
                </div>
              </div>
              {vendorOfferType?.length == 0 && vendorOfferTypeErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Vendor Offer Type </p>
                </div>
              )}
            </div> */}

            <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Payment Terms*
              </p>
              <div className="position-relative">
                <input
                  placeholder="Payment Terms"
                  type="text"
                  value={
                    paymentTerms?.length > 0
                      ? paymentTerms?.toString()
                      : "Select Payment Terms"
                  }
                  onClick={() => {
                    setDropDownToggle(4);
                    setVendorCatShow(false);
                    setShowSub(false);
                    setPaymentMethodShow(false);
                    setLineBusinessShow(false);
                  }}
                  className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setDropDownToggle(4);
                    setVendorCatShow(false);
                    setShowSub(false);
                    setPaymentMethodShow(false);
                    setLineBusinessShow(false);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
                {dropDownToggle === 4 && (
                  <div
                    className="invisible-cont2 z-0"
                    onClick={() => setDropDownToggle(undefined)}
                  />
                )}
                <div
                  className={`${
                    dropDownToggle === 4 && "submenu_1 h-auto py-2"
                  } submenu_cont_1 overflow-scroll z-3`}
                >
                  <div className="d-flex gap-1">
                    <input
                      className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                      placeholder="Search..."
                      onChange={(e) => searchTermsPayment(e.target.value)}
                      value={searchValueTermsPay}
                    />
                  </div>
                  {paymentTermsLists?.map((item, ind) => {
                    return (
                      <button
                        className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                        onClick={() => {
                          setPaymentTerms(item);
                          setDropDownToggle(undefined);
                        }}
                      >
                        <button className="px-2 cust-btn text-start py-1 w-100 ">
                          {item}
                        </button>
                      </button>
                    );
                  })}
                </div>
              </div>

              {paymentTerms?.length == 0 && paymentTermsErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Payment Terms </p>
                </div>
              )}
            </div>

            <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Payment Method*
              </p>
              <div className="position-relative">
                <input
                  placeholder="Payment Method"
                  type="text"
                  value={
                    paymentMethod?.length > 0
                      ? paymentMethod?.toString()
                      : "Select Payment Method"
                  }
                  onClick={() => {
                    setLineBusinessShow(!lineBusinessShow);
                    setVendorCatShow(false);
                    setDropDownToggle(undefined);
                    setPaymentMethodShow(false);
                    setShowPayMethod(true);
                  }}
                  className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setShowPayMethod(true);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
                {showPayMethod && (
                  <div
                    className="invisible-cont2 z-0"
                    onClick={() => setShowPayMethod(false)}
                  />
                )}
                <div
                  className={`${
                    showPayMethod && "submenu_1 h-auto py-2"
                  } submenu_cont_1 overflow-scroll z-3`}
                >
                  <div className="d-flex gap-1">
                    <input
                      className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                      placeholder="Search..."
                      onChange={(e) => searchPayMethod(e.target.value)}
                      value={searchValuePaymethod}
                    />
                    <button
                      className="border-0 bg-transparent"
                      onClick={() => {
                        setSelectALLPay(!selectALLPay);
                        allSelectPay();
                      }}
                    >
                      All
                    </button>
                  </div>
                  {paymentsMethodList?.map((item, ind) => {
                    return (
                      <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb">
                        <button
                          className="px-2 cust-btn text-start py-1 w-100 "
                          onClick={() => {
                            onPaySelect(item);
                            setShowPayMethod(false);
                          }}
                        >
                          {item}
                        </button>
                        <button
                          className="px-2 cust-btn text-start"
                          style={{ fontSize: "large" }}
                          onClick={() => {
                            onPaySelect(item);
                          }}
                        >
                          {paymentMethod?.includes(item) ? (
                            <IoIosRadioButtonOn className="primary" />
                          ) : (
                            <IoIosRadioButtonOff className="primary" />
                          )}
                        </button>
                      </button>
                    );
                  })}
                </div>
              </div>

              {paymentMethod?.length == 0 && paymentMethodErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Payment Method </p>
                </div>
              )}
            </div>
            <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                GST Number*
              </p>
              <input
                placeholder="Required Field"
                value={GstID}
                type="text"
                onChange={(e) => handleGST(e.target.value)}
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              {GstID.length == 0 && GstIDErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter GST Number</p>
                </div>
              )}
              {GstID?.length > 0 && gstNoErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Valid GST Number</p>
                </div>
              )}
            </div>

            <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
              <div className="w-100 ac-jb d-flex">
                <div className="w-100">
                  <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                    Address Line 1*
                  </p>
                  <input
                    placeholder="Required Field"
                    value={address1}
                    onChange={(e) => setAddress1(e.target.value)}
                    className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  />
                  {address1?.length == 0 && address1Err && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Enter Address 1</p>
                    </div>
                  )}
                </div>
                {/* <div className="w-35">
              <div className="w-100">
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Address Type
                </p>
                <select
                  placeholder="Required Field"
                  className="w-100 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3 mt-2"
                  name=""
                  id=""
                >
                  <option>Shipping</option>
                  <option>Billing</option>
                  <option>Both</option>
                </select>
              </div>
            </div> */}
              </div>
            </div>
            <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Address Line 2
              </p>
              <input
                placeholder="Required Field"
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
                className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              />
              {/* <div className="w-35">
                <div className="w-100">
                  <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                    Address Type
                  </p>
                  <select
                    placeholder="Required Field"
                    className="w-100 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3 mt-2"
                    name=""
                    id=""
                  >
                    <option>Shipping</option>
                    <option>Billing</option>
                    <option>Both</option>
                  </select>
                </div>
              </div> */}
              {address2?.length == 0 && address2Err && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Address 2</p>
                </div>
              )}
            </div>
            <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Registered Address Country*
              </p>
              <div className="position-relative">
                <input
                  placeholder="Country"
                  type="text"
                  value={
                    country?.length > 0 ? country?.toString() : "Select Country"
                  }
                  onClick={() => {
                    setDropDownToggle(3);
                    setVendorCatShow(false);
                    setShowSub(false);
                    setPaymentMethodShow(false);
                    setLineBusinessShow(false);
                  }}
                  className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setDropDownToggle(3);
                    setVendorCatShow(false);
                    setShowSub(false);
                    setPaymentMethodShow(false);
                    setLineBusinessShow(false);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
                {dropDownToggle === 3 && (
                  <div
                    className="invisible-cont2 z-0"
                    onClick={() => setDropDownToggle(undefined)}
                  />
                )}
                <div
                  className={`${
                    dropDownToggle === 3 && "submenu_1 h-auto py-2"
                  } submenu_cont_1 overflow-scroll z-3`}
                >
                  <div className="d-flex gap-1">
                    <input
                      className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                      placeholder="Search..."
                      onChange={(e) => searchCountry(e.target.value)}
                      value={searchValueCountry}
                    />
                  </div>
                  {listCountries?.map((item, ind) => {
                    return (
                      <button
                        className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                        onClick={() => {
                          getCountries(item?.name);
                        }}
                      >
                        <button className="px-2 cust-btn text-start py-1 w-100 ">
                          {item?.name}
                        </button>
                      </button>
                    );
                  })}
                </div>
              </div>
              {country?.length == 0 && countryErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">
                    Select Registered Address Country
                  </p>
                </div>
              )}
            </div>
            <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Registered Address State*
              </p>
              <div className="position-relative">
                <input
                  placeholder="State"
                  type="text"
                  value={state?.length > 0 ? state?.toString() : "Select State"}
                  onClick={() => {
                    setDropDownToggle(5);
                    setVendorCatShow(false);
                    setShowSub(false);
                    setPaymentMethodShow(false);
                    setLineBusinessShow(false);
                  }}
                  className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setDropDownToggle(5);
                    setVendorCatShow(false);
                    setShowSub(false);
                    setPaymentMethodShow(false);
                    setLineBusinessShow(false);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
                {dropDownToggle === 5 && (
                  <div
                    className="invisible-cont2 z-0"
                    onClick={() => setDropDownToggle(undefined)}
                  />
                )}
                <div
                  className={`${
                    dropDownToggle === 5 && "submenu_1 h-auto py-2"
                  } submenu_cont_1 overflow-scroll z-3`}
                >
                  <div className="d-flex gap-1">
                    <input
                      className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                      placeholder="Search..."
                      onChange={(e) => searchStates(e.target.value)}
                      value={searchValueStates}
                    />
                  </div>
                  {listStateNew?.map((item, ind) => {
                    return (
                      <button
                        className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                        onClick={() => {
                          setDropDownToggle(undefined);
                          getStates(item?.name);
                        }}
                      >
                        <button className="px-2 cust-btn text-start py-1 w-100 ">
                          {item?.name}
                        </button>
                      </button>
                    );
                  })}
                </div>
              </div>

              {state?.length == 0 && stateErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">
                    Select Registered Address Stat
                  </p>
                </div>
              )}
            </div>

            <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Registered Address City*
              </p>
              <div className="position-relative">
                <input
                  placeholder="City"
                  type="text"
                  value={city?.length > 0 ? city?.toString() : "Select City"}
                  onClick={() => {
                    setDropDownToggle(6);
                    setVendorCatShow(false);
                    setShowSub(false);
                    setPaymentMethodShow(false);
                    setLineBusinessShow(false);
                  }}
                  className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setDropDownToggle(6);
                    setVendorCatShow(false);
                    setShowSub(false);
                    setPaymentMethodShow(false);
                    setLineBusinessShow(false);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
                {dropDownToggle === 6 && (
                  <div
                    className="invisible-cont2 z-0"
                    onClick={() => setDropDownToggle(undefined)}
                  />
                )}
                <div
                  className={`${
                    dropDownToggle === 6 && "submenu_1 h-auto py-2"
                  } submenu_cont_1 overflow-scroll z-3`}
                >
                  <div className="d-flex gap-1">
                    <input
                      className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                      placeholder="Search..."
                      onChange={(e) => searchCities(e.target.value)}
                      value={searchValueCities}
                    />
                  </div>
                  {listCityNew?.map((item, ind) => {
                    return (
                      <button
                        className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                        onClick={() => {
                          setDropDownToggle(undefined);
                          setCity(item?.name);
                        }}
                      >
                        <button className="px-2 cust-btn text-start py-1 w-100 ">
                          {item?.name}
                        </button>
                      </button>
                    );
                  })}
                </div>
              </div>
              {city?.length == 0 && cityErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">
                    Select Registered Address City
                  </p>
                </div>
              )}
            </div>
            <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Registered Pincode*
              </p>
              <input
                placeholder="Required Field"
                value={pincode}
                type={"number"}
                onChange={(e) => setPincode(e.target.value)}
                className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              />
              {pincode?.length == 0 && pincodeErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Pincode</p>
                </div>
              )}
            </div>

            <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Currency*
              </p>

              <div className="position-relative">
                <input
                  placeholder="Currency"
                  type="text"
                  value={
                    currencyName?.length > 0
                      ? currencyName?.toString()
                      : "Select Currency"
                  }
                  onClick={() => {
                    setDropDownToggle(10);
                    setVendorCatShow(false);
                    setShowSub(false);
                    setPaymentMethodShow(false);
                    setLineBusinessShow(false);
                  }}
                  className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setDropDownToggle(10);
                    setVendorCatShow(false);
                    setShowSub(false);
                    setPaymentMethodShow(false);
                    setLineBusinessShow(false);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
                {dropDownToggle === 10 && (
                  <div
                    className="invisible-cont2 z-0"
                    onClick={() => setDropDownToggle(undefined)}
                  />
                )}
                <div
                  className={`${
                    dropDownToggle === 10 && "submenu_1 h-auto py-2"
                  } submenu_cont_1 overflow-scroll z-3`}
                >
                  <div className="d-flex gap-1">
                    <input
                      className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                      placeholder="Search..."
                      onChange={(e) => searchCurrency(e.target.value)}
                      value={searchValueCurrency}
                    />
                  </div>
                  {listCurrency?.map((item, ind) => {
                    return (
                      <button
                        className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                        onClick={() => {
                          setDropDownToggle(undefined);
                          setCurrency(item?.id);
                          setCurrencyName(item?.code);
                        }}
                      >
                        <button className="px-2 cust-btn text-start py-1 w-100 ">
                          {item?.code}
                        </button>
                      </button>
                    );
                  })}
                </div>
              </div>

              {currency?.length == 0 && currencyErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select Currency</p>
                </div>
              )}
            </div>
            {/* <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Tags
            </p>
            <input
              placeholder="Required Field"
              value=""
              className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div> */}

            {/* <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Logo Image
              </p>
              <input
                type="file"
                onChange={(e) => {
                  console.log(e.target.files[0]);
                }}
                className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              />
            </div>
            <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Banner Image
              </p>
              <input
                type="file"
                onChange={(e) => {
                  console.log(e.target.files[0]);
                }}
                className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              />
            </div> */}

            {/* <div className="d-flex flex-wrap w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 mt-4 ac-jc">
              <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 d-flex ac-jc ">
                <div className="w-100">
                  <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-10">
                    <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                      Logo Image
                    </p>
                    <label className="w-100 mt-2 mb-3" onClick={() => dualToggele()}>
                      <div className="py-1 w-100">
                        <div className="d-flex ac-jb w-100">
                          <p className="editBtn rounded-3 cp border-2 text-nowrap py-1 px-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">{croperImage?.length === 0 ? 'Browse or Drag a file' : 'Image Uploded'}</p>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 d-flex ac-jc">
                <div className="w-100">
                  <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                    <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                      Banner Image
                    </p>
                    <label className="w-100 mt-2 mb-3" onClick={() => dualToggele('banner')}>
                      <div className="py-1 w-100">
                        <div className="d-flex ac-jb w-100">
                          <p className="editBtn rounded-3 cp border-2 text-nowrap py-1 px-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">{croperImage?.length === 0 ? 'Browse or Drag a file' : 'Image Uploded'}</p>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {croper && (
              <ImageCroper
                toggleImagePopup={toggleImagePopup}
                setCropImage={setCropImage}
              />
            )} */}

            <div
              className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100"
              // onClick={() => dualToggele("ban")}
            >
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Banner Image (8000 * 2550 px)
              </p>
              <input
                style={{ cursor: "pointer" }}
                type={"file"}
                // value={
                //   banner
                //     ? "Banner Uploaded Successfully"
                //     : "Browse or Drag a file"
                // }
                onChange={(e) => setBanner(e.target.files[0])}
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              />
              {banner?.length == 0 && bannerErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Upload Banner</p>
                </div>
              )}
              {banner ? "Banner uploaded successfully" : ""}
            </div>

            <div
              className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100"
              onClick={() => dualToggele("logo")}
            >
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Logo Image(180 * 180 px)
              </p>
              <input
                style={{ cursor: "pointer" }}
                value={
                  logo ? "Logo Uploaded Successfully" : "Browse or Drag a file"
                }
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              />
              {logo?.length == 0 && logoErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Upload Logo</p>
                </div>
              )}
            </div>

            <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Profile Description*
              </p>
              <textarea
                value={description}
                placeholder="Required Field"
                onChange={(e) => setDescription(e.target.value)}
                className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              />
              {description?.length == 0 && descriptionErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Description</p>
                </div>
              )}
            </div>

            {/* <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Profile Description
            </p>
            <textarea
              value="Description"
              className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div> */}
          </div>
        </div>
      </div>
      <div className="d-flex flex-wrap ac-jb flex-m-r px-md-4">
        <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Primary Name*
          </p>
          <input
            disabled
            placeholder=""
            value={firstname}
            className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Primary Email*
          </p>
          <input
            disabled
            placeholder=""
            value={email}
            className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Primary Phone Number*
          </p>
          <input
            disabled
            placeholder=""
            value={mobile}
            className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Vendor Offerings*
          </p>
          <input
            placeholder="Required Field"
            value={
              vendorOfferType == 1
                ? "Product"
                : vendorOfferType == 2
                ? "Services"
                : vendorOfferType == 3
                ? "Both"
                : null
            }
            type="text"
            disabled
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
          />
        </div>
        {/* <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Password
          </p>
          <input
            placeholder="Required Field"
            value={password}
            onChange={(e) => handlePassword(e)}
            className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            onKeyDown={keydownHandler}
          />
          {password?.length == 0 && passwordErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter password</p>
            </div>
          )}
          {password?.length > 0 && passwordStringerr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">{passwordStringerr}</p>
            </div>
          )}
        </div>
        <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Confirm Password
          </p>
          <input
            placeholder="Required Field"
            value={confirmPassword}
            onChange={(e) => setConfirmPass(e.target.value)}
            className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            onKeyDown={keydownHandler}
          />
          {confirmPassword?.length == 0 && confirmPasswordErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Confirm password</p>
            </div>
          )}
          {confirmPassword?.length > 0 && password != confirmPassword && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">
                Confirm Password Must Be Password
              </p>
            </div>
          )}
        </div> */}
      </div>
    </>
  );
};

export default AddDet3;
