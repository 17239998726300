import { animatedImg, searchIcon } from "../assets/img";
import { Search } from "@mui/icons-material";
import { selecLibList } from "../redux/api/DummyJson";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useVendorLibraryEditMutation,
  useVendorLibraryListMutation,
} from "../redux/api/api";
import { useEffect, useRef, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditLibraryVendor from "../components/Popup/EditLibraryVendor";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";
import "bootstrap/js/src/modal";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const ViewLibraryList = () => {
  const [types, setTypes] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [popupType, setPopupType] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [list, setList] = useState([]);
  const [lists, setLists] = useState([]);
  const [search, setSearch] = useState("");
  const [selectitem, setSelectItem] = useState([]);
  const dispatch = useDispatch();

  const [image, setImage] = useState("");
  const [imageErr, setImageErr] = useState(false);
  const [imageTitle, setImageTitle] = useState("");
  const [imageTitleErr, setImageTitleErr] = useState(false);
  const [libId, setLibId] = useState();

  const vendors = JSON.parse(localStorage.getItem("user"));

  const [typesErr, setTypesErr] = useState(false);
  const [btn, setBtn] = useState(false);
  // console.log(location);

  const toggleShowPopup = (item) => {
    console.log("toggleShowPopup", item);
    setShowPopup(!showPopup);
    setSelectItem(item);
    setImage(item?.file_url);
    setImageTitle(item?.file_title);
    setLibId(item?.id);
  };

  // RTK QUERY
  const [vendorLibarayListApi] = useVendorLibraryListMutation();
  const [editLibraryVendor] = useVendorLibraryEditMutation();

  // VENDOR LIBRARY LIST
  const getLibraryList = (id, type) => {
    let formdata = new FormData();
    formdata.append("type", type == 0 ? "vendor" : type == 1 ? "product" : "");
    formdata.append("vendor_id", id);
    vendorLibarayListApi(formdata)
      .unwrap()
      .then((res) => {
        setList(res?.libraries);
        setLists(res?.libraries);
        console.log("ressss", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (location?.state?.vendorID) {
      getLibraryList(location?.state?.vendorID, location?.state?.type);
    }

    setTypes(location?.state?.type);
  }, []);

  // GET SEARCH
  const getSearch = (event) => {
    setSearch(event);
    if (event?.length > 0) {
      let filter = lists?.filter((i) =>
        i?.file_title?.toLowerCase().includes(event?.toLowerCase())
      );
      setList(filter);
    } else {
      setList(lists);
    }
  };

  // useEffect(() => {

  //     setImage(datas?.file_url)
  //     setImageTitle(datas?.file_title)
  //     setTypes(datas?.id)
  // }, [showPopup])

  const Submit = () => {
    // console.log('datas', );
    let formdata = new FormData();
    if (image?.name) {
      formdata.append("file", image);
    }
    formdata.append("library_id", libId);
    formdata.append("vendor_id", vendors?.vendor?.id);
    formdata.append("file_title", imageTitle);
    formdata.append("is_product_image", types);
    formdata.append("is_admin_image", 2);

    setBtn(true);
    dispatch(saveLoader(true));
    editLibraryVendor(formdata)
      .unwrap()
      .then((res) => {
        setBtn(false);
        dispatch(saveLoader(false));
        console.log("reslist", res);
        setShowPopup(false);
        setImage("");
        setImageTitle("");
        setLibId("");
        setTypes(location?.state?.type);
        getLibraryList(vendors?.vendor?.id, location?.state?.type);
      })
      .catch((err) => {
        setBtn(false);
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  console.log("types", types);

  return (
    <>
      {showPopup && (
        <EditLibraryVendor
          toggleShowPopup={toggleShowPopup}
          showPopup={showPopup}
          datas={selectitem}
          vendorID={location?.state?.vendorID}
          Submit={Submit}
          imageTitle={imageTitle}
          image={image}
          setImage={setImage}
          setImageTitle={setImageTitle}
          setTypes={setTypes}
          btn={btn}
          types={types}
        />
      )}
      <div className="dashRightView p-5">
        <div className="d-flex w-100 searchField p-2">
          <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
          <input
            className="cust-btn ms-4 w-100"
            placeholder="Enter Project Name, Client Name, number or PO# to search"
          />
          <img src={animatedImg} className="searchManImg" />
        </div>
        <div className="d-flex justify-content-between ac-jb  w-100 mt-5">
          <div className="w-100 d-flex align-items-center primary">
            <KeyboardBackspaceIcon
              className="me-2"
              onClick={() => navigate("/vendor-details", { state: { tab: 2 } })}
            />
            <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary text-nowrap">
              {location?.state?.type == 0
                ? "Vendor"
                : location?.state?.type == 1
                ? "Product"
                : ""}{" "}
              Library List
            </p>
          </div>
          <div className="d-flex justify-content-end  flex-wrap  w-100">
            <div className="d-flex justify-content-end">
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont px-4 mt-2">
                Total Count :{lists?.length}
              </p>
            </div>
            <div className="d-flex flex-md-row flex-column">
              <div className="d-flex search_new_design mx-2">
                <img src={searchIcon} className="searchiConImg" />
                <input
                  className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 w-100"
                  placeholder="Search"
                  onChange={(e) => getSearch(e.target.value)}
                  value={search}
                />
              </div>
              <button
                onClick={() =>
                  navigate("/vendor-details", { state: { tab: 2 } })
                }
                className="cust-btn addbtn  ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
              >
                + Add Library
              </button>
            </div>
          </div>
        </div>

        <div className="d-flex flex-wrap">
          {list?.map((item) => {
            return (
              <div
                className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
                // onClick={() => navigate('/add-new-product/products/product-detail')}
              >
                <div className="prod-list-box">
                  <div className="cust-btn d-flex ac-jc rounded-3">
                    {item?.file_url?.endsWith(".pdf") ? (
                      <iframe
                        src={item?.file_url}
                        data-bs-toggle="modal"
                        data-bs-target="#imageExample"
                        className="custom-w-h rounded-3"
                      />
                    ) : (
                      <img
                        src={item?.file_url}
                        alt="Image"
                        data-bs-toggle="modal"
                        data-bs-target="#imageExample"
                        className="custom-w-h rounded-3"
                      />
                    )}
                    <div
                      className="modal fade"
                      id="imageExample"
                      tabIndex="-1"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-body">
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                            <img src={item?.file_url} className="w-100" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex ac-jb">
                    <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
                      {item?.file_title}
                    </p>
                    <p
                      className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2 primary pointer"
                      onClick={() => toggleShowPopup(item)}
                    >
                      <EditIcon style={{ fontSize: "14px" }} />
                    </p>
                  </div>
                  {/* <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
                  {item?.description}
                </p>
                <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
                  {item?.price}
                </p>
                <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">
                  {item?.data}
                </p> */}
                </div>
              </div>
            );
          })}
        </div>
        <div className="w-90 ac-jb flex-md-row flex-column d-flex mt-5">
          <button
            // onClick={() => {
            //   path === "decorative"
            //     ? navigate("/decorative-vendor", {
            //         state: { type: "library" },
            //       })
            //     : navigate("/vendor-details", {
            //         state: { type: "library" },
            //       });
            // }}x`x`x
            onClick={() => navigate("/vendor-details", { state: { tab: 2 } })}
            className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
          >
            Back
          </button>
          {location?.state?.type === "edit" && (
            <button className=" cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
              Update Details
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewLibraryList;
