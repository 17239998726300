import { animatedImg, empty_profile, noLogo } from "../../assets/img";
import { Search } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import LinkIcon from "@mui/icons-material/Link";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { useEffect, useState } from "react";
import AddNotes from "./AddNotes";
import DownloadIcon from "@mui/icons-material/Download";
import PushPinIcon from "@mui/icons-material/PushPin";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLazyThreadListQuery } from "../../redux/api/api";
import moment from "moment/moment";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";

const ViewThread = () => {
  const [reply, setReply] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const history_data = location?.state?.history_data;
  const [list, setList] = useState({});

  // RTK QUERY
  const [threadListApi] = useLazyThreadListQuery();

  const getThreadList = () => {
    threadListApi(history_data?.id)
      .unwrap()
      .then((res) => {
        let temp = res?.threads?.[0];
        setList(temp);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (history_data?.id) {
      getThreadList();
    }
  }, []);

  return (
    <div className="dashRightView p-5">
      <div className="d-flex w-100 searchField p-2">
        <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
        <input
          className="cust-btn ms-4 w-100"
          placeholder="Enter Project Name, Client Name, number or PO# to search"
        />
        <img src={animatedImg} className="searchManImg" />
      </div>
      <div className="d-flex flex-md-row flex-column mt-5 w-100">
        <div className="w-lg-50 w-md-100 w-sm-100 w-xs-100 dailyStatus mt-4 rounded-3">
          <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            Profile Post Details
          </p>
        </div>
      </div>
      <div className="w-100 ac-jc mt-5">
        <div className="w-100 d-flex  ac-jc gap-3">
          <div>
            <img src={empty_profile} className="replyImg rounded-3" />
          </div>
          <div className="w-100">
            <p className="primary f3 fs-xs-10 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18">
              {list?.vendor?.name}{" "}
              {/* <span className="primary1 f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Hourglass Essentials Pvt. Ltd.
              </span> */}
            </p>
            <p className="primary1 f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              {list?.vendor?.name} added the vendor account{" "}
              <span className="text-black f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                {list?.contact?.first_name}
              </span>
            </p>
            <p className="primary1 f3 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
              Tagged in this post.
            </p>
          </div>
        </div>
        <div className="mt-3 w-xl-100 w-lg-100 w-xs-100 w-sm-100 d-flex ac-jb">
          <div onClick={() => setReply(true)} className="d-flex pointerView">
            <ChatBubbleOutlineIcon className="black f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
            <p className="black f2 ms-1 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
              Reply
            </p>
          </div>
          <div onClick={() => setReply(true)} className="d-flex pointerView">
            <DownloadIcon className="black f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
            <p className="black f2 ms-1 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
              Download
            </p>
          </div>
          <div onClick={() => setReply(true)} className="d-flex pointerView">
            <DeleteIcon className="black f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
            <p className="black f2 ms-1 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
              Delete
            </p>
          </div>
        </div>
        <div className="">
          <p className="primary f2 ms-1 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
            {list?.remind_slot ? moment(list?.remind_slot).format("LLL") : "-"}
          </p>
        </div>
      </div>
      <AddNotes type="thread" />

      <div className="w-100 replyPaddings mt-1">
        {list?.child?.map((item, index) => {
          return (
            <div className=" w-xl-100 w-lg-100 w-md-90 w-sm-100 w-xs-100 d-grid ac-jc mt-5">
              <div className="w-100 d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row ac-jc gap-3">
                <div>
                  <img
                    src={item?.file_urls ? item?.file_urls : noLogo}
                    className="replyImg rounded-3"
                  />
                </div>
                <div>
                  <p className="primary f3 fs-xs-10 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18">
                    {item?.vendor?.name}{" "}
                    {/* <span className="primary1 f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                    {item?.name}{" "}
                    </span> */}
                  </p>
                  <p className="primary1 f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                    <span className="text-black f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                      {item?.vendor?.name}
                    </span>{" "}
                    added the vendor account to{" "}
                    <span className="text-black f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                      {item?.vendor?.legal_name}
                    </span>{" "}
                    for {item?.contact?.first_name}
                  </p>
                  <p className="primary1 f3 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Tagged in this post.
                  </p>
                </div>
              </div>
              {/* <div className="mt-3 w-xl-100 w-lg-100 w-xs-100 w-sm-100 d-flex ac-jb">
              <p className="primary f3 fs-xs-10 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18">
                    Reminder for  {item?.remind?.first_name}{" "} on  {" "}
                    <span className="primary f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                    {item?.remind_slot}
                    </span>
                  </p>
                </div> */}
              <div className="mt-3 w-xl-100 w-lg-100 w-xs-100 w-sm-100 d-flex ac-jb">
                <div
                  // onClick={() => {
                  //   handleReply(index);
                  //   setRepIndex(index);
                  //   setNoteIndex(null);
                  // }}
                  className="d-flex pointerView"
                >
                  <ChatBubbleOutlineIcon className="black f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
                  <p className="black f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Reply
                  </p>
                </div>
                <div
                  // onClick={() => {
                  //   setNoteIndex(index);
                  //   handleNotes(index);
                  //   setRepIndex(null);
                  // }}
                  className="d-flex pointerView"
                >
                  <NoteAddOutlinedIcon className="black f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
                  <p className="black f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Add Note
                  </p>
                </div>
                <div
                  onClick={() =>
                    navigate("/view-thread", { state: { history_data: item } })
                  }
                  className="d-flex pointerView"
                >
                  <LinkIcon className="black linkImg f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
                  <p className="black f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Full thread
                  </p>
                </div>
                <div className="d-flex pointerView">
                  <DownloadIcon className="black f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
                  <p className="black f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Download
                  </p>
                </div>
                {/* <div className="d-flex pointerView">
                  <DeleteIcon className="black f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
                  <p className="black f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Delete
                  </p>
                </div> */}
              </div>
              <div className="mt-2">
                <p className="primary1 f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Reminder for{" "}
                  <span className="primary f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                    {" "}
                    {item?.remind?.first_name}
                  </span>{" "}
                  on{" "}
                  <span className="primary f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                    {item?.remind_slot}
                  </span>
                </p>
              </div>
              {/* {repIndex === index && reply && (
                <div className="w-100 mt-2">
                  <div className="d-flex ac-je">
                    <textarea
                      className="editBtn rounded-3 p-2 w-90"
                      placeholder="Type here..."
                    />
                    <div className="mt-4 ms-1">
                      <SendIcon className="pointerView primary f2 fs-xs-17 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21 fs-xxl-22" />
                    </div>
                  </div>
                </div>
              )} */}
              {/* {noteIndex === index && openNotes && (
                <AddNotes
                  type="thread"
                  setOpenNotes={setOpenNotes}
                  index={index}
                  item={item}
                  setNoteIndex={setNoteIndex}
                  getNotesList={getNotesList}
                  setNote={setNote}
                />
              )} */}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ViewThread;
