import React, { useState } from "react";
import { product, searchIcon } from "../../assets/img";
import CachedIcon from "@mui/icons-material/Cached";
import { Search } from "@mui/icons-material";
import UploadIcon from "@mui/icons-material/Upload";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import GetAppIcon from "@mui/icons-material/GetApp";
import {
  useLazyProductSuggestionViewQuery,
  useProductSuggestionEditMutation,
  useProductSuggestionListMutation,
  useProductSuggestionViewQuery,
} from "../../redux/api/api";
import VendorProductView from "./vendorProductView";

const NewProductSuggestion = ({
  place,
  handleUploadHandler,
  places,
  location,
  list,
  pagination,
  getProduct,
  btn,
  suggestStatus,
  suggestSearch,
  newProPage,
}) => {
  const [popView, setPopView] = useState(false);

  const vendors = JSON.parse(localStorage.getItem("user"));
  // const [pagination,setPagination]=useState({});
  // const[list,setList]=useState([]);
  const [productId, setProductId] = useState("");
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");

  const [price, setPrice] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImages] = useState("");

  //Err
  const [priceErr, setPriceErr] = useState(false);
  const [titleErr, setTitleErr] = useState(false);
  const [imageErr, setImageErr] = useState(false);

  //RTK

  const [ProductSuggestionApi] = useProductSuggestionListMutation();
  const [ProductEditApi] = useProductSuggestionEditMutation();
  const [ProductViewApi] = useLazyProductSuggestionViewQuery();
  //   popUp upload function
  const EditHandelor = (e) => {
    // console.log(e, "e");
    if (title?.length == "" || image?.length == "" || price?.length == "") {
      setPriceErr(true);
      setTitleErr(true);
      setImageErr(true);
    } else {
      const formData = new FormData();
      if (image?.name) {
        formData.append("image", image);
      }
      formData.append("vendor_id", vendors?.vendor?.id);
      formData.append("product_suggestion_id", productId);
      formData.append("name", title);
      formData.append("total_amount", price);
      ProductEditApi(formData)
        .unwrap()
        .then((res) => {
          setPopView(!popView);
          getProduct();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getView = (id) => {
    ProductViewApi(id)
      .unwrap()
      .then((res) => {
        console.log(res);
        const data = res?.decor_price;
        setPrice(data?.total_amount);
        setImages(data?.image_url);
        setTitle(data?.name);
        setStatus(data?.status);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //   close popView
  const handleClose = () => {
    setPopView(!popView);
  };

  return (
    <div>
      <div className="mt-5 banner-left">
        <div className="d-flex ac-jb flex-column flex-md-row">
          <div className="d-flex gap-3">
            {/* <select className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3">
              <option hidden>Select Categories</option>
              <option>Mobile Phone</option>
              <option>Water Bottle</option>
            </select> */}
            <select
              className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3"
              onChange={(e) =>
                getProduct(e.target.value, suggestSearch, newProPage)
              }
              value={suggestStatus}
            >
              <option value={""} hidden>
                Select Status
              </option>
              <option value={"1"}>Requested </option>
              <option value={"2"}>Inprogress</option>
              <option value={"3"}>Accepted</option>
              <option value={"4"}>Rejected</option>
            </select>
          </div>
          <div className="d-flex ac-jc place-holde justify-content-md-end gap-3 flex-wrap w-100 w-md-90 mb-md-0  mb-4">
            <button className="cust-btn addbtn2   gray f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
              <GetAppIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
              Export
            </button>
            <div className="d-flex mt-sm-3 border-search">
              <img src={searchIcon} alt="img" className="searchiConImg mt-1" />
              <input
                className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                placeholder="Search"
                onChange={(e) =>
                  getProduct(suggestStatus, e.target.value, newProPage)
                }
                value={suggestSearch}
              />
            </div>
            <div className="d-flex ac-jc gap-3">
              {/* <button
                // onClick={() => navigate("/add-vendor")}
                className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
              >
                <Search />
              </button> */}
              <button
                // onClick={() => navigate("/add-vendor")}
                onClick={(e) => getProduct("", "", "", "")}
                className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
              >
                <CachedIcon />
              </button>
            </div>{" "}
          </div>
        </div>
      </div>
      <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select
            className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary"
            onChange={(e) =>
              getProduct(suggestStatus, suggestSearch, e.target.value)
            }
            value={newProPage}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont">
            Total Count : {pagination?.total}
          </p>
        </div>
      </div>

      <div className="w-100">
        <div className=" d-flex ac-js flex-wrap mt-5">
          {Array.isArray(list) &&
            list.map((item, ind) => {
              return (
                <div
                  className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2 cp position-relative"
                  onClick={() => {
                    setPopView(!popView);
                    setProductId(item?.id);
                    getView(item?.id);
                  }}
                >
                  <div className="prod-list-box">
                    <div className="cust-btn d-flex ac-jc rounded-3">
                      <img
                        src={item?.image_url ? item?.image_url : product}
                        alt=""
                        className="custom-w-h rounded-3"
                      />
                    </div>
                    <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark mb-2 text-custom-res">
                      {item?.name}
                    </p>
                    <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2 primary1 text-custom-res">
                      {item?.category?.name}
                    </p>
                    <p>
                      {item?.status == 1 ? (
                        <p className="f4 fs-11 fs-sm-12 text-custom-res fs-lg-13 parag mb-0 request-text">
                          Request{" "}
                        </p>
                      ) : item?.status == 2 ? (
                        <p className="f4 fs-11 fs-sm-12 text-custom-res fs-lg-13 parag mb-0 Inprocess-text">
                          Inprogress
                        </p>
                      ) : item?.status == 3 ? (
                        <p className="f4 fs-11 fs-sm-12 text-custom-res fs-lg-13 parag mb-0 Accept-text">
                          Accepted
                        </p>
                      ) : item?.status == 4 ? (
                        <p className="f4 fs-11 fs-sm-12 text-custom-res fs-lg-13 parag mb-0 text-danger">
                          Rejected
                          <OverlayTrigger
                            delay={{ hide: 450, show: 300 }}
                            overlay={(props) => (
                              <Tooltip {...props}>
                                {/* Product Rejected reason will show here */}
                                {item?.notes}
                              </Tooltip>
                            )}
                            placement="bottom"
                          >
                            <InfoOutlinedIcon
                              className="fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-1 parag mt-1"
                              style={{ color: "#06679c" }}
                            />
                          </OverlayTrigger>
                        </p>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                </div>
              );
            })}
          {/* <div
            className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2 cp position-relative"
            onClick={() => handleUploadHandler("view")}
          >
            <div className="prod-list-box">
              <div className="cust-btn d-flex ac-jc rounded-3">
                <img src={product} alt="" className="custom-w-h rounded-3" />
              </div>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark mb-2 text-custom-res">
                {place}
              </p>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2 primary1 text-custom-res">
                Mobile Phone
              </p>
              <p className="f4 fs-11 fs-sm-12 text-custom-res fs-lg-13 parag mb-0 Inprocess-text">
                In Process
              </p>
            </div>
          </div> */}
          {/* <div
            className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2 cp position-relative"
            onClick={() => handleUploadHandler("view")}
          >
            <div className="prod-list-box">
              <div className="cust-btn d-flex ac-jc rounded-3">
                <img src={product} alt="" className="custom-w-h rounded-3" />
              </div>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark mb-2 text-custom-res">
                {place}
              </p>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2 primary1 text-custom-res">
                Mobile Phone
              </p>
              <p className="f4 fs-11 fs-sm-12 text-custom-res fs-lg-13 parag mb-0 request-text">
                Request
              </p>
            </div>
          </div> */}
          {/* <div
            className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2 cp position-relative"
            onClick={() => handleUploadHandler("view")}
          >
            <div className="prod-list-box">
              <div className="cust-btn d-flex ac-jc rounded-3">
                <img src={product} alt="" className="custom-w-h rounded-3" />
              </div>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark mb-2 text-custom-res">
                {place}
              </p>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2 primary1 text-custom-res">
                Mobile Phone
              </p>
              <p className="f4 fs-11 fs-sm-12 text-custom-res fs-lg-13 parag mb-0 Accept-text">
                Accepted
              </p>
            </div>
          </div> */}
          {/* <div
            className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2 cp position-relative"
            onClick={() => handleUploadHandler("view")}
          >
            <div className="prod-list-box">
              <div className="cust-btn d-flex ac-jc rounded-3">
                <img src={product} alt="" className="custom-w-h rounded-3" />
              </div>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark mb-2 text-custom-res">
                {place}
              </p>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2 primary1 text-custom-res">
                Mobile Phone
              </p>
              <div className="d-flex">
                <p className="f4 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 mt-2 parag mb-0 Regected-text">
                  Rejected
                  <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props) => (
                      <Tooltip {...props}>
                        Product Rejected reason will show here
                      </Tooltip>
                    )}
                    placement="bottom"
                  >
                    <InfoOutlinedIcon
                      className="fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-1 parag"
                      style={{ color: "#06679c" }}
                    />
                  </OverlayTrigger>
                </p>
              </div>
            </div>
            <div className="d-flex mt-4 ms-1 position-absolute top-0 end-0 px-4"></div>
      {popView && <VendorProductView handleClose={handleClose} price={price} priceErr={priceErr} setPrice={setPrice} image={image} setImages={setImages}
          </div> */}
        </div>
      </div>
      {popView && (
        <VendorProductView
          handleClose={handleClose}
          price={price}
          priceErr={priceErr}
          setPrice={setPrice}
          image={image}
          setImages={setImages}
          imageErr={imageErr}
          title={title}
          setTitle={setTitle}
          titleErr={titleErr}
          EditHandelor={EditHandelor}
          status={status}
        />
      )}
    </div>
  );
};

export default NewProductSuggestion;
